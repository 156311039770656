<td-dialog-shell
  [dialogTitle]="dialogData.dialogTitle | translate"
  [actionButtonName]="'AUTH_LIB.ACCESS_TOKEN.REVOKE' | translate"
  actionButtonColor="negative"
  (actionHit)="callRevoke()"
  (cancelHit)="onCancel()"
>
  <p class="mat-body-1" translate>{{ dialogData.dialogMessage }}</p>
  <p class="no-top-spacing mat-body-1" translate>AUTH_LIB.CAN_NOT_UNDO</p>
</td-dialog-shell>
