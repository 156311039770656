import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { MatIconRegistry, MatIconModule } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({
  imports: [HttpClientModule],
  providers: [MatIconModule],
})
export class IconModule {
  constructor(
    private readonly _iconRegistry: MatIconRegistry,
    private readonly _domSanitizer: DomSanitizer
  ) {
    // Covalent Icons
    this._iconRegistry.registerFontClassAlias('covalent', 'covalent-icons');

    // Register Teradata icons
    this._iconRegistry.addSvgIconSetInNamespace(
      'td-icons',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/teradata-icons.svg'
      )
    );

    this._iconRegistry.addSvgIconSetInNamespace(
      'td-logo',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/teradata-logo.svg'
      )
    );

    // SVG Icons
    this._iconRegistry.addSvgIcon(
      'teradata',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/teradata.svg'
      )
    );
    this._iconRegistry.addSvgIcon(
      'teradata-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/teradata-icon.svg'
      )
    );
    this._iconRegistry.addSvgIcon(
      'size-large',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/machines/size-large.svg'
      )
    );
    this._iconRegistry.addSvgIcon(
      'size-large-white',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/machines/size-large-white.svg'
      )
    );
    this._iconRegistry.addSvgIcon(
      'size-medium',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/machines/size-medium.svg'
      )
    );
    this._iconRegistry.addSvgIcon(
      'size-medium-white',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/machines/size-medium-white.svg'
      )
    );
    this._iconRegistry.addSvgIcon(
      'size-small',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/machines/size-small.svg'
      )
    );
    this._iconRegistry.addSvgIcon(
      'size-small-white',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/machines/size-small-white.svg'
      )
    );
  }
}
