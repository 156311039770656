<div class="sidesheet-header">
  <h2 class="mat-title" translate>AUTH_LIB.ACCESS_TOKEN.DETAILS.TITLE</h2>
  <button mat-icon-button (click)="dialogRef.close()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-divider></mat-divider>
<mat-progress-bar
  color="accent"
  mode="indeterminate"
  *ngIf="isLoading$ | async"
></mat-progress-bar>

<ng-container *ngIf="patDetails$ | async as patDetails">
  <ng-container *ngIf="patDetails.active">
    <td-message
      class="match-spec positive"
      icon="check"
      [label]="'AUTH_LIB.ACCESS_TOKEN.DETAILS.TOKEN_ACTIVE' | translate"
    >
      <button
        mat-button
        td-message-actions
        (click)="handleRevokeToken(patDetails.reference_id)"
      >
        {{ 'AUTH_LIB.ACCESS_TOKEN.REVOKE' | translate }}
      </button>
    </td-message>

    <mat-divider></mat-divider>
  </ng-container>

  <dl class="details-list">
    <dt translate>AUTH_LIB.ACCESS_TOKEN.DETAILS.STATUS</dt>
    <dd translate>
      {{ !!patDetails.active ? 'AUTH_LIB.ACCESS_TOKEN.DETAILS.ACTIVE' : 'uh' }}
    </dd>

    <dt translate>AUTH_LIB.ACCESS_TOKEN.SERVICE</dt>
    <dd>
      {{ patDetails.service || 'AUTH_LIB.ACCESS_TOKEN.UNKNOWN' | translate }}
    </dd>

    <dt translate>AUTH_LIB.ACCESS_TOKEN.ENVIRONMENT</dt>
    <dd>
      {{
        patDetails.environment_id || 'AUTH_LIB.ACCESS_TOKEN.UNKNOWN' | translate
      }}
    </dd>

    <dt translate>AUTH_LIB.ACCESS_TOKEN.USER</dt>
    <dd>
      {{ patDetails.owner || 'AUTH_LIB.ACCESS_TOKEN.UNKNOWN' | translate }}
    </dd>

    <dt translate>AUTH_LIB.ACCESS_TOKEN.ORGANIZATION</dt>
    <dd>
      {{ patDetails.org_name || 'AUTH_LIB.ACCESS_TOKEN.UNKNOWN' | translate }}
    </dd>

    <dt translate>AUTH_LIB.ACCESS_TOKEN.CREATED</dt>
    <dd>{{ patDetails.created | date: 'short':'UTC' }}</dd>

    <dt translate>AUTH_LIB.ACCESS_TOKEN.EXPIRES</dt>
    <dd>{{ patDetails.expiration | date: 'short':'UTC' }}</dd>

    <dt translate>AUTH_LIB.ACCESS_TOKEN.LAST_EXCHANGED</dt>
    <dd>{{ patDetails.last_exchanged | date: 'short':'UTC' }}</dd>

    <dt translate>AUTH_LIB.ACCESS_TOKEN.DETAILS.DESCRIPTION</dt>
    <dd>{{ patDetails.description }}</dd>
  </dl>
</ng-container>
