import { Component, Input } from '@angular/core';
import { AskAiService } from '@janus/ask-ai';

@Component({
  selector: 'ask-ai-nav-item',
  templateUrl: './ask-ai-nav-item.component.html',
  styleUrls: ['./ask-ai-nav-item.component.scss'],
})
export class AskAiNavItemComponent {
  @Input() expanded: boolean;

  constructor(private askAiService: AskAiService) {}

  openAskAi(): void {
    this.askAiService.openAskAi();
  }
}
