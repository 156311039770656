<header>
  <img
    class="logo light-mode"
    src="https://cloud.vantage.teradata.com/common/assets/icons/teradata-dark.svg"
    alt="Teradata"
  />
  <img
    class="logo dark-mode"
    src="https://cloud.vantage.teradata.com/common/assets/icons/teradata.svg"
    alt="Teradata"
  />
</header>
<mat-divider class="divider"></mat-divider>
<mat-dialog-content>
  <p
    [innerHTML]="
      'COOKIES_MESSAGE'
        | translate
          : {
              link: 'https://assets.teradata.com/pdf/Teradata-Privacy-Policy.pdf'
            }
    "
  ></p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button data-test="deny-button" [mat-dialog-close]="false">
    {{ 'DENY' | translate }}
  </button>
  <button
    mat-button
    color="accent"
    data-test="accept-button"
    [mat-dialog-close]="true"
  >
    {{ 'ACCEPT' | translate }}
  </button>
</mat-dialog-actions>
