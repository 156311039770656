import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

export interface IRegion {
  code: string;
  platform: ICloudPlatform;
}

export enum ICloudPlatform {
  AZURE = 'AZURE',
  AWS = 'AWS',
  GCP = 'GCP',
  PRIVATE = 'PRIVATE',
  ON_PREM = 'ON_PREM',
}

@Injectable({
  providedIn: 'root',
})
export class RegionsService {
  getRegions(platform?: ICloudPlatform): Observable<IRegion[]> {
    return of(
      this.REGIONS.filter(
        (region) => region.platform === (platform ?? ICloudPlatform.AWS)
      )
    );
  }

  private REGIONS: IRegion[] = [
    // AWS Regions
    { code: 'us-east-2', platform: ICloudPlatform.AWS },
    { code: 'us-east-1', platform: ICloudPlatform.AWS },
    { code: 'us-west-1', platform: ICloudPlatform.AWS },
    { code: 'us-west-2', platform: ICloudPlatform.AWS },
    { code: 'af-south-1', platform: ICloudPlatform.AWS },
    { code: 'ap-east-1', platform: ICloudPlatform.AWS },
    { code: 'ap-southeast-3', platform: ICloudPlatform.AWS },
    { code: 'ap-south-1', platform: ICloudPlatform.AWS },
    { code: 'ap-northeast-3', platform: ICloudPlatform.AWS },
    { code: 'ap-northeast-2', platform: ICloudPlatform.AWS },
    { code: 'ap-southeast-1', platform: ICloudPlatform.AWS },
    { code: 'ap-southeast-2', platform: ICloudPlatform.AWS },
    { code: 'ap-northeast-1', platform: ICloudPlatform.AWS },
    { code: 'ca-central-1', platform: ICloudPlatform.AWS },
    { code: 'eu-central-1', platform: ICloudPlatform.AWS },
    { code: 'eu-west-1', platform: ICloudPlatform.AWS },
    { code: 'eu-west-2', platform: ICloudPlatform.AWS },
    { code: 'eu-south-1', platform: ICloudPlatform.AWS },
    { code: 'eu-west-3', platform: ICloudPlatform.AWS },
    { code: 'eu-north-1', platform: ICloudPlatform.AWS },
    { code: 'me-south-1', platform: ICloudPlatform.AWS },
    { code: 'me-central-1', platform: ICloudPlatform.AWS },
    { code: 'sa-east-1', platform: ICloudPlatform.AWS },
  ];
}
