import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlagsDirective } from './flags.directive';
import { FeatureFlagGuard } from './flags.guard';
import { TD_FLAGS_PROVIDER } from './flags.service';

@NgModule({
  imports: [CommonModule],
  declarations: [FlagsDirective],
  exports: [FlagsDirective],
  providers: [FeatureFlagGuard, TD_FLAGS_PROVIDER],
})
export class VantageFlagsModule {
  static forRoot(): ModuleWithProviders<VantageFlagsModule> {
    return {
      ngModule: VantageFlagsModule,
    };
  }
}
