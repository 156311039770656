import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CovalentDialogsModule } from '@covalent/core/dialogs';
import { CovalentMessageModule } from '@covalent/core/message';
import {
  initTranslateService,
  startTranslateService,
  addTranslation,
  getSupportedLanguages,
} from '@janus/translate';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SafeHtmlModule } from '@td/ui/common';
import { OrgAdminCreateDialogComponent } from './org-admin-create-dialog/org-admin-create-dialog.component';
import { OrgAdminsListComponent } from './org-admins-list/org-admins-list.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatToolbarModule,
    TranslateModule.forChild(),
    CovalentDialogsModule,
    CovalentMessageModule,
    SafeHtmlModule,
  ],
  declarations: [OrgAdminCreateDialogComponent, OrgAdminsListComponent],
  exports: [OrgAdminCreateDialogComponent, OrgAdminsListComponent],
})
export class OrganizationsModule {
  constructor(translateService: TranslateService) {
    initTranslateService(translateService);

    getSupportedLanguages().forEach((lang: string) => {
      let translateFile: any;
      try {
        translateFile = require(`../../assets/i18n/${lang}.json`);
      } catch (err: any) {
        translateFile = {};
      }

      addTranslation(translateService, lang, translateFile);
    });

    startTranslateService(translateService);
  }
}
