import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ForeignServerService } from './foreign-server.service';
import { DataSourceService, IQGDataSource } from './data-source.service';
import {
  IQGSystemDetails,
  ISystemType,
  IDataSourceType,
} from './data-source.interface';

export interface ICompleteableFormStep {
  formGroup: FormGroup;
  started: BehaviorSubject<boolean>;
  completed: BehaviorSubject<boolean>;
}

export interface IStepper {
  step1: ICompleteableFormStep;
  step2: ICompleteableFormStep;
  step3?: ICompleteableFormStep;
  step4?: ICompleteableFormStep;
  step5?: ICompleteableFormStep;
  step6?: ICompleteableFormStep;
}

export interface ICompleteableSystemStep {
  system: IQGSystemDetails;
  step: ICompleteableFormStep;
  dataSource?: IQGDataSource;
  selectedConnectorType?: IDataSourceType;
  interfaceEndpointDNSName?: string;
  existingNetworkId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConnectDataSourceStepperService {
  private dataSources: Map<string, IQGDataSource> = new Map();

  constructor(
    private _dataSourceService: DataSourceService,
    private _foreignServerService: ForeignServerService
  ) {}

  getNewStepper(): IStepper {
    return {
      step1: this.getNewDataSourceStep(),
      step2: this.getNewDataSourceStep(),
      step3: this.getNewDataSourceStep(),
      step4: this.getNewDataSourceStep(),
      step5: this.getNewDataSourceStep(),
      step6: this.getNewForeignServerStep(ISystemType.TERADATA),
    };
  }

  private getNewDataSourceStep(): ICompleteableFormStep {
    return {
      formGroup: this._dataSourceService.newDataSourceFormGroup(),
      started: new BehaviorSubject<boolean>(false),
      completed: new BehaviorSubject<boolean>(false),
    };
  }

  getNewForeignServerStep(systemType: string): ICompleteableFormStep {
    return {
      formGroup:
        this._foreignServerService.newForeignServerFormGroup(systemType),
      started: new BehaviorSubject<boolean>(false),
      completed: new BehaviorSubject<boolean>(false),
    };
  }

  addDataSource(dataSource: IQGDataSource): void {
    if (dataSource.system.id) {
      this.dataSources.set(dataSource.system.id, dataSource);
    }
  }
  getDataSource(id: string): IQGDataSource | undefined {
    return this.dataSources.get(id);
  }
  deleteDataSource(id: string): boolean {
    return this.dataSources.delete(id);
  }
}
