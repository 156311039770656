import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function tdShortCronValidator(): ValidatorFn {
  return (control: FormControl): ValidationErrors | null => {
    const cronString: string = control.value;
    let errorFound: boolean = false;

    if (
      cronString.trim().split(' ').length !== 5 ||
      cronString.includes('  ')
    ) {
      errorFound = true;
    }

    return errorFound ? { invalidForm: true } : null;
  };
}
