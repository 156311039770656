import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { IMessage, AskAiService } from '../../ask-ai.service';
import { BehaviorSubject, take } from 'rxjs';
import { IAccount } from '@janus/accounts';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ask-ai-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent {
  aiTyping$ = new BehaviorSubject(false);

  messageCtrl = new FormControl('');

  conversation: IMessage[] = [
    {
      text: this.translate.instant('ASK_AI.WELCOME_MSG'),
      from: 'ai',
      timestamp: new Date(),
    },
  ];

  @ViewChildren('conversationRef', {
    read: ElementRef,
  })
  conversationRef!: QueryList<ElementRef<HTMLElement>>;

  @Input() selectedAccount: IAccount;
  @Input() chatHistory: IMessage[];

  @Output() setChatDirty = new EventEmitter<void>();
  @Output() setChatHistory = new EventEmitter<IMessage[]>();

  constructor(
    private askAiService: AskAiService,
    private translate: TranslateService
  ) {}

  ngAfterViewInit(): void {
    this.conversationRef.changes.subscribe(() => {
      this.conversationRef.last.nativeElement.scrollIntoView();
    });

    this.messageCtrl.valueChanges
      .pipe(take(1))
      .subscribe(() => this.setChatDirty.emit());

    if (this.chatHistory) {
      this.conversation = [...this.chatHistory];
    }
  }

  sendMessage(): void {
    const question = this.messageCtrl.value.trim();
    this.conversation.push({
      text: this.messageCtrl.value,
      from: 'user',
      timestamp: new Date(),
    });
    this.messageCtrl.setValue('');
    this.aiTyping$.next(true);
    this.askAiService
      .postQuestion(this.selectedAccount.account_id, question)
      .subscribe((answer) => {
        this.aiTyping$.next(false);
        const message: IMessage = {
          text: answer,
          from: 'ai',
          timestamp: new Date(),
        };

        this.conversation.push(message);
        this.setChatHistory.emit(this.conversation);
      });
  }
}
