import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CovalentDialogsModule } from '@covalent/core/dialogs';

import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { VANTAGE_ERROR_PROVIDER } from './error.service';
import { VANTAGE_TOAST_PROVIDER } from './toast.service';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatSelectModule,
    MatSnackBarModule,
    CovalentDialogsModule,
  ],
  providers: [VANTAGE_ERROR_PROVIDER, VANTAGE_TOAST_PROVIDER],
})
export class VantageUserFeedbackModule {}
