import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../common/api.service';

export interface IDataCenter {
  id: string;
  name: string;
  description?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  constructor(private _http: HttpClient, private _apiService: ApiService) {}

  getDataCenters(): Observable<IDataCenter[]> {
    return this._http
      .get<any>(`${this._apiService.getBaseUrl()}/config/datacenters`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }
}
