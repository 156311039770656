<mat-list-item
  data-cy="ask-ai-expanded-nav-item"
  *ngIf="expanded; else collapsed"
  class="expanded-item"
  data-cy="askAiMenu"
  (click)="openAskAi()"
>
  <mat-icon
    matListIcon
    class="ask-ai-icon"
    svgIcon="td-icons:ask-ai"
  ></mat-icon>

  <div matLine class="mat-body-1 ask-ai-title">
    <span class="push-right-sm">
      {{ 'ASK_AI_NAV_TITLE' | translate }}
    </span>
    <span class="private-preview-flag">{{
      'ASK_AI.PRIVATE_PREVIEW' | translate
    }}</span>
  </div>
  <div matLine class="caption ask-ai-description">
    {{ 'ASK_AI_NAV_DESCRIPTION' | translate }}
  </div>
</mat-list-item>

<ng-template #collapsed>
  <mat-list-item disableRipple data-cy="ask-aiMenu">
    <button
      data-cy="ask-ai-collapsed-nav-item"
      mat-icon-button
      [matTooltip]="'ASK_AI_NAV_TITLE' | translate"
      matTooltipPosition="after"
      (click)="openAskAi()"
    >
      <mat-icon
        matListIcon
        class="ask-ai-icon"
        svgIcon="td-icons:ask-ai"
      ></mat-icon>
    </button>
  </mat-list-item>
</ng-template>
