import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TdDialogService } from '@covalent/core/dialogs';
import {
  IMarkdownNavigatorItem,
  IMarkdownNavigatorWindowConfig,
  TdMarkdownNavigatorWindowComponent,
  TdMarkdownNavigatorWindowService,
} from '@covalent/markdown-navigator';
import { TranslateService } from '@ngx-translate/core';
import {
  VantageConnectableService,
  VantageConnectionMonitorService,
  VantageEditorConnectionService,
  VantageQueryService,
} from '@td/vantage/query';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LoadAssetDialogComponent } from '../load-asset-dialog/load-asset-dialog.component';
import { VantageErrorService } from '@td/vantage/user-feedback';
import { Location, DOCUMENT } from '@angular/common';

const BASE_HELP_JSON = 'Vantage/base-help-en.json';
const BASE_VIDEO_LIST_JSON = 'Videos/base-help-en.json';
export interface IHelpUrls {
  help_base_url: string;
  support: string;
  documentation: string;
}

export interface IHelpBase {
  items: IMarkdownNavigatorItem[];
}

interface useCase {
  id: string;
  name: string;
  enabled: boolean;
  description?: string;
  industries: [];
  content_url: string;
}

@Injectable({
  providedIn: 'root',
})
export class HelpMenuService {
  private _helpBaseUrl = '';
  private _helpUrls: IHelpUrls;
  private loadAssetsDialog: MatDialogRef<LoadAssetDialogComponent>;
  public _loadAssetSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public _loadAsset$: Observable<any> = this._loadAssetSubject.asObservable();
  public _noCurrentConnectionSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(false);
  public _noCurrentConnection$: Observable<any> =
    this._noCurrentConnectionSubject.asObservable();

  public useCasesBaseUrl = '';
  helpPanelId: string;
  helpPanelOpened: boolean | string = false;
  assetId: string;
  readonly USE_CASES_ID: string = 'bkm1640280721917';

  isEditor: boolean = false;
  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private readonly _httpClient: HttpClient,
    private readonly _markdownNavigatorWindowService: TdMarkdownNavigatorWindowService,
    private _dialogService: TdDialogService,
    private _connectionMonitorService: VantageConnectionMonitorService,
    private readonly _queryService: VantageQueryService,
    private readonly _connectableService: VantageConnectableService,
    private _translateService: TranslateService,
    private _route: ActivatedRoute,
    private _errorService: VantageErrorService,
    private _location: Location,
    private connectionService: VantageEditorConnectionService,
    private _router: Router
  ) {
    this._helpBaseUrl = environment.helpUrls.help_base_url;
    this._helpUrls = environment.helpUrls;
  }

  getHelpBaseURL(): string {
    return this._helpBaseUrl;
  }

  getHelpJSON(fileName = BASE_HELP_JSON): Observable<IMarkdownNavigatorItem[]> {
    const lang = this._translateService.currentLang;
    const target = `${this._helpBaseUrl}${fileName}`;

    let src;

    if (lang && lang !== 'en') {
      src = this._httpClient
        .get(this._localizeUrl(target))
        .pipe(catchError(() => this._httpClient.get(target)));
    } else {
      src = this._httpClient.get(target);
    }

    return src.pipe(
      map((baseHelpJson: IHelpBase) => {
        if (baseHelpJson && baseHelpJson.items) {
          this.appendBaseUrltoUrl(baseHelpJson.items);
        }
        return baseHelpJson.items;
      })
    );
  }

  openSupport(): void {
    if (this._helpUrls?.support) {
      window.open(this._helpUrls.support, '_blank');
    }
  }

  openDocumentation(): void {
    if (this._helpUrls?.documentation) {
      window.open(this._helpUrls.documentation, '_blank');
    }
  }

  openHelpCheck(isEditor: boolean): void {
    this.isEditor = isEditor;
    this.helpPanelId = this._route.snapshot.queryParams['help_panel_id'];
    this.helpPanelOpened =
      this._route.snapshot.queryParams['help_panel_opened'];
    this.assetId = this._route.snapshot.queryParams['asset_id'];

    if (this.helpPanelOpened == 'true' && this.helpPanelId) {
      this.openHelpById(this.helpPanelId);
    } else if (this.helpPanelOpened == 'true') {
      this.openHelp();
    }
  }

  openHelp(): void {
    this._openMarkdownNavigatorWindow();
  }

  openHelpById(id: string): void {
    this._openMarkdownNavigatorWindow(id);
  }

  openVideoHelp(): void {
    this._openMarkdownNavigatorWindow(undefined, BASE_VIDEO_LIST_JSON);
  }

  isPanelOpened() {
    return this.helpPanelOpened;
  }

  private appendBaseUrltoUrl(items: IMarkdownNavigatorItem[]) {
    items.forEach((item: IMarkdownNavigatorItem) => {
      if (item.id != this.USE_CASES_ID) {
        if (item.url) {
          item.url = this._helpBaseUrl + item.url;
        }

        if (item.children && item.children.length > 0) {
          this.appendBaseUrltoUrl(item.children);
        }

        if (item.childrenUrl) {
          item.url = this._helpBaseUrl + item.url;
        }
      }
    });
  }

  private _localizeUrl(url: string): string {
    const lang = this._translateService.currentLang;
    // change any `-en` tags to the target language
    const localized = url.replace('-en.json', `-${lang}.json`);
    // splice in the translation subfolder
    return localized.replace(/\/([^/]+)$/i, `/${lang}/$1`);
  }

  private _openMarkdownNavigatorWindow(
    id = '',
    jsonFile = BASE_HELP_JSON
  ): void {
    // tweak the size to be a bit wider than the default
    const windowOptions: Partial<IMarkdownNavigatorWindowConfig> = {
      dialogConfig: {
        width: '464px',
        height: '60vh',
      },
      copyCodeToClipboard: true,
    };

    //drill down into the help file to show the relevant content
    if (id) {
      this.helpPanelId = id;
      windowOptions.startAt = { id };
    }
    this.getHelpJSON(jsonFile)
      .pipe(
        switchMap((items: IMarkdownNavigatorItem[]) => {
          return this.handleUseCasesUrl(items);
        })
      )
      .subscribe({
        next: (items: IMarkdownNavigatorItem[]) => {
          const dialog: MatDialogRef<TdMarkdownNavigatorWindowComponent> =
            this._markdownNavigatorWindowService.open({
              items: items,
              labels: {
                title: this._translateService.instant('HELP'),
                close: this._translateService.instant('CLOSE'),
              },
              ...windowOptions,
            });
          dialog.addPanelClass('no-overflow-y');

          this.helpPanelOpened = true;

          if (this.assetId && this.connectionService.currentConnection) {
            this.loadAsset(this.assetId);
          }
          dialog.componentInstance.closed.subscribe(() => {
            this.helpPanelOpened = false;
          });

          this.clearQueryParams();
          dialog.componentInstance.itemSelected.subscribe(
            (item: IMarkdownNavigatorItem) => {
              if (item?.id) {
                this.helpPanelId = item.id;
              } else {
                this.helpPanelId = undefined;
              }
            }
          );

          dialog.componentInstance.buttonClicked.subscribe((data: any) => {
            // if not in editor, navigate to editor
            const assetId = JSON.parse(data.data).id;
            if (!this.isEditor) {
              this.navigateToEditor(assetId);
            } else {
              //Load asset button is clicked
              this.assetId = assetId;
              if (assetId) {
                if (!this.connectionService.currentConnection) {
                  this._noCurrentConnectionSubject.next(true);
                } else {
                  this.loadAsset(assetId);
                }
              }
            }
          });
        },
        error: (err) => {
          this._errorService.open(err);
        },
      });
  }

  loadAsset(assetId: any): void {
    this.loadAssetsDialog = this._dialogService.open(LoadAssetDialogComponent, {
      disableClose: true,
      width: '500px',
      maxHeight: '100vh',
      data: {
        dataset: assetId,
        baseUrl: this.useCasesBaseUrl,
        system: this.connectionService.currentConnection.system,
      },
    });

    this.loadAssetsDialog.afterClosed().subscribe(() => this.resetAssetId());
  }

  handleUseCasesUrl(items: IMarkdownNavigatorItem[]) {
    const useCasesJSON: IMarkdownNavigatorItem = items.find(
      (item: IMarkdownNavigatorItem) => item.id == this.USE_CASES_ID
    );
    if (useCasesJSON) {
      const lang = this._translateService.currentLang;
      const useCasesJSONUrl = this._helpBaseUrl + useCasesJSON.childrenUrl;
      this.useCasesBaseUrl = useCasesJSONUrl.replace(/use-cases.*\.json/g, '');

      let src;

      if (lang && lang !== 'en') {
        src = this._httpClient
          .get(this._localizeUrl(useCasesJSONUrl))
          .pipe(catchError(() => this._httpClient.get(useCasesJSONUrl)));
      } else {
        src = this._httpClient.get(useCasesJSONUrl);
      }

      return src.pipe(
        map((useCasesRes: useCase[]) => {
          const useCaseChildren: IMarkdownNavigatorItem[] = [];
          useCasesRes.forEach((useCase: useCase) => {
            if (useCase.enabled) {
              useCaseChildren.push({
                id: useCase.id,
                title: useCase.name,
                url: this.useCasesBaseUrl + useCase.content_url,
                description: useCase.description,
              });
            }
          });

          items.forEach((item: IMarkdownNavigatorItem) => {
            this.replaceUseCaseURLs(item, useCaseChildren);
          });
          return items;
        })
      );
    }
  }

  replaceUseCaseURLs(
    item: IMarkdownNavigatorItem,
    useCaseChildren: IMarkdownNavigatorItem[]
  ): void {
    if (item.id == this.USE_CASES_ID) {
      item.children = useCaseChildren;
      item.url = this._helpBaseUrl + item.url;
      delete item.childrenUrl;
    }
    if (item.children) {
      item.children.forEach((ele: IMarkdownNavigatorItem) => {
        this.replaceUseCaseURLs(ele, useCaseChildren);
      });
    }
  }

  navigateToEditor(assetId?: string): void {
    let href = '/query';
    if (this.helpPanelOpened) {
      href = href + `?help_panel_opened=${this.helpPanelOpened}`;
      if (this.helpPanelId) {
        href = href + `&help_panel_id=${this.helpPanelId}`;
      }
    }

    if (assetId && assetId !== 'editor') {
      href = href + `&&asset_id=${assetId}`;
    }
    this._document.location.href = href;
  }

  resetAssetId(): void {
    this.assetId = undefined;
  }

  clearQueryParams(): void {
    if (this._route.snapshot.queryParams['help_panel_opened']) {
      const scriptId = this._route.snapshot.queryParams['scriptId'];
      if (scriptId) {
        this._router.navigate([], {
          relativeTo: this._route,
          queryParams: { scriptId: scriptId },
        });
      } else {
        this._router.navigate([], {
          relativeTo: this._route,
          queryParams: {},
        });
      }
    }
  }
}
