<div layout="row" layout-align="space-between" flex>
  <h3 class="push-bottom push-top-none">
    {{ 'Notifications' | translate }}
  </h3>
  <div>
    <mat-icon
      class="push-right"
      (click)="clearAll()"
      matTooltip="Clear all"
      matTooltipPosition="above"
    >
      clear_all</mat-icon
    >
    <mat-icon (click)="closeDialog()">close</mat-icon>
  </div>
</div>
<mat-divider class="divider-margin"></mat-divider>
<mat-dialog-content>
  <ng-container *ngIf="data && data.length != 0; else noNotification">
    <ng-container *ngFor="let job of data">
      <!-- <button> -->
      <div
        flex
        layout="row"
        (click)="viewDetails(job)"
        class="notification pad pad-left-md pad-right-md"
      >
        <div class="push-right icon-center">
          <mat-icon svgIcon="td-icons:database"></mat-icon>
        </div>
        <div>
          <div class="push-bottom-sm">{{ job.datasetName }}</div>
          <div class="job-status">
            {{ 'USE_CASE.' + job.datasetStatus | translate }}
          </div>
        </div>
        <div
          style="margin-left: 'auto'"
          class="icon-center clear-notificaiton"
          (click)="clearNotification(job); $event.stopPropagation()"
        >
          <mat-icon style="cursor: 'pointer'">close</mat-icon>
        </div>
      </div>
      <!-- </button> -->
      <mat-divider class="divider-margin"></mat-divider>
    </ng-container>
  </ng-container>

  <ng-template #noNotification>
    <div class="pad-md pad-top-lg no-notificaiton">
      {{ 'NO_NOTIFICATIONS' | translate }}
    </div>
  </ng-template>
</mat-dialog-content>
