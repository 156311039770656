import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { IStatusPackage, StatusService } from '@td/ui/common';

@Component({
  selector: 'ui-table-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiStatusComponent {
  statusPackage: IStatusPackage = {} as IStatusPackage;

  @Input() showIcon = true;
  @Input() showLabel = true;
  @Input() set status(status: string) {
    this.statusPackage = this.statusService.buildStatusPackage(status);
  }
  @Input() customLabel: string;

  constructor(private readonly statusService: StatusService) {}
}
