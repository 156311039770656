import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { MatIconRegistry, MatIconModule } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import {
  THEME_LOCAL_STORAGE_KEY,
  VantageTheme,
  ThemeService,
  VANTAGE_THEME_PROVIDER,
} from './theme.service';

@NgModule({
  imports: [HttpClientModule, CommonModule],
  providers: [MatIconModule, VANTAGE_THEME_PROVIDER],
})
export class ThemeModule {
  constructor(
    private readonly _iconRegistry: MatIconRegistry,
    private readonly _domSanitizer: DomSanitizer,
    private readonly vantageThemeService: ThemeService
  ) {
    // Set default dark theme default
    if (!this.localStorageTheme()) {
      this.vantageThemeService.applyDarkTheme();
    }

    // Covalent Icons
    this._iconRegistry.registerFontClassAlias('covalent', 'covalent-icons');

    // Register Teradata icons
    this._iconRegistry.addSvgIconSetInNamespace(
      'td-icons',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/teradata-icons.svg'
      )
    );

    this._iconRegistry.addSvgIconSetInNamespace(
      'td-logo',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/teradata-logo.svg'
      )
    );

    this._iconRegistry.addSvgIconInNamespace(
      'assets',
      'illustration',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/illustration.svg'
      )
    );

    this._iconRegistry.addSvgIconInNamespace(
      'assets',
      'illustration-db-user',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/illustration-db-user.svg'
      )
    );

    this._iconRegistry.addSvgIconInNamespace(
      'assets',
      'sidebar',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/sidebar.svg'
      )
    );

    this._iconRegistry.addSvgIconInNamespace(
      'assets',
      'empty-state',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/empty-state.svg'
      )
    );

    this._iconRegistry.addSvgIcon(
      'beta-box',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/beta-box.svg'
      )
    );
    this._iconRegistry.addSvgIconInNamespace(
      'assets',
      'abc',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/abc.svg')
    );

    this._iconRegistry.addSvgIconInNamespace(
      'assets',
      '123',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/123.svg')
    );
    this._iconRegistry.addSvgIconInNamespace(
      'assets',
      'fulljoin',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fulljoin.svg'
      )
    );
    this._iconRegistry.addSvgIconInNamespace(
      'assets',
      'innerjoin',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/innerjoin.svg'
      )
    );
    this._iconRegistry.addSvgIconInNamespace(
      'assets',
      'leftjoin',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/leftjoin.svg'
      )
    );
    this._iconRegistry.addSvgIconInNamespace(
      'assets',
      'rightjoin',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/rightjoin.svg'
      )
    );
    this._iconRegistry.addSvgIconInNamespace(
      'assets',
      'crossjoin',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/crossjoin.svg'
      )
    );
  }

  private localStorageTheme(): VantageTheme {
    return localStorage.getItem(THEME_LOCAL_STORAGE_KEY) as VantageTheme;
  }
}
