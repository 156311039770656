import { Component, Input } from '@angular/core';

import { HelpMenuService } from './help-menu.service';

@Component({
  selector: 'td-help-menu',
  templateUrl: './help-menu.component.html',
  styleUrls: ['./help-menu.component.scss'],
})
export class HelpMenuComponent {
  @Input() showHelp: boolean = false;

  constructor(private readonly _helpMenuService: HelpMenuService) {}

  openSupport() {
    this._helpMenuService.openSupport();
  }

  openDocumentation() {
    this._helpMenuService.openDocumentation();
  }

  openHelp() {
    this._helpMenuService.openHelp();
  }
}
