<ng-container *ngIf="displayType === 'sideBar'; else popUp">
  <div
    class="attribute-filter-wrapper side-bar"
    [ngClass]="{ 'edit-mode': addMode }"
  >
    <div class="filter-header">
      <div class="mat-subheading-2 filter-text" *ngIf="!addMode; else backIcon">
        {{ 'FILTERS' | translate }}
      </div>
      <ng-template #backIcon>
        <button
          mat-icon-button
          aria-label="'BACK' | translate"
          data-cy="back"
          (click)="resetEditMode()"
        >
          <mat-icon>arrow_back</mat-icon>
        </button>
      </ng-template>
      <div>
        <button
          *ngIf="!addMode; else deleteIcon"
          mat-icon-button
          aria-label="'ADD_FILTERS' | translate"
          data-cy="add-filter"
          (click)="addFilter()"
        >
          <mat-icon>add</mat-icon>
        </button>

        <ng-template #deleteIcon>
          <button
            mat-icon-button
            aria-label="'CLOSE' | translate"
            data-cy="delete-filter"
            *ngIf="selectedFilter"
            (click)="removeFilter()"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </ng-template>

        <button
          mat-icon-button
          aria-label="'CLOSE' | translate"
          data-cy="close-filter"
          (click)="close()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="no-filters" *ngIf="!addMode && selectedFilters.length === 0">
      {{ 'NO_FILTERS' | translate }}
    </div>

    <mat-selection-list
      class="filter-list"
      [multiple]="false"
      data-cy="edit-filter"
      *ngIf="!addMode && selectedFilters.length > 0"
    >
      <mat-list-option
        class="filter-list-item"
        *ngFor="let selectedFilter of selectedFilters"
        [value]="selectedFilter"
        (click)="editFilter(selectedFilter)"
      >
        <div class="filter-wrapper">
          <div>
            <div class="filter-type-wrapper">
              {{ getColumnById(selectedFilter.columnId).name }}
              <mat-icon class="dot">fiber_manual_record</mat-icon>
              {{
                'FILTER_TYPES.' + selectedFilter.filterType.toUpperCase()
                  | translate
              }}
            </div>
            <div class="filter-value">
              {{
                selectedFilter.accessor
                  ? selectedFilter.accessor(selectedFilter.filterValue)
                  : selectedFilter.filterValue
              }}
            </div>
          </div>
          <mat-icon class="edit-icon">edit</mat-icon>
        </div>
      </mat-list-option>
    </mat-selection-list>

    <form
      class="filter-creation-form"
      [formGroup]="filterCreationForm"
      (ngSubmit)="applyFilter(true)"
      *ngIf="addMode"
    >
      <mat-form-field appearance="outline" data-cy="filter-column">
        <mat-select formControlName="columnId">
          <mat-option
            *ngFor="let column of getFilterableColumns()"
            [value]="column.id"
            >{{ column.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" data-cy="filter-type">
        <mat-select formControlName="filterType">
          <mat-option *ngFor="let type of filterTypes" [value]="type">
            {{ 'FILTER_TYPES.' + type.toUpperCase() | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" data-cy="filter-value">
        <ng-container *ngIf="isEnum || isStatuses; else stringValue">
          <ng-container *ngIf="isEnum">
            <mat-select formControlName="filterValue">
              <mat-option
                *ngFor="let filterValue of filterValues"
                [value]="filterValue.value"
              >
                {{ filterValue.label }}
              </mat-option>
            </mat-select>
          </ng-container>
          <ng-container *ngIf="isStatuses">
            <mat-select formControlName="filterValue">
              <mat-select-trigger>
                <ui-table-status
                  [status]="filterCreationForm.get('filterValue').value"
                ></ui-table-status>
              </mat-select-trigger>
              <mat-option
                *ngFor="let filterValue of statusValues"
                [value]="filterValue"
              >
                <ui-table-status [status]="filterValue"></ui-table-status>
              </mat-option>
            </mat-select>
          </ng-container>
        </ng-container>

        <ng-template #stringValue>
          <input matInput formControlName="filterValue" />
        </ng-template>
      </mat-form-field>
    </form>

    <div *ngIf="addMode && !instantFiltering" class="filter-footer">
      <mat-divider></mat-divider>
      <button
        mat-flat-button
        color="accent"
        (click)="applyFilter()"
        [disabled]="filterCreationForm.invalid"
        data-cy="filter-apply"
      >
        {{ 'APPLY' | translate }}
      </button>
    </div>
  </div>
</ng-container>

<ng-template #popUp>
  <button
    mat-icon-button
    (click)="onFilterIconClick($event)"
    [disabled]="isDisabled"
    data-test="filter-button"
    class="attribute-filter-button"
    data-cy="filter-button"
  >
    <mat-icon
      [matBadge]="selectedFilters.length"
      matBadgeColor="accent"
      [matBadgeHidden]="selectedFilters.length === 0"
    >
      filter_list
    </mat-icon>
  </button>
</ng-template>
