// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const require: any;

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatBadgeModule } from '@angular/material/badge';
import { OverlayModule } from '@angular/cdk/overlay';

import { CovalentLayoutModule } from '@covalent/core/layout';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

import {
  initTranslateService,
  startTranslateService,
  addTranslation,
  getSupportedLanguages,
  LocalizationModule,
} from '@janus/translate';

import { HelpModule } from '@janus/help';

import { AskAiModule } from '@janus/ask-ai';

import { CookiesDialogComponent } from './cookies-dialog/cookies-dialog.component';
import { NavContentCollapsedComponent } from './nav-rail/nav-content-collapsed/nav-content-collapsed.component';
import { NavContentExpandedComponent } from './nav-rail/nav-content-expanded/nav-content-expanded.component';
import { NavRailData } from './nav-rail/utils/nav-rail-data';
import { NavMainComponent } from './nav-main/nav-main.component';
import { NavRailComponent } from './nav-rail/nav-rail.component';
import { UsersModule } from '@janus/users';
import { VantageFlagsModule } from '@td/vantage/flags';
import { NavService } from './nav-rail/utils/nav.service';
import { NotificationDialogComponent } from './nav-rail/nav-items/notifications-nav-item/notification-dialog/notification-dialog.component';
import { EulaDialogComponent } from './eula-dialog/eula-dialog.component';
import { CovalentFlavoredMarkdownModule } from '@covalent/flavored-markdown';
import { HelpNavItemComponent } from './nav-rail/nav-items/help-nav-item/help-nav-item.component';
import { NotificationsNavItemComponent } from './nav-rail/nav-items/notifications-nav-item/notifications-nav-item.component';
import { YourAccountNavItemComponent } from './nav-rail/nav-items/your-account-nav-item/your-account-nav-item.component';
import { AskAiNavItemComponent } from './nav-rail/nav-items/ask-ai-nav-item/ask-ai-nav-item.component';
@NgModule({
  declarations: [
    CookiesDialogComponent,
    NavMainComponent,
    NavRailComponent,
    NavContentExpandedComponent,
    NavContentCollapsedComponent,
    NotificationDialogComponent,
    EulaDialogComponent,
    YourAccountNavItemComponent,
    HelpNavItemComponent,
    NotificationsNavItemComponent,
    AskAiNavItemComponent,
  ],
  exports: [
    CookiesDialogComponent,
    EulaDialogComponent,
    NavMainComponent,
    NavRailComponent,
    NavContentExpandedComponent,
    NavContentCollapsedComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    UsersModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatTooltipModule,
    CovalentFlavoredMarkdownModule,
    CovalentLayoutModule,
    HelpModule,
    TranslateModule.forChild(),
    LocalizationModule,
    VantageFlagsModule,
    OverlayModule,
    AskAiModule,
  ],
  providers: [NavRailData, NavService],
})
export class NavigationModule {
  constructor(translateService: TranslateService) {
    initTranslateService(translateService);

    getSupportedLanguages().forEach((lang: string) => {
      let translateFile: any;
      try {
        translateFile = require(`../../assets/i18n/${lang}.json`);
      } catch (err: any) {
        translateFile = {};
      }

      addTranslation(translateService, lang, translateFile);
    });

    startTranslateService(translateService);
  }
}
