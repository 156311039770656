<div class="chat-container">
  <div class="messages">
    <div *ngFor="let message of conversation" #conversationRef>
      <ng-container [ngSwitch]="message.from">
        <div
          *ngSwitchCase="'user'"
          data-cy="ask-ai-message-user"
          class="user-message mat-subheading-2"
        >
          {{ message.text }}
        </div>
        <div *ngSwitchCase="'ai'" class="ask-ai-message">
          <div class="icon-container">
            <mat-icon class="ask-ai-icon" svgIcon="td-icons:ask-ai"></mat-icon>
          </div>
          <div class="message-content-container">
            <td-flavored-markdown
              data-cy="ask-ai-message-ai"
              [content]="message.text"
              [copyCodeToClipboard]="true"
              [toggleRawCode]="true"
            ></td-flavored-markdown>
          </div>
        </div>
      </ng-container>
    </div>
    <div
      #conversationRef
      class="ask-ai-message in-progress"
      *ngIf="aiTyping$ | async"
    >
      <div class="icon-container">
        <mat-icon class="ask-ai-icon" svgIcon="td-icons:ask-ai"></mat-icon>
      </div>
      <div>
        <mat-icon svgIcon="td-icons:loader_dots"></mat-icon>
      </div>
    </div>
  </div>
  <div class="input-container">
    <mat-form-field appearance="outline">
      <input
        matInput
        data-cy="ask-ai-user-input"
        [placeholder]="'ASK_AI.MESSAGE_PLACEHOLDER' | translate"
        [formControl]="messageCtrl"
        (keyup.enter)="sendMessage()"
      />
    </mat-form-field>
    <button
      data-cy="ask-ai-send-button"
      [disabled]="messageCtrl.value.trim().length < 1"
      mat-icon-button
      class="send-button"
      (click)="sendMessage()"
    >
      <mat-icon>send</mat-icon>
    </button>
  </div>
</div>
