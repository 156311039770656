import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { CovalentDialogsModule } from '@covalent/core/dialogs';
import { CovalentMessageModule } from '@covalent/core/message';
import { CovalentSideSheetModule } from '@covalent/core/side-sheet';
import {
  addTranslation,
  getSupportedLanguages,
  initTranslateService,
  startTranslateService,
} from '@janus/translate';
import { TdDialogShellModule } from '@janus/ui/dialog-shell';
import { TdEmptyStateModule } from '@janus/ui/empty-state';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { VantageUserFeedbackModule } from '@td/vantage-user-feedback';

import { AccessTokenDetailsComponent } from './access-token-details/access-token-details.component';
import { AccessTokenListComponent } from './access-token-list/access-token-list.component';
import { RevokeDialogComponent } from './revoke-dialog/revoke-dialog.component';
import { TdGhostLoadingModule } from '@janus/ui/ghost-loading';

@NgModule({
  declarations: [
    AccessTokenDetailsComponent,
    AccessTokenListComponent,
    RevokeDialogComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatProgressBarModule,
    MatTableModule,
    CovalentDialogsModule,
    CovalentMessageModule,
    CovalentSideSheetModule,
    TdDialogShellModule,
    TdEmptyStateModule,
    TdGhostLoadingModule,
    VantageUserFeedbackModule,
  ],
  exports: [AccessTokenListComponent],
})
export class AuthenticationUiModule {
  constructor(translateService: TranslateService) {
    initTranslateService(translateService);

    getSupportedLanguages().forEach((lang: string) => {
      let translateFile: any;
      try {
        translateFile = require(`../../../assets/i18n/${lang}.json`);
      } catch (err: any) {
        translateFile = {};
      }

      addTranslation(translateService, lang, translateFile);
    });

    startTranslateService(translateService);
  }
}
