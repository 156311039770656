import { Injectable, Optional, Provider, SkipSelf } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface IFlag {
  enabled: boolean;
  feature_id: string;
}

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private readonly sessionStorageKey: string = 'VCFLAGS';

  flags: any = {};

  constructor(private readonly _http: HttpClient) {
    // cache values in sessionStorage
    const existing = window.sessionStorage.getItem(this.sessionStorageKey);
    if (existing) {
      this.flags = JSON.parse(existing);
    }
  }

  check(key: string, refresh?: boolean): Observable<boolean> {
    if (!key) {
      return of(true);
    }

    // pull from cache when possible
    if (!refresh && key in this.flags) {
      return of(this.flags[key]);
    }

    return this._http.get(`/api/features?feature_ids=${key}`).pipe(
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      }),
      map((items: IFlag[]) => {
        if (items.length) {
          const [{ enabled }] = items;

          // keep the map current
          this.set(key, enabled);

          return enabled;
        }
        // if it's not even on the server, then it's not available
        return false;
      })
    );
  }

  set(key: string, enabled: boolean): void {
    this.flags[key] = enabled;
    window.sessionStorage.setItem(
      this.sessionStorageKey,
      JSON.stringify(this.flags)
    );
  }
}

export function TD_FLAGS_PROVIDER_FACTORY(
  parent: FeatureFlagService,
  http: HttpClient
): FeatureFlagService {
  return parent || new FeatureFlagService(http);
}

// NOTE: singleton service pattern
export const TD_FLAGS_PROVIDER: Provider = {
  provide: FeatureFlagService,
  deps: [[new Optional(), new SkipSelf(), FeatureFlagService], HttpClient],
  useFactory: TD_FLAGS_PROVIDER_FACTORY,
};
