<mat-list-item
  *ngIf="expanded; else collapsed"
  [matMenuTriggerFor]="yourAccountMenu"
  class="expanded-item"
  [ngClass]="isActiveRoute()"
  data-cy="userMenu"
>
  <mat-icon matListIcon>person</mat-icon>
  <h3 matLine>
    <span class="mat-body-2">{{ 'YOUR_ACCOUNT' | translate }}</span>
  </h3>
</mat-list-item>

<ng-template #collapsed>
  <mat-list-item disableRipple>
    <button
      mat-icon-button
      [matTooltip]="'YOUR_ACCOUNT' | translate"
      matTooltipPosition="after"
      [matMenuTriggerFor]="yourAccountMenu"
      [ngClass]="isActiveRoute()"
      data-cy="userMenu"
    >
      <mat-icon matListIcon>person</mat-icon>
    </button>
  </mat-list-item>
</ng-template>

<mat-menu #yourAccountMenu="matMenu" backdropClass="rail-menu">
  <button mat-menu-item class="user-info" disabled>
    <mat-list>
      <mat-list-item>
        <mat-icon matListAvatar>person</mat-icon>
        <span matLine>{{ displayName$ | async }}</span>
        <span
          matLine
          class="secondary"
          *ngIf="!isDbUser && displayEmail$ | async as displayEmail"
          >{{ displayEmail }}</span
        >
      </mat-list-item>
    </mat-list>
  </button>

  <mat-divider></mat-divider>

  <button mat-menu-item (click)="launchAccountSettings()">
    <mat-icon matListIcon>settings</mat-icon>
    <span>{{ 'YOUR_ACCOUNT_MENU.ACCOUNT_SETTINGS' | translate }}</span>
  </button>

  <mat-divider></mat-divider>

  <button
    data-cy="signout"
    mat-menu-item
    class="signout-block"
    (click)="logout()"
  >
    <mat-icon matListIcon></mat-icon>
    <span>{{ 'SIGN_OUT' | translate }}</span>
  </button>
</mat-menu>
