import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError, of } from 'rxjs';
import { AuthenticationService } from '@janus/authentication';
import { TranslateService } from '@ngx-translate/core';

const UNAUTHORIZED = 401;
const PROXY_TIMEOUT = 504;

@Injectable({
  providedIn: 'root',
})
export class AuthRedirectInterceptor implements HttpInterceptor {
  constructor(
    private _authenticationService: AuthenticationService,
    private _translate: TranslateService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.indexOf('/query-grid-config') > -1) {
      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === UNAUTHORIZED) {
            this._authenticationService.logout();
            return of();
          } else if (error.status === PROXY_TIMEOUT) {
            const timeoutError = {
              message: this._translate.instant('QUERYGRID.QGM_NOT_ACCESSIBLE'),
            };
            const timeoutResponse = Object.assign({}, error, {
              error: timeoutError,
            });
            return throwError(() => timeoutResponse);
          } else {
            return throwError(() => error);
          }
        })
      );
    }
    return next.handle(request);
  }
}
