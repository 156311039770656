import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { ApiService } from '../common/api.service';

export enum ELinkVersionType {
  'ACTIVE',
  'PENDING',
  'PREVIOUS',
}

export interface ILinkFlatten {
  name: string;
  description: string;
  versionId?: string;
  fabricId: string;
  initiatorConnectorId: string;
  initiatorProperties?: string;
  overridableInitiatorPropertyNames?: string[] | null;
  initiatorNetworkId: string;
  initiatorThreadsPerQuery?: number;
  targetConnectorId: string;
  targetProperties?: string;
  overridableTargetPropertyNames?: string[] | null;
  targetNetworkId: string;
  targetThreadsPerQuery?: number;
  commPolicyId: string;
  userMappingId?: string;
  usersToTroubleshoot: any;
  enableAcks: boolean;
  bridges?: ILinkBridgeHop[];
}

export interface ILink {
  id: string;
  name: string;
  description?: string;
  version?: ILinkVersion;
  active?: ILinkVersion;
  previous?: ILinkVersion;
  pending?: ILinkVersion;
  isActive(): boolean;
  isPending(): boolean;
  isPrevious(): boolean;
  hasPendingVersion: boolean;
}

export interface ILinkVersion {
  versionId: string;
  versionType: ELinkVersionType;
  fabricId: string;
  targetNetworkId?: string;
  initiatorNetworkId?: string;
  initiatorConnectorId?: string;
  initiatorProperties?: Record<string, any>;
  overridableInitiatorPropertyNames?: string[] | null;
  targetConnectorId?: string;
  targetProperties?: Record<string, any>;
  overridableTargetPropertyNames?: string[] | null;
  commPolicyId?: string;
  userMappingId?: string;
  bridges?: ILinkBridgeHop[];
  enableAcks: boolean;
  usersToTroubleshoot?: any;
  _extraInfo?: {
    initiatingSystemName: string;
    initiatingConnectorName: string;
    targetSystemName: string;
    targetConnectorName: string;
  };
}

export interface ILinkBridgeHop {
  bridgeId: string;
  initiatorNetworkId?: string;
  targetNetworkId?: string;
  commPolicyId: string;
  threadsPerQuery: number;
}

export enum LinkItemType {
  BRIDGE = 'BRIDGE',
  NETWORK = 'NETWORK',
  COMMUNICATION_POLICY = 'COMMUNICATION_POLICY',
  USER_MAPPING = 'USER_MAPPING',
}

export interface ILinkType {
  linkItemType: LinkItemType;
  linkItemId: string;
  linkItemName: string;
  linkSubType?: string;
  helpId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class LinksService {
  private linkChangedBS: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  linkChanged$ = this.linkChangedBS.asObservable();

  constructor(private _http: HttpClient, private _apiService: ApiService) {}

  getLink(id: string): Observable<ILink> {
    const baseUrl: string = `${this.getBaseUrl()}/config/links/${id}?extraInfo=true`;
    return this._http.get<any>(baseUrl).pipe(
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      })
    );
  }

  getLinks(filterBy?: string, filterByValue?: string): Observable<ILink[]> {
    let baseUrl: string = `${this.getBaseUrl()}/config/links?extraInfo=true`;
    if (filterBy) {
      switch (filterBy) {
        case 'SYSTEM':
          baseUrl = baseUrl + '&filterBySystemId=' + filterByValue;
          break;
        case 'FABRIC':
          baseUrl = baseUrl + '&filterByFabricId=' + filterByValue;
          break;
        case 'COMMUNICATION_POLICY':
          baseUrl = baseUrl + '&filterByCommPolicyId=' + filterByValue;
          break;
        case 'NETWORK':
          baseUrl = baseUrl + '&filterByNetworkId=' + filterByValue;
          break;
        case 'BRIDGE':
          baseUrl = baseUrl + '&filterByBridgeId=' + filterByValue;
          break;
        case 'USER_MAPPING':
          baseUrl = baseUrl + '&filterByUserMappingId=' + filterByValue;
          break;
      }
    }
    return this._http.get<any>(baseUrl).pipe(
      map((links: ILink[]) => {
        return this.renderLinks(links);
      }),
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      })
    );
  }

  deleteLink(link: ILink): Observable<any> {
    let url = `${this.getBaseUrl()}/config/links/${link.id}`;
    if (link.isPrevious()) {
      url = url + `/previous`;
    } else if (link.isPending()) {
      url = url + `/pending`;
    }
    return this._http.delete<any>(url).pipe(
      map((resp: any) => {
        this.linkChangedBS.next(true);
        return resp;
      }),
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      })
    );
  }

  renderLinks(links: ILink[]): ILink[] {
    const newLinks: ILink[] = [];
    links.forEach((link) => {
      const active = this.renderLink(
        link,
        ELinkVersionType.ACTIVE,
        link.active
      );
      const pending = this.renderLink(
        link,
        ELinkVersionType.PENDING,
        link.pending
      );
      const previous = this.renderLink(
        link,
        ELinkVersionType.PREVIOUS,
        link.previous
      );
      if (active) {
        if (pending) active.hasPendingVersion = true;
        newLinks.push(active);
      }
      if (pending) newLinks.push(pending);
      if (previous) newLinks.push(previous);
    });
    return newLinks;
  }

  renderLink(
    link: ILink,
    versionType: ELinkVersionType,
    version?: ILinkVersion
  ): ILink | null {
    if (version) {
      const newlink: ILink = {
        id: link.id,
        name: link.name,
        description: link.description,
        version: version,
        isActive: () => ELinkVersionType.ACTIVE === versionType,
        isPending: () => ELinkVersionType.PENDING === versionType,
        isPrevious: () => ELinkVersionType.PREVIOUS === versionType,
        hasPendingVersion: false,
      };
      if (newlink.version) {
        newlink.version.versionType = versionType;
      }
      return newlink;
    }
    return null;
  }

  activateLink(id: string, versionId: string): Observable<any> {
    return this._http
      .patch<any>(`${this.getBaseUrl()}/config/links/${id}/active`, versionId)
      .pipe(
        map((resp: any) => {
          this.linkChangedBS.next(true);
          return resp;
        }),
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  createLink(link: ILinkFlatten): Observable<any> {
    return this._http.post(`${this.getBaseUrl()}/config/links`, link).pipe(
      map((resp: any) => {
        this.linkChangedBS.next(true);
        return resp;
      }),
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      })
    );
  }

  // only updates name and description
  updateLink(id: string, link: ILinkFlatten): Observable<any> {
    return this._http
      .patch(`${this.getBaseUrl()}/config/links/${id}`, link)
      .pipe(
        map((resp: any) => {
          this.linkChangedBS.next(true);
          return resp;
        }),
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  updateLinkVersion(
    id: string,
    versionType: ELinkVersionType,
    link: ILinkFlatten
  ): Observable<any> {
    let baseUrl: string = `${this.getBaseUrl()}/config/links/${id}/`;
    baseUrl =
      versionType === ELinkVersionType.ACTIVE
        ? baseUrl + 'active'
        : baseUrl + 'pending';
    return this._http.put(baseUrl, link).pipe(
      map((resp: any) => {
        this.linkChangedBS.next(true);
        return resp;
      }),
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      })
    );
  }

  updateActiveLinkVersion(
    linkId: string,
    linkVersionPatch: Partial<ILinkVersion>
  ): Observable<ILinkVersion> {
    const url: string = `${this.getBaseUrl()}/config/links/${linkId}/active`;
    return this._http.get<any>(url).pipe(
      concatMap((linkVersion) => {
        const patchedLinkVersion = Object.assign(
          {},
          linkVersion,
          linkVersionPatch
        );
        return this._http.put<any>(url, patchedLinkVersion);
      }),
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      })
    );
  }

  getBaseUrl(): string {
    return this._apiService.getBaseUrl();
  }
}
