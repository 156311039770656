import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private accountId: string = '';
  private accountName: string = '';

  getBaseUrl(): string {
    if (this.accountId === '' || this.accountId === null) {
      throw new Error('Invalid account');
    }
    return `/api/accounts/${this.accountId}/query-grid-config`;
    //return '/query-grid/api/v1';
  }

  getBaseUrlProvision(): string {
    return `/api/accounts/${this.accountId}/query-grid-provision`;
  }

  setAccountId(accId: string) {
    this.accountId = accId;
  }

  getAccountId() {
    return this.accountId;
  }

  setAccountName(accName: string) {
    this.accountName = accName;
  }

  getAccountName() {
    return this.accountName;
  }
}
