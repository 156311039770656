import { Injectable } from '@angular/core';

export interface ITaskConfig {
  interval: number;
  fuzzFactor: number;
}

export const DEFAULT_CONFIG: ITaskConfig = {
  interval: 30000,
  fuzzFactor: 500,
};

export interface IScheduledTask {
  start: () => void;
  stop: () => void;
  restart: () => void;
  complete: () => void;
}

type IScheduledTaskStatus =
  | 'scheduled'
  | 'running'
  | 'stopping'
  | 'restarting'
  | 'done';

class ScheduledTask implements IScheduledTask {
  private status: IScheduledTaskStatus;
  private interval: number;
  private timeout: any;

  constructor(private task: () => Promise<any>, private config: ITaskConfig) {
    this.status = 'done';
    this.interval = this.findInterval();
  }

  start(): void {
    if (this.status === 'running') {
      this.restart();
    } else if (this.status === 'done') {
      this.timeout = setTimeout(() => {
        this.setStatus('running');
        this.task()
          .then(() => this.completeExecution())
          .catch(() => this.completeExecution());
      }, this.interval);
      this.setStatus('scheduled');
    }
  }

  private completeExecution(): void {
    clearTimeout(this.timeout);
    if (this.status === 'stopping') {
      this.setStatus('done');
    } else {
      this.setStatus('done');
      this.start();
    }
  }

  stop(): void {
    if (this.status === 'running') {
      this.setStatus('stopping');
    } else {
      clearTimeout(this.timeout);
      this.setStatus('done');
    }
  }

  complete(): void {
    this.stop();
  }

  restart(): void {
    if (this.status === 'running' || this.status === 'stopping') {
      this.setStatus('restarting');
    } else if (this.status === 'scheduled') {
      this.stop();
      this.start();
    } else if (this.status === 'done') {
      this.start();
    }
  }

  private setStatus(newStatus: IScheduledTaskStatus) {
    this.status = newStatus;
  }

  private findInterval(): number {
    return (
      this.config.interval + Math.floor(Math.random() * this.config.fuzzFactor)
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class SchedulerService {
  createTask(
    callback: () => Promise<any>,
    config?: Partial<ITaskConfig>
  ): IScheduledTask {
    const taskConfig = Object.assign({}, DEFAULT_CONFIG, config);
    return new ScheduledTask(callback, taskConfig);
  }
}
