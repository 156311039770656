<div
  class="dialog-reset"
  (keyup.enter)="!actionButtonDisabled && actionHit.emit()"
>
  <h2 mat-dialog-title class="mat-title">{{ dialogTitle }}</h2>

  <mat-divider></mat-divider>

  <div class="dialog-content">
    <ng-content></ng-content>
  </div>

  <mat-divider></mat-divider>

  <mat-dialog-actions class="dialog-shell-actions">
    <button
      mat-raised-button
      [color]="actionButtonColor || 'accent'"
      [class.caution]="isActionButtonColor('caution')"
      [class.negative]="isActionButtonColor('negative')"
      [disabled]="actionButtonDisabled"
      (click)="actionHit.emit()"
    >
      {{ actionButtonName }}
    </button>

    <button mat-button (click)="cancelHit.emit()">
      {{ cancelButtonName }}
    </button>
  </mat-dialog-actions>
</div>
