import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export const NLB_UPDATE_LOCAL_STORAGE_KEY = 'NLB_UPDATED_ADDRESS_KEY';
export const NLB_UPDATED_NETWORK_NAME = 'NLB_UPDATED_NETWORK_NAME';
export const DEFAULT_EXPIRY_TIMER_IN_SECONDS = 3600;
export const IMPACTED_DATA_SOURCES_NAMES_AFTER_LB_UPDATE =
  'IMPACTED_DATA_SOURCES_AFTER_LB_UPDATE';

@Injectable({
  providedIn: 'root',
})
export class NetworksLoadBalancerUpdateService {
  private updateNWCompletedBS: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  updateNWCompleted$ = this.updateNWCompletedBS.asObservable();

  setLocalStorageItemWithExpiry(
    key: string,
    value: string,
    expiryInSeconds: number
  ): void {
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + expiryInSeconds * 1000, // Calculate expiry time in milliseconds
    };
    localStorage.setItem(key, JSON.stringify(item));
  }

  getLocalStorageItem(key: string): string | null {
    const value = localStorage.getItem(key);
    if (value) {
      const item = JSON.parse(value);
      if (item && new Date().getTime() < item.expiry) {
        return item.value;
      }
      // Item has expired or does not exist
      localStorage.removeItem(key);
    }
    return null;
  }

  checkIfNLBUpdateHappendInLastOneHour(): boolean {
    return this.getLocalStorageItem('lbUpdate') ? true : false;
  }

  updateNetworkCompleted(nwName: string): void {
    this.updateNWCompletedBS.next(nwName);
  }

  checkIfReregisterOptionNeedsBeDisplayed(dsname: string): boolean {
    const dataSources = this.getLocalStorageItem(
      IMPACTED_DATA_SOURCES_NAMES_AFTER_LB_UPDATE
    );
    if (dataSources) {
      if (JSON.parse(dataSources).includes(dsname)) {
        return true;
      }
    }
    return false;
  }
}
