import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from '../common/api.service';

export enum FabricObjectType {
  SYSTEM = 'SYSTEM',
  CONNECTOR = 'CONNECTOR',
  LINK = 'LINK',
}

export interface IQGObject {
  id: string;
  name: string;
  fullName?: string;
  description?: string;
  lastModified?: number;
  active?: any;
  pending?: any;
  softwareVersion?: any;
  _extraInfo?: any;
  symbol?: string;
  label?: any;
  symbolSize?: number;
  type?: FabricObjectType;
}

export interface IQGFabricInfo extends IQGObject {
  nodesOnline?: number;
  nodesOffline?: number;
  port?: number;
  pending?: any;
  previous?: any;
  authKeySize?: number;
  tags?: any;
}

export interface IFabricSystem extends IQGObject {
  bridgeOnly?: boolean;
  dataCenterId: string;
  dataCenterName: string;
  region: string;
  platformType: string;
  systemType: string;
  nodesOnline: number;
  nodesOffline: number;
  connectors?: any[];
  index?: number;
}

export interface IFabricConnector extends IQGObject {
  softwareName: string;
  driverNodes?: any[];
  systemId: string;
  fabricId: string;
  index?: number;
  driversOnline?: number;
  driversOffline?: number;
}

export interface IFabricLink extends IQGObject {
  initiatorConnectorId: string;
  targetConnectorId: string;
  source: string;
  target: string;
  fabricId: string;
  commonLinks?: IFabricLink[];
  index?: number;
  version?: string;
}

export interface IFabricOverview {
  fabrics: IQGFabricInfo[];
  systems: IFabricSystem[];
  connectors: IFabricConnector[];
  links: IFabricLink[];
}

export interface IPendingVersion {
  port: number;
  name: string;
  description: string;
  authKeySize: number;
  softwareVersion: string;
  tags: {
    destinationSystem: string;
  };
}

export interface ISoftwareVersion {
  version: string;
}

@Injectable({
  providedIn: 'root',
})
export class FabricService {
  constructor(private http: HttpClient, private _apiService: ApiService) {}

  getFabrics(): Observable<IQGFabricInfo[]> {
    return this.http
      .get<IQGFabricInfo[]>(`${this.getBaseUrl()}/config/fabrics`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  getBaseUrl(): string {
    return this._apiService.getBaseUrl();
  }

  activateVersion(fabricId: string, versionId: string): Observable<any> {
    return this.http
      .patch<IPendingVersion>(
        `${this.getBaseUrl()}/config/fabrics/${fabricId}/active`,
        versionId
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  addPendingVersion(
    fabricId: string,
    pending: IPendingVersion
  ): Observable<IPendingVersion> {
    return this.http
      .put<IPendingVersion>(
        `${this.getBaseUrl()}/config/fabrics/${fabricId}/pending`,
        pending
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  deleteFabricPreviousVersion(fabricId: string): Observable<any> {
    return this.http
      .delete(`${this.getBaseUrl()}/config/fabrics/${fabricId}/previous`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  deleteFabricPendingVersion(fabricId: string): Observable<any> {
    return this.http
      .delete(`${this.getBaseUrl()}/config/fabrics/${fabricId}/pending`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  getSoftwareVersions(): Observable<ISoftwareVersion[]> {
    return this.http
      .get<ISoftwareVersion[]>(
        `${this.getBaseUrl()}/software?filterByName=tdqg-fabric`
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  getFabricOverview(): Observable<any> {
    return forkJoin([
      this.getFabricForChart(),
      this.getSystems(),
      this.getConnectors(),
      this.getLinks(),
    ]).pipe(
      map(([fabrics, systems, connectors, links]) => {
        return {
          fabrics,
          systems,
          connectors,
          links,
        };
      }),
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      })
    );
  }

  private getFabricForChart(): Observable<IQGFabricInfo[]> {
    return this.http.get<IQGFabricInfo[]>(
      `${this.getBaseUrl()}/config/fabrics?extraInfo=true`
    );
  }

  private getSystems(): Observable<IFabricSystem[]> {
    return this.http
      .get<IFabricSystem[]>(
        `${this.getBaseUrl()}/config/systems?extraInfo=true`
      )
      .pipe(
        map((systems) => {
          return systems
            .filter((system) => !system.bridgeOnly)
            .map(
              ({
                id,
                name,
                platformType,
                softwareVersion,
                dataCenterId,
                dataCenterName,
                _extraInfo,
                region,
                systemType,
              }) => {
                return {
                  id,
                  name,
                  platformType,
                  softwareVersion,
                  dataCenterName,
                  dataCenterId,
                  type: FabricObjectType.SYSTEM,
                  ..._extraInfo,
                  region: region?.toUpperCase(),
                  systemType,
                };
              }
            );
        })
      );
  }

  private getConnectors(): Observable<IFabricConnector[]> {
    return this.http.get<IFabricConnector[]>(
      `${this.getBaseUrl()}/config/connectors?extraInfo=true`
    );
  }

  private getLinks(): Observable<IFabricLink[]> {
    return this.http.get<IFabricLink[]>(`${this.getBaseUrl()}/config/links`);
  }
}
