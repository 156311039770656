<mat-progress-spinner
  mode="indeterminate"
  class="center"
  *ngIf="connecting || !!(loading$ | async)"
></mat-progress-spinner>
<ng-template #noEnvironments>
  <div class="no-environments" *ngIf="!(loading$ | async)">
    <div class="mat-avatar" (click)="connect()">
      <mat-icon matListAvatar svgIcon="td-icons:server"></mat-icon>
    </div>
    <span class="title pad-top">{{ 'NO_ENVIRONMENTS' | translate }}</span>
    <small class="mat-caption">{{
      'NO_ENVIRONMENTS_SUBTITLE' | translate
    }}</small>
    <button
      mat-button
      mat-raised-button
      color="accent"
      class="push-bottom"
      (click)="exitToCreateAccount()"
    >
      {{ 'CREATE_AN_ENVIRONMENT' | translate }}
    </button>
  </div>
</ng-template>

<ng-container
  *ngIf="isDesktop || (system$ | async)?.length; else noEnvironments"
>
  <h2 mat-dialog-title class="push-bottom">
    {{ 'CREATE_CONNECTION' | translate }}
  </h2>
  <mat-divider></mat-divider>
  <div *ngIf="errorMsg" class="errmsgdiv">
    <td-message [sublabel]="errorMsg | translate" color="warn"></td-message>
  </div>
  <form [formGroup]="connectionForm">
    <mat-dialog-content class="pad-top">
      <div *ngIf="isDesktop; then manualSystem; else selectSystem"></div>
      <div *ngIf="basicAuthEnabled; then basicCredentials"></div>
      <div *ngIf="showDefaultDb; then defaultDatabase"></div>
    </mat-dialog-content>

    <ng-template #manualSystem>
      <div formGroupName="system">
        <div layout="row">
          <mat-form-field appearance="outline" flex>
            <mat-label>{{ 'CONNECTION_NAME' | translate }}</mat-label>
            <input
              matInput
              formControlName="name"
              type="text"
              cdkFocusInitial
              required
              (change)="syncName($event.target.value)"
            />
          </mat-form-field>
        </div>
        <div layout="row">
          <mat-form-field appearance="outline" flex>
            <mat-label>{{ 'HOSTNAME' | translate }}</mat-label>
            <input matInput formControlName="host" type="text" required />
          </mat-form-field>
        </div>
      </div>
      <div layout="row">
        <mat-form-field appearance="outline" flex>
          <mat-label>{{ 'LOGMECH' | translate }}</mat-label>
          <mat-select formControlName="logmech" name="logmech">
            <mat-option value="TD2" default>TD2</mat-option>
            <mat-option value="LDAP">LDAP</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-template>

    <ng-template #selectSystem>
      <div layout="row" formGroupName="system">
        <mat-form-field appearance="outline" flex>
          <mat-label>
            {{ 'CREDENTIALS_ENVIRONMENT' | translate }}
          </mat-label>
          <mat-select
            (selectionChange)="updateSystem($event.value)"
            name="system"
            formControlName="nickname"
            [compareWith]="compareSystemWith"
          >
            <mat-option
              *ngFor="let sys of system$ | async"
              [value]="sys"
              class="vui-credentials-dialog-system-option"
              >{{ sys.nickname }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-template>

    <ng-template #basicCredentials>
      <div formGroupName="basicCredentials">
        <div layout="row">
          <mat-form-field appearance="outline" flex>
            <mat-label>
              {{ 'USERNAME' | translate }}
            </mat-label>
            <input
              formControlName="username"
              (keyup.enter)="connectionForm.valid && connect()"
              matInput
              type="text"
              name="username"
              required
            />
          </mat-form-field>
        </div>
        <div layout="row">
          <mat-form-field appearance="outline" flex>
            <mat-label>
              {{ 'PASSWORD' | translate }}
            </mat-label>
            <input
              matInput
              [type]="showPassword ? 'text' : 'password'"
              (keyup.enter)="connectionForm.valid && connect()"
              formControlName="password"
              name="password"
              required
            />
            <button
              type="button"
              mat-button
              matSuffix
              mat-icon-button
              class="tc-faded"
              (click)="showPassword = !showPassword"
            >
              <mat-icon>{{
                showPassword ? 'visibility' : 'visibility_off'
              }}</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
    </ng-template>

    <ng-template #defaultDatabase>
      <div layout="row">
        <mat-form-field appearance="outline" flex>
          <mat-label>{{ 'DEFAULT_DATABASE' | translate }}</mat-label>
          <input matInput formControlName="database" type="text" />
        </mat-form-field>
      </div>
    </ng-template>
    <mat-divider></mat-divider>
    <div *ngIf="_debugForm; then formDebug"></div>
    <mat-dialog-actions>
      <button
        mat-raised-button
        color="primary"
        class="push-right-sm"
        type="reset"
        (click)="cancel()"
      >
        {{ 'CANCEL' | translate }}
      </button>
      <button
        mat-raised-button
        color="accent"
        [disabled]="!connectionForm.valid || connecting"
        (click)="connect()"
      >
        {{ 'CONNECT' | translate }}
      </button>
    </mat-dialog-actions>
  </form>
</ng-container>
