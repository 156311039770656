// Map object kind value to translation key and type value
// Type is usd by object-type-icon component to determine icon
export const OBJECT_KINDS = {
  A: { label: 'AGGREGATE_FUNCTION', type: 'aggregate_function' },
  B: { label: 'COMBO_FUNCTION', type: 'combined_aggregate_functions' },
  C: { label: 'TABLE_OP_PARSER_CONTRACT_FN', type: 'table_function' },
  D: { label: 'JAR', type: 'jar' },
  E: { label: 'EXTERNAL_STORED_PROCEDURE', type: 'stored_procedure' },
  F: { label: 'STANDARD_FUNCTION', type: 'standard_function' },
  G: { label: 'TRIGGER', type: 'trigger' },
  H: {
    label: 'INSTANCE_OR_CONSTRUCTOR_METHOD',
    type: 'instance_or_constructor_method',
  },
  I: { label: 'JOIN_INDEX', type: 'join_index' },
  J: { label: 'JOURNAL', type: 'journal' },
  K: { label: 'FOREIGN_SERVER', type: 'server_object' },
  L: { label: 'USER_DEFINED_TABLE_OPERATOR', type: 'user_defined_method' },
  M: { label: 'MACRO', type: 'macro' },
  N: { label: 'HASH_INDEX', type: 'hash_index' },
  O: { label: 'TABLE_WITH_NO_PRIMARY_INDEX', type: 'table' },
  P: { label: 'STORED_PROCEDURE', type: 'stored_procedure' },
  Q: { label: 'QUEUE_TABLE', type: 'table' },
  R: { label: 'TABLE_FUNCTION', type: 'function' },
  S: { label: 'ORDERED_ANALYTICAL_FUNCTION', type: 'function' },
  T: { label: 'TABLE', type: 'table' },
  U: { label: 'USER_DEFINED_TYPE', type: 'user_defined_data_type' },
  V: { label: 'VIEW', type: 'view' },
  X: { label: 'AUTHORIZATION', type: 'authorization' },
  Y: { label: 'GLOP_SET', type: 'glop_set' },
  Z: { label: 'USER_INSTALLED_FILE', type: 'user_installed_file' },
};
