import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  lastValueFrom,
  Observable,
  throwError,
  timer,
} from 'rxjs';
import {
  map,
  mapTo,
  mergeMap,
  retryWhen,
  switchMap,
  timeout,
} from 'rxjs/operators';

import { removeVantageBaseURL, setVantageBaseURL } from './api-pointer';

import {
  IConnectOptions,
  IVantageConnectionState,
} from './connection-interface';
import {
  IConnectable,
  IQueryResultSet,
  ISQLEConnection,
  VantageQueryService,
} from './query.service';

const EDITOR_CONNECTION_SESSION_KEY = 'vantage.editor_connection_state';

export function generateConnectionKey(
  connection: ISQLEConnection,
  displayName?: string
): string {
  const creds = connection?.creds || displayName;
  if (creds) {
    return `${connection.system.nickname}${creds}`;
  }
}

export function createGUID(): string {
  let d = new Date().getTime();
  if (window.performance && typeof window.performance.now === 'function') {
    d += performance.now();
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

@Injectable({
  providedIn: 'root',
})
export class VantageEditorConnectionService {
  initialized = false;

  protected readonly _currentConnectionSubject: BehaviorSubject<ISQLEConnection> =
    new BehaviorSubject<ISQLEConnection>(undefined);
  private readonly _currentScriptConnectionSubject: BehaviorSubject<ISQLEConnection> =
    new BehaviorSubject<ISQLEConnection>(undefined);
  protected readonly _connectionsSubject: BehaviorSubject<ISQLEConnection[]> =
    new BehaviorSubject<ISQLEConnection[]>([]);
  public currentConnection$: Observable<ISQLEConnection> =
    this._currentConnectionSubject.asObservable();
  // Object reference managed externally from the service.
  // Allows for other components to be alerted of script connection change events.
  public currentScriptConnection$: Observable<ISQLEConnection> =
    this._currentScriptConnectionSubject.asObservable();
  public connections$: Observable<ISQLEConnection[]> =
    this._connectionsSubject.asObservable();

  constructor(private _queryService: VantageQueryService) {
    this._getConnectionState();
  }

  private set _currentConnection(connection: ISQLEConnection) {
    if (!this.initialized) {
      this._getConnectionState();
    }
    this._setConnectionState({
      current: connection,
      connections: this._connections,
    });
    this._currentConnectionSubject.next(connection);
    if (connection) {
      setVantageBaseURL(connection.system.siteURL, connection.system.nickname);
    }
  }
  private get _currentConnection(): ISQLEConnection {
    if (!this.initialized) {
      this._getConnectionState();
    }
    return this._currentConnectionSubject.getValue();
  }
  public get currentConnection(): ISQLEConnection {
    return this._currentConnection;
  }

  private set _connections(connections: ISQLEConnection[]) {
    if (!this.initialized) {
      this._getConnectionState();
    }
    this._setConnectionState({
      current: this._currentConnection,
      connections,
    });
    this._connectionsSubject.next(connections);
  }
  private get _connections(): ISQLEConnection[] {
    if (!this.initialized) {
      this._getConnectionState();
    }
    return this._connectionsSubject.getValue();
  }

  public get connections(): ISQLEConnection[] {
    return this._connections;
  }

  setCurrentScriptConnection(connection: ISQLEConnection): void {
    return this._currentScriptConnectionSubject.next(connection);
  }

  getCurrentScriptConnection(): ISQLEConnection {
    return this._currentScriptConnectionSubject.value;
  }

  public getById(id: string): ISQLEConnection {
    const connectionArray = this._connections.filter(
      (connection: ISQLEConnection) => {
        return connection.connectionId === id;
      }
    );
    if (connectionArray && connectionArray.length > 0) {
      return connectionArray[0];
    }
    return undefined;
  }

  connectDbUser(system: IConnectable): void {
    const connection: ISQLEConnection = { system: system };
    if (this.exists(connection)) {
      this.setAsCurrent(connection)
        .toPromise()
        .catch((err) => {
          throw err;
        });
    } else {
      this.add(connection, true)
        .toPromise()
        .catch((err) => {
          throw err;
        });
    }
  }

  public add(
    connection: ISQLEConnection,
    setAsCurrent: boolean,
    options?: IConnectOptions
  ): Observable<ISQLEConnection> {
    if (!connection.connectionId) {
      connection.connectionId = createGUID();
    }
    if (this._getConnectionIndex(connection) > -1) {
      throw Error('Connection already exists');
    }
    return this.pingAndSave(connection, setAsCurrent, options);
  }

  public addSavedConnection(
    connection: ISQLEConnection,
    setAsCurrent: boolean,
    options?: IConnectOptions
  ): Observable<ISQLEConnection> {
    if (!connection.connectionId) {
      connection.connectionId = createGUID();
    }
    if (
      connection.savedConnection &&
      this._getConnectionIndex(connection) > -1
    ) {
      throw Error('Connection already exists');
    }

    return this.pingAndSave(connection, setAsCurrent, options);
  }

  public setAsCurrent(
    connection: ISQLEConnection,
    options?: IConnectOptions
  ): Observable<ISQLEConnection> {
    if (this._getConnectionIndex(connection) > -1) {
      return this.pingAndSave(connection, true, options);
    }

    throw Error('Connection does not exist');
  }

  public clearCurrent(): void {
    this._currentConnection = undefined;
  }

  public isCurrent(connection: ISQLEConnection): boolean {
    if (
      (this.currentConnection && !connection) ||
      (!this.currentConnection && connection)
    ) {
      return false;
    }
    return this._areEqual(connection, this.currentConnection);
  }

  setCurrentComputeGroup(computeGroup: string) {
    if (this.currentConnection) {
      this.currentConnection.computeGroup = computeGroup;
    }
  }

  setFavoriteDatabases(favoriteDatabases: string[]) {
    if (this.currentConnection) {
      this.currentConnection.favoriteDatabases = favoriteDatabases;
    }
    this._setConnectionState({
      current: this.currentConnection,
      connections: this._connections,
    });
  }

  public remove(connection: ISQLEConnection): ISQLEConnection {
    const index: number = this._getConnectionIndex(connection);
    if (index > -1) {
      this._connections = [
        ...this._connections.slice(0, index),
        ...this._connections.slice(index + 1),
      ];
      this._currentConnection =
        this._currentConnection &&
        this._areEqual(this._currentConnection, connection)
          ? undefined
          : this._currentConnection;
      removeVantageBaseURL(connection.system.nickname);

      return connection;
    }

    return undefined;
  }

  public removeAll(): void {
    this._connections = [];
    this._currentConnection = undefined;
  }

  public exists(connection: ISQLEConnection): boolean {
    return this._getConnectionIndex(connection) > -1;
  }

  public databaseExists(
    connection: ISQLEConnection,
    databaseName: string
  ): Observable<boolean> {
    const queryStr: string =
      "SELECT databasename FROM dbc.databasesVX WHERE databasename='" +
      databaseName +
      "';";
    return this._queryService
      .querySystem(connection, {
        query: queryStr,
        isMetadataQuery: true,
      })
      .pipe(
        map((resultSet: IQueryResultSet) => {
          if (
            resultSet.results &&
            resultSet.results[0] &&
            resultSet.results[0].rowCount > 0
          ) {
            return true;
          } else {
            return false;
          }
        })
      );
  }

  public pingAndSave(
    connection: ISQLEConnection,
    setAsCurrent: boolean,
    opts?: IConnectOptions
  ): Observable<ISQLEConnection> {
    return this._queryService
      .querySystem(connection, { query: 'SELECT 1;', isMetadataQuery: true })
      .pipe(
        // timeout connection if more than 7 seconds
        timeout(opts?.timeout || 7000),
        // retry only after a certain number of attempts or if the error is something else than 420
        retryWhen((errors: Observable<{ httpStatus: number }>) => {
          return errors.pipe(
            mergeMap((error: { httpStatus: number }, index: number) => {
              const retryAttempt: number = index + 1;
              if (
                retryAttempt > (opts?.attempts || 2) ||
                error.httpStatus === 420
              ) {
                return throwError(error);
              }
              return timer(0);
            })
          );
        }),
        switchMap(async () => {
          if (connection.hasComputeGroupsView === undefined) {
            const queryStr = `SELECT * FROM dbc.tablesv WHERE TableName LIKE 'ComputeGroups';`;

            const results = await lastValueFrom(
              this._queryService.querySystem(connection, { query: queryStr })
            );
            connection.hasComputeGroupsView =
              results?.results?.length > 0 && results.results[0].rowCount > 0;
          }
          const index: number = this._getConnectionIndex(connection);
          if (index === -1) {
            this._connections = [...this._connections, connection];
          }
          if (setAsCurrent) {
            this._currentConnection = connection;
          }
        }),
        mapTo(connection)
      );
  }

  async checkComputeGroupView(connection: ISQLEConnection): Promise<boolean> {
    const queryStr = `SELECT * FROM dbc.tablesv WHERE TableName LIKE 'ComputeGroups';`;

    const results = await lastValueFrom(
      this._queryService.querySystem(connection, { query: queryStr })
    );
    connection.hasComputeGroupsView =
      results?.results?.length > 0 && results.results[0].rowCount > 0;
    return results.results.length > 0;
  }

  private _areEqual(
    connectionA: ISQLEConnection,
    connectionB: ISQLEConnection
  ): boolean {
    return (
      connectionA.creds === connectionB.creds &&
      connectionA.system.nickname === connectionB.system.nickname
    );
  }

  private _getConnectionIndex(connection: ISQLEConnection): number {
    return this.connections.findIndex((conn: ISQLEConnection) =>
      this._areEqual(connection, conn)
    );
  }

  protected _getConnectionState(): void {
    try {
      const connectionState: IVantageConnectionState = JSON.parse(
        sessionStorage.getItem(EDITOR_CONNECTION_SESSION_KEY)
      );
      if (connectionState) {
        if (connectionState.current) {
          setVantageBaseURL(
            connectionState.current.system.siteURL,
            connectionState.current.system.nickname
          );
        }
        let didUpdate = false;
        if (!this.initialized && connectionState.connections) {
          connectionState.connections.forEach((connection: ISQLEConnection) => {
            if (!connection.connectionId) {
              connection.connectionId = createGUID();
              didUpdate = true;
            }
          });
        }
        if (didUpdate) {
          this._setConnectionState(connectionState);
        }
        if (connectionState.connections) {
          // Sort first by system nickname and then by user name
          connectionState.connections.sort(
            (a: ISQLEConnection, b: ISQLEConnection) => {
              if (a.system.nickname === b.system.nickname) {
                const aName = atob(a.creds).split(':')[0];
                const bName = atob(b.creds).split(':')[0];
                const value = aName < bName ? -1 : 1;
                return value;
              }
              const value = a.system.nickname < b.system.nickname ? -1 : 1;
              return value;
            }
          );
        }

        this._currentConnectionSubject.next(connectionState.current);
        this._connectionsSubject.next(connectionState.connections);
        this.initialized = true;
      } else {
        this._currentConnectionSubject.next(undefined);
        this._connectionsSubject.next([]);
        this.initialized = true;
      }
    } catch {
      this._currentConnectionSubject.next(undefined);
      this._connectionsSubject.next([]);
      this.initialized = true;
    }
  }

  protected _setConnectionState(
    connectionState: IVantageConnectionState
  ): void {
    sessionStorage.setItem(
      EDITOR_CONNECTION_SESSION_KEY,
      JSON.stringify(connectionState)
    );
  }

  public _saveConnection(connection: ISQLEConnection) {
    this._connections.push(connection);

    this.sortConnections();

    this._setConnectionState({
      connections: this.connections,
    });

    this._connectionsSubject.next(this.connections);
  }

  public _updateSavedConnection(tempId: string, newData: ISQLEConnection) {
    const index = this._connections.findIndex(
      (connection) => connection.tempId === tempId
    );

    if (index !== -1) {
      this._connections[index] = newData;

      this.sortConnections();

      this._setConnectionState({
        connections: this.connections,
      });
    }

    this._connectionsSubject.next(this.connections);
  }

  public _removeSavedConnection(tempId: string) {
    const index = this._connections.findIndex(
      (connection) => connection.tempId === tempId
    );

    if (index !== -1) {
      this._connections.splice(index, 1);

      this._setConnectionState({
        connections: this.connections,
      });
    }

    this._connectionsSubject.next(this.connections);
  }

  /**
   * Sort connection items alphabetically
   */
  public sortConnections() {
    this.connections.sort((a: ISQLEConnection, b: ISQLEConnection) => {
      const nicknameComparison: any = a.system.nickname.localeCompare(
        b.system.nickname
      );

      if (nicknameComparison === 0) {
        return a.username.localeCompare(b.username);
      } else {
        return nicknameComparison;
      }
    });
  }
}
