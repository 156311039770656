import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../environments/environment';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { combineLatest, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { FeatureFlagService } from '@td/vantage/flags';
import { ProfileService } from '@janus/profile';
import { TranslateService } from '@ngx-translate/core';
import { getSelectedLanguage } from '@janus/translate';

export const DATABASE_USER = 'DATABASE_USER';
export const ORG_ADMIN = 'ORG_ADMIN';
export const SUPERUSER = 'SUPERUSER';
export const SUPERUSER_EDITACCESS = 'SUPERUSER_EDITACCESS';
export const SUPERUSER_READONLY = 'SUPERUSER_READONLY';
export const SUPPORT_PORTAL_ADMIN = 'PORTAL_ADMIN';

export const DB_USER_ROUTES = [
  '/catalog',
  '/dashboard',
  '/data-sharing',
  '/editor',
  '/environments',
  '/',
  '/queries',
  '/query-grid',
  '/trial-expired',
];

export const ORG_ADMIN_ROUTES = [
  '/admin',
  '/alerts',
  '/catalog',
  '/consumption',
  '/cost-calculator',
  '/dashboard',
  '/data-sharing',
  '/data-sources',
  '/editor',
  '/environments',
  '/',
  '/organization/',
  '/queries',
  '/query-grid',
  '/trial-expired',
];

export const SUPERUSER_ROUTES = [
  '/admin',
  '/alerts',
  '/catalog',
  '/dashboard',
  '/data-sharing',
  '/data-sources',
  '/editor',
  '/',
  '/organizations',
  '/queries',
  '/query-grid',
  '/tenants',
  '/tenants/tenant',
  '/trial-expired',
  '/consumption',
];

export interface UserInfo {
  display_name: string;
  email: string;
  family_name: string;
  given_name: string;
  preferred_username: string;
  organization: string;
  org_id: string;
  roles: string[];
  sub?: string;
}

const EDITOR_CONNECTION_SESSION_KEY: string = 'vantage.editor_connection_state';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private _userInfo: UserInfo;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _http: HttpClient,
    private _profileService: ProfileService,
    private _featureFlagService: FeatureFlagService,
    private _translateService: TranslateService
  ) {}

  /*
   * Set User Info from auth userinfo endpoint
   */
  private async setUserInfo(): Promise<void> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const request: Observable<any> = this._http.get(
      '/auth/idp/userinfo.openid',
      {
        headers,
      }
    );
    this.userInfo = await request
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.goToLogin();
          throw Object.assign({}, error.error, { httpStatus: error.status });
        }),
        map((response: any) => {
          return response;
        })
      )
      .toPromise();
    this.initPendo();
  }

  goToLogin(): void {
    if (
      this.document.location.hostname === 'localhost' ||
      this.document.location.hostname.indexOf('review.teracloud.ninja') > -1
    ) {
      this.document.location.href =
        '/start-login' +
        '?nonce=' +
        Math.floor(1000000000 + Math.random() * 9000000000);
    } else {
      this.document.location.href =
        '/' + '?nonce=' + Math.floor(1000000000 + Math.random() * 9000000000);
    }
  }

  getCookiebyName(name: string): string {
    const pair: string[] = document.cookie.match(new RegExp(name + '=([^;]+)'));
    // eslint-disable-next-line
    return !!pair ? pair[1] : undefined;
  }

  /*
   * Promise to see if user is currently authenticated
   */
  async isAuthenticated(): Promise<boolean> {
    if (!this.userInfo || !this.userInfo.display_name) {
      await this.setUserInfo();
    }
    return true;
  }

  /*
   * Logout of IDP
   */
  logout(): void {
    sessionStorage.removeItem(EDITOR_CONNECTION_SESSION_KEY);
    this.document.location.href =
      environment.sessionLogout +
      this.document.location.origin +
      '?nonce=' +
      Math.floor(1000000000 + Math.random() * 9000000000);
  }

  get userInfo(): UserInfo {
    return this._userInfo;
  }

  set userInfo(userInfo: UserInfo) {
    this._userInfo = userInfo;
  }

  // Return user's organization affiliation
  getOrganization(): string {
    return this.userInfo.organization;
  }

  // Return user's username
  getUsername(): string {
    return this.userInfo.display_name;
  }

  // Return user's organization id affiliation
  getOrgId(): string {
    return this.userInfo.org_id;
  }

  // Check if user is org admin
  isOrgAdmin(): boolean {
    return this.userInfo.roles.includes(ORG_ADMIN);
  }

  get isASuperUser(): boolean {
    return (
      this.isSuperUser() ||
      this.isReadOnlySuperUser() ||
      this.isEditAccessSuperUser()
    );
  }

  // Check if user is superuser
  isSuperUser(): boolean {
    return this.userInfo.roles.includes(SUPERUSER);
  }

  // is this user able to manage storage and other scaling items?
  isEditAccessSuperUser(): boolean {
    return this.userInfo.roles.includes(SUPERUSER_EDITACCESS);
  }

  // will check for `SUPERUSER` as a role, instead of `SUPERUSER*`, which is
  // what isSuperUser appears to be doing
  isOnlySuperUser(): boolean {
    if (typeof this.userInfo.roles === 'string') {
      return this.userInfo.roles === SUPERUSER;
    } else {
      return this.userInfo.roles.includes(SUPERUSER);
    }
  }

  // Check if user is readonly user (like suerperuse but w/o CUD abilities)
  isReadOnlySuperUser(): boolean {
    return this.userInfo.roles.includes(SUPERUSER_READONLY);
  }

  // Check if user is database user
  isDBUser(): boolean {
    return this.userInfo.roles.includes(DATABASE_USER);
  }

  // check if logged-in user has support portal admin role
  isSupportPortalAdmin(): boolean {
    return this.userInfo.roles.includes(SUPPORT_PORTAL_ADMIN);
  }

  // Initialize pendo tracking script if feature enabled
  async initPendo(): Promise<void> {
    const guid: string = await this._profileService.getUserGUID();

    // yes it's a duplicate of Organizations service code, but it avoids circular dependencies :-(
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    const getOrgStatus = this._http
      .get('/api/my-organization', {
        headers,
      })
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((org: any) => org.operating_mode === 'trial')
      );

    combineLatest([
      this._profileService.getUserProfile(),
      getOrgStatus,
      this._featureFlagService.check('pendo'),
    ])
      .pipe(
        map(([profile, isTrialsUser, enabled]) => {
          return {
            allowAnalytics: profile['allow_analytics'] === 'true',
            enabled,
            isTrialsUser,
          };
        })
      )
      .subscribe((data) => {
        if (data.allowAnalytics && data.enabled) {
          const lang = getSelectedLanguage(this._translateService);
          const settings = {
            visitor: {
              id: guid,
              roles: this.userInfo.roles,
              app: 'lake',
              language: lang,
              preferred_language: lang,
              isTrialsUser: data.isTrialsUser,
            },
            account: {
              id: this.userInfo.org_id,
              organization: this.userInfo.organization,
            },
          };
          (window as any).pendo?.initialize(settings);
        }
      });
  }

  // Update pendo roles and permissions
  async updatePendoRolesPermissions(updateJson: any): Promise<void> {
    const metadata = (window as any).pendo?.getSerializedMetadata();

    if (
      metadata.visitor.roles !== undefined &&
      updateJson.visitor.roles !== undefined
    ) {
      metadata.visitor.roles = [
        ...metadata.visitor.roles,
        ...updateJson.visitor.roles,
      ];
    } else if (updateJson.visitor.roles !== undefined) {
      metadata.visitor.roles = updateJson.visitor.roles;
    }

    metadata.visitor.permissions = updateJson.visitor.permissions;

    (window as any).pendo?.updateOptions(metadata);
  }
}
