import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { CovalentMessageModule } from '@covalent/core/message';
import { TranslateModule } from '@ngx-translate/core';
import { VantageCredentialsDialogComponent } from './credentials-dialog/credentials-dialog.component';
import { VantageEditConnectionsDialogComponent } from './edit-connections-dialog/edit-connections-dialog.component';
import { MatMenuModule } from '@angular/material/menu';
import { RemoveConnectionDialogComponent } from './components/remove-connection-dialog/remove-connection-dialog.component';
@NgModule({
  declarations: [
    VantageCredentialsDialogComponent,
    VantageEditConnectionsDialogComponent,
    RemoveConnectionDialogComponent,
  ],
  exports: [
    VantageCredentialsDialogComponent,
    VantageEditConnectionsDialogComponent,
    RemoveConnectionDialogComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatIconModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    CovalentMessageModule,
    TranslateModule,
  ],
})
export class VantageQueryModule {}
