import { Injectable } from '@angular/core';
import {
  CanActivateChild,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { FeatureFlagService } from './flags.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagGuard implements CanActivateChild {
  constructor(
    private readonly _flagService: FeatureFlagService,
    private readonly _router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    // this whole thing only works if a flag is provided on the route data
    const flag = route.data?.featureFlag || '';

    // if flag is not enabled, then tell user that
    return this._flagService.check(flag).pipe(
      tap((allowed) => {
        if (!allowed) {
          this._router.navigate(['/']);
        }
      })
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canActivate(route);
  }
}
