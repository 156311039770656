import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'td-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TdEmptyStateComponent {
  @Input() centerVertically = false;
  @Input() flag?: string;
  @Input() icon?: string;
  @Input() learnMore?: string;
  @Input() title?: string;
  @Input() subtitle?: string;
}
