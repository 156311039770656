<div class="header-container">
  <span class="mat-title" *ngIf="state === 'chat'">{{
    'ASK_AI.NAME' | translate
  }}</span>
  <span class="private-preview-flag" *ngIf="state === 'chat'">{{
    'ASK_AI.PRIVATE_PREVIEW' | translate
  }}</span>
  <span class="spacer"></span>
  <span
    class="selected-environment mat-caption"
    data-cy="ask-ai-top-environment-label"
    *ngIf="state === 'chat' && !!selectedAccount.account_name"
  >
    <mat-icon class="environment-icon" svgIcon="td-icons:server"></mat-icon>
    {{
      'ASK_AI.SELECTED_ENVIRONMENT'
        | translate: { name: selectedAccount.account_name }
    }}
  </span>
  <button mat-icon-button (click)="close()" data-test="side-sheet-close-button">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="content-container">
  <ng-container [ngSwitch]="state">
    <div *ngSwitchCase="'not_enabled'" class="not-enabled centered-content">
      <img
        src="assets/images/illustration-askai-onlight.svg"
        class="light-askai-illustration"
      />
      <img
        src="assets/images/illustration-askai-ondark.svg"
        class="dark-askai-illustration"
      />

      <div class="askai-titleblock">
        <div class="askai-title mat-display-3 push-bottom-none">
          {{ 'ASK_AI.TRY_ASK_AI_TITLE' | translate }}
        </div>
        <div class="askai-description mat-input">
          {{ 'ASK_AI.TRY_ASK_AI_DESCRIPTION' | translate }}
        </div>
      </div>

      <div class="askai-tryit">
        <span class="askai-promo-left mat-body-1"> Try the </span>
        <span class="private-preview-badge mat-caption">
          {{ 'ASK_AI.PRIVATE_PREVIEW' | translate }}
        </span>
        <span class="askai-promo-right mat-body-1">
          on VantageCloud Lake.
        </span>
      </div>

      <div class="accounts-list" *ngIf="!selectedAccount">
        <div class="accounts-list-title pad">
          {{
            (data.preselectAccountName
              ? 'ASK_AI.CHECKING_STATUS'
              : 'ASK_AI.CHOOSE_AN_ENVIRONMENT'
            ) | translate
          }}
        </div>
        <mat-progress-bar
          *ngIf="loadingAccounts$ | async"
          mode="indeterminate"
          color="accent"
        ></mat-progress-bar>
        <mat-action-list class="pad-top-none">
          <ng-container *ngFor="let account of accounts">
            <mat-divider></mat-divider>
            <button
              mat-list-item
              class="environment-item"
              [attr.data-cy]="
                account.account_name + '-ask-ai-envrionment-select'
              "
              [value]="account"
              [disabled]="
                [
                  AiServiceStatuses.Provisioning,
                  AiServiceStatuses.Error
                ].includes(account.aiStatus) ||
                (!isAdminUser &&
                  account.aiStatus === AiServiceStatuses.NotProvisioned)
              "
              (click)="selectAccount(account)"
            >
              <span mat-line> {{ account.account_name }}</span>
              <span mat-line class="status-line">
                <ng-container [ngSwitch]="account.aiStatus">
                  <ng-container
                    *ngSwitchCase="AiServiceStatuses.CreatedSuccessfully"
                  >
                    <div class="ready">
                      <mat-icon [inline]="true">check</mat-icon
                      >{{ 'ASK_AI.READY' | translate }}
                    </div>
                    <mat-icon class="next-arrow">arrow_forward</mat-icon>
                  </ng-container>

                  <ng-container
                    *ngSwitchCase="AiServiceStatuses.NotProvisioned"
                  >
                    <span class="not-provisioned">{{
                      'ASK_AI.NOT_PROVISIONED' | translate
                    }}</span>
                    <mat-icon class="next-arrow">arrow_forward</mat-icon>
                  </ng-container>

                  <ng-container *ngSwitchCase="AiServiceStatuses.Provisioning">
                    <div class="provisioning">
                      <mat-icon svgIcon="td-icons:loader_dots"></mat-icon
                      >{{ 'ASK_AI.PROVISIONING' | translate }}
                    </div>
                  </ng-container>

                  <ng-cointainer *ngSwitchCase="AiServiceStatuses.Error">
                    <span class="tc-warn">{{
                      'ASK_AI.ERROR' | translate
                    }}</span>
                  </ng-cointainer>
                </ng-container>
              </span>
            </button>
          </ng-container>
          <ng-container
            *ngIf="
              (loadingAccounts$ | async) === false && accounts.length === 0
            "
          >
            <mat-divider></mat-divider>
            <button mat-list-item class="environment-item" [disabled]="true">
              {{ 'ASK_AI.NO_RUNNING_ENVIRONMENTS' | translate }}
            </button>
          </ng-container>
        </mat-action-list>
      </div>

      <div class="accounts-list" *ngIf="selectedAccount">
        <div class="selected-account-title pad-sm">
          <button
            mat-icon-button
            class="push-right-sm push-left-sm"
            (click)="backToList()"
            *ngIf="!data.preselectAccountName"
          >
            <mat-icon>arrow_back</mat-icon>
          </button>
          <span>{{ selectedAccount.account_name }}</span>
        </div>
        <mat-divider></mat-divider>
        <div
          class="pad-md"
          *ngIf="
            !isAdminUser &&
              [
                AiServiceStatuses.NotProvisioned,
                AiServiceStatuses.Error
              ].includes(selectedAccount.aiStatus);
            else adminProvision
          "
        >
          <div class="mat-body-1">
            {{
              selectedAccount.aiStatus === AiServiceStatuses.NotProvisioned
                ? 'ASK_AI.NOT_ENABLED'
                : ('ASK_AI.ERROR' | translate)
            }}
          </div>
        </div>
        <ng-template #adminProvision>
          <div class="pad-md">
            <div class="mat-body-1">
              {{ 'ASK_AI.SETUP_ASK_AI_MESSAGE' | translate }}
            </div>
            <mat-checkbox
              class="push-top push-bottom"
              [(ngModel)]="termsAgreedTo"
              data-cy="ask-ai-terms-checkbox"
              ><span
                class="mat-body-1"
                [innerHTML]="
                  'ASK_AI.TERMS_CHECKBOX_LABEL' | translate | safeHtml
                "
              ></span
            ></mat-checkbox>
          </div>
          <div class="pad selected-account-buttons">
            <button
              mat-raised-button
              data-cy="ask-ai-setup-button"
              color="accent"
              [disabled]="!termsAgreedTo"
              (click)="enable(selectedAccount.account_id)"
            >
              {{ 'ASK_AI.SET_UP_ASK_AI' | translate }}
            </button>
          </div>
        </ng-template>
      </div>
    </div>

    <ng-container *ngSwitchCase="'chat'">
      <ask-ai-chat
        [selectedAccount]="selectedAccount"
        [chatHistory]="chatHistory"
        (setChatHistory)="setChatHistory($event)"
        (setChatDirty)="setChatDirty()"
      ></ask-ai-chat>
    </ng-container>
  </ng-container>
</div>
<div
  class="footer caption"
  [innerHTML]="'ASK_AI.FOOTNOTE' | translate | safeHtml"
></div>
