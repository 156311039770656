import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TdDialogService } from '@covalent/core/dialogs';
import { CovalentSideSheetRef } from '@covalent/core/side-sheet';
import { TranslateService } from '@ngx-translate/core';
import { VantageToastService } from '@td/vantage-user-feedback';
import {
  BehaviorSubject,
  filter,
  Observable,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';

import {
  IPat,
  PersonalAccessTokensService,
} from '../../personal-access-tokens.service';
import { RevokeDialogComponent } from '../revoke-dialog/revoke-dialog.component';

export interface IAccessTokensDetailsInput {
  tokenId: string;
}

@Component({
  selector: 'janus-access-token-details',
  templateUrl: './access-token-details.component.html',
  styleUrls: ['./access-token-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessTokenDetailsComponent implements OnInit {
  private readonly isLoadingBS = new BehaviorSubject<boolean>(true);

  isLoading$ = this.isLoadingBS.asObservable();
  patDetails$: Observable<IPat>;

  constructor(
    private readonly patsService: PersonalAccessTokensService,
    private readonly translate: TranslateService,
    private readonly toastService: VantageToastService,
    private readonly dialogService: TdDialogService,
    public readonly dialogRef: CovalentSideSheetRef<AccessTokenDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: IAccessTokensDetailsInput
  ) {}

  ngOnInit(): void {
    this.patDetails$ = this.patsService
      .getPATDetails$(this.dialogData.tokenId)
      .pipe(
        tap(() => this.isLoadingBS.next(false)),
        shareReplay(1)
      );
  }

  handleRevokeToken(tokenId: string): void {
    this.dialogService
      .open(RevokeDialogComponent, { width: '460px', disableClose: true })
      .afterClosed()
      .pipe(
        filter((confirm: boolean) => !!confirm),
        tap(() => this.isLoadingBS.next(true)),
        switchMap(() => this.patsService.revokeSpecificPAT$(tokenId))
      )
      .subscribe({
        next: () => {
          this.toastService.open(
            this.translate.instant(
              'AUTH_LIB.ACCESS_TOKEN.REVOKE_OUTCOMES.REVOKE_SUCCEEDED'
            ),
            4000
          );

          this.dialogRef.close(true);
        },
        error: () => {
          this.isLoadingBS.next(false);

          this.toastService.open(
            this.translate.instant(
              'AUTH_LIB.ACCESS_TOKEN.REVOKE_OUTCOMES.REVOKE_FAILED'
            ),
            10000
          );
        },
      });
  }
}
