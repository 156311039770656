import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError, timer } from 'rxjs';
import { mapTo, mergeMap, retryWhen, tap, timeout } from 'rxjs/operators';

import { setVantageBaseURL } from './api-pointer';

import { IConnectOptions } from './connection-interface';
import {
  IConnectable,
  ISQLEConnection,
  VantageQueryService,
} from './query.service';

@Injectable({
  providedIn: 'root',
})
export class VantageConnectionService {
  private readonly _currentConnectionSubject: BehaviorSubject<ISQLEConnection> =
    new BehaviorSubject<ISQLEConnection>(null);
  private readonly _connectionsSubject: BehaviorSubject<ISQLEConnection[]> =
    new BehaviorSubject<ISQLEConnection[]>(null);
  public currentConnection$: Observable<ISQLEConnection> =
    this._currentConnectionSubject.asObservable();
  public connections$: Observable<ISQLEConnection[]> =
    this._connectionsSubject.asObservable();

  constructor(private _queryService: VantageQueryService) {}

  private set _currentConnection(connection: ISQLEConnection) {
    this._currentConnectionSubject.next(connection);
  }
  private get _currentConnection(): ISQLEConnection {
    return this._currentConnectionSubject.getValue();
  }
  public get currentConnection(): ISQLEConnection {
    return this._currentConnection;
  }

  private set _connections(connections: ISQLEConnection[]) {
    this._connectionsSubject.next(connections);
  }
  private get _connections(): ISQLEConnection[] {
    return this._connectionsSubject.getValue();
  }
  public get connections(): ISQLEConnection[] {
    return this._connections;
  }

  public removeAll(): void {
    this._connections = [];
    this._currentConnection = undefined;
  }

  public addAndSetAsCurrent(
    connection: ISQLEConnection,
    options?: IConnectOptions
  ): Observable<ISQLEConnection> {
    setVantageBaseURL(connection.system.siteURL, connection.system.nickname);

    return this._queryService
      .querySystem(connection, {
        query: 'SELECT 1;',
        isMetadataQuery: true,
      })
      .pipe(
        // timeout connection if more than 7 seconds
        timeout(options?.timeout || 7000),
        // retry only after a certain number of attempts or if the error is something else than 420
        retryWhen((errors: Observable<{ httpStatus: number }>) => {
          return errors.pipe(
            mergeMap((error: { httpStatus: number }, index: number) => {
              const retryAttempt: number = index + 1;
              if (
                retryAttempt > (options?.attempts || 2) ||
                error.httpStatus === 420
              ) {
                return throwError(error);
              }

              return timer(0);
            })
          );
        }),
        tap(() => {
          // if successful, save
          this._connections = [connection];
          this._currentConnection = connection;
        }),
        mapTo(connection)
      );
  }

  connectDbUser(system: IConnectable): void {
    const connection: ISQLEConnection = { system: system };

    this.addAndSetAsCurrent(connection).toPromise();
  }

  public setDBUserCurrent(connection: ISQLEConnection) {
    this._connections = [connection];
    this._currentConnection = connection;
  }
}
