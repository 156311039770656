import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { CovalentDialogsModule } from '@covalent/core/dialogs';
import { TranslateModule } from '@ngx-translate/core';

import { TdDialogShellComponent } from './dialog-shell.component';

@NgModule({
  declarations: [TdDialogShellComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    CovalentDialogsModule,
  ],
  exports: [TdDialogShellComponent],
})
export class TdDialogShellModule {}
