import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthenticationService } from '@janus/authentication';
import { VantageConnectionService } from '@td/vantage/query';
import { VantageErrorService } from '@td/vantage-user-feedback';

export enum PhoneNumberType {
  Home = 'Home',
  Mobile = 'Mobile',
  Other = 'Other',
  Work = 'Work',
}

export interface IPhoneNumber {
  number: string;
  type: string;
}

export interface IUser {
  id: string;
  email: string;
  username: string;
  first_name?: string;
  last_name?: string;
  phone_numbers?: IPhoneNumber[];
  local?: boolean;
  locked?: boolean;
  roles?: string[];
}

export interface INewUser {
  username?: string;
  password?: string;
  description?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  phone_numbers?: IPhoneNumber[];
  roles?: string[];
  local?: boolean;
}

export interface IUpdateUser {
  initialize_username?: boolean;
  username?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  phone_numbers?: IPhoneNumber[];
  old_pasword?: string;
  new_password?: string;
}

export interface IRolesUpdate {
  roles_to_add?: string[];
  roles_to_remove?: string[];
}

export interface IDatabaseUser {
  userId?: string;
  username: string;
  password?: string;
  permanent_space: number;
  parent?: string;
  roles?: string[];
}

export interface IDatabaseUserPermissions {
  grant_logon?: boolean;
  grant_role?: boolean;
  grant_user?: boolean;
}

// Can use exact match for equality

//TD_COMPUTE_CLUSTER_ADMIN can create COGs only
//TD_USER_ADMIN Users Tab
//TD_CC_ADMIN_XXXX show COG screens, allow to administrate/create Profiles, but hide create COG button
//TD_CC_MEMBER_XXXX then hide COG screens
export enum StaticRoles {
  'TD_COMPUTE_CLUSTER_ADMIN' = 'TD_COMPUTE_CLUSTER_ADMIN',
  'TD_USER_ADMIN' = 'TD_USER_ADMIN',
  'TD_CURRENT_QUERIES_VIEW' = 'TD_CURRENT_QUERIES_VIEW',
  'TD_CURRENT_QUERIES_ADMIN' = 'TD_CURRENT_QUERIES_ADMIN',
  'TD_QUERY_HISTORY_VIEW' = 'TD_QUERY_HISTORY_VIEW',
  'TD_FLOW_COMPOSER' = 'TD_FLOW_COMPOSER',
  'TD_FLOW_ADMIN' = 'TD_FLOW_ADMIN',
}

// Must use "starts with" check for equality
export enum DynamicRoles {
  'TD_CC_ADMIN_' = 'TD_CC_ADMIN_',
  'TD_CC_MEMBER_' = 'TD_CC_MEMBER_',
}

const catchHttpErrors = catchError((error: HttpErrorResponse) => {
  throw Object.assign({}, error.error, { httpStatus: error.status });
});

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(
    private _http: HttpClient,
    private _authenticationService: AuthenticationService,
    private _connectionService: VantageConnectionService,
    private _errorService: VantageErrorService,
    private readonly authenticationService: AuthenticationService
  ) {}

  dataBaseUserRoles: string[];

  get credentials(): string {
    return this._connectionService.currentConnection?.creds;
  }

  getHeaders(): HttpHeaders {
    if (this._authenticationService.isDBUser()) {
      return undefined;
    }
    return new HttpHeaders({
      'X-Auth-Credentials': `Basic ${this.credentials}`,
    });
  }

  // Local user (aka LDAP) user oriented function calls

  getUsers(): Observable<IUser[]> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const request: Observable<any> = this._http.get('/api/users', {
      headers,
    });

    return request.pipe(
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      }),
      map((users: IUser[]) => {
        return users;
      })
    );
  }

  getUser(userId: string): Observable<IUser> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const request: Observable<any> = this._http.get('/api/users/' + userId, {
      headers,
    });

    return request.pipe(
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      }),
      map((user: IUser) => {
        return user;
      })
    );
  }

  createUser(user: INewUser): Observable<IUser> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const request: Observable<any> = this._http.post('/api/users', user, {
      headers,
    });

    return request.pipe(
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      }),
      map((createdUser: IUser) => {
        return createdUser;
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deleteUser(userId: string): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const request: Observable<any> = this._http.delete('/api/users/' + userId, {
      headers,
    });

    return request.pipe(
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      }),
      map((results: IUser) => {
        return results;
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateUserRoles(userId: string, rolesUpdate: IRolesUpdate): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const request: Observable<any> = this._http.patch(
      '/api/users',
      rolesUpdate,
      {
        headers,
      }
    );

    return request.pipe(
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      map((response: any) => {
        return response;
      })
    );
  }

  getOrganizationAdmins(orgId: string): Observable<IUser[]> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const request: Observable<any> = this._http.get(
      '/api/adminusers?org=' + orgId,
      {
        headers,
      }
    );

    return request.pipe(
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      }),
      map((users: IUser[]) => {
        return users;
      })
    );
  }

  createOrganizationAdmin(orgId: string, user: INewUser): Observable<IUser> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const request: Observable<any> = this._http.post(
      '/api/adminusers?org=' + orgId,
      user,
      {
        headers,
      }
    );

    return request.pipe(
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      }),
      map((user: IUser) => {
        return user;
      })
    );
  }

  updateOrganizationAdmin(
    orgId: string,
    id: string,
    user: IUpdateUser
  ): Observable<IUser> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const request: Observable<any> = this._http.put(
      '/api/adminusers/' + id + '?org=' + orgId,
      user,
      {
        headers,
      }
    );

    return request.pipe(
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      }),
      map((user: IUser) => {
        return user;
      })
    );
  }

  deleteOrganizationAdmin(orgId: string, id: string): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const request: Observable<any> = this._http.delete(
      '/api/adminusers/' + id + '?org=' + orgId,
      {
        headers,
      }
    );

    return request.pipe(
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      }),
      map((resp: any) => {
        return resp;
      })
    );
  }

  // Database user oriented functions
  // These users only reside in the data and are not represented in LDAP

  // Returns string[]
  getDatabaseRoles(
    accountId: string,
    page: number,
    pageSize: number,
    pattern: string
  ): Observable<any> {
    const params = new HttpParams({
      fromString:
        pattern !== ''
          ? `page=${page}&page-size=${pageSize}&pattern=${pattern}`
          : `page=${page}&page-size=${pageSize}`,
    });
    const opts = { headers: this.getHeaders(), params };
    return this._http.get(`/api/accounts/${accountId}/roles`, opts).pipe(
      map((usersResponse: any) => {
        return usersResponse || [];
      }),
      catchHttpErrors
    );
  }

  // Returns string[]
  getDatabaseUsers(
    accountId: string,
    page: number,
    pageSize: number,
    pattern: string
  ): Observable<any> {
    const params = new HttpParams({
      fromString:
        pattern !== ''
          ? `page=${page}&page-size=${pageSize}&pattern=${pattern}`
          : `page=${page}&page-size=${pageSize}`,
    });
    const opts = { headers: this.getHeaders(), params };
    return this._http.get(`/api/accounts/${accountId}/users`, opts).pipe(
      map((usersResponse: any) => {
        return usersResponse || [];
      }),
      catchHttpErrors
    );
  }

  searchDatabaseUsers(accountId: string, filters = []): Observable<any> {
    return this._http
      .post(`/api/accounts/${accountId}/users/search`, filters, {
        headers: this.getHeaders(),
      })
      .pipe(
        map((createResponse: any) => {
          return createResponse;
        }),
        catchHttpErrors
      );
  }

  getDatabaseUser(accountId: string, userId: string): Observable<any> {
    return this._http
      .get(`/api/accounts/${accountId}/users/${userId}`, {
        headers: this.getHeaders(),
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchHttpErrors
      );
  }

  createDatabaseUser(
    accountId: string,
    newUser: IDatabaseUser
  ): Observable<any> {
    return this._http
      .post(`/api/accounts/${accountId}/users`, newUser, {
        headers: this.getHeaders(),
      })
      .pipe(
        map((createResponse: any) => {
          return createResponse;
        }),
        catchHttpErrors
      );
  }

  editDatabaseUser(
    accountId: string,
    userId: string,
    user: IDatabaseUser
  ): Observable<any> {
    return this._http.put(`/api/accounts/${accountId}/users/${userId}`, user, {
      headers: this.getHeaders(),
    });
  }

  getDatabaseUserPermissions(
    accountId: string,
    userId: string
  ): Observable<any> {
    return this._http
      .get(`/api/accounts/${accountId}/users/${userId}/permissions`, {
        headers: this.getHeaders(),
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchHttpErrors
      );
  }

  updateDatabaseUserPermissions(
    accountId: string,
    userId: string,
    userPermissions: IDatabaseUserPermissions
  ): Observable<any> {
    return this._http
      .patch(
        `/api/accounts/${accountId}/users/${userId}/permissions`,
        userPermissions,
        {
          headers: this.getHeaders(),
        }
      )
      .pipe(
        map((updateResponse: any) => {
          return updateResponse;
        }),
        catchHttpErrors
      );
  }

  deleteDatabaseUser(accountId: string, userId: string): Observable<any> {
    return this._http
      .delete(`/api/accounts/${accountId}/users/${userId}`, {
        headers: this.getHeaders(),
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchHttpErrors
      );
  }

  getDataBaseUserRoles(accountId: string, userId: string): Observable<any> {
    return this._http
      .get(`/api/accounts/${accountId}/users/${userId}/roles`, {
        headers: this.getHeaders(),
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchHttpErrors
      );
  }

  patchDataBaseUserRoles(
    accountId: string,
    userId: string,
    rolesToAdd: string[],
    rolesToRemove: string[]
  ): Observable<any> {
    return this._http
      .patch(
        `/api/accounts/${accountId}/users/${userId}/roles`,
        { roles_to_add: rolesToAdd, roles_to_remove: rolesToRemove },
        { headers: this.getHeaders() }
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchHttpErrors
      );
  }

  revokeUserTokens(userId: string): Observable<any> {
    return this._http
      .delete(`/api/users/${userId}/tokens`, { headers: this.getHeaders() })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchHttpErrors
      );
  }

  getRoles(accountId: string): Observable<any> {
    return this._http
      .get(`/api/accounts/${accountId}/roles`, { headers: this.getHeaders() })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchHttpErrors
      );
  }

  getRoleDetails(accountId: string, roleName: string) {
    return this._http
      .get(`/api/accounts/${accountId}/roles/${roleName}`, {
        headers: this.getHeaders(),
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchHttpErrors
      );
  }

  getRoleMembers(accountId: string, roleName: string) {
    return this._http
      .get(`/api/accounts/${accountId}/roles/${roleName}/members`, {
        headers: this.getHeaders(),
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchHttpErrors
      );
  }

  getMyRoles(accountId: string): Observable<any> {
    return this._http
      .get(`/api/accounts/${accountId}/my-roles`, {
        headers: this.getHeaders(),
      })
      .pipe(
        map((roles: string[]) => {
          this.dataBaseUserRoles = roles;
          return roles;
        }),
        catchHttpErrors
      );
  }

  updateMyRoles(accountId: string): void {
    this.getMyRoles(accountId).subscribe({
      error: (err) => {
        this._errorService.open(err);
      },
    });
  }

  isUserAdmin(): boolean {
    return this.dataBaseUserRoles?.includes(StaticRoles.TD_USER_ADMIN);
  }

  isComputeClusterAdmin(): boolean {
    return this.dataBaseUserRoles.includes(
      StaticRoles.TD_COMPUTE_CLUSTER_ADMIN
    );
  }

  isComputeGroupAdmin(): boolean {
    let isAdmin = false;
    for (let i = 0; i < this.dataBaseUserRoles.length; i++) {
      if (this.dataBaseUserRoles[i].indexOf(DynamicRoles.TD_CC_ADMIN_) != -1) {
        isAdmin = true;
        break;
      }
    }
    return isAdmin;
  }

  isQueryHistroyView(): boolean {
    return this.dataBaseUserRoles.includes(StaticRoles.TD_QUERY_HISTORY_VIEW);
  }

  isCurrentQueriesView(): boolean {
    return this.dataBaseUserRoles.includes(StaticRoles.TD_CURRENT_QUERIES_VIEW);
  }

  isCurrentQueriesAdmin(): boolean {
    return this.dataBaseUserRoles.includes(
      StaticRoles.TD_CURRENT_QUERIES_ADMIN
    );
  }

  isStreamsUser(): boolean {
    return (
      this.dataBaseUserRoles.includes(StaticRoles.TD_FLOW_ADMIN) ||
      this.dataBaseUserRoles.includes(StaticRoles.TD_FLOW_COMPOSER)
    );
  }

  isFlowsAdmin(): boolean {
    return this.dataBaseUserRoles
      ? this.dataBaseUserRoles.includes(StaticRoles.TD_FLOW_ADMIN)
      : false;
  }

  isFlowsComposer(): boolean {
    return this.dataBaseUserRoles
      ? this.dataBaseUserRoles.includes(StaticRoles.TD_FLOW_COMPOSER)
      : false;
  }

  hasAccess(tab: string): boolean {
    if (this._authenticationService.isOrgAdmin()) {
      return true;
    } else {
      switch (tab.toUpperCase()) {
        case 'COMPUTE GROUPS':
          if (this.isComputeClusterAdmin() || this.isComputeGroupAdmin()) {
            return true;
          }
          return false;
        case 'USERS':
          if (this._authenticationService.isOrgAdmin()) {
            return true;
          }
          return false;
        case 'QUERIES':
          if (
            this.isCurrentQueriesAdmin() ||
            this.isCurrentQueriesView() ||
            this.isQueryHistroyView()
          ) {
            return true;
          }
          return false;
        case 'FLOWS':
          if (this.authenticationService.isDBUser()) {
            return true;
          }
          return false;
        default:
          return false;
      }
    }
  }
}
