import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { ApiService } from '../common/api.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExternalTranslationService {
  constructor(private _http: HttpClient, private _apiService: ApiService) {}

  translationMap: Map<string, string> = new Map<string, string>();

  getResourceMapIssues(locale: string): Observable<any> {
    if (this.translationMap.size === 0) {
      const request: Observable<any> = this._http.get(
        `${this._apiService.getBaseUrl()}/resource-bundle?locale=${locale}`
      );

      return request.pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        }),
        map((resultSet: any) => {
          this.translationMap = new Map(Object.entries(resultSet));
          return this.translationMap;
        })
      );
    } else {
      return of(this.translationMap);
    }
  }
}
