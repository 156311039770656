import { Injectable } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { generateConnectionKey } from './editor-connection.service';
import { OBJECT_KINDS } from './object-types';

import {
  IQueryResultSet,
  ISQLEConnection,
  VantageSessionQueryService,
} from './query.service';

export const sysDatabases: string[] = [
  'DBC',
  'dbcmngr',
  'SQLJ',
  'SystemFe',
  'SysAdmin',
  'SYSBAR',
  'SYSJDBC',
  'SYSLIB',
  'SYSUDTLIB',
  'TDMaps',
  'TD_SERVER_DB',
  'TD_SYSFNLIB',
  'TD_SYSXML',
  'Sys_Calendar',
];

export enum TypeFormatTypes {
  'A1' = 'array',
  'AN' = 'varray',
  'AT' = 'time',
  'BF' = 'byte',
  'BO' = 'byteint',
  'BV' = 'varbyte',
  'CF' = 'char',
  'CO' = 'clob',
  'CV' = 'varchar',
  'D' = 'decimal',
  'DA' = 'date',
  'DH' = 'interval day to hour',
  'DM' = 'interval day to minute',
  'DS' = 'interval day to second',
  'DY' = 'interval day',
  'F' = 'float',
  'HM' = 'interval hour to minute',
  'HR' = 'interval hour',
  'HS' = 'interval hour to second',
  'I' = 'integer',
  'I1' = 'byteint',
  'I2' = 'smallint',
  'I8' = 'bigint',
  'JN' = 'json',
  'MI' = 'interval minute',
  'MO' = 'interval month',
  'MS' = 'interval minute to second',
  'N' = 'number',
  'PD' = 'period(date)',
  'PM' = 'period(timestamp with time zone)',
  'PS' = 'period(timestamp)',
  'PT' = 'period(time)',
  'PZ' = 'period(time with time zone)',
  'SC' = 'interval second',
  'SZ' = 'timestamp with time zone',
  'TS' = 'timestamp',
  'TZ' = 'time with time zone',
  'UT' = 'UDT type',
  'XM' = 'xml',
  'YM' = 'interval year to month',
  'YR' = 'interval year',
}

/**
 * FunctionType
 * A - Aggregate
 * B - Aggregate and statistical
 * C - Contract function
 * D - External stored procedure
 * F - Scalar
 * H - User defined method
 * I - Internal type method
 * L - Table operator
 * R - Table Function
 * S - Statistical
 */

/**
 * TableKind for dbc.tablesvx;
 * A - Aggregate function
 * B - Combined aggregate and ordered analytical function
 * C - Table operator parser contract function
 * D - JAR
 * E - External Stored Procedure
 * F - Standard Function
 * G - Trigger
 * H - Instance or contructor method
 * I - Join Index
 * J - Journal
 * K - Foreign Server Object
 * L - User defined table operator
 * M - Macro
 * N - Hash index
 * O - Table with no primary index and no partitioning
 * P - Stored Procedure
 * Q - Queue table
 * R - Table function
 * S - Ordered Analytical function
 * T - table with a primary index or primary AMP index, partitioning or both. Or a partioned table with NoPI;
 * U - User defined type
 * V - View
 * X - Authorization
 * Y - GLOP set
 * Z - UIF - User Installed File
 * 1 - A DATASET schema object created by CREATE SCHEMA
 * 2 - Functon alias object
 */

/**
 * dbc.externalSPs
 * dbc.sessionInfovx
 */

const VANTAGE_OBJECTBROWSER_VIEW = 'vantage.objectbrowser.includeAllItems';

@Injectable({
  providedIn: 'root',
})
export class VantageDictionaryService {
  hasStorageChache = new Map<string, boolean>();

  constructor(private _queryService: VantageSessionQueryService) {}

  getTableViewHelp(
    connection: ISQLEConnection,
    database: string,
    table: string,
    type: string
  ): Observable<any> {
    const quotedDatabase = this.getQuotedObjectName(database);
    const quotedTable = this.getQuotedObjectName(table);

    // need to use different queries to get columns for tables and views
    let columnsQuery: string;
    if (type === 'table') {
      const escapedDatabase = database.replace("'", "''");
      const escapedTable = table.replace("'", "''");
      const viewName = this.getViewName('dbc.columnsV');
      columnsQuery = this.getColumnsQuery(
        escapedDatabase,
        escapedTable,
        type,
        viewName
      );
    } else {
      columnsQuery = this.getColumnsQuery(quotedDatabase, quotedTable, type);
    }

    return combineLatest([
      this._queryService.querySystem(connection, {
        query: `
            LOCK ROW FOR ACCESS
            SELECT CAST(COUNT(*) AS BIGINT) as cnt
            FROM ${quotedDatabase}.${quotedTable};        
          `,
        isMetadataQuery: true,
      }),
      this._queryService.querySystem(connection, {
        query: `SHOW ${type} ${quotedDatabase}.${quotedTable};`,
        isMetadataQuery: true,
      }),
      this._queryService.querySystem(connection, {
        query: columnsQuery,
        isMetadataQuery: true,
      }),
    ]).pipe(
      take(1),
      map(
        ([countResponse, showRespose, helpResponse]: [
          IQueryResultSet,
          IQueryResultSet,
          IQueryResultSet
        ]) => {
          let count: string;
          countResponse.results[0].data.forEach((row: any) => {
            count = row.cnt;
          });

          let ddlStatement: string = '';
          showRespose.results[0].data.forEach((row: any) => {
            ddlStatement += row['Request Text'];
          });

          const columns: any[] = helpResponse.results[0].data.map(
            (column: any) => {
              return {
                columnName: column['Column Dictionary Name'],
                type: TypeFormatTypes[column['Type'].trimEnd()],
              };
            }
          );

          return {
            database,
            table,
            columns,
            count,
            ddlStatement,
          };
        }
      )
    );
  }

  getDatabases(connection: ISQLEConnection): Observable<any[]> {
    const viewName = this.getViewName('dbc.databasesV');
    const queryStr: string = `
      SELECT databasename, PermSpace, SpoolSpace, TempSpace, CommentString, DBKind
      FROM ${viewName} ORDER BY databasename;`;
    return this._queryService
      .querySystem(connection, {
        query: queryStr,
        isMetadataQuery: true,
      })
      .pipe(
        map((resultSet: IQueryResultSet) => {
          return resultSet.results[0].data.map((row: any) => {
            return {
              name: row.DatabaseName || row.schemaname,
              type: row.DBKind.trim() === 'U' ? 'user' : 'database',
              permSpace: row.PermSpace,
              spoolSpace: row.SpoolSpace,
              tempSpace: row.TempSpace,
              comment: row.CommentString,
            };
          });
        })
      );
  }

  // check if the current db supports storage
  hasStorage(connection: ISQLEConnection): Observable<boolean> {
    const connectionString = generateConnectionKey(connection);
    if (this.hasStorageChache.get(connectionString)) {
      return of(this.hasStorageChache.get(connectionString));
    } else {
      const query =
        "SELECT 1 FROM dbc.TablesV WHERE databasename = 'DBC' AND TABLENAME = 'Storage'";
      return this._queryService
        .querySystem(connection, {
          query: query,
          isMetadataQuery: true,
        })
        .pipe(
          map(
            (resultSet: IQueryResultSet) =>
              resultSet?.results?.length > 0 &&
              resultSet.results[0].rowCount > 0
          ),
          tap((hasStorage) =>
            this.hasStorageChache.set(connectionString, hasStorage)
          )
        );
    }
  }

  getDatabaseObjects(
    connection: ISQLEConnection,
    databaseId: string,
    tablesOnly: boolean = true
  ): Observable<any[]> {
    const viewName = this.getViewName('dbc.tablesV');
    return this.hasStorage(connection).pipe(
      switchMap((hasStorage) => {
        let columnsList = 'DataBaseName, TableName, TableKind, CommentString';
        if (hasStorage) {
          columnsList += ', StorageName';
        }
        const database = databaseId.replace("'", "''");
        const queryStr: string = `SELECT ${columnsList} FROM ${viewName}
          WHERE ${tablesOnly ? "TableKind in ('T', 'O', 'V') AND" : ''} 
          DataBaseName = '${database}' ORDER BY TableName ASC;`;

        return this._queryService.querySystem(connection, {
          query: queryStr,
          isMetadataQuery: true,
        });
      }),
      map((resultSet: IQueryResultSet) => {
        return resultSet.results && resultSet.results.length > 0
          ? resultSet.results[0].data.map((row: any) => {
              const type =
                OBJECT_KINDS[row.TableKind.trim() || row.tablekind.trim()]
                  ?.type || 'unknown';
              return {
                kind: row.TableKind.trim() || row.tablekind.trim(),
                type,
                name: row.TableName || row.tablename,
                database: row.DataBaseName || row.databasename,
                comment: row.CommentString,
                storageTier: row.StorageName,
              };
            })
          : [];
      })
    );
  }

  getTableViewInfo(
    connection: ISQLEConnection,
    database: string,
    name: string,
    type: string
  ): Observable<any> {
    const quotedDatabase = this.getQuotedObjectName(database);
    const quotedTable = this.getQuotedObjectName(name);
    const kind = type === 'table' ? 'T' : 'V';

    // need to use different queries to get columns for tables and views
    let queryStr: string;
    if (kind === 'T') {
      const escapedDatabase = database.replace("'", "''");
      const escapedTable = name.replace("'", "''");
      const viewName = this.getViewName('dbc.columnsV');
      queryStr = this.getColumnsQuery(
        escapedDatabase,
        escapedTable,
        type,
        viewName
      );
    } else {
      queryStr = this.getColumnsQuery(quotedDatabase, quotedTable, type);
    }

    return this._queryService
      .querySystem(connection, { query: queryStr, isMetadataQuery: true })
      .pipe(
        map((resultSet: any) => {
          const cols = resultSet.results[0].data.map((column: any) => {
            return {
              type: TypeFormatTypes[column['Type'].trimEnd()],
              name: column['Column Dictionary Name'].trimEnd(),
            };
          });

          return {
            columns: cols,
            database: database,
            name: name,
            tableKind: kind,
          };
        })
      );
  }

  getTableColumns(
    type: string,
    connection: ISQLEConnection,
    databaseId: string,
    tableId: string
  ): Observable<any> {
    return this.getTableViewInfo(connection, databaseId, tableId, type).pipe(
      map((resultSet: any) => {
        return resultSet.columns.map((column: any) => {
          return {
            comment: column.remarks,
            type: 'column',
            columnType: column.type,
            name: column.name,
            database: resultSet.database,
            table: resultSet.name,
          };
        });
      })
    );
  }

  // search for objects with searchStr in name
  // objectType: 'database' or 'table'
  // parent: search for tables within a database, set parent to the database name
  search(
    connection: ISQLEConnection,
    searchStr: string,
    objectType: string
  ): Observable<any[]> {
    let query: string;
    if (objectType === 'database') {
      const viewName = this.getViewName('dbc.databasesV');
      query = `
        SELECT DatabaseName as thename,
            'database' as thetype,
            DBKind as thekind,
            CommentString as thecomment
        FROM ${viewName}
        WHERE UPPER(DatabaseName) LIKE UPPER('%${searchStr}%')
        ORDER BY name
      `;
    } else if (objectType === 'table') {
      const dbViewName = this.getViewName('dbc.databasesV');
      const tableViewName = this.getViewName('dbc.tablesV');
      query = `
        SELECT
            t.DataBaseName as dbName,
            d.DBKind as dbKind,
            t.TableName as thename,
            t.TableKind as thekind,
            t.CommentString as thecomment
        FROM ${tableViewName} t
        INNER JOIN ${dbViewName} d
        ON t.DataBaseName = d.DataBaseName
        WHERE t.TableKind in ('T', 'O', 'V')
        AND UPPER(t.TableName) LIKE UPPER('%${searchStr}%')
        ORDER BY t.databasename
        `;
    } else {
      throw new Error('Invalid object type ' + objectType);
    }
    return this._queryService
      .querySystem(connection, {
        query: query,
        isMetadataQuery: true,
      })
      .pipe(
        map((resultSet: IQueryResultSet) => {
          if (!resultSet.results || resultSet.results.length === 0) {
            return [];
          }
          return resultSet.results[0].data.map((row: any) => {
            const kind: string = row.thekind.trim();
            let dbKind: string = '';
            let type: string;
            // map to proper type/kind
            if (row.type === 'database') {
              type = kind === 'D' ? 'database' : 'user';
            } else {
              dbKind = row.dbKind.trim() === 'D' ? 'database' : 'user';
              type = kind === 'V' ? 'view' : 'table';
            }
            return {
              dbName: row.dbName ? row.dbName : '',
              dbKind,
              name: row.thename,
              kind,
              type: type,
              comment: row.thecomment,
            };
          });
        })
      );
  }

  filteredObjectSearch(
    connection,
    filters: { columnId: string; filterType: string; filterValue: string }[],
    dbName = ''
  ): Observable<any[]> {
    const objectNameColumn = 'TableName';
    let objectNameClause = '';
    let storageClause = '';
    let kindClause = '';
    const databaseClause = dbName
      ? "DatabaseName = UPPER('" + dbName.toUpperCase() + "')"
      : '';

    filters.forEach((filter) => {
      switch (filter.columnId) {
        case 'ObjectName':
          objectNameClause = `UPPER(${objectNameColumn}) LIKE UPPER('%${filter.filterValue}%')`;
          break;
        case 'StorageName':
          storageClause = `StorageName ${
            filter.filterType === 'is' ? '=' : '<>'
          } '${filter.filterValue}'`;
          break;
        case 'TableKind':
          kindClause = `TableKind ${filter.filterType === 'is' ? '=' : '<>'} '${
            filter.filterValue
          }'`;
          break;
        default:
          break;
      }
    });

    const whereClause = [
      databaseClause,
      objectNameClause,
      storageClause,
      kindClause,
    ]
      .filter((clause) => clause !== '')
      .join(' AND ');

    const viewName = this.getViewName('dbc.tablesV');
    const queryStr: string = `SELECT DatabaseName, TableName, TableKind, StorageName
      FROM ${viewName}
      WHERE ${whereClause} AND DatabaseName <> 'DBC'
      ORDER BY DatabaseName ASC;`;
    return this._queryService
      .querySystem(connection, {
        query: queryStr,
      })
      .pipe(
        map((resultSet: IQueryResultSet) => {
          return resultSet.results[0].data.map((row: any) => {
            const type =
              OBJECT_KINDS[row.TableKind.trim() || row.tablekind.trim()]
                ?.type || 'unknown';
            return {
              type,
              name: row.TableName,
              database: row.DataBaseName,
              storageTier: row.StorageName,
            };
          });
        })
      );
  }

  getQuotedObjectName(objectName: string): string {
    return '"' + objectName.replace('"', '""') + '"';
  }

  getViewName(viewRoot: string): string {
    const includeAllItems = localStorage.getItem(VANTAGE_OBJECTBROWSER_VIEW);
    if (!includeAllItems || includeAllItems === 'false') {
      return viewRoot + 'X';
    }
    return viewRoot;
  }

  /* getColumnsQuery returns query used to get columns:
     use 'select' query to get columns for tables
     use 'help column' query to get columns for views because 'select' query does not return type for views
     not using 'help column' for both because 'help column' requires user has 'select' access
     on the table
  */
  getColumnsQuery(
    database: string,
    table: string,
    type: string,
    viewName?: string
  ): string {
    if (type === 'table') {
      return `SELECT ColumnName AS "Column Dictionary Name", ColumnType AS "Type" FROM ${viewName} WHERE databaseName='${database}' and tableName = '${table}' ORDER BY ColumnName`;
    } else {
      return `HELP column * FROM ${database}.${table};`;
    }
  }
}
