export const CONF = {
  BASE: 'en',
  DISPLAY: [
    {
      key: 'en',
      label: 'English',
    },
    {
      key: 'fr',
      label: 'Français',
    },
    {
      key: 'de',
      label: 'Deutsch',
    },
    {
      key: 'ja',
      label: '日本語',
    },
    {
      key: 'es',
      label: 'Español',
    },
  ],
  SUPPORTED: ['en', 'fr', 'de', 'ja', 'es'],
  SOURCES: [
    'apps/analyst/src/assets/i18n/en.json',
    'apps/clearscape-analytics/src/assets/i18n/en.json',
    'apps/data-explore/src/assets/i18n/en.json',
    'apps/data-mover/src/assets/i18n/en.json',
    'apps/data-sharing/src/assets/i18n/en.json',
    'apps/editor/src/assets/i18n/en.json',
    'apps/janus/src/assets/i18n/en.json',
    'apps/model-ops/src/assets/i18n/en.json',
    'apps/user-defined-functions/src/assets/i18n/en.json',
    'libs/analystlibs/assets/i18n/en.json',
    'libs/ask-ai/src/assets/i18n/en.json',
    'libs/authentication/assets/i18n/en.json',
    'libs/clearscapeanalytics/assets/i18n/en.json',
    'libs/consumption/assets/i18n/en.json',
    'libs/help/assets/i18n/en.json',
    'libs/modelops/modelops-ui-alerts/assets/i18n/en.json',
    'libs/modelops/modelops-ui-common/assets/i18n/en.json',
    'libs/modelops/modelops-ui-core/assets/i18n/en.json',
    'libs/modelops/modelops-ui-datasets/assets/i18n/en.json',
    'libs/modelops/modelops-ui-deployments/assets/i18n/en.json',
    'libs/modelops/modelops-ui-feature-engineering/assets/i18n/en.json',
    'libs/modelops/modelops-ui-jobs/assets/i18n/en.json',
    'libs/modelops/modelops-ui-models/assets/i18n/en.json',
    'libs/modelops/modelops-ui-projects/assets/i18n/en.json',
    'libs/modelops/modelops-ui-settings/assets/i18n/en.json',
    'libs/navigation/assets/i18n/en.json',
    'libs/navigation/assets/i18n/eula.en.md',
    'libs/organizations/assets/i18n/en.json',
    'libs/profile/assets/i18n/en.json',
    'libs/tabs/access-management-tab/assets/i18n/en.json',
    'libs/tabs/data-protection-tab/assets/i18n/en.json',
    'libs/tabs/flows-tab/assets/i18n/en.json',
    'libs/tabs/query-grid-tab/assets/i18n/en.json',
    'libs/ui/cron-scheduler/assets/i18n/en.json',
    'libs/ui/status-details/assets/i18n/en.json',
    'libs/ui/table/assets/i18n/en.json',
    'libs/vantage/editor/assets/i18n/en.json',
    'libs/vantage/object-browser/assets/i18n/en.json',
  ],
};
