import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TdDialogService } from '@covalent/core/dialogs';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import {
  ColumnType,
  FilterType,
  FILTER_TYPES,
  IColumn,
  ISelectedFilter,
  IValueChoice,
} from './attribute-filter.service';
import { PopupDialogComponent } from './popup-dialog/popup-dialog.component';

export enum AttributeFilterTypes {
  POPUP = 'popUp',
  SIDEBAR = 'sideBar',
}

const POPUP_DIALOG_WIDTH = 600;

@Component({
  selector: 'td-attribute-filter',
  templateUrl: './attribute-filter.component.html',
  styleUrls: ['./attribute-filter.component.scss'],
})
export class TdAttributeFilterComponent
  implements OnInit, OnDestroy, OnChanges
{
  private _takeUntil$ = new Subject<boolean>();
  @Input() displayType = '';
  @Input() columns: IColumn[] = [];
  @Input() instantFiltering = false;
  @Input() isDisabled: boolean = false;
  @Input() set defaultFilters(filters: ISelectedFilter[]) {
    this.selectedFilters = filters || [];
  }

  @Output() filterChange: EventEmitter<ISelectedFilter[]> = new EventEmitter<
    ISelectedFilter[]
  >();
  @Output() closeFilter: EventEmitter<ISelectedFilter[]> = new EventEmitter<
    ISelectedFilter[]
  >();

  selectedFilters: ISelectedFilter[] = [];

  addMode = false;
  filterCreationForm: FormGroup;
  filterDisplayForm: FormGroup = new FormGroup({
    filters: new FormArray([]),
  });
  selectedFilter: ISelectedFilter;

  constructor(
    private fb: FormBuilder,
    private _dialogService: TdDialogService
  ) {
    this.buildFilterCreationForm();
  }

  ngOnInit(): void {
    this.filterDisplayForm.controls['filters'].valueChanges
      .pipe(
        debounceTime(500),
        filter(() => this.instantFiltering),
        takeUntil(this._takeUntil$)
      )
      .subscribe(() => {
        if (this.displayType === AttributeFilterTypes.POPUP) {
          this.applyPopupFilters();
        } else {
          this.applyFilter();
        }
      });

    // update the operator menu when the column changes
    this.filterCreationForm.controls['columnId'].valueChanges
      .pipe(takeUntil(this._takeUntil$))
      .subscribe(() => {
        // if the current filterType is not in the list of possible types,
        // choose the first one
        if (
          !this.filterTypes.includes(
            this.filterCreationForm.get('filterType').value
          )
        ) {
          this.filterCreationForm
            .get('filterType')
            .setValue(this.filterTypes[0]);
        }
      });
  }

  ngOnDestroy(): void {
    this._takeUntil$.next(true);
    this._takeUntil$.complete();
  }

  ngOnChanges(): void {
    if (this.displayType === AttributeFilterTypes.SIDEBAR) {
      this.selectDefaults();
      if (this.instantFiltering) {
        this.filterCreationForm.controls.filterValue.clearValidators();
      } else {
        this.filterCreationForm.controls.filterValue.setValidators([
          Validators.required,
        ]);
      }
    }
  }

  onFilterIconClick(event: MouseEvent) {
    if (this.displayType === AttributeFilterTypes.POPUP) {
      this._dialogService
        .open(PopupDialogComponent, {
          panelClass: 'attibute-filter-popup-dialog',
          width: `${POPUP_DIALOG_WIDTH}px`,
          data: {
            columns: this.columns,
            dialogWidth: POPUP_DIALOG_WIDTH,
            instantFiltering: this.instantFiltering,
            positionRelativeToElement: new ElementRef(event.currentTarget),
            selectedFilters: this.selectedFilters,
          },
        })
        .afterClosed()
        .pipe(filter((filters: ISelectedFilter[]) => !!filters))
        .subscribe((filters: ISelectedFilter[]) => {
          this.selectedFilters = filters;
          this.filterChange.emit(this.selectedFilters);
        });
    }
  }

  get chosenColumn(): IColumn {
    return this.getColumnById(this.filterCreationForm.get('columnId').value);
  }

  get filterTypes(): FilterType[] {
    return FILTER_TYPES[this.chosenColumn?.type || 'string'];
  }

  get filterValues(): IValueChoice[] {
    return this.chosenColumn.valueChoices;
  }

  get statusValues(): string[] {
    return this.chosenColumn.statusChoices;
  }

  get isEnum(): boolean {
    return (
      this.chosenColumn.type === 'enum' &&
      this.chosenColumn.valueChoices?.length > 0
    );
  }

  get isStatuses(): boolean {
    return (
      this.chosenColumn.type === (ColumnType.STATUS as string) &&
      this.chosenColumn.statusChoices?.length > 0
    );
  }

  close() {
    this.addMode = false;
    this.closeFilter.emit(this.selectedFilters);
  }

  hasError(controlName: string, errorName: string): boolean {
    return this.filterCreationForm.controls[controlName].hasError(errorName);
  }

  getColumnById(id) {
    return this.columns.filter((column) => column.id === id)[0];
  }

  addFilter() {
    this.selectedFilter = null;
    this.filterCreationForm.reset();
    this.filterCreationForm.controls.index.setValue(-1);
    this.selectDefaults();
    this.filterCreationForm.markAsPristine();
    this.addMode = true;
  }

  editFilter(selectedFilter: ISelectedFilter) {
    this.selectedFilter = selectedFilter;
    this.filterCreationForm.setValue(selectedFilter);
    this.filterCreationForm.controls.index.setValue(selectedFilter.index);
    this.addMode = true;
  }

  applyFilter(onSubmit = false) {
    const formVal = this.filterCreationForm.value;
    formVal.filterValue = this.filterCreationForm.get('filterValue').value;

    if (
      this.selectedFilters[formVal.index] &&
      !formVal.filterValue &&
      this.instantFiltering
    ) {
      this.selectedFilters.splice(formVal.index, 1);
      this.filterChange.emit(this.selectedFilters);
      return;
    }

    if (this.filterCreationForm.invalid || !formVal.filterValue) {
      this.filterCreationForm.markAsDirty();
      return;
    }

    if (formVal.index === -1) {
      formVal.index = this.selectedFilters.length;
      this.filterCreationForm.controls.index.setValue(formVal.index);
      // make sure to pass along the correct accessor function for the filter value
      const col = this.getColumnById(formVal.columnId);

      this.selectedFilters.push(
        col.accessor ? { ...formVal, filterAccessor: col.accessor } : formVal
      );
    } else {
      this.selectedFilters[formVal.index] = formVal;
    }

    if (!this.instantFiltering || onSubmit) {
      this.addMode = false;
    }
    this.filterChange.emit(this.selectedFilters);
  }

  removeFilter() {
    if (this.selectedFilter && this.selectedFilter.index > -1) {
      this.selectedFilters = this.selectedFilters.filter(
        (filter) => filter.index !== this.selectedFilter.index
      );
      this.selectedFilter = null;
      this.addMode = false;
      this.filterChange.emit(this.selectedFilters);
    }
  }

  // providing a way for the consumer to be able to close the form; e.g. if
  // there is a button to clear filters in their UI
  resetEditMode(): void {
    this.addMode = false;
  }

  buildFilterCreationForm() {
    this.filterCreationForm = this.fb.group({
      columnId: ['', [Validators.required]],
      filterType: ['', [Validators.required]],
      filterValue: ['', [Validators.required]],
      filterAccessor: [''],
      index: -1,
    });
  }

  filterDisplayTypes(filterIndex: number): FilterType[] {
    const chosenColumn = this.getColumnById(
      this.filters.value[filterIndex].columnId
    );

    return FILTER_TYPES[chosenColumn?.type || 'string'];
  }

  filterTypeIsEnum(filterIndex: number): boolean {
    const chosenColumn = this.getColumnById(
      this.filters.value[filterIndex].columnId
    );

    return chosenColumn?.type === ColumnType.ENUM;
  }

  filterValueChoices(filterIndex: number): IValueChoice[] {
    const chosenColumn = this.getColumnById(
      this.filters.value[filterIndex].columnId
    );

    return chosenColumn?.valueChoices || [];
  }

  get filters() {
    return this.filterDisplayForm.controls['filters'] as FormArray;
  }

  deleteFilterFromTable(filterIndex: number) {
    this.filters.removeAt(filterIndex);
  }

  applyPopupFilters(onSubmit = false) {
    this.selectedFilters = this.filters.value.map((obj: ISelectedFilter) => ({
      ...obj,
    }));

    if (!this.instantFiltering || onSubmit) {
      this.addMode = false;
    }
    this.filterChange.emit(this.selectedFilters);
  }

  private selectDefaults() {
    if (this.columns && this.columns.length > 0) {
      this.filterCreationForm.get('columnId').setValue(this.columns[0].id);
    }
    this.filterCreationForm.get('filterType').setValue(this.filterTypes[0]);
  }

  removeFilters() {
    this.selectedFilters = [];
  }

  getFilterableColumns(): IColumn[] {
    return this.columns.filter(
      (column: IColumn) => !column.excludeFromFiltering
    );
  }
}
