<header>
  <mat-icon class="eula-icon" svgIcon="td-logo:logo-wordmark"></mat-icon>
</header>

<mat-divider class="divider"></mat-divider>

<mat-dialog-content class="mat-typography">
  <h2 mat-dialog-title>{{ 'EULA' | translate | uppercase }}</h2>
  <td-flavored-markdown-loader
    [url]="markdownUrl"
  ></td-flavored-markdown-loader>
</mat-dialog-content>

<mat-divider class="divider"></mat-divider>

<ng-container *ngIf="!data?.hideActions; else basic">
  <mat-dialog-actions align="end">
    <mat-checkbox [(ngModel)]="agreed">{{
      'READ_AND_AGREED' | translate
    }}</mat-checkbox>
    <button
      mat-button
      color="accent"
      [disabled]="!agreed"
      [mat-dialog-close]="true"
    >
      {{ 'ACCEPT' | translate }}
    </button>
  </mat-dialog-actions>
</ng-container>

<ng-template #basic>
  <mat-dialog-actions align="end">
    <button mat-button color="accent" [mat-dialog-close]="true">
      {{ 'CLOSE' | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>
