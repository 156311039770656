import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function cidrsValidator(): ValidatorFn {
  return (control: FormControl): ValidationErrors | null => {
    const ipAddresses: string = control.value;
    let errorFound: boolean = false;

    if (ipAddresses.length) {
      // Split lines
      const ipAddressesArray: string[] = ipAddresses
        .trim()
        .split(/[,\n]/)
        .map((ip) => ip.trim());

      ipAddressesArray.forEach((ipAddress) => {
        if (
          (ipAddress.length &&
            !ipAddress.match(
              '^([0-9]{1,3}.){3}[0-9]{1,3}(/([0-9]|[1-2][0-9]|3[0-2]))$'
            ) &&
            !ipAddress.match('^([0-9]{1,3}.){3}[0-9]{1,3}$')) ||
          ipAddress === '0.0.0.0' ||
          ipAddress === '0.0.0.0/0'
        ) {
          errorFound = true;
        }
      });
    }

    return errorFound ? { invalidForm: true } : null;
  };
}
