import { Inject, Injectable, Optional } from '@angular/core';

import { STORAGE_KEY_PREFIX } from './key';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor(
    @Optional()
    @Inject(STORAGE_KEY_PREFIX)
    private _storageKeyPrefix: string
  ) {
    this._storageKeyPrefix = '';
  }

  /**
   * Sets state and overrides what ever was set before for a given key
   */
  put<T>(key: string, state: T) {
    localStorage.setItem(this._storageKeyPrefix + key, JSON.stringify(state));
  }

  /**
   * Sets state and adds/changes only given properties
   */
  patch<T>(key: string, state: T) {
    localStorage.setItem(
      this._storageKeyPrefix + key,
      JSON.stringify(Object.assign({}, this.get(key), state))
    );
  }

  /**
   * Gets state for a given key
   */
  get<T>(key: string): T {
    return JSON.parse(localStorage.getItem(this._storageKeyPrefix + key));
  }

  /**
   * Removes all state and keys
   */
  clearAll(): void {
    for (let i = 0; i < localStorage.length; i++) {
      const key: string = localStorage.key(i);
      if (key.startsWith(this._storageKeyPrefix)) {
        localStorage.removeItem(key);
      }
    }
  }
}
