import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { FeatureFlagService } from '@td/vantage-flags';
import { Observable } from 'rxjs';

@Component({
  selector: 'help-nav-item',
  templateUrl: './help-nav-item.component.html',
  styleUrls: ['./help-nav-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpNavItemComponent implements OnInit {
  displayInlineHelp$: Observable<boolean>;

  @Input() expanded: boolean;

  constructor(private readonly featureFlagService: FeatureFlagService) {}

  ngOnInit(): void {
    this.displayInlineHelp$ = this.featureFlagService.check('inline_help');
  }
}
