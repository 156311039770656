import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export type StatusTypeClasses =
  | ''
  | 'accent'
  | 'caution'
  | 'disabled-text'
  | 'negative'
  | 'positive';

export interface IStatusPackage {
  statusClass: StatusTypeClasses;
  statusLabel?: string;
  statusMatIcon?: string;
  statusTdIcon?: string;
  useSpinner: boolean;
}

export enum Statuses {
  ABORTED = 'ABORTED',
  ABORTING = 'ABORTING',
  ACTIVE = 'ACTIVE',
  ALLOCATED = 'ALLOCATED',
  ALLOCATING = 'ALLOCATING',
  CANCELED = 'CANCELED',
  COMPLETE = 'COMPLETE',
  COMPLETED = 'COMPLETED',
  COMPLETED_WITH_ERRORS = 'COMPLETED_WITH_ERRORS',
  COMPLETED_WITH_WARNINGS = 'COMPLETED_WITH_WARNINGS',
  CREATE_FAILED = 'CREATE_FAILED',
  CREATED_SUCCESSFULLY = 'CREATED_SUCCESSFULLY',
  CREATED = 'CREATED',
  CREATING = 'CREATING',
  CONFIGURING = 'CONFIGURING',
  DELETE_IN_PROGRESS = 'DELETE_IN_PROGRESS',
  DELETE_FAILED = 'DELETE_FAILED',
  DELETED = 'DELETED',
  DELETING = 'DELETING',
  ERROR = 'ERROR',
  ERRORS = 'ERRORS',
  EXECUTING = 'EXECUTING',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  INITIATED = 'INITIATED',
  NEW = 'NEW',
  PARTIAL_SUCCESS = 'PARTIAL_SUCCESS',
  PAUSED = 'PAUSED',
  PAUSING = 'PAUSING',
  PENDING = 'PENDING',
  PENDING_CREATE = 'PENDING_CREATE',
  PREPARING = 'PREPARING',
  RESETTING = 'RESETTING',
  RESUMING = 'RESUMING',
  REPLICATION_IN_PROGRESS = 'REPLICATION_IN_PROGRESS',
  RUNNING = 'RUNNING',
  SKIPPED = 'SKIPPED',
  SUBMITTED = 'SUBMITTED',
  SUCCESS = 'SUCCESS',
  SUCCESSFUL = 'SUCCESSFUL',
  SYNCING = 'SYNCING',
  UNKNOWN = 'UNKNOWN',
  UPDATE_IN_PROGRESS = 'UPDATE_IN_PROGRESS',
  UPDATING = 'UPDATING',
}

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  /**
   * Default status package object
   */
  statusObj: IStatusPackage = {
    statusClass: '',
    useSpinner: false,
  };

  constructor(private readonly translateService: TranslateService) {}

  /**
   * Build a class and icon object to use proper icon and color
   * @param status String: status to match against
   */
  computeClassAndIcon(status: string): IStatusPackage {
    // do some defensive programming, just in case :)
    if (!status) {
      return this.statusObj;
    }

    let statusClass: StatusTypeClasses = '';
    let statusTdIcon: string = '';
    let statusMatIcon: string = '';
    let useSpinner: boolean = false;

    switch (status?.toUpperCase()) {
      case 'EXECUTING':
      case 'RUNNING':
      case 'STARTING':
        statusClass = 'accent';
        useSpinner = true;
        break;
      case 'ACTIVE':
      case 'COMPLETE':
      case 'COMPLETED':
        statusClass = 'positive';
        statusMatIcon = 'check';
        break;

      case 'SUCCESS':
      case 'SUCCESSFUL':
        statusClass = 'positive';
        statusMatIcon = 'done';
        break;

      case 'ERRORS':
      case 'COMPLETED_WITH_ERRORS':
      case 'COMPLETED_WITH_WARNINGS':
      case 'SKIPPED':
      case 'PARTIAL_SUCCESS':
        statusClass = 'caution';
        statusMatIcon = 'warning';
        break;

      case 'PAUSED':
        statusClass = 'disabled-text';
        statusMatIcon = 'pause';
        break;

      case 'ERROR':
      case 'FAILED':
      case 'CREATE_FAILED':
      case 'DELETE_FAILED':
        statusClass = 'negative';
        statusMatIcon = 'error';
        break;

      case 'ABORTED':
      case 'CANCELED':
        statusClass = 'negative';
        statusMatIcon = 'cancel';
        break;

      case 'CREATED':
      case 'NEW':
        statusClass = 'accent';
        statusMatIcon = 'new_releases';
        break;

      case 'INDETERMINATE':
      case 'UNKNOWN':
        statusClass = '';
        statusMatIcon = 'help_outline';
        break;

      case 'ABORTING':
      case 'ALLOCATED':
      case 'ALLOCATING':
      case 'CANCELING':
      case 'CONFIGURING':
      case 'CREATING':
      case 'DELETE_IN_PROGRESS':
      case 'DELETING':
      case 'IN_PROGRESS':
      case 'IN_PROGRESS_DP':
      case 'PAUSING':
      case 'PENDING':
      case 'PENDING_CREATE':
      case 'PREPARING':
      case 'RESETTING':
      case 'RESUMING':
      case 'REPLICATION_IN_PROGRESS':
      case 'SUBMITTED':
      case 'SYNCING':
      case 'UPDATE_IN_PROGRESS':
      case 'UPDATING':
        statusClass = '';
        statusTdIcon = 'td-icons:loader_dots';
        break;
    }

    // add whatever we just computed and then send it back to the caller :)
    this.statusObj.statusClass = statusClass;
    this.statusObj.statusTdIcon = statusTdIcon;
    this.statusObj.statusMatIcon = statusMatIcon;
    this.statusObj.useSpinner = useSpinner;

    return this.statusObj;
  }

  /**
   * Map item status to a more meaningful string for users
   * @param status String: status to translate
   */
  computeLabel(status: string): string {
    // do some defensive programming, just in case :)
    if (!status) {
      return '';
    }

    let statusString = status.toUpperCase();

    // only handle the strings that will change
    switch (status?.toUpperCase()) {
      case 'ABORTED':
        statusString = 'STOPPED';
        break;

      case 'ABORTING':
        statusString = 'STOPPING';
        break;

      case 'COMPLETED_WITH_ERRORS':
        statusString = 'ERRORS';
        break;

      case 'COMPLETED_WITH_WARNINGS':
        statusString = 'WARNINGS';
        break;

      case 'CREATE_FAILED':
        statusString = 'CREATE_FAILED';
        break;

      case 'CREATED_SUCCESSFULLY':
        statusString = 'CREATED_SUCCESSFULLY';
        break;

      case 'DELETE_FAILED':
        statusString = 'DELETE_FAILED';
        break;

      case 'DELETE_IN_PROGRESS':
        statusString = 'DELETING';
        break;

      case 'IN_PROGRESS_DP':
        statusString = 'CREATING';
        break;

      case 'NEW':
        statusString = 'NEW';
        break;

      case 'RUNNING':
        statusString = 'RUNNING';
        break;

      case 'SUCCESSFUL':
        statusString = 'SUCCESS';
        break;

      case 'UPDATE_IN_PROGRESS':
      case 'UPDATING_IN_PROGRESS':
        statusString = 'UPDATING';
        break;

      // default handled by initial assignment
    }

    this.statusObj.statusLabel = this.translateService.instant(
      `STATUSES.${statusString}`
    );

    return this.statusObj.statusLabel;
  }

  /**
   * Will build an object with the necessary attributes to use on either a ui-table-status or a ui-icon-status component
   * @param status String: used to build classes, labels, and icons object
   * @returns IStatusPackage object
   */
  buildStatusPackage(status: string): IStatusPackage {
    // Default object to avoid overlap
    this.statusObj = {
      statusClass: null,
      useSpinner: false,
    };

    this.computeClassAndIcon(status);
    this.computeLabel(status);

    return this.statusObj;
  }
}
