<span class="status-line" [ngClass]="statusPackage.statusClass">
  <ng-container *ngIf="showIcon">
    <ng-container *ngIf="statusPackage.useSpinner; else mustBeIcon">
      <mat-spinner color="accent" diameter="24"></mat-spinner>
    </ng-container>

    <ng-template #mustBeIcon>
      <mat-icon *ngIf="statusPackage.statusMatIcon">{{
        statusPackage.statusMatIcon
      }}</mat-icon>
      <mat-icon
        *ngIf="statusPackage.statusTdIcon"
        [svgIcon]="statusPackage.statusTdIcon"
      ></mat-icon>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="showLabel">
    <ng-container *ngIf="customLabel; else componentLabel">
      {{ customLabel }}
    </ng-container>
    <ng-template #componentLabel>
      {{ statusPackage.statusLabel }}
    </ng-template>
  </ng-container>
</span>
