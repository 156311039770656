import { Injectable } from '@angular/core';
import { IUserProfile, ProfileService } from '@janus/profile';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';

export interface IDatasetLoadingJobs {
  accountId: string;
  jobId: string;
  datasetName: string;
  datasetStatus?: string;
}

export interface IAssetScript {
  script_name: string;
  status: string;
  url: string;
  sqlText?: string;
}

export interface ILoadAssetRes {
  id?: string;
  dataset_name: string;
  status: string;
  scripts: IAssetScript[];
}

@Injectable({
  providedIn: 'root',
})
export class LoadAssetService {
  constructor(private _profileService: ProfileService) {}

  public _loadAssetSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public loadAsset$: Observable<any> = this._loadAssetSubject.asObservable();

  async setDatasetLoading(
    accountId: string,
    jobId: string,
    datasetName: string,
    running = true,
    datasetStatus?: string
  ): Promise<any> {
    const profile: IUserProfile = await lastValueFrom(
      this._profileService.getUserProfile()
    );
    if (
      profile &&
      ((running && profile.dataset_loading_running) ||
        (!running && profile.dataset_loading_completed))
    ) {
      const jobs: IDatasetLoadingJobs[] = JSON.parse(
        running
          ? profile.dataset_loading_running
          : profile.dataset_loading_completed
      );
      jobs.unshift({ accountId, jobId, datasetName, datasetStatus });
      if (running) {
        profile.dataset_loading_running = JSON.stringify(jobs);
      } else {
        profile.dataset_loading_completed = JSON.stringify(jobs);
      }
      await lastValueFrom(this._profileService.updateUserProfile(profile));
    } else {
      const job: IDatasetLoadingJobs[] = [];
      if (running) {
        job.push({ accountId, jobId, datasetName });
        profile.dataset_loading_running = JSON.stringify(job);
      } else {
        job.push({ accountId, jobId, datasetName, datasetStatus });
        profile.dataset_loading_completed = JSON.stringify(job);
      }
      await lastValueFrom(this._profileService.updateUserProfile(profile));
    }
  }

  async removeDatasetLoading(jobId: string, running = true): Promise<any> {
    const profile: IUserProfile = await lastValueFrom(
      this._profileService.getUserProfile()
    );
    const jobs: IDatasetLoadingJobs[] = JSON.parse(
      running
        ? profile.dataset_loading_running
        : profile.dataset_loading_completed
    );
    const index: number = jobs.findIndex((ele) => jobId == ele.jobId);
    if (index != -1) {
      jobs.splice(index, 1);
      if (running) {
        profile.dataset_loading_running = JSON.stringify(jobs);
      } else {
        profile.dataset_loading_completed = JSON.stringify(jobs);
      }
      await lastValueFrom(this._profileService.updateUserProfile(profile));
    }
  }
}
