<div class="help-menu-wrapper">
  <ng-container *ngIf="showHelp">
    <button id="allhelp-button" mat-menu-item (click)="openHelp()">
      {{ 'ALL_HELP' | translate }}
    </button>
    <mat-divider></mat-divider>
  </ng-container>
  <button
    id="help-support-button"
    mat-menu-item
    (click)="openSupport()"
    class="end-icon-button"
  >
    {{ 'SUPPORT' | translate }}
    <mat-icon matSuffix>open_in_new</mat-icon>
  </button>
  <button
    id="help-documentation-button"
    mat-menu-item
    (click)="openDocumentation()"
    class="end-icon-button"
  >
    {{ 'DOCUMENTATION' | translate }}
    <mat-icon matSuffix>open_in_new</mat-icon>
  </button>
</div>
