<td-layout layout-fill sidenavWidth="280px">
  <td-navigation-drawer class="hide-drawer-toolbar" flex>
    <app-nav-rail
      [navItems]="navItems"
      [expandedRail]="true"
      [notificationCount]="notificationCount"
    ></app-nav-rail>
  </td-navigation-drawer>
  <div layout="row" flex layout-fill>
    <app-nav-rail
      #mainNav
      [navItems]="navItems"
      [expandedRail]="expandedRail"
      layout="column"
      [notificationCount]="notificationCount"
      *ngIf="!isSmallScreen"
    ></app-nav-rail>
    <mat-divider vertical></mat-divider>
    <div flex layout="column" class="bgc" id="main-container">
      <mat-toolbar hide-gt-xs>
        <button mat-icon-button td-menu-button tdLayoutToggle>
          <mat-icon>menu</mat-icon>
        </button>
      </mat-toolbar>

      <ng-content></ng-content>
    </div>
  </div>
</td-layout>
