import { Injectable } from '@angular/core';
import { map, Observable, BehaviorSubject, forkJoin, mergeMap } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ILink, LinksService } from '../common/links.service';
import { IDataSourceNode, NodeService } from './node.service';
import { ApiService } from '../common/api.service';
import { IBridge } from '../settings/bridges/bridge.service';
import { IQGSystemDetails } from './data-source.interface';

export interface TimeEntry {
  hours: number;
  minutes: number;
  seconds: number;
}

export interface IDiagnosticNode {
  systemId: string;
  hostname: string;
  systemName: string;
  status: string;
  endTime: string;
  startTime: string;
  error: string;
  nodeId: string;
}

//represents diagnostic (status) data
export interface IDiagnostic {
  id: string;
  totalBytesReceived: number;
  bandwidth: number;
  totalCount: number;
  inProgressCount: number;
  totalBytesSent: number;
  successCount: number;
  errorCount: number;
  startTime: string;
  endTime: string;
  nodes: IDiagnosticNode[];
  status?: EDiagnosticCheckStatus;
}

export enum EDiagnosticCheckStatus {
  'IN_PROGRESS' = 'IN_PROGRESS',
  'SUCCESS' = 'SUCCESS',
  'FAILURE' = 'FAILURE',
  'CANCELLED' = 'CANCELLED',
  'RESTARTED' = 'RESTARTED',
}

export enum EDiagnosticType {
  'LINK',
  'CONNECTOR',
  'CONNECTOR_INSTALL',
  'LINK_BANDWIDTH',
  'DATA_SOURCE_CONNECT', //network diagnostic
  'DATA_SOURCE_NODE', //network diagnostic
}

export enum EVersionOptions {
  'ACTIVE' = 'ACTIVE',
  'PENDING' = 'PENDING',
}

export enum EDataDirectionOptions {
  'IMPORT' = 'IMPORT',
  'EXPORT' = 'EXPORT',
}

//represents a single object diagnostic such as link, connector diagnostic
export interface IDiagnosticProcessInfo {
  componentId: string;
  diagnosticType: EDiagnosticType;
  version?: any;
  diagnosticCheckId?: string;
  _extraInfo?: {
    systemId?: string;
    nodeId?: string;
    dataFlow?: string;
    bandwidthMBPerNode?: number;
    componentName?: string;
    properties?: any;
  };
  diagnostic?: IDiagnostic;
  processId?: string;
}

//represents a network diagnostic
export interface INetworkDiagnostic {
  system: IQGSystemDetails;
  diagnostics?: IDiagnosticProcessInfo[];
  activeLinks?: ILink[];
  pendingLinks?: ILink[];
  systemNodeCount?: number;
  processId?: string;
  status?: EDiagnosticCheckStatus;
  type?: EDiagnosticType;
  bridge?: IBridge;
}

export interface INetworkDiagnosticCheckData {
  systemNodeCount: number;
  diagnostics: Map<string, IDiagnostic>;
}

export interface IDiagnosticStatus {
  diagnosticExists: boolean;
  totalCount: number;
  inProgressCount: number;
  successCount: number;
  errorCount: number;
  startTime: string;
  endTime: string;
  status: EDiagnosticCheckStatus;
}

export interface ICompletedDiagnostic {
  processId: string | undefined;
  status: EDiagnosticCheckStatus | undefined;
}

export enum EDiagnosticActionRibbonView {
  'DATA_SOURCE_CONNECT' = 'DATA_SOURCE_CONNECT',
  'DATA_SOURCE_NODE' = 'DATA_SOURCE_NODE',
  'STEPPER' = 'STEPPER',
  'EDIT_BRIDGE' = 'EDIT_BRIDGE',
}

export const statusSeverity: Map<EDiagnosticCheckStatus, number> = new Map([
  [EDiagnosticCheckStatus.FAILURE, 0],
  [EDiagnosticCheckStatus.CANCELLED, 1],
  [EDiagnosticCheckStatus.IN_PROGRESS, 2],
  [EDiagnosticCheckStatus.SUCCESS, 3],
]);
@Injectable({
  providedIn: 'root',
})
export class DiagnosticService {
  private networkDiagnosticProcesses: INetworkDiagnostic[] = [];
  private diagnosticProcesses: IDiagnosticProcessInfo[] = [];
  private diagnosticProcessesChangedBS: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  diagnosticProcessesChanged$ =
    this.diagnosticProcessesChangedBS.asObservable();
  private diagnosticProcessesCompletedBS = new BehaviorSubject<
    ICompletedDiagnostic | undefined
  >(undefined);
  diagnosticProcessesCompleted$ =
    this.diagnosticProcessesCompletedBS.asObservable();

  constructor(
    private readonly http: HttpClient,
    private readonly linksService: LinksService,
    private readonly nodeService: NodeService,
    private _apiService: ApiService
  ) {}

  /**
   * Runs one specific type of diagnostic
   * @param request the IDiagnosticProcessInfo that encapsulates
   *        diagnostic request details
   * @returns the id of the initiated diagnostic
   */
  runDiagnostic(request: IDiagnosticProcessInfo): Observable<string> {
    const requestData = {
      componentId: request.componentId,
      type: Object.values(EDiagnosticType)[request.diagnosticType],
      dataFlow: request._extraInfo?.dataFlow,
      nodeId: request._extraInfo?.nodeId,
      version: request.version,
      bandwidthMBPerNode: request._extraInfo?.bandwidthMBPerNode,
      properties: request._extraInfo?.properties,
    };
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    return this.http
      .post(`${this.getBaseUrl()}/operations/diagnostic-check`, requestData, {
        headers,
      })
      .pipe(
        map((response: any) => {
          return response.id;
        }),
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  /**
   * Runs diagnostics concurrently for all {@link ILink}s
   * associated with the given system (id) and waits until
   * all of them have completed.
   * @param system DataSource/system
   * @returns Observable<INetworkDiagnostic>
   */
  runNetworkDiagnostic(
    system: IQGSystemDetails,
    bridge?: IBridge
  ): Observable<INetworkDiagnostic> {
    const links$ = this.linksService.getLinks(
      bridge ? 'BRIDGE' : 'SYSTEM',
      bridge ? bridge.id : system.id
    );
    return links$.pipe(
      //subscribes to the links then start/switch to the run diagnostic Observable
      mergeMap((links) => {
        return this.runLinksDiagnostic(links).pipe(
          map((diagnosticProcessInfos) => {
            const activeLinks: ILink[] = [];
            const pendingLinks: ILink[] = [];
            links.forEach((link) => {
              if (link.isActive()) activeLinks.push(link);
              else if (link.isPending()) pendingLinks.push(link);
            });
            return {
              bridge: bridge,
              system: system,
              diagnostics: diagnosticProcessInfos,
              activeLinks: activeLinks,
              pendingLinks: pendingLinks,
              status: EDiagnosticCheckStatus.IN_PROGRESS,
              processId: uuid(),
            };
          })
        );
      })
    );
  }

  /**
   * Retrieves the status of network diagnostic
   * @param networkDiagnostic the INetworkDiagnostic input
   * @returns Observable<INetworkDiagnosticDetails> that
   * contains information about diagnostic data and the
   * count of registered system nodes at the time the
   * diagnostic details are retrieved
   */
  getNetworkDiagnosticData(
    networkDiagnostic: INetworkDiagnostic
  ): Observable<INetworkDiagnosticCheckData> {
    const systemNodeCount$ = this.getSystemNodeCount(
      networkDiagnostic.system.id || ''
    );
    return systemNodeCount$.pipe(
      mergeMap((systemNodeCount) => {
        const diagnostic$ = (id: string) => this.getDiagnosticData(id);
        const diagnosticIds: string[] =
          networkDiagnostic.diagnostics?.map((d) =>
            d.diagnosticCheckId ? d.diagnosticCheckId : ''
          ) || [];
        const diagnosticsS: Observable<IDiagnostic>[] = diagnosticIds.map(
          (id) => diagnostic$(id)
        );
        return forkJoin(diagnosticsS).pipe(
          map((diagnostics) => {
            return {
              systemNodeCount: systemNodeCount,
              diagnostics: new Map(diagnostics.map((d) => [d.id, d])),
            };
          })
        );
      })
    );
  }

  /**
   * Stops one or more running diagnostics
   * @param networkDiagnostic the INetworkdDiagnostic
   */
  stopNetworkDiagnostic(
    networkDiagnostic: INetworkDiagnostic
  ): Observable<any> {
    const stopDiagnostic$ = (id: string) =>
      this.http.delete<any>(
        `${this.getBaseUrl()}/operations/diagnostic-check/${id}`
      );
    const diagnosticIds: string[] =
      networkDiagnostic.diagnostics
        ?.filter((d) => d.diagnostic?.inProgressCount !== 0)
        .map((d) => (d.diagnosticCheckId ? d.diagnosticCheckId : '')) || [];
    const diagnosticsS: Observable<IDiagnostic>[] = diagnosticIds.map((id) =>
      stopDiagnostic$(id)
    );
    return forkJoin(diagnosticsS).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      })
    );
  }
  /**
   * Retrieves details about the diagnostic represented by the
   * given diagnostic id
   * @param id the diagnostic id
   * @param systemId
   * @returns the diagnostic detail
   */
  getDiagnosticData(id: string, systemId?: string): Observable<IDiagnostic> {
    return this.http
      .get(`${this.getBaseUrl()}/operations/diagnostic-check/${id}`)
      .pipe(
        map((diagnosticData: any) => {
          if (systemId) {
            diagnosticData.nodes = diagnosticData.nodes.filter(
              (node: IDiagnosticNode) => node.systemId === systemId
            );
          }
          return diagnosticData;
        }),
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  stopDiagnostic(id: string): Observable<any> {
    return this.http
      .delete<any>(`${this.getBaseUrl()}/operations/diagnostic-check/${id}`)
      .pipe(
        map((resp: any) => {
          this.deleteDiagnosticProcess(id);
          this.diagnosticProcessesChangedBS.next(true);
          return resp;
        }),
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  setDiagnosticProcess(
    processInfo?: IDiagnosticProcessInfo | INetworkDiagnostic | undefined
  ) {
    const process = processInfo ? processInfo : undefined;
    if (process) {
      if ('system' in process) {
        this.setNetworkDiagnostic(process as INetworkDiagnostic);
      } else {
        this.setDiagnostic(process as IDiagnosticProcessInfo);
      }
    }
  }

  getAllDiagnostics(): any[] {
    return [...this.networkDiagnosticProcesses, ...this.diagnosticProcesses];
  }

  getDiagnosticProcesses(
    systemId: string,
    type: EDiagnosticType,
    status?: EDiagnosticCheckStatus
  ): IDiagnosticProcessInfo[] {
    return status
      ? this.diagnosticProcesses.filter(
          (p) =>
            systemId === p._extraInfo?.systemId &&
            p.diagnosticType === type &&
            p.diagnostic?.status === status
        )
      : this.diagnosticProcesses.filter(
          (p) =>
            systemId === p._extraInfo?.systemId && p.diagnosticType === type
        );
  }

  getDataSourceConnectDiagnostics(systemId?: string): INetworkDiagnostic[] {
    return systemId
      ? this.networkDiagnosticProcesses.filter(
          (p) =>
            EDiagnosticType.DATA_SOURCE_CONNECT === p.type &&
            systemId === p.system.id
        )
      : this.networkDiagnosticProcesses.filter(
          (p) => EDiagnosticType.DATA_SOURCE_CONNECT === p.type
        );
  }

  getDataSourceNodeDiagnostics(systemId?: string): INetworkDiagnostic[] {
    return systemId
      ? this.networkDiagnosticProcesses.filter(
          (p) =>
            EDiagnosticType.DATA_SOURCE_NODE === p.type &&
            systemId === p.system.id
        )
      : this.networkDiagnosticProcesses.filter(
          (p) => EDiagnosticType.DATA_SOURCE_NODE === p.type
        );
  }

  completeDiagnosticProcess(
    process: INetworkDiagnostic | IDiagnosticProcessInfo
  ): void {
    if (process) {
      if ('system' in process) {
        const index = this.networkDiagnosticProcesses.findIndex(
          (p) => p.processId === process.processId
        );
        if (index !== -1) {
          this.networkDiagnosticProcesses[index] = process;
          this.diagnosticProcessesCompletedBS.next({
            processId: process.processId,
            status: process.status,
          });
        }
      } else {
        const index = this.diagnosticProcesses.findIndex(
          (p) => p.diagnosticCheckId === process.diagnosticCheckId
        );
        if (index !== -1) {
          this.diagnosticProcesses[index] = process;
          this.diagnosticProcessesCompletedBS.next({
            processId: process.diagnostic?.id,
            status: process.diagnostic?.status,
          });
        }
      }
    }
  }

  completedDiagnosticProcessById(
    id: string,
    status: EDiagnosticCheckStatus
  ): void {
    const process = this.diagnosticProcesses.find(
      (p) => p.diagnosticCheckId === id
    );
    if (process) {
      this.diagnosticProcessesCompletedBS.next({
        processId: id,
        status: status,
      });
    }
  }

  downloadNetworkDiagnosticSupportBundle(diagnosticId: string): any {
    return this.http
      .get(
        `${this.getBaseUrl()}/support-archive/diagnostic-check?id=${diagnosticId}`,
        {
          responseType: 'blob',
        }
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  getBaseUrl(): string {
    return this._apiService.getBaseUrl();
  }

  private linkToDiagnosticRequest(link: ILink): IDiagnosticProcessInfo {
    return {
      componentId: link.id,
      diagnosticType: EDiagnosticType.LINK,
      version: link.isActive()
        ? EVersionOptions.ACTIVE
        : EVersionOptions.PENDING,
    };
  }

  private runLinksDiagnostic(
    links: ILink[]
  ): Observable<IDiagnosticProcessInfo[]> {
    const diagnosticRequests$: Observable<any>[] = [];
    //active and pending links only
    links
      .filter((l) => !l.isPrevious())
      .forEach((link: ILink) => {
        diagnosticRequests$.push(
          this.runDiagnostic(this.linkToDiagnosticRequest(link)).pipe(
            map((id) => {
              return {
                componentId: link.id,
                diagnosticType: EDiagnosticType.LINK,
                version: link.isActive()
                  ? EVersionOptions.ACTIVE
                  : EVersionOptions.PENDING,
                diagnosticCheckId: id,
                _extraInfo: {
                  componentName: link.name,
                },
              };
            })
          )
        );
      });
    //run diagnostics concurrently then wait for all to complete
    return forkJoin(diagnosticRequests$);
  }

  private getSystemNodeCount(systemId: string): Observable<number> {
    return this.nodeService
      .getNodesBySystemId(systemId)
      .pipe(map((nodes: IDataSourceNode[]) => nodes.length));
  }

  deleteNetworkDiagnosticProcess(id?: string): boolean {
    if (id) {
      for (let i = 0; i < this.networkDiagnosticProcesses.length; i++) {
        const process = this.networkDiagnosticProcesses[i];
        if (process.processId === id) {
          this.networkDiagnosticProcesses.splice(i, 1);
          this.diagnosticProcessesChangedBS.next(true);
          return true;
        }
      }
    }
    return false;
  }

  getNetworkDiagnostic(processId: string): INetworkDiagnostic | undefined {
    return this.networkDiagnosticProcesses.find(
      (d) => d.processId === processId
    );
  }

  getDiagnosticProcess(processId: string): IDiagnosticProcessInfo | undefined {
    return this.diagnosticProcesses.find(
      (d) => d.diagnosticCheckId === processId
    );
  }

  deleteDiagnosticProcess(id?: string): boolean {
    for (let i = 0; i < this.diagnosticProcesses.length; i++) {
      const process = this.diagnosticProcesses[i];
      if (process.diagnosticCheckId === id) {
        this.diagnosticProcesses.splice(i, 1);
        this.diagnosticProcessesChangedBS.next(true);
        return true;
      }
    }
    return false;
  }

  private setNetworkDiagnostic(diagnostic: INetworkDiagnostic): void {
    const index = this.networkDiagnosticProcesses.findIndex(
      (p) => p.processId === diagnostic.processId
    );
    if (index !== -1) {
      this.networkDiagnosticProcesses[index] = diagnostic;
      this.diagnosticProcessesChangedBS.next(true);
    } else {
      //new or cancelled process, add to the list if inprogress
      if (
        diagnostic.status === EDiagnosticCheckStatus.IN_PROGRESS ||
        diagnostic.status === EDiagnosticCheckStatus.CANCELLED
      ) {
        this.networkDiagnosticProcesses.push(diagnostic);
      }
      this.diagnosticProcessesChangedBS.next(true);
    }
  }

  private setDiagnostic(diagnostic: IDiagnosticProcessInfo): void {
    const index = this.diagnosticProcesses.findIndex(
      (p) => p.diagnosticCheckId === diagnostic.diagnosticCheckId
    );
    if (index !== -1) {
      this.diagnosticProcesses[index] = diagnostic;
      this.diagnosticProcessesChangedBS.next(true);
    } else {
      //new process, add to the list if inprogress
      if (
        diagnostic.diagnostic?.status === EDiagnosticCheckStatus.IN_PROGRESS
      ) {
        this.diagnosticProcesses.push(diagnostic);
      }
      this.diagnosticProcessesChangedBS.next(true);
    }
  }
}

export function uuid(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    (c: string) => {
      const r: number = (Math.random() * 16) | 0;
      const v: number = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    }
  );
}
