import { HttpResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { TdDialogService } from '@covalent/core/dialogs';
import { CovalentSideSheet } from '@covalent/core/side-sheet';
import { TranslateService } from '@ngx-translate/core';
import { VantageToastService } from '@td/vantage-user-feedback';
import { BehaviorSubject, filter, map, Observable, switchMap, tap } from 'rxjs';

import { AuthenticationService } from '../../authentication.service';
import {
  IPat,
  IPatListResponse,
  PersonalAccessTokensService,
} from '../../personal-access-tokens.service';
import {
  AccessTokenDetailsComponent,
  IAccessTokensDetailsInput,
} from '../access-token-details/access-token-details.component';
import { RevokeDialogComponent } from '../revoke-dialog/revoke-dialog.component';

@Component({
  selector: 'janus-access-token-list',
  templateUrl: './access-token-list.component.html',
  styleUrls: ['./access-token-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessTokenListComponent implements OnInit {
  private isLoadingBS = new BehaviorSubject<boolean>(false);
  private _limitToLoggedInUser: boolean = true;

  readonly COLUMNS_FOR_ORG_ADMIN_VIEW = [
    'last_exchanged',
    'organization',
    'user',
  ];

  displayedColumns = [
    'service',
    'environment',
    'created',
    'expires',
    'actions',
  ];
  isLoading$: Observable<boolean> = this.isLoadingBS.asObservable();
  patsList$: Observable<IPat[]>;

  @Input() allowRevokeOrg: boolean = false;
  @Input() allowRevokeUser: boolean = false;
  @Input() set limitToLoggedInUser(limitView: boolean) {
    this._limitToLoggedInUser = limitView;

    // if true, we want to add additional columns to be shown, otherwise
    // they need to be removed
    if (limitView) {
      this.COLUMNS_FOR_ORG_ADMIN_VIEW.forEach((name: string) => {
        const index: number = this.displayedColumns.findIndex(
          (column) => column === name
        );

        if (-1 !== index) {
          this.displayedColumns.splice(index, 1);
        }
      });
    } else {
      this.displayedColumns.splice(-1, 0, 'last_exchanged');
      this.displayedColumns.splice(2, 0, 'user', 'organization');
    }
  }

  constructor(
    private readonly patsService: PersonalAccessTokensService,
    private readonly authService: AuthenticationService,
    private readonly translate: TranslateService,
    private readonly dialogService: TdDialogService,
    private readonly sidesheet: CovalentSideSheet,
    private readonly toastService: VantageToastService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loadPats();
  }

  detailsClicked(tokenId: string): void {
    this.sidesheet
      .open(AccessTokenDetailsComponent, {
        data: { tokenId } as IAccessTokensDetailsInput,
        width: '600px',
      })
      .afterClosed()
      .pipe(filter((reloadList: boolean) => !!reloadList))
      .subscribe(() => {
        this.loadPats();
      });
  }

  isShowRevokeAllOrgTokens(token: IPat): boolean {
    return !!token.org_name && !this._limitToLoggedInUser;
  }

  isShowRevokeAllServiceTokens(token: IPat): boolean {
    return !!token.service && !this._limitToLoggedInUser;
  }

  isShowRevokeAllUserTokens(token: IPat): boolean {
    return !!token.owner;
  }

  loadPats(): void {
    this.isLoadingBS.next(true);

    this.patsList$ = (
      this._limitToLoggedInUser
        ? this.patsService.getUserPATs$(this.authService.userInfo.sub)
        : this.patsService.getOrgPATs$()
    ).pipe(
      map((response: HttpResponse<IPatListResponse>) => response.body.pats),
      map((userPats: IPat[]) =>
        userPats.filter((userPat: IPat) => !!userPat.active)
      ),
      tap(() => this.isLoadingBS.next(false))
    );
  }

  revokeAllOrgClicked(token: IPat): void {
    this.confirmAndRevokeTokens(
      'AUTH_LIB.ACCESS_TOKEN.LIST.REVOKE_ALL_ORG_TOKENS',
      'AUTH_LIB.ACCESS_TOKEN.REVOKE_MULTI_MESSAGE',
      this.patsService.revokeAllOrganizationPAT$(token.org_id)
    );
  }

  revokeAllServiceTokensClicked(token: IPat): void {
    this.confirmAndRevokeTokens(
      'AUTH_LIB.ACCESS_TOKEN.LIST.REVOKE_ALL_SERVICE_TOKENS',
      'AUTH_LIB.ACCESS_TOKEN.REVOKE_MULTI_MESSAGE',
      this.patsService.revokeAllServicePAT$(token.service)
    );
  }

  revokeAllUserTokensClicked(token: IPat): void {
    this.confirmAndRevokeTokens(
      'AUTH_LIB.ACCESS_TOKEN.LIST.REVOKE_ALL_USER_TOKENS',
      'AUTH_LIB.ACCESS_TOKEN.REVOKE_MULTI_MESSAGE',
      this.patsService.revokeAllUserPAT$(token.owner)
    );
  }

  revokeSpecificTokenClicked(token: IPat): void {
    this.confirmAndRevokeTokens(
      'AUTH_LIB.ACCESS_TOKEN.LIST.REVOKE_TOKEN',
      'AUTH_LIB.ACCESS_TOKEN.REVOKE_MESSAGE',
      this.patsService.revokeSpecificPAT$(token.reference_id)
    );
  }

  // the state of the menu being open doesn't update when the menu closes;
  // trying to force the update (since we are using OnPush)
  updateMenuOpenedState(): void {
    this.cdr.detectChanges();
  }

  // this is a stop gap for now; want to make it harder for user to delete
  // multiple tokens at the same time (maybe use the delete confirmation from
  // BYOIDP designs?)
  private confirmAndRevokeTokens(
    dialogTitle: string,
    dialogMessage: string,
    revokeCall: Observable<void>
  ): void {
    this.dialogService
      .open(RevokeDialogComponent, {
        width: '460px',
        disableClose: true,
        data: {
          dialogTitle,
          dialogMessage,
        },
      })
      .afterClosed()
      .pipe(
        filter((confirm: boolean) => !!confirm),
        tap(() => this.isLoadingBS.next(true)),
        switchMap(() => revokeCall)
      )
      .subscribe({
        next: () => {
          this.toastService.open(
            this.translate.instant(
              'AUTH_LIB.ACCESS_TOKEN.REVOKE_OUTCOMES.REVOKE_SUCCEEDED'
            ),
            4000
          );

          this.loadPats();
        },
        error: () => {
          this.isLoadingBS.next(false);

          this.toastService.open(
            this.translate.instant(
              'AUTH_LIB.ACCESS_TOKEN.REVOKE_OUTCOMES.REVOKE_FAILED'
            ),
            10000
          );
        },
      });
  }
}
