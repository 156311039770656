import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEventType,
  HttpHeaders,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../common/api.service';

export interface IQGSoftware {
  id: string;
  name: string;
  fileName: string;
  type: string;
  version: string;
  platform: string;
  defaultValue: string;
  buildDate: string;
  connectorProperties: IQGConnectorProperty[];
}

export interface IQGConnectorSoftware extends IQGSoftware {
  connectorProperties: IQGConnectorProperty[];
}

export interface JDBCDriver extends IQGSoftware {
  lastModified: string;
  qgmVersion: string;
  packageSize: string;
  queryPointSupport: boolean;
  driverSupportEnabled: boolean;
  driverName: string;
  loaderConnectorName: string;
  remoteConnectorName: string;
  remoteConnectorClassName: string;
  managerId: string;
  installMethod: string;
}

export enum DataType {
  'STRING' = 'STRING',
  'PASSWORD' = 'PASSWORD',
  'INTEGER' = 'INTEGER',
  'LONG' = 'LONG',
  'FLOAT' = 'FLOAT',
  'DOUBLE' = 'DOUBLE',
  'BOOLEAN' = 'BOOLEAN',
  'ENUM' = 'ENUM',
}

export enum EConnectorPropertyApply {
  INITIATOR = 'INITIATOR',
  TARGET = 'TARGET',
  BOTH = 'BOTH',
}

export interface IQGConnectorProperty {
  name: string;
  i18nKey?: string;
  dataType: DataType;
  required?: boolean;
  connectorCheckOnly?: boolean;
  defaultValue?: any;
  enumValues?: string[];
  enumi18nKeyPrefix?: string;
  unitsi18nKey?: string;
  validationRules?: Record<string, any>;
  overridable?: boolean;
  requiredForInstall?: boolean;
  installOnly?: boolean;
  order?: number;
  group: string;
  appliesTo: string;
}

@Injectable({
  providedIn: 'root',
})
export class SoftwareService {
  constructor(
    private readonly http: HttpClient,
    private _apiService: ApiService
  ) {}

  getSoftwares(): Observable<IQGSoftware[]> {
    return this.http.get<IQGSoftware[]>(`${this.getBaseUrl()}/software`).pipe(
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      })
    );
  }

  getSoftwaresBySoftwareVersion(
    softwareVersion: string
  ): Observable<IQGSoftware[]> {
    return this.http
      .get<IQGSoftware[]>(
        `${this.getBaseUrl()}/software?filterByName=tdqg-node`
      )
      .pipe(
        map((softwares: IQGSoftware[]) => {
          return softwares.filter(
            (software) => software.version === softwareVersion
          );
        }),
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  getConnectorSoftware(): Observable<IQGConnectorSoftware[]> {
    return this.http
      .get<IQGConnectorSoftware[]>(
        `${this.getBaseUrl()}/software/connector?filterByName=tdqg-node`
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  getGenericJdbcConnectorSoftware(): Observable<IQGSoftware[]> {
    return this.http
      .get<IQGSoftware[]>(
        `${this.getBaseUrl()}/software?filterByName=tdqg-genericjdbc-connector`
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  getJdbcSoftwares(): Observable<JDBCDriver[]> {
    return this.http
      .get<JDBCDriver[]>(`${this.getBaseUrl()}/software/jdbc-driver`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  postJdbcDriver(
    jdbcDriver: File,
    jdbcDriverName: string
  ): Observable<JDBCDriver | any> {
    const formData = new FormData();
    formData.append('jdbcDriverFile', jdbcDriver);
    formData.append('jdbcDriverName', jdbcDriverName);
    return this.http
      .post<JDBCDriver | any>(`${this.getBaseUrl()}/software`, formData, {
        headers: new HttpHeaders({
          Accept: 'application/json',
        }),
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map((resp: any) => {
          if (resp.type === HttpEventType.Response) {
            return resp;
          }
          if (resp.type === HttpEventType.UploadProgress) {
            return { progress: Math.round((100 * resp.loaded) / resp.total) };
          }
        }),
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  deleteJDBCDriver(driverId: string) {
    return this.http
      .delete(`${this.getBaseUrl()}/software/jdbc-driver/${driverId}`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw { ...error.error, httpStatus: error.status };
        })
      );
  }

  getSoftwareResourceBundle(
    softwareId: string
  ): Observable<Record<string, string>> {
    return this.http
      .get<Record<string, string>>(
        `${this.getBaseUrl()}/software/${softwareId}/resource-bundle`
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  downloadTdqgNode(softwareId: string): any {
    return this.http
      .get(`${this.getBaseUrl()}/software/${softwareId}/package`, {
        responseType: 'blob',
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  downloadTdqgNodeJson(
    systemId: string,
    isAntaresType: boolean = false
  ): Observable<string> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    const systemdata = {
      systemId: systemId,
      expirationDays: 90,
      clusterOption: isAntaresType ? 'PRIMARY' : 'SECONDARY',
    };
    return this.http
      .post(
        `${this.getBaseUrl()}/operations/nodes-manual-install`,
        systemdata,
        {
          headers,
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  getBaseUrl(): string {
    return this._apiService.getBaseUrl();
  }
}
