declare const require: any;

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { CovalentDialogsModule } from '@covalent/core/dialogs';

import { TdAttributeFilterService } from './attribute-filter.service';
import { TdAttributeFilterComponent } from './attribute-filter.component';
import { PopupDialogComponent } from './popup-dialog/popup-dialog.component';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

import {
  initTranslateService,
  startTranslateService,
  addTranslation,
  getSupportedLanguages,
} from '@janus/translate';
import { UiStatusModule } from '../status/status.module';

@NgModule({
  declarations: [TdAttributeFilterComponent, PopupDialogComponent],
  imports: [
    /** Angular Modules */
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatBadgeModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatSelectModule,
    CovalentDialogsModule,
    TranslateModule.forChild(),
    UiStatusModule,
  ],
  exports: [TdAttributeFilterComponent],
  providers: [TdAttributeFilterService],
})
export class TdAttributeFilterModule {
  constructor(translateService: TranslateService) {
    initTranslateService(translateService);

    getSupportedLanguages().forEach((lang: string) => {
      let translateFile: any;
      try {
        translateFile = require(`../../../assets/i18n/${lang}.json`);
      } catch (err: any) {
        translateFile = {};
      }

      addTranslation(translateService, lang, translateFile);
    });

    startTranslateService(translateService);
  }
}
