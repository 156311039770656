import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Observable, from } from 'rxjs';

import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(private readonly _authenticationService: AuthenticationService) {}

  canActivate(): Observable<boolean> {
    return from(this._authenticationService.isAuthenticated());
  }
}
