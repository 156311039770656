import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FILTER_TYPES } from '../attribute-filter.service';
import {
  ColumnType,
  FilterType,
  IColumn,
  ISelectedFilter,
  IValueChoice,
} from '../attribute-filter.service';
import { TranslateService } from '@ngx-translate/core';

export interface IPopupDialogIncoming {
  columns: IColumn[];
  dialogWidth: number;
  instantFiltering: boolean;
  positionRelativeToElement: ElementRef;
  selectedFilters: ISelectedFilter[];
}

@Component({
  selector: 'td-popup-dialog',
  templateUrl: './popup-dialog.component.html',
  styleUrls: ['./popup-dialog.component.scss'],
})
export class PopupDialogComponent implements OnInit, OnDestroy {
  private _takeUntil$ = new Subject<boolean>();

  addMode = false;
  filterDisplayForm: FormGroup = new FormGroup({
    filters: new FormArray([]),
  });

  constructor(
    private translate: TranslateService,
    private readonly _dialogRef: MatDialogRef<PopupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IPopupDialogIncoming,
    private readonly _formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    // move dialog to just below the button that opened us
    const matDialogConfig = new MatDialogConfig();
    const rect: DOMRect =
      this.data.positionRelativeToElement.nativeElement.getBoundingClientRect();

    // figure out if dialog will show up on screen, otherwise, let's move it so
    // it would show up
    const leftPosition = this.computeLeftPosition(
      rect.right - this.data.dialogWidth
    );

    matDialogConfig.position = {
      left: `${leftPosition}px`,
      top: `${rect.bottom + 2}px`,
    };
    this._dialogRef.updatePosition(matDialogConfig.position);

    // add any existing filters to the list
    this.data.selectedFilters.forEach((filter: ISelectedFilter) => {
      this.addFilterToTable(filter);
    });
  }

  ngOnDestroy(): void {
    this._takeUntil$.next(true);
    this._takeUntil$.complete();
  }

  addFilterToTable(filter: ISelectedFilter) {
    this.addMode = true;
    const filterForm = this._formBuilder.group({
      columnId: [filter?.columnId || '', Validators.required],
      filterType: [
        filter?.filterType || FilterType.CONTAINS,
        Validators.required,
      ],
      filterValue: [filter?.filterValue || '', Validators.required],
      filterAccessor: null,
      index: 0,
    });

    filterForm.controls['columnId'].valueChanges
      .pipe(takeUntil(this._takeUntil$))
      .subscribe((idChosen: string) => {
        // update the filter type, if needed
        const chosenColumn = this.getColumnById(idChosen);
        const filterTypes = FILTER_TYPES[chosenColumn?.type || 'string'];

        if (!filterTypes.includes(filterForm.controls['filterType'].value)) {
          filterForm.controls['filterType'].setValue(filterTypes[0]);
        }
      });

    this.filters.push(filterForm);
  }

  applyPopupFilters() {
    this._dialogRef.close(
      this.filters.value.map((obj: ISelectedFilter) => ({ ...obj }))
    );
  }

  deleteFilterFromTable(filterIndex: number) {
    this.filters.removeAt(filterIndex);

    if (this.filters.length === 0) {
      this.addMode = false;
    }
  }

  filterDisplayTypes(filterIndex: number): FilterType[] {
    const chosenColumn = this.getColumnById(
      this.filters.value[filterIndex].columnId
    );

    return FILTER_TYPES[chosenColumn?.type || 'string'];
  }

  filterTypeTranslation(key: string): string {
    return this.translate.instant(`FILTER_TYPES.${key.toUpperCase()}`);
  }

  filterTypeIsEnum(filterIndex: number): boolean {
    const chosenColumn = this.getColumnById(
      this.filters.value[filterIndex].columnId
    );

    return (
      chosenColumn?.type === ColumnType.ENUM ||
      chosenColumn?.type === ColumnType.ENUM_INCLUDE_ONLY
    );
  }

  filterValueChoices(filterIndex: number): IValueChoice[] {
    const chosenColumn = this.getColumnById(
      this.filters.value[filterIndex].columnId
    );

    return chosenColumn?.valueChoices || [];
  }

  get filters() {
    return this.filterDisplayForm.controls['filters'] as FormArray;
  }

  getColumnById(id: string): IColumn {
    return this.data.columns.filter((column) => column.id === id)[0];
  }

  getFilterableColumns(): IColumn[] {
    return this.data.columns.filter(
      (column: IColumn) => !column.excludeFromFiltering
    );
  }

  private computeLeftPosition(startPos: number): number {
    if (startPos < 16) {
      return 16;
    }

    return startPos;
  }
}
