<div layout="column" layout-fill>
  <mat-card-title class="mat-title" *ngIf="!showStatus">
    {{ 'USE_CASE.ASSET_DESTINATION' | translate }}
  </mat-card-title>
  <mat-card-title class="mat-title" *ngIf="showStatus">
    {{ 'USE_CASE.LOADING_DATASET' | translate }}</mat-card-title
  >
  <div *ngIf="errorMsg">
    <td-message [sublabel]="errorMsg" color="warn"></td-message>
  </div>

  <mat-progress-bar
    class="push-bottom"
    *ngIf="assetLoading"
    color="accent"
    mode="indeterminate"
  ></mat-progress-bar>

  <mat-divider *ngIf="!showStatus"></mat-divider>
  <ng-container *ngIf="!showStatus">
    <mat-dialog-content>
      <div class="push-top push-bottom dataSet-container">
        <div class="dataSet-label">
          {{ 'USE_CASE.DATASET_NAME' | translate }}
        </div>
        <div class="dataSet-value push-top-sm">
          {{ data.dataset }}
        </div>
      </div>
      <div class="dataSet-label">
        {{ 'ACCOUNT' | translate }}
      </div>
      <div class="dataSet-value push-bottom-sm push-top-sm">
        {{ data.system.name }}
      </div>

      <ng-container *ngIf="paramsReuried">
        <div class="push-bottom">{{ 'USE_CASE.NEW_USER' | translate }}</div>

        <form [formGroup]="assetForm">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'USERNAME' | translate }}</mat-label>
            <input
              matInput
              name="userName"
              formControlName="userName"
              autocomplete="off"
            />
          </mat-form-field>
          <mat-form-field data-test="password-input" appearance="outline">
            <mat-label>{{ 'NEW_PASSWORD' | translate }}</mat-label>
            <input
              *ngIf="!showPassword"
              matInput
              autocomplete="off"
              name="password"
              type="password"
              formControlName="tempPassword"
              required
            />
            <button
              *ngIf="!showPassword"
              mat-button
              matSuffix
              mat-icon-button
              class="tc-faded"
              type="button"
              (click)="showPassword = !showPassword"
            >
              <mat-icon>visibility</mat-icon>
            </button>
            <input
              *ngIf="showPassword"
              matInput
              autocomplete="off"
              name="password"
              formControlName="tempPassword"
              required
            />
            <button
              *ngIf="showPassword"
              mat-button
              matSuffix
              mat-icon-button
              class="tc-faded"
              type="button"
              (click)="showPassword = !showPassword"
            >
              <mat-icon>visibility_off</mat-icon>
            </button>
          </mat-form-field>
          <mat-hint class="push-bottom">{{
            'PASSWORD_HINT' | translate
          }}</mat-hint>
          <mat-form-field
            data-test="confirm-password-input"
            appearance="outline"
          >
            <mat-label>{{ 'CONFIRM_PASSWORD' | translate }}</mat-label>
            <input
              *ngIf="!showConfirmPassword"
              matInput
              autocomplete="off"
              name="confirmPassword"
              type="password"
              formControlName="tempConfirmPassword"
              required
            />
            <button
              *ngIf="!showConfirmPassword"
              mat-button
              matSuffix
              mat-icon-button
              class="tc-faded"
              type="button"
              (click)="showConfirmPassword = !showConfirmPassword"
            >
              <mat-icon>visibility</mat-icon>
            </button>
            <input
              *ngIf="showConfirmPassword"
              matInput
              autocomplete="off"
              name="confirmPassword"
              formControlName="tempConfirmPassword"
              required
            />
            <button
              *ngIf="showConfirmPassword"
              mat-button
              matSuffix
              mat-icon-button
              class="tc-faded"
              type="button"
              (click)="showConfirmPassword = !showConfirmPassword"
            >
              <mat-icon>visibility_off</mat-icon>
            </button>
          </mat-form-field>
        </form>
      </ng-container>
    </mat-dialog-content>
    <mat-divider></mat-divider>
    <mat-dialog-actions class="mat-dialog-actions">
      <button mat-button color="primary" (click)="close()">
        {{ 'CANCEL' | translate }}
      </button>
      <button
        mat-raised-button
        color="accent"
        (click)="onLoadAsset()"
        [disabled]="assetForm?.invalid"
      >
        {{ 'USE_CASE.LOAD_ASSETS' | translate }}
      </button>
    </mat-dialog-actions>
  </ng-container>

  <ng-container *ngIf="showStatus">
    <div class="push-top-sm push-bottom-sm dataset-message">
      {{ 'USE_CASE.CLOSE_DIALOG' | translate }}
    </div>
    <mat-dialog-content class="dataset-status">
      <mat-accordion>
        <ng-container *ngFor="let script of scripts; index as index">
          <mat-expansion-panel
            (opened)="onPanelExpanded(script, index)"
            [expanded]="index == expandedIndex"
            (closed)="onPanelClosed()"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="script-name mat-input">
                  {{ script.script_name }}
                </div>
                <div
                  class="script-status mat-body-1"
                  [matTooltip]="script.message ? script.message : ''"
                >
                  <div
                    *ngIf="getStatusClass(script.status).icon"
                    class="push-right-sm"
                  >
                    <mat-icon
                      [class]="getStatusClass(script.status).class"
                      [svgIcon]="getStatusClass(script.status).icon"
                      *ngIf="getStatusClass(script.status).class"
                    ></mat-icon>
                  </div>
                  <div
                    [ngClass]="getStatusClass(script.status).class"
                    class="mat-body-1"
                  >
                    {{ 'USE_CASE.' + script.status | translate }}
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <td-highlight
              codeLang="sql"
              [content]="scriptsMap.get(index)"
              [copyCodeToClipboard]="true"
            >
            </td-highlight>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </mat-dialog-content>

    <mat-dialog-actions class="button-close">
      <button
        mat-button
        color="primary"
        (click)="close()"
        [disabled]="assetLoading"
      >
        {{ 'CLOSE' | translate }}
      </button>
    </mat-dialog-actions>
  </ng-container>
</div>
