import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { NavItem } from '../utils/nav-rail-data';
import { HelpMenuService } from '@janus/help';

@Injectable({
  providedIn: 'root',
})
export class NavService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private _helpMenuService: HelpMenuService
  ) {}

  appRouteMap = new Map<string, string[]>([
    [
      'janus',
      [
        '',
        'home',
        'admin',
        'consumption',
        'cost-calculator',
        'environments',
        'organization',
        'organizations',
        'your-account',
        'queries',
        'alerts',
      ],
    ],
    ['dataCatalog', ['catalog', 'data-sources']],
    ['dataSharing', ['data-sharing', 'permissions']],
    ['editor', ['query']],
    ['dataMover', ['data-copy', 'data-migration']],
    ['udf', ['user-defined-functions']],
  ]);

  navigate(navItem: NavItem): void {
    const basePathname = this.document.location.pathname.split('/')[1];
    const currentApp = this.getAppName(basePathname);
    const toApp = this.getAppName(navItem.value);

    if (currentApp === toApp) {
      // in the same App, use route
      this.router.navigateByUrl(navItem.route);
    } else {
      // switch bewtween different Apps, use herf
      let href = navItem.href || navItem.route;

      if (this._helpMenuService.helpPanelOpened) {
        href += `?help_panel_opened=${this._helpMenuService.helpPanelOpened}`;

        if (this._helpMenuService.helpPanelId) {
          href += `&help_panel_id=${this._helpMenuService.helpPanelId}`;
        }
      }
      this.document.location.href = href;
    }
  }

  private getAppName(route: string): string {
    let appName: string;

    this.appRouteMap.forEach((routes, app) => {
      if (routes.includes(route)) {
        appName = app;
      }
    });

    return appName;
  }

  isActiveRoute(navItem: NavItem, isExpanded = false): string {
    //case when any of its children route is active and the list is expanded, then highlighting
    // is not needed on parent nav item.
    if (isExpanded && this.isActiveRouteInChildren(navItem)) {
      return '';
    }
    const basePathname =
      this.document.location.pathname !== undefined &&
      this.document.location.pathname.indexOf('?') !== -1
        ? this.document.location.pathname.split('?')[0]
        : this.document.location.pathname;
    const baseRoute =
      navItem.route !== undefined && navItem.route.indexOf('?') !== -1
        ? navItem.route.split('?')[0]
        : navItem.route;
    const href = navItem.href;

    // very specific case; if we are at the "home" route (i.e. /), only mark
    // it active if the location indicates we are there too
    if (baseRoute === '/') {
      if (basePathname === '/') {
        return 'active tc-accent';
      }
    } else if (
      basePathname.startsWith(baseRoute) ||
      (href && basePathname.startsWith(href))
    ) {
      return 'active tc-accent';
    } else {
      return '';
    }
  }

  isActiveRouteInChildren(navItem: NavItem): string {
    if (
      navItem.children &&
      navItem.children.some((item) =>
        this.router.isActive(item.route, {
          paths: 'subset',
          queryParams: 'subset',
          fragment: 'ignored',
          matrixParams: 'ignored',
        })
      )
    ) {
      return 'active tc-accent';
    }

    return '';
  }
}
