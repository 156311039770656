import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApiService } from '../../common/api.service';
import { AbstractControl } from '@angular/forms';
export interface IBridge {
  id?: string;
  name: string;
  type?: string;
  description?: string;
  systemName?: string;
  systemId: string;
  nodeIds: string[];
  nodeSelection?: string;
  tags?: {
    network_object_id?: string;
    secretName?: string;
    antaresType?: string;
  };
  _extraInfo?: {
    nodesOnline: number;
    nodesOffline: number;
    systemName: string;
  };
}

export interface ISystemNode {
  id: string;
  name: string;
  platform: string;
  systemId: string;
  _extraInfo?: {
    status: string;
    startTime: string;
    softwareVersion: string;
    lastHeartbeatTime: string;
  };
}

export enum ENodesSelection {
  'NO_SELECTION' = 'no selection',
  'SELECTED' = 'selected',
}

export interface INodesSelection {
  selectedNodeIds: string[];
  status: ENodesSelection;
}

@Injectable({
  providedIn: 'root',
})
export class BridgeService {
  private nodesSelectionCompleteBS: BehaviorSubject<INodesSelection> =
    new BehaviorSubject<INodesSelection>({
      selectedNodeIds: [],
      status: ENodesSelection.NO_SELECTION,
    });
  nodesSelectionComplete$ = this.nodesSelectionCompleteBS.asObservable();
  private bridgeChangedBS: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  bridgeChanged$ = this.bridgeChangedBS.asObservable();

  constructor(private _http: HttpClient, private _apiService: ApiService) {}

  getBridges(): Observable<IBridge[]> {
    return this._http
      .get<any>(`${this.getBaseUrl()}/config/bridges?extraInfo=true`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  getBridgesFiltered(
    filterType: string,
    filterId: string,
    extraInfo?: boolean
  ): Observable<IBridge[]> {
    let baseUrl: string = `${this.getBaseUrl()}/config/bridges`;
    baseUrl = baseUrl + '?extraInfo=' + extraInfo;
    switch (filterType) {
      case 'SYSTEM':
        baseUrl = baseUrl + '&filterBySystemId=' + filterId;
        break;
      case 'FABRIC':
        baseUrl = baseUrl + '&filterByFabricId=' + filterId;
        break;
      case 'CONNECTOR':
        baseUrl = baseUrl + '&filterByConnectorId=' + filterId;
        break;
    }
    return this._http.get<any>(baseUrl).pipe(
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      })
    );
  }

  deleteBridge(id: string): Observable<any> {
    return this._http
      .delete<any>(`${this.getBaseUrl()}/config/bridges/${id}`)
      .pipe(
        map((resp: any) => {
          this.bridgeChangedBS.next(true);
          return resp;
        }),
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  getNodesBySystemId(systemId: string): Observable<ISystemNode[]> {
    return this._http
      .get<any>(
        `${this.getBaseUrl()}/nodes?filterBySystemId=${systemId}&extraInfo=true`
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  createBridge(bridge: IBridge): Observable<any> {
    return this._http.post(`${this.getBaseUrl()}/config/bridges`, bridge).pipe(
      map((resp: any) => {
        this.bridgeChangedBS.next(true);
        return resp;
      }),
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      })
    );
  }

  updateBridge(bridge: IBridge): Observable<any> {
    return this._http
      .put(`${this.getBaseUrl()}/config/bridges/${bridge.id}`, bridge)
      .pipe(
        map((resp: any) => {
          this.bridgeChangedBS.next(true);
          return resp;
        }),
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  completeNodesSelection(selectedNodeIds: string[]): void {
    const nodesSelection = {
      selectedNodeIds: selectedNodeIds,
      status: ENodesSelection.SELECTED,
    };
    this.nodesSelectionCompleteBS.next(nodesSelection);
  }

  initNodesSelectionBS(): void {
    const nodesSelection = {
      selectedNodeIds: [],
      status: ENodesSelection.NO_SELECTION,
    };
    this.nodesSelectionCompleteBS.next(nodesSelection);
  }

  getBaseUrl(): string {
    return this._apiService.getBaseUrl();
  }

  noSpecificChars(c: AbstractControl) {
    const ILLEGAL_NAME_CHARS =
      '\\!\\@\\#\\$\\%\\^\\&\\*\\(\\)\\{\\}\\+\\=\\;\\:\\"\'\\,\\<\\>\\?\\|';
    const SPECIAL_CHARS_REGEXP = new RegExp(`^[^${ILLEGAL_NAME_CHARS}]*$`);
    return SPECIAL_CHARS_REGEXP.test(c.value)
      ? null
      : {
          noSpecificChars: {
            valid: false,
          },
        };
  }

  noSpace(c: AbstractControl) {
    const SPACE_REGEXP = /[\S]/;
    if (c.value && c.value.length > 0) {
      return SPACE_REGEXP.test(c.value)
        ? null
        : {
            noSpace: {
              valid: false,
            },
          };
    } else {
      return null;
    }
  }
}
