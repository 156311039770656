import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AuthenticationService, UserInfo } from '@janus/authentication';
import { BehaviorSubject } from 'rxjs';

import { NavItem } from '../../utils/nav-rail-data';
import { NavService } from '../../utils/nav.service';

const YOUR_ACCOUNT_NAV_ITEM: NavItem = {
  name: 'YOUR_ACCOUNT',
  value: 'your-account',
  route: '/your-account',
};

@Component({
  selector: 'your-account-nav-item',
  templateUrl: './your-account-nav-item.component.html',
  styleUrls: ['./your-account-nav-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YourAccountNavItemComponent implements OnInit, OnDestroy {
  private readonly displayEmailBS = new BehaviorSubject<string>('');
  private readonly displayNameBS = new BehaviorSubject<string>('');

  displayEmail$ = this.displayEmailBS.asObservable();
  displayName$ = this.displayNameBS.asObservable();

  @Input() expanded: boolean;

  isDbUser: boolean;
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly navService: NavService
  ) {}

  ngOnInit(): void {
    this.isDbUser = this.authenticationService.isDBUser();
    this.getUserInfo();
  }

  ngOnDestroy(): void {
    this.displayEmailBS.complete();
    this.displayNameBS.complete();
  }

  getUserInfo(): void {
    const userInfo: UserInfo = this.authenticationService.userInfo;
    let fullName: string = userInfo.given_name || '';

    if (userInfo.family_name) {
      fullName += ` ${userInfo.family_name}`;
    }

    this.displayEmailBS.next(userInfo.email || '');
    this.displayNameBS.next(
      this.authenticationService.isDBUser() ? userInfo.display_name : fullName
    );
  }

  isActiveRoute(): string {
    return this.navService.isActiveRoute(YOUR_ACCOUNT_NAV_ITEM);
  }

  launchAccountSettings(): void {
    this.navService.navigate(YOUR_ACCOUNT_NAV_ITEM);
  }

  logout() {
    this.authenticationService.logout();
  }
}
