import { Injectable, Provider, Optional, SkipSelf } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';

import { BreakpointObserver } from '@angular/cdk/layout';

@Injectable()
export class VantageToastService {
  constructor(
    private _snackBarService: MatSnackBar,
    private breakpointObserver: BreakpointObserver
  ) {}

  open(message: string, time: number = 3000): void {
    if (message) {
      this._snackBarService.open(message, undefined, {
        duration: time,
        horizontalPosition: this.breakpointObserver.isMatched(
          'min-width: 960px'
        )
          ? 'center'
          : 'start',
      });
    }
  }
}

export function VANTAGE_TOAST_PROVIDER_FACTORY(
  parent: VantageToastService,
  snackBar: MatSnackBar,
  breakpointObserver: BreakpointObserver
): VantageToastService {
  return parent || new VantageToastService(snackBar, breakpointObserver);
}

export const VANTAGE_TOAST_PROVIDER: Provider = {
  // If there is already a service available, use that. Otherwise, provide a new one.
  provide: VantageToastService,
  deps: [
    [new Optional(), new SkipSelf(), VantageToastService],
    MatSnackBar,
    [new Optional(), BreakpointObserver],
  ],
  useFactory: VANTAGE_TOAST_PROVIDER_FACTORY,
};
