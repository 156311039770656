import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from '../../common/api.service';

export interface IQGCommunicationPolicy {
  id: string;
  name: string;
  description?: string;
  transferConcurrency: number;
  version: EQGCommunicationPolicyVersion;
  versionId: string;
  compressionAlgorithm: string;
  hasPendingVersion?: boolean;
}

export interface ICommunicationPolicy extends IQGCommunicationPolicy {
  displayName: string;
  compressionEnabled: boolean;
}

export enum EQGCompressionAlgorithms {
  'NONE' = 'NONE',
  'ZSTD' = 'ZSTD',
}

export enum EQGCommunicationPolicyVersion {
  'ACTIVE' = 'ACTIVE',
  'PENDING' = 'PENDING',
  'PREVIOUS' = 'PREVIOUS',
}

@Injectable({
  providedIn: 'root',
})
export class CommunicationPolicyService {
  private policyListChange = new Subject<string>();
  policyListChanges$ = this.policyListChange.asObservable();

  constructor(
    private _http: HttpClient,
    private _apiService: ApiService,
    private _translateService: TranslateService
  ) {}

  private _errorHandler = (error: HttpErrorResponse) => {
    throw Object.assign({}, error.error, { httpStatus: error.status });
  };

  getCommunicationPolicies(): Observable<ICommunicationPolicy[]> {
    return this._http
      .get<any>(`${this.getBaseUrl()}/config/comm-policies?flatten=true`)
      .pipe(
        map(this._populateHasPendingVersion),
        map((policies) => this._toDisplayValues(policies)),
        catchError(this._errorHandler)
      );
  }

  deleteCommunicationPolicy(policy: IQGCommunicationPolicy): Observable<any> {
    let deleteUrl: string;
    if (policy.version === EQGCommunicationPolicyVersion.PENDING) {
      deleteUrl = `${this.getBaseUrl()}/config/comm-policies/${
        policy.id
      }/pending`;
    } else if (policy.version === EQGCommunicationPolicyVersion.PREVIOUS) {
      deleteUrl = `${this.getBaseUrl()}/config/comm-policies/${
        policy.id
      }/previous`;
    } else {
      deleteUrl = `${this.getBaseUrl()}/config/comm-policies/${policy.id}`;
    }
    return this._http.delete<any>(deleteUrl).pipe(
      map((m) => {
        this.policyListChange.next('deleted');
        return m;
      }),
      catchError(this._errorHandler)
    );
  }

  createCommunicationPolicy(
    policy: IQGCommunicationPolicy
  ): Observable<IQGCommunicationPolicy> {
    if (policy.id !== null) {
      throw new Error('Policy cannot be created.');
    }
    return this._http
      .post<IQGCommunicationPolicy>(
        `${this.getBaseUrl()}/config/comm-policies`,
        policy
      )
      .pipe(
        map((m) => {
          this.policyListChange.next(m.id);
          return m;
        }),
        catchError(this._errorHandler)
      );
  }

  updateCommunicationPolicy(
    policy: IQGCommunicationPolicy
  ): Observable<IQGCommunicationPolicy> {
    if (
      policy.id === null ||
      policy.version === EQGCommunicationPolicyVersion.PREVIOUS
    ) {
      throw new Error('Policy cannot be updated.');
    }
    let updateUrl: string;
    let updatedPolicy: any;
    if (policy.version === EQGCommunicationPolicyVersion.ACTIVE) {
      updateUrl = `${this.getBaseUrl()}/config/comm-policies/${
        policy.id
      }/active`;
      updatedPolicy = policy;
    } else {
      updateUrl = `${this.getBaseUrl()}/config/comm-policies/${
        policy.id
      }/pending`;
      updatedPolicy = {
        compressionAlgorithm: policy.compressionAlgorithm,
        transferConcurrency: policy.transferConcurrency,
      };
    }
    return this._http
      .put<IQGCommunicationPolicy>(updateUrl, updatedPolicy)
      .pipe(
        map((m) => {
          this.policyListChange.next('updated');
          return m;
        }),
        catchError(this._errorHandler)
      );
  }

  activateVersion(policy: IQGCommunicationPolicy): Observable<any> {
    const activateUrl: string = `${this.getBaseUrl()}/config/comm-policies/${
      policy.id
    }/active`;
    return this._http.patch<string>(activateUrl, policy.versionId).pipe(
      map((m) => {
        this.policyListChange.next('updated');
        return m;
      }),
      catchError(this._errorHandler)
    );
  }

  getBaseUrl(): string {
    return this._apiService.getBaseUrl();
  }

  private _toDisplayValues(
    servicePolicies: IQGCommunicationPolicy[]
  ): ICommunicationPolicy[] {
    const policies: ICommunicationPolicy[] = [];
    for (let i = 0; i < servicePolicies.length; i++) {
      const servicePolicy = servicePolicies[i];
      let displayName = servicePolicy.name;
      if (servicePolicy.version !== EQGCommunicationPolicyVersion.ACTIVE) {
        displayName += this._translateService.instant(
          'QUERYGRID.SETTINGS.COMMUNICATION_POLICIES.VERSION.' +
            servicePolicy.version
        );
      }
      const policy: ICommunicationPolicy = Object.assign({}, servicePolicy, {
        displayName: displayName,
        compressionEnabled:
          servicePolicy.compressionAlgorithm !== EQGCompressionAlgorithms.NONE,
      });
      policies.push(policy);
    }
    return policies;
  }

  private _populateHasPendingVersion(
    policies: IQGCommunicationPolicy[]
  ): IQGCommunicationPolicy[] {
    // create mapping of which policies have pending versions
    const pending = <any>{};
    for (let i = 0; i < policies.length; i++) {
      const policy: IQGCommunicationPolicy = policies[i];
      pending[policy.id] =
        pending[policy.id] ||
        policy.version === EQGCommunicationPolicyVersion.PENDING;
    }
    // set the hasPendingVersion property on each policy using the mapping
    for (let i = 0; i < policies.length; i++) {
      const policy: IQGCommunicationPolicy = policies[i];
      policy.hasPendingVersion = pending[policy.id];
    }
    return policies;
  }
}
