<section class="td-empty-state" [class.center-vertically]="centerVertically">
  <div class="base-settings">
    <div class="mat-avatar" *ngIf="icon">
      <ng-container *ngIf="icon.includes(':'); else matIcon">
        <mat-icon matListAvatar [svgIcon]="icon"></mat-icon>
      </ng-container>

      <ng-template #matIcon>
        <mat-icon matListAvatar>{{ icon }}</mat-icon>
      </ng-template>

      <ng-container *ngIf="flag">
        <span class="flag">
          <small>{{ flag }}</small>
        </span>
      </ng-container>
    </div>

    <div class="title mat-title" *ngIf="title">{{ title }}</div>
    <div class="subtitle mat-caption" *ngIf="subtitle || learnMore">
      {{ subtitle }}
      <a
        [href]="learnMore"
        *ngIf="learnMore"
        target="_blank"
        class="learn-more"
        >{{ 'PROVISIONING.LEARN_MORE' | translate }}</a
      >
    </div>
  </div>

  <ng-content></ng-content>
</section>
