<table mat-table [dataSource]="patsList$ | async">
  <ng-container matColumnDef="service">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'AUTH_LIB.ACCESS_TOKEN.SERVICE' | translate }}
    </th>

    <td mat-cell *matCellDef="let data" [class.informational]="!data.service">
      {{ data.service || 'AUTH_LIB.ACCESS_TOKEN.UNKNOWN' | translate }}
    </td>
  </ng-container>

  <ng-container matColumnDef="environment">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'AUTH_LIB.ACCESS_TOKEN.ENVIRONMENT' | translate }}
    </th>

    <td
      mat-cell
      *matCellDef="let data"
      [class.informational]="!data.environment_id"
    >
      {{ data.environment_id || 'AUTH_LIB.ACCESS_TOKEN.UNKNOWN' | translate }}
    </td>
  </ng-container>

  <ng-container matColumnDef="user">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'AUTH_LIB.ACCESS_TOKEN.USER' | translate }}
    </th>

    <td mat-cell *matCellDef="let data" [class.informational]="!data.owner">
      {{ data.owner || 'AUTH_LIB.ACCESS_TOKEN.UNKNOWN' | translate }}
    </td>
  </ng-container>

  <ng-container matColumnDef="organization">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'AUTH_LIB.ACCESS_TOKEN.ORGANIZATION' | translate }}
    </th>

    <td mat-cell *matCellDef="let data" [class.informational]="!data.org_name">
      {{ data.org_name || 'AUTH_LIB.ACCESS_TOKEN.UNKNOWN' | translate }}
    </td>
  </ng-container>

  <ng-container matColumnDef="created">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'AUTH_LIB.ACCESS_TOKEN.CREATED' | translate }}
    </th>
    <td mat-cell *matCellDef="let data">
      {{ data.created | date: 'medium' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="expires">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'AUTH_LIB.ACCESS_TOKEN.EXPIRES' | translate }}
    </th>
    <td mat-cell *matCellDef="let data">
      {{ data.expiration | date: 'medium' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="last_exchanged">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'AUTH_LIB.ACCESS_TOKEN.LAST_EXCHANGED' | translate }}
    </th>
    <td mat-cell *matCellDef="let data">
      {{ data.last_exchanged | date: 'medium' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let row">
      <button
        mat-icon-button
        #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="rowMenu"
        [class.opened]="menuTrigger.menuOpen"
        (click)="$event.stopPropagation()"
        (menuClosed)="updateMenuOpenedState()"
      >
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #rowMenu="matMenu">
        <button mat-menu-item (click)="detailsClicked(row.reference_id)">
          {{ 'AUTH_LIB.ACCESS_TOKEN.LIST.DETAILS' | translate }}
        </button>

        <button mat-menu-item (click)="revokeSpecificTokenClicked(row)">
          {{ 'AUTH_LIB.ACCESS_TOKEN.LIST.REVOKE_TOKEN' | translate }}
        </button>

        <button
          mat-menu-item
          (click)="revokeAllUserTokensClicked(row)"
          *ngIf="isShowRevokeAllUserTokens(row)"
        >
          {{ 'AUTH_LIB.ACCESS_TOKEN.LIST.REVOKE_ALL_USER_TOKENS' | translate }}
        </button>

        <button
          mat-menu-item
          (click)="revokeAllServiceTokensClicked(row)"
          *ngIf="isShowRevokeAllServiceTokens(row)"
        >
          {{
            'AUTH_LIB.ACCESS_TOKEN.LIST.REVOKE_ALL_SERVICE_TOKENS' | translate
          }}
        </button>

        <button
          mat-menu-item
          (click)="revokeAllOrgClicked(row)"
          *ngIf="isShowRevokeAllOrgTokens(row)"
        >
          {{ 'AUTH_LIB.ACCESS_TOKEN.LIST.REVOKE_ALL_ORG_TOKENS' | translate }}
        </button>
      </mat-menu>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    (click)="detailsClicked(row.reference_id)"
  ></tr>
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" [attr.colspan]="displayedColumns.length">
      <td-empty-state
        [title]="'AUTH_LIB.ACCESS_TOKEN.LIST.NO_TOKENS' | translate"
      ></td-empty-state>
    </td>
  </tr>
</table>

<ng-container *ngIf="isLoading$ | async">
  <td-ghost-loading></td-ghost-loading>
</ng-container>
