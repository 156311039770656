import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../common/api.service';

export enum BulkDeleteType {
  'NODE' = 'NODE',
  'ISSUE' = 'ISSUE',
}

export interface IQGQuerySummaryPhaseDetails {
  startTime: string;
  duration: number;
  totalCpu: number;
  node?: {
    name: string;
  };
}

export interface IQGQuerySummaryPhaseDetailsDataTransfer
  extends IQGQuerySummaryPhaseDetails {
  totalNodes: number;
  cpuSkew: number;
  highestCpuNode?: {
    name: string;
  };
  dataSkew: number;
  highestDataNode?: {
    name: string;
  };
  bytesTransferred: number;
  bytesSent?: number;
  bytesReceived?: number;
  compressionRatio?: number;
}

export interface IQGQuerySummaryError {
  hostname: string;
  phase: string;
  timestamp: string;
  message: string;
}

export interface IQGQuerySummaryDetails {
  name?: string;
  system?: { name: string };
  connector?: {
    name: string;
    softwareVersion: string;
  };
  network?: {
    name: string;
  };
  queryText?: string[];
  username?: string;
  hostId?: string;
  requestId?: string;
  sessionId?: string;
  queryId?: string;
  metadataPhase?: IQGQuerySummaryPhaseDetails;
  executionPhase?: IQGQuerySummaryPhaseDetails;
  dataTransferPhase?: IQGQuerySummaryPhaseDetailsDataTransfer;
  errors?: IQGQuerySummaryError[];
}

export interface IQGQuerySummary {
  queryId: string;
  phase: string;
  startTime: string;
  duration: number;
  transferType: string;
  rowsTransferred: number;
  bytesTransferred: number;
  compressionRatio: number;
  transferRate: number;
  bottleneck?: {
    location: string;
    node?: {
      name: string;
    };
  };
  configVersion: string;
  link: {
    name: string;
  };
  fabric: {
    name: string;
    port: number;
    softwareVersion: string;
  };
  commPolicy: {
    name: string;
    compression: boolean;
    encryption: boolean;
  };
  initiator: IQGQuerySummaryDetails;
  target?: IQGQuerySummaryDetails;
  bridges?: [IQGQuerySummaryDetails];
}

export interface IMemoryParameters {
  queryConcurrency: number;
  linkBufferSize: number;
  linkBufferCount: number;
  workersPerNode: number;
}

@Injectable({
  providedIn: 'root',
})
export class OperationsService {
  constructor(private _http: HttpClient, private _apiService: ApiService) {}

  getAllQueryGridQueries(
    completed: boolean,
    dateSince: Date
  ): Observable<IQGQuerySummary[]> {
    let url = `${this.getBaseUrl()}/queries`;
    if (completed) {
      url = `${url}?completed=true&lastModifiedAfter=${dateSince.toISOString()}`;
    }
    return this._http.get<any>(url).pipe(
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      })
    );
  }

  getQueryGridQueryDetails(
    initiatorQueryId: string
  ): Observable<IQGQuerySummary[]> {
    return this._http
      .get<any>(
        `${this.getBaseUrl()}/queries?initiatorQueryId=${initiatorQueryId}`
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  bulkDelete(type: string, ids: string[]): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    const requestBody = {
      configType: type,
      ids: ids,
    };
    return this._http
      .post(`${this.getBaseUrl()}/operations/bulk-delete`, requestBody, {
        headers,
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  calculateSharedMemory(parameters: IMemoryParameters): Observable<any> {
    return this._http
      .post(`${this.getBaseUrl()}/shared-memory-estimator`, parameters)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  downloadQuerySupportBundle(queryId: string): any {
    return this._http
      .get(`${this.getBaseUrl()}/support-archive/query?query=${queryId}`, {
        responseType: 'blob',
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  getBaseUrl(): string {
    return this._apiService.getBaseUrl();
  }
}
