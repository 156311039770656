import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(private _authenticationService: AuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.indexOf('/api') > -1) {
      /*const authenticationToken: string = this._authenticationService.getAccessToken();
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${authenticationToken}`,
        },
      });*/
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          error.status === HttpStatusCode.Unauthorized &&
          !request.headers.has('X-Auth-Credentials') // checking for not basic auth (should only redirect when jwt auth fails)
        ) {
          // Redirect the user to the login screen
          this._authenticationService.goToLogin();
        }
        return throwError(() => error);
      })
    );
  }
}
