import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CovalentDialogsModule } from '@covalent/core/dialogs';
import { CovalentSideSheetModule } from '@covalent/core/side-sheet';
import { CovalentFlavoredMarkdownModule } from '@covalent/flavored-markdown';
import { CovalentHighlightModule } from '@covalent/highlight';
import { CovalentMarkdownModule } from '@covalent/markdown';
import { AccountsModule } from '@janus/accounts';
import {
  getSelectedLanguage,
  initTranslateService,
  startTranslateService,
} from '@janus/translate';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SafeHtmlModule } from '@td/ui/common';

import { AskAiSidesheetComponent } from './ask-ai-sidesheet/ask-ai-sidesheet.component';
import { ChatComponent } from './ask-ai-sidesheet/chat/chat.component';
import { TosDialogComponent } from './ask-ai-sidesheet/tos-dialog/tos-dialog.component';
import { AskAiService } from './ask-ai.service';

@NgModule({
  declarations: [AskAiSidesheetComponent, ChatComponent, TosDialogComponent],
  imports: [
    CovalentSideSheetModule,
    CovalentFlavoredMarkdownModule,
    CovalentMarkdownModule,
    CovalentHighlightModule,
    CovalentDialogsModule,
    ClipboardModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule,
    MatButtonModule,
    MatSnackBarModule,
    CommonModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    SafeHtmlModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatListModule,
    AccountsModule,
    TranslateModule.forChild(),
  ],
  providers: [AskAiService],
})
export class AskAiModule {
  constructor(translateService: TranslateService) {
    initTranslateService(translateService);

    const translateFile: any = require('../assets/i18n/' +
      getSelectedLanguage(translateService) +
      '.json');

    startTranslateService(translateService, translateFile);
  }
}
