import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiService } from '../common/api.service';
import { ILink } from '../common/links.service';
import { IQGConnectorInfo } from './connector.service';
import { BridgeService } from '../settings/bridges/bridge.service';
import {
  IDataSourceType,
  IQGSystemAndConnector,
  IQGSystemDetails,
} from './data-source.interface';

export interface IMemoryEstimate {
  bytes: number;
  gigabytes: number;
}

export interface IQGDataSource {
  system: IQGSystemDetails;
  connector: IQGConnectorInfo;
  initiatorLink: ILink;
  targetLink: ILink;
}

export const KILO_BYTES = 1000;
export const AUTO_SCALE_DEFAULT = 48;

@Injectable({
  providedIn: 'root',
})
export class DataSourceService {
  private dataSourceDetailsChange = new Subject<string>();
  dataSourceDetailsChanges$ = this.dataSourceDetailsChange.asObservable();

  private createDataSourceChange = new Subject<void>();
  createDataSourceChange$ = this.createDataSourceChange.asObservable();

  private connectDataSource = new BehaviorSubject<boolean>(false);
  connectDataSource$ = this.connectDataSource.asObservable();

  private maxMemoryPerNodeChange = new Subject<IMemoryEstimate>();
  maxMemoryPerNodeChange$ = this.maxMemoryPerNodeChange.asObservable();

  private systemChangedBS: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  systemChanged$ = this.systemChangedBS.asObservable();

  private completeConnectDataSourceBS = new BehaviorSubject<
    IQGSystemDetails | undefined
  >(undefined);
  completeConnectDataSource$ = this.completeConnectDataSourceBS.asObservable();

  private antaresDatasourceBS = new BehaviorSubject<
    IQGSystemDetails | undefined
  >(undefined);
  antaresDatasource$ = this.antaresDatasourceBS.asObservable();

  private selectedSystemType: string = '';

  set systemType(systemType: string) {
    this.selectedSystemType = systemType;
  }

  get systemType() {
    return this.selectedSystemType;
  }

  constructor(
    private _http: HttpClient,
    private _apiService: ApiService,
    private _bridgeService: BridgeService
  ) {}

  createSystem(system: IQGSystemDetails): Observable<any> {
    return this._http.post(`${this.getBaseUrl()}/config/systems`, system).pipe(
      map((resp: any) => {
        this.systemChangedBS.next(true);
        return resp;
      }),
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      })
    );
  }

  getSystemDetails(filterBridge: boolean): Observable<IQGSystemDetails[]> {
    return this._http
      .get<any>(`${this.getBaseUrl()}/config/systems?extraInfo=true`)
      .pipe(
        map((response: IQGSystemDetails[]) => {
          const systems: IQGSystemDetails[] = response || [];
          if (filterBridge) {
            return systems.filter((system) => !system.bridgeOnly);
          } else {
            return systems;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  getSingleSystemDetail(id: string): Observable<IQGSystemDetails> {
    return this._http.get<any>(`${this.getBaseUrl()}/config/systems/${id}`);
  }

  getSingleSystemDetailByName(
    systemName: string
  ): Observable<IQGSystemDetails> {
    return this._http.get<any>(
      `${this.getBaseUrl()}/config/systems?filterByName=${systemName}`
    );
  }

  deleteDataSource(id: string): Observable<any> {
    return this._http
      .delete<any>(`${this.getBaseUrl()}/config/systems/${id}`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  updateDatasource(dataSource: IQGSystemDetails): Observable<IQGSystemDetails> {
    return this._http
      .put<IQGSystemDetails>(
        `${this.getBaseUrl()}/config/systems/${dataSource.id}`,
        dataSource
      )
      .pipe(
        tap({ complete: () => this._pushChange(dataSource.id || '') }),
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  createDataSource(
    dataSource: IQGSystemAndConnector
  ): Observable<IQGDataSource> {
    return this._http
      .post<any>(`${this.getBaseUrl()}/operations/datasource`, dataSource)
      .pipe(
        map((response: IQGDataSource) => {
          this.createDataSourceChange.next();
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  updateDataSourceMaxMemoryPerNode(estimate: IMemoryEstimate): void {
    this.maxMemoryPerNodeChange.next(estimate);
  }

  getEstimateFromBytes(estimateInBytes: number): IMemoryEstimate {
    return {
      bytes: estimateInBytes,
      gigabytes: this._bytesToGigabytes(estimateInBytes),
    };
  }

  getEstimateFromGigabytes(estimateInGigabytes: number): IMemoryEstimate {
    return {
      bytes: this._gigabytesToBytes(estimateInGigabytes),
      gigabytes: estimateInGigabytes,
    };
  }

  getBaseUrl(): string {
    return this._apiService.getBaseUrl();
  }

  connectNewDataSource(isConnect: boolean): void {
    this.connectDataSource.next(isConnect);
  }

  completeConnectDataSource(dataSource: IQGSystemDetails): void {
    this.completeConnectDataSourceBS.next(dataSource);
  }

  antaresDataSource(dataSource: IQGSystemDetails): void {
    this.antaresDatasourceBS.next(dataSource);
  }

  newDataSourceFormGroup(): FormGroup {
    return new FormGroup({
      systemType: new FormControl(IDataSourceType.TERADATA),
      name: new FormControl('', [
        Validators.required,
        this._bridgeService.noSpace,
        this._bridgeService.noSpecificChars,
      ]),
      enableOverridePort: new FormControl(''),
      overridePort: new FormControl(''),
      platformType: new FormControl('', [Validators.required]),
      region: new FormControl(null),
      dataCenterId: new FormControl(null),
      description: new FormControl(''),
      maximumMemoryPerNode: new FormControl('', [Validators.required]),
      customerBridgeId: new FormControl(null),
      networkId: new FormControl(''),
      enableProxy: new FormControl(false),
      proxyPort: new FormControl(null),
      proxySupportType: new FormControl(''),
      softwareVersion: new FormControl(undefined),
      proxyNetworkId: new FormControl(null),
      proxySystemId: new FormControl(null),
      proxyService: new FormControl('NO_PROXY'),
      autoNodeDelete: new FormControl(''),
      autoNodeDeleteMinutes: new FormControl(AUTO_SCALE_DEFAULT),
      connectorSoftwareVersion: new FormControl(''),
      userMappingId: new FormControl(''),
      connectorProperties: this.newDataSourceConectorPropertiesFormGroup(),
    });
  }

  editDataSourceFormGroup(): FormGroup {
    return new FormGroup({
      systemType: new FormControl(IDataSourceType.TERADATA),
      name: new FormControl('', [
        Validators.required,
        this._bridgeService.noSpace,
        this._bridgeService.noSpecificChars,
      ]),
      enableOverridePort: new FormControl(''),
      overridePort: new FormControl(''),
      platformType: new FormControl('', [Validators.required]),
      region: new FormControl(null),
      dataCenterId: new FormControl(''),
      softwareVersion: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      maximumMemoryPerNode: new FormControl(''),
      enableProxy: new FormControl(false),
      proxyPort: new FormControl(null),
      proxySupportType: new FormControl(''),
      proxyNetworkId: new FormControl(null),
      proxySystemId: new FormControl(''),
      proxyService: new FormControl(null),
      autoNodeDelete: new FormControl(''),
      autoNodeDeleteMinutes: new FormControl(''),
      connectorProperties: this.newDataSourceConectorPropertiesFormGroup(),
    });
  }

  newDataSourceConectorPropertiesFormGroup(): FormGroup {
    return new FormGroup({
      server: new FormControl(''),
      port: new FormControl(''),
      databaseName: new FormControl(''),
      sparkHome: new FormControl(''),
      sparkExecutionType: new FormControl(''),
      sparkAdditionalJarPaths: new FormControl(''),
      sparkCustomJars: new FormControl(''),
      authUserName: new FormControl(''),
      authPassword: new FormControl(''),
      confFilePaths: new FormControl(''),
      hadoopLibPath: new FormControl(''),
      hadoopProperties: new FormControl(''),
      jdbcUrl: new FormControl(''),
      enableQueryLogging: new FormControl(false),
    });
  }

  newDataSourceAdvancedSettingsFormGroup(): FormGroup {
    return new FormGroup({
      customerBridgeId: new FormControl(null),
      enableProxy: new FormControl(''),
      proxyPort: new FormControl(null),
      proxySupportType: new FormControl(''),
      proxyNetworkId: new FormControl(null),
      proxySystemId: new FormControl(''),
      proxyService: new FormControl('NO_PROXY'),
      autoNodeDelete: new FormControl(''),
      autoNodeDeleteMinutes: new FormControl(''),
      userMappingId: new FormControl(''),
    });
  }

  private _bytesToGigabytes(bytes: number): number {
    return parseFloat(
      (bytes / (KILO_BYTES * KILO_BYTES * KILO_BYTES)).toFixed(3)
    );
  }

  private _gigabytesToBytes(gigabytes: number): number {
    return gigabytes * KILO_BYTES * KILO_BYTES * KILO_BYTES;
  }

  private _pushChange(dataSourceId: string) {
    this.dataSourceDetailsChange.next(dataSourceId);
  }
}
