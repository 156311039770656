import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { getSelectedLanguage } from '@janus/translate';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'eula-dialog',
  templateUrl: './eula-dialog.component.html',
  styleUrls: ['./eula-dialog.component.scss'],
})
export class EulaDialogComponent {
  agreed: boolean;
  markdownUrl: string;

  constructor(
    private _translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: { hideActions: boolean }
  ) {
    this.markdownUrl = `/assets/i18n/navigation/eula.${getSelectedLanguage(
      this._translate
    )}.md`;
  }
}
