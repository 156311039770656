import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  TranslateService,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ProfileModule, ProfileService } from '@janus/profile';

import { CONF } from '../assets/language-pack.conf';
// import { LocalizationService } from './translate.service';

export const TRANSLATE_STORAGE_KEY = 'ngx-translate-lang';
export const DISPLAY_LANGS: any[] = CONF.DISPLAY;
export const SUPPORTED_LANGS: string[] = CONF.SUPPORTED;
export const NON_ENGLISH_LANGS: string[] = CONF.SUPPORTED.filter(
  (lang: string) => lang !== CONF.BASE
);
export type DisplayLanguage = {
  key: string;
  label: string;
};

/**
 * Utility method to add a new set of translation data for a given language without changing the current language selection
 */
export function addTranslation(
  translateService: TranslateService,
  translateLanguage: string,
  translateFile: any
): void {
  // add this to the available language set
  translateService.setTranslation(translateLanguage, translateFile, true);
}

/**
 * Utility method to get the full list of supported languages (including the default)
 */
export function getSupportedLanguages(
  enableNonEnglishLangs: boolean = true
): string[] {
  return enableNonEnglishLangs ? CONF.SUPPORTED : SUPPORTED_LANGS;
}

export interface ITranslatable<T> {
  i18n?: { [key: string]: Partial<T> };
}

export const translateAPIMessages = <T extends object>(
  n: ITranslatable<T>
): T => {
  const deepMerge = <T extends object>(target: T, source: Partial<T>): T => {
    for (const key in source) {
      if (
        source[key] instanceof Object &&
        key in target &&
        target[key] instanceof Object
      ) {
        target[key as keyof T] = deepMerge(
          target[key] as unknown as object,
          source[key] as unknown as object
        ) as unknown as T[keyof T];
      } else {
        target[key as keyof T] = source[key] as T[keyof T];
      }
    }
    return target;
  };

  const lang = Object.keys(n.i18n ?? {})[0];
  return lang ? deepMerge({ ...n } as T, { ...n.i18n?.[lang] }) : (n as T);
};

/**
 * Utility method to get selected language from sessionStorage or browser
 */
export function getSelectedLanguage(
  translateService: TranslateService
): string {
  const storedLanguage: string = JSON.parse(
    localStorage.getItem('profile')
  )?.language;
  // Check if the language has been stored else if the language wasnt stored, then use browser default if supported
  if (storedLanguage && translateService.getLangs().includes(storedLanguage)) {
    return storedLanguage;
  } else if (
    translateService.getLangs().includes(translateService.getBrowserLang())
  ) {
    return translateService.getBrowserLang();
  }
  // If everything fails, then use default lang
  return CONF.BASE;
}

/**
 * Utility method to set the selected language into sessionStorage (and the translate service)
 */
export function setSelectedLanguage(
  lang: string,
  translateService: TranslateService
): void {
  // Make sure the language is actually supported first
  if (
    !getSupportedLanguages(true).includes(lang) ||
    !translateService.getLangs().includes(lang)
  ) {
    // TODO: actually handle this somehow besides a console error
    console.error(
      `Language "${lang}" is not currently supported in VantageCloud Lake`
    );
  } else {
    // make sure the translation service uses the new language
    translateService.use(lang);

    // make sure we load it up
    window.location.reload();
  }
}

export function getSelectedLocale(translateService: TranslateService): string {
  return translateService.getBrowserCultureLang();
}

/**
 * Create custom TranslateLoader since we have a diff dir structure for our json files
 */
export function createTranslateLoader(httpClient: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(httpClient, 'assets/i18n/', '.json');
}

export function initTranslateService(
  translateService: TranslateService,
  enableNonEnglishLangs: boolean = true
): void {
  // set the default language
  translateService.setDefaultLang(CONF.BASE);

  translateService.addLangs(
    enableNonEnglishLangs ? CONF.SUPPORTED : SUPPORTED_LANGS
  );
}

export function startTranslateService(
  translateService: TranslateService,
  translateFile?: any
): void {
  // Get selected language and load it
  const selectedLanguage: string = getSelectedLanguage(translateService);

  // using require here so can avoid making an http request ajax to get the language files
  // this prevents the language keys from flashing on the screen for a second before the actual
  // language files are loaded

  if (translateFile) {
    translateService.setTranslation(selectedLanguage, translateFile, true);
  }
  translateService.use(selectedLanguage);
}

@NgModule({
  declarations: [],
  imports: [CommonModule, ProfileModule, TranslateModule.forChild()],
  // providers: [LocalizationService],
})
export class LocalizationModule {
  constructor(private profile: ProfileService) {
    // if there is not a pre-existing saved profile locally then we need to get one and reload
    if (!localStorage.getItem('profile')) {
      // add a temporary placeholder to prevent infinite loops if the profile call fails
      localStorage.setItem('profile', '{}');
      this.profile.getUserProfile().subscribe(() => {
        window.location.reload();
      });
    }
  }
}
