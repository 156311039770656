/**
 * Teradata 16.50
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const definition: any = {
  defaultToken: '',
  tokenPostfix: '.sql',
  ignoreCase: true,

  brackets: [
    { open: '[', close: ']', token: 'delimiter.square' },
    { open: '(', close: ')', token: 'delimiter.parenthesis' },
  ],

  keywords: [
    // These are the reserved words extracted from the reserved words section of
    // the teradatasql.jjt file in the TD1510 directory.
    'ABORT',
    'ABORTSESSION',
    'ABS',
    'ACCESS_LOCK',
    'ACCOUNT',
    'ACOS',
    'ACOSH',
    'ADD',
    'ADD_MONTHS',
    'ADMIN',
    'AFTER',
    'AGGREGATE',
    'ALL',
    'ALTER',
    'AMP',
    'AND',
    'ANSIDATE',
    'ANY',
    'AS',
    'ASC',
    'ASIN',
    'ASINH',
    'AT',
    'ATAN',
    'ATAN2',
    'ATANH',
    'ATOMIC',
    'AUTHORIZATION',
    'AVE',
    'AVERAGE',
    'AVG',
    'BEFORE',
    'BEGIN',
    'BETWEEN',
    'BIGINT',
    'BINARY',
    'BLOB',
    'BOTH',
    'BT',
    'BUT',
    'BY',
    'BYTE',
    'BYTEINT',
    'BYTES',
    'CALL',
    'CASE',
    'CASE_N',
    'CASESPECIFIC',
    'CAST',
    'CD',
    'CHAR',
    'CHAR_LENGTH',
    'CHAR2HEXINT',
    'CHARACTER',
    'CHARACTER_LENGTH',
    'CHARACTERS',
    'CHARS',
    'CHECK',
    'CHECKPOINT',
    'CLASS',
    'CLOB',
    'CLOSE',
    'CLUSTER',
    'CM',
    'COALESCE',
    'COLLATION',
    'COLLECT',
    'COLUMN',
    'COMMENT',
    'COMMIT',
    'COMPRESS',
    'CONNECT',
    'CONSTRAINT',
    'CONSTRUCTOR',
    'CONSUME',
    'CONTAINS',
    'CONTINUE',
    'CONVERT_TABLE_HEADER',
    'CORR',
    'COS',
    'COSH',
    'COUNT',
    'COVAR_POP',
    'COVAR_SAMP',
    'CREATE',
    'CROSS',
    'CS',
    'CSUM',
    'CT',
    'CTCONTROL',
    'CUBE',
    'CURRENT',
    'CURRENT_DATE',
    'CURRENT_ROLE',
    'CURRENT_TIME',
    'CURRENT_TIMESTAMP',
    'CURRENT_USER',
    'CURSOR',
    'CV',
    'CYCLE',
    'DATABASE',
    'DATABLOCKSIZE',
    'DATE',
    'DATEFORM',
    'DAY',
    'DEALLOCATE',
    'DEC',
    'DECIMAL',
    'DECLARE',
    'DEFAULT',
    'DEFERRED',
    'DEGREES',
    'DEL',
    'DELETE',
    'DESC',
    'DETERMINISTIC',
    'DIAGNOSTIC',
    'DISABLED',
    'DISTINCT',
    'DO',
    'DOMAIN',
    'DOUBLE',
    'DROP',
    'DUAL',
    'DUMP',
    'DYNAMIC',
    'EACH',
    'ECHO',
    'ELSE',
    'ELSEIF',
    'ENABLED',
    'END',
    'EQ',
    'EQUALS',
    'ERROR',
    'ERRORFILES',
    'ERRORTABLES',
    'ESCAPE',
    'ET',
    'EXCEPT',
    'EXEC',
    'EXECUTE',
    'EXISTS',
    'EXIT',
    'EXP',
    'EXPAND',
    'EXPANDING',
    'EXPLAIN',
    'EXTERNAL',
    'EXTRACT',
    'FALLBACK',
    'FASTEXPORT',
    'FETCH',
    'FIRST',
    'FLOAT',
    'FOR',
    'FOREIGN',
    'FORMAT',
    'FOUND',
    'FREESPACE',
    'FROM',
    'FULL',
    'FUNCTION',
    'GE',
    'GENERATED',
    'GET',
    'GIVE',
    'GRANT',
    'GRAPHIC',
    'GROUP',
    'GROUPING',
    'GT',
    'HANDLER',
    'HASH',
    'HASHAMP',
    'HASHBAKAMP',
    'HASHBUCKET',
    'HASHROW',
    'HAVING',
    'HELP',
    'HOUR',
    'IDENTITY',
    'ID2BIGINT',
    'IF',
    'IMMEDIATE',
    'IN',
    'INCONSISTENT',
    'INDEX',
    'INITIATE',
    'INNER',
    'INOUT',
    'INPUT',
    'INS',
    'INSERT',
    'INSTANCE',
    'INSTEAD',
    'INT',
    'INTEGER',
    'INTEGERDATE',
    'INTERSECT',
    'INTERVAL',
    'INTO',
    'IS',
    'ITERATE',
    'JAR',
    'JOIN',
    'JOURNAL',
    'KEY',
    'KURTOSIS',
    'LANGUAGE',
    'LARGE',
    'LE',
    'LEADING',
    'LEAVE',
    'LEFT',
    'LIKE',
    'LIMIT',
    'LN',
    'LOADING',
    'LOCAL',
    'LOCATOR',
    'LOCK',
    'LOCKING',
    'LOG',
    'LOGGING',
    'LOGON',
    'LONG',
    'LOOP',
    'LOWER',
    'LT',
    'MACRO',
    'MAP',
    'MAVG',
    'MAX',
    'MAXIMUM',
    'MCHARACTERS',
    'MDIFF',
    'MERGE',
    'METHOD',
    'MIN',
    'MINDEX',
    'MINIMUM',
    'MINUS',
    'MINUTE',
    'MLINREG',
    'MLOAD',
    'MOD',
    'MODE',
    'MODIFIES',
    'MODIFY',
    'MONITOR',
    'MONRESOURCE',
    'MONSESSION',
    'MONTH',
    'MSUBSTR',
    'MSUM',
    'MULTISET',
    'NAMED',
    'NATURAL',
    'NE',
    'NEW',
    'NEW_TABLE',
    'NEXT',
    'NO',
    'NONE',
    'NONTEMPORAL',
    'NORMALIZE',
    'NOSYNC',
    'NOT',
    'NOWAIT',
    'NULL',
    'NULLIF',
    'NULLIFZERO',
    'NUMBER',
    'NUMERIC',
    'OBJECT',
    'OBJECTS',
    'OCTET_LENGTH',
    'OF',
    'OFF',
    'OLD',
    'OLD_TABLE',
    'ON',
    'ONLY',
    'OPEN',
    'OPTION',
    'OR',
    'ORDER',
    'ORDERING',
    'OUT',
    'OUTER',
    'OVER',
    'OVERLAPS',
    'OVERRIDE',
    'PARAMETER',
    'PASSWORD',
    'PERCENT',
    'PERCENT_RANK',
    'PERM',
    'PERMANENT',
    'PLAN_DIRECTIVE',
    'POSITION',
    'PRECISION',
    'PREPARE',
    'PRESERVE',
    'PRIMARY',
    'PRIVILEGES',
    'PROCEDURE',
    'PROFILE',
    'PROTECTION',
    'PUBLIC',
    'QUALIFIED',
    'QUALIFY',
    'QUANTILE',
    'QUEUE',
    'RADIANS',
    'RANDOM',
    'RANGE_N',
    'RANK',
    'READS',
    'REAL',
    'RECURSIVE',
    'REFERENCES',
    'REFERENCING',
    'REGR_AVGX',
    'REGR_AVGY',
    'REGR_COUNT',
    'REGR_INTERCEPT',
    'REGR_R2',
    'REGR_SLOPE',
    'REGR_SXX',
    'REGR_SXY',
    'REGR_SYY',
    'RELATIVE',
    'RELEASE',
    'RENAME',
    'REPEAT',
    'REPLACE',
    'REPLCONTROL',
    'REPLICATION',
    'REQUEST',
    'RESIGNAL',
    'RESTART',
    'RESTORE',
    'RESULT',
    'RESUME',
    'RET',
    'RETRIEVE',
    'RETURN',
    'RETURNS',
    'REVALIDATE',
    'REVOKE',
    'RIGHT',
    'RIGHTS',
    'ROLE',
    'ROLLBACK',
    'ROLLFORWARD',
    'ROLLUP',
    'ROW',
    'ROW_NUMBER',
    'ROWID',
    'ROWS',
    'SAMPLE',
    'SAMPLEID',
    'SCROLL',
    'SECOND',
    'SEL',
    'SELECT',
    'SESSION',
    'SET',
    'SETRESRATE',
    'SETS',
    'SETSESSRATE',
    'SHOW',
    'SIGNAL',
    'SIN',
    'SINH',
    'SKEW',
    'SMALLINT',
    'SOME',
    'SOUNDEX',
    'SPECIFIC',
    'SPOOL',
    'SQL',
    'SQLEXCEPTION',
    'SQLTEXT',
    'SQLWARNING',
    'SQRT',
    'SS',
    'START',
    'STARTUP',
    'STATEMENT',
    'STATISTICS',
    'STDDEV_POP',
    'STDDEV_SAMP',
    'STEPINFO',
    'STRING_CS',
    'SUBSCRIBER',
    'SUBSTR',
    'SUBSTRING',
    'SUM',
    'SUMMARY',
    'SUSPEND',
    'TABLE',
    'TAN',
    'TANH',
    'TBL_CS',
    'TD_ANYTYPE',
    'TD_AUTHID',
    'TD_HOST',
    'TD_ROWLOADID',
    'TD_VALIST',
    'TEMPORARY',
    'TERMINATE',
    'THEN',
    'THRESHOLD',
    'TIME',
    'TIMESTAMP',
    'TIMEZONE_HOUR',
    'TIMEZONE_MINUTE',
    'TITLE',
    'TO',
    'TOP',
    'TRACE',
    'TRAILING',
    'TRANSACTION',
    'TRANSACTIONTIME',
    'TRANSFORM',
    'TRANSLATE',
    'TRANSLATE_CHK',
    'TRIGGER',
    'TRIM',
    'TYPE',
    'UC',
    'UDTCASTAS',
    'UDTCASTLPAREN',
    'UDTMETHOD',
    'UDTTYPE',
    'UDTUSAGE',
    'UESCAPE',
    'UNDEFINED',
    'UNDO',
    'UNION',
    'UNIQUE',
    'UNTIL',
    'UNTIL_CHANGED',
    'UNTIL_CLOSED',
    'UPD',
    'UPDATE',
    'UPPER',
    'UPPERCASE',
    'USER',
    'USING',
    'VALIDTIME',
    'VALUE',
    'VALUES',
    'VAR_POP',
    'VAR_SAMP',
    'VARBYTE',
    'VARCHAR',
    'VARGRAPHIC',
    'VARIANT_TYPE',
    'VARYING',
    'VIEW',
    'VOLATILE',
    'WHEN',
    'WHERE',
    'WHILE',
    'WIDTH_BUCKET',
    'WITH',
    'WITHOUT',
    'WORK',
    'XMLPLAN',
    'YEAR',
    'ZEROIFNULL',
    'ZONE',
  ],
  unreservedkeywords: [
    // These are the unreserved keywords extracted from the unreserved keywords section of
    // the teradatasql.jjt file in the TD1510 directory. (That is, the many UK_xxx() rule declarations.)
    'ABSENT',
    'ACCESS',
    'ACCORDING',
    'ACCUMULATE',
    'AG',
    'ALLOCATE',
    'ALLOCATION',
    'ALLDBQL',
    'ALLPARAMS',
    'ALLTDWM',
    'ALWAYS',
    'AMPCOUNT',
    'ANALYSIS',
    'ANCHOR',
    'ANCHOR_HOUR',
    'ANCHOR_MILLISECOND',
    'ANCHOR_MINUTE',
    'ANCHOR_SECOND',
    'ANSIQUALIFIER',
    'APPLNAME',
    'ARCHIVE',
    'ARRAY',
    'ASCII',
    'ASSIGNMENT',
    'ATTR',
    'ATTRIBUTE',
    'ATTRIBUTES',
    'ATTRIBUTION',
    'ATTRS',
    'AUTH',
    'AUTO',
    'AUTOTEMP',
    'AVRO',
    'BIT_LENGTH',
    'BLOCKCOMPRESSION',
    'BLOCKCOMPRESSIONALGORITHM',
    'BLOCKCOMPRESSIONLEVEL',
    'BOM',
    'BOTTOM',
    'BSON',
    'C',
    'CALENDAR',
    'CAPTURE',
    'CALLED',
    'CALLER',
    'CAL_DAYS',
    'CAL_MONTHS',
    'CAL_YEARS',
    'CDAY',
    'CDAYS',
    'CDISTINCT',
    'CHANGERATE',
    'CHARACTERISTICS',
    'CHARSET',
    'CHECKING',
    'CHARSET_COLL',
    'CHECKSUM',
    'CLASS_ORIGIN',
    'CLICKLAG',
    'CLIENT',
    'CMONTH',
    'CMONTHS',
    'COLOCATE',
    'COMMITTED',
    'CONCAT',
    'COPY',
    'CNT',
    'COLUMNMETA',
    'COLUMNS',
    'COLUMNSPERINDEX',
    'COLUMNSPERJOININDEX',
    'COMMAND_FUNCTION',
    'COMMAND_FUNCTION_CODE',
    'COMPARABLE',
    'COMPARISON',
    'COMPILE',
    'CONCURRENT',
    'CONDITION',
    'CONDITION_IDENTIFIER',
    'CONDITION_NUMBER',
    'CONTAINED',
    'CONTENT',
    'CONTIGUOUS',
    'COST',
    'COSTS',
    'CPP',
    'CPUTIME',
    'CPUTIMENORM',
    'CREATEDATASET',
    'CREATOR',
    'CUME_DIST',
    'CURDATE',
    'CURTIME',
    'CYEARS',
    'CYUK_CYEAR',
    'D',
    'DAYS',
    'DATA',
    'DATASET',
    'DBA',
    'DBC',
    'DEBUG',
    'DECOMPRESS',
    'DEFINER',
    'DEFINITION',
    'DELETED',
    'DELIMITER',
    'DELTA_T',
    'DEMOGRAPHICS',
    'DENIALS',
    'DENSE',
    'DENSE_RANK',
    'DESCRIBE',
    'DETAILED',
    'DETAILDIAG',
    'DIAGNOSTICS',
    'DIGITS',
    'DIMENSION',
    'DOCUMENT',
    'DOT',
    'DOWN',
    'DR',
    'DSA',
    'DUPCOUNT',
    'DUPCOUNTCUM',
    'EBCDIC',
    'ELAPSEDSEC',
    'ELAPSEDTIME',
    'ELEMENT',
    'ELZS_H',
    'EMITNULL',
    'EMPTY',
    'ENCODING',
    'ENCRYPT',
    'ERRORS',
    'ERRORTBL',
    'EVENT_COLUMN_NAME',
    'EXCL',
    'EXCLUDE',
    'EXCLUDING',
    'EXCEPTION',
    'EXCLUSIVE',
    'EXPIRE',
    'EXPORT',
    'EXPORTWIDTH',
    'FALSE',
    'FEATUREINFO',
    'FILE',
    'FILL',
    'FILTER',
    'FINAL',
    'FIRST_NOTNULL',
    'FIRST_VALUE',
    'FLUSH',
    'FOLLOWING',
    'FOREIGNFUNCTION',
    'FRIDAY',
    'FUNCTIONPARAMETER',
    'G',
    'GLOBAL',
    'GLOP',
    'H',
    'HISTORY',
    'HIGH',
    'HOST',
    'HOURS',
    'HR',
    'HRS',
    'IFP',
    'IGNORE',
    'IMMEDIATELY',
    'IMPORT',
    'INCLUDE',
    'INCLUDING',
    'INCREMENT',
    'INDENT',
    'INDEX_ANALYSIS',
    'INDEXESPERTABLE',
    'INDEXMAINTMODE',
    'INIT',
    'INLINE',
    'ISOLATED',
    'INSTANTIABLE',
    'INTERFACE',
    'INTERNAL',
    'INVOKER',
    'IOCOUNT',
    'ISOLATION',
    'JSON',
    'JSONGETVALUE',
    'JSON_AGG',
    'JSON_COMPOSE',
    'JAVA',
    'JIS_COLL',
    'K',
    'KANJI1',
    'KANJISJIS',
    'KBYTE',
    'KBYTES',
    'KEEP',
    'KILOBYTES',
    'LAG',
    'LAST',
    'LAST_NOTNULL',
    'LAST_VALUE',
    'LATEST',
    'LATIN',
    'LDIFF',
    'LEAD',
    'LENGTH',
    'LEVEL',
    'LIST',
    'LOAD',
    'LOCATE',
    'LOCKEDUSEREXPIRE',
    'LOGICALROW',
    'LOW',
    'M',
    'MAD',
    'MANUAL',
    'MAPPING',
    'MAPS',
    'MATCHED',
    'MAXCHAR',
    'MAXINTERVALS',
    'MAXLOGONATTEMPTS',
    'MAXVALUE',
    'MAXVALUELENGTH',
    'MAX_CHOOSE',
    'MEDIAN',
    'MEDIUM',
    'MEETS',
    'MEMBER',
    'MERGEBLOCKRATIO',
    'MESSAGE_LENGTH',
    'MESSAGE_TEXT',
    'MICROSECOND',
    'MICROSECONDS',
    'MILLISECOND',
    'MILLISECONDS',
    'MINCHAR',
    'MINS',
    'MINVALUE',
    'MINUTES',
    'MIN_CHOOSE',
    'MODIFIED',
    'MONDAY',
    'MONTH_BEGIN',
    'MONTH_END',
    'MORE',
    'MS',
    'MSEC',
    'MSECS',
    'MULTINATIONAL',
    'MULTIPLE',
    'NAME',
    'NAMESPACE',
    'NEVER',
    'NIL',
    'NODDLTEXT',
    'NODE',
    'NONOPTCOST',
    'NONOPTINIT',
    'NONSEQUENCED',
    'NORIGHT',
    'NOTATION',
    'NOW',
    'NPATH',
    'NTH',
    'NULLS',
    'NUMBER',
    'OA',
    'OLD_NEW_TABLE',
    'ONLINE',
    'OPERATOR',
    'OPTIONS',
    'ORDERED_ANALYTIC',
    'ORDINALITY',
    'OVERLAYS',
    'OWNER',
    'P_INTERSECT',
    'P_NORMALIZE',
    'PARAMID',
    'PARAMINFO',
    'PARENT',
    'PARTITION',
    'PARTITION#L1',
    'PARTITION#L2',
    'PARTITION#L3',
    'PARTITION#L4',
    'PARTITION#L5',
    'PARTITION#L6',
    'PARTITION#L7',
    'PARTITION#L8',
    'PARTITION#L9',
    'PARTITION#L10',
    'PARTITION#L11',
    'PARTITION#L12',
    'PARTITION#L13',
    'PARTITION#L14',
    'PARTITION#L15',
    'PARTITIONED',
    'PARTITIONNAMES',
    'PASS',
    'PASSING',
    'PATH',
    'PATH_GENERATOR',
    'PATH_START',
    'PATH_SUMMARIZER',
    'PATTERN',
    'PERCENTILE',
    'PERCENTILE_CONT',
    'PERCENTILE_DISC',
    'PERIOD',
    'PIVOT',
    'PRECEDES',
    'PRECEDING',
    'PREFIX',
    'PREV',
    'PREVIOUS',
    'PORTION',
    'PRINT',
    'PRIOR',
    'PROTECTED',
    'QUARTER_BEGIN',
    'QUARTER_END',
    'QUERY',
    'QUERY_BAND',
    'QUOTECHAR',
    'RANDOMIZED',
    'RANGE',
    'RANGE#L1',
    'RANGE#L2',
    'RANGE#L3',
    'RANGE#L4',
    'RANGE#L5',
    'RANGE#L6',
    'RANGE#L7',
    'RANGE#L8',
    'RANGE#L9',
    'RANGE#L10',
    'RANGE#L11',
    'RANGE#L12',
    'RANGE#L13',
    'RANGE#L14',
    'RANGE#L15',
    'RANGE#L16',
    'RANGE#L17',
    'RANGE#L18',
    'RANGE#L19',
    'RANGE#L20',
    'RANGE#L21',
    'RANGE#L22',
    'RANGE#L23',
    'RANGE#L24',
    'RANGE#L25',
    'RANGE#L26',
    'RANGE#L27',
    'RANGE#L28',
    'RANGE#L29',
    'RANGE#L30',
    'RANGE#L31',
    'RANGE#L32',
    'RANGE#L33',
    'RANGE#L34',
    'RANGE#L35',
    'RANGE#L36',
    'RANGE#L37',
    'RANGE#L38',
    'RANGE#L39',
    'RANGE#L40',
    'RANGE#L41',
    'RANGE#L42',
    'RANGE#L43',
    'RANGE#L44',
    'RANGE#L45',
    'RANGE#L46',
    'RANGE#L47',
    'RANGE#L48',
    'RANGE#L49',
    'RANGE#L50',
    'RANGE#L51',
    'RANGE#L52',
    'RANGE#L53',
    'RANGE#L54',
    'RANGE#L55',
    'RANGE#L56',
    'RANGE#L57',
    'RANGE#L58',
    'RANGE#L59',
    'RANGE#L60',
    'RANGE#L61',
    'RANGE#L62',
    'RDIFF',
    'READ',
    'RECALC',
    'REPLACEMENT',
    'RESET',
    'RESPECT',
    'RESTRICTWORDS',
    'RETAIN',
    'RETURNED_SQLSTATE',
    'RETURNING',
    'REUSE',
    'RLS',
    'ROOT',
    'ROW_COUNT',
    'RU',
    'RULES',
    'RULESET',
    'S',
    'SEC',
    'SECONDS',
    'SECS',
    'SAMPLES',
    'SATURDAY',
    'SAS',
    'SCHEMA',
    'SCRIPT',
    'SCRIPT_COMMAND',
    'SEARCHSPACE',
    'SEARCHUIFDBPATH',
    'SECURITY',
    'SEED',
    'SELF',
    'SEQ',
    'SEQUENCE',
    'SEQUENCED',
    'SERIALIZABLE',
    'SERVER',
    'SESSIONIZE',
    'SHARE',
    'SIGNON',
    'SINGLE',
    'SIZE',
    'SNAPPY_COMPRESS',
    'SNAPPY_DECOMPRESS',
    'SOURCE',
    'SPARSE',
    'SPECCHAR',
    'SPL',
    'SQLDATA',
    'SQLSTATE',
    'SQLTABLE',
    'SR',
    'STAT',
    'STATIC',
    'STATS',
    'STATSUSAGE',
    'STORAGE',
    'STRIP',
    'STYLE',
    'ST_GEOMETRY',
    'SUCCEEDS',
    'SUBCLASS_ORIGIN',
    'SUMMARYONLY',
    'SUNDAY',
    'SYMBOLS',
    'SYSTEM',
    'SYSTEMTEST',
    'SYSTEM_TIME',
    'SYSUDTLIB',
    'TABLES',
    'TARGET',
    'STYLE',
    'TD_DATASET',
    'TD_GENERAL',
    'TD_INTERNAL',
    'TD_INTERNAL_SQLTABLE',
    'TD_INTERNAL_SQLTABLE2',
    'TD_SYSFNLIB',
    'TD_TIME_BUCKET_NUMBER',
    'TD_TS_OPTION',
    'TDWMEVENT',
    'TDWMEXCEPTION',
    'TDWMHISTORY',
    'TEMPORAL_DATE',
    'TEMPORAL_TIMESTAMP',
    'TEXT',
    'THREADSAFE',
    'THROUGH',
    'THURSDAY',
    'TIES',
    'TIMECODE',
    'TIMECOLUMN',
    'TIMEDATEWZCONTROL',
    'TIMEOUT',
    'TIMESTAMP_COLUMN_NAME',
    'TPA',
    'TRANSACTION_ACTIVE',
    'TRUE',
    'TRUSTED',
    'TRUST_ONLY',
    'TRYCAST',
    'TS_TOP',
    'TTGRANULARITY',
    'TUESDAY',
    'UBJSON',
    'UNBOUNDED',
    'UNCOMMITTED',
    'UNICODE',
    'UNKNOWN',
    'UNPIVOT',
    'UCASE',
    'US',
    'USE',
    'USEC',
    'USECS',
    'USECOUNT',
    'UTILITYINFO',
    'VARRAY',
    'VERBOSE',
    'VERSION',
    'VERSIONING',
    'W',
    'WARNING',
    'WEDNESDAY',
    'WEEK',
    'WEEKS',
    'WEEK_BEGIN',
    'WEEK_END',
    'WHITESPACE',
    'WINDOW',
    'WITHIN',
    'WORKLOAD',
    'WRITE',
    'XML',
    'XMLAGG',
    'XMLATTRIBUTES',
    'XMLCOMMENT',
    'XMLCONCAT',
    'XMLDECLARATION',
    'XMLDOCUMENT',
    'XMLELEMENT',
    'XMLFOREST',
    'XMLNAMESPACES',
    'XMLPARSE',
    'XMLPI',
    'XMLQUERY',
    'XMLSCHEMA',
    'XMLSERIALIZE',
    'XMLTABLE',
    'XMLTEXT',
    'XMLTYPE',
    'XMLVALIDATE',
    'XSLT_XML2SQL',
    'YEAR_BEGIN',
    'YEAR_END',
    'ZLIB',
  ],
  operatorsSeparators: [
    // These are the operators and separators extracted from the operators and separators section of
    // the teradatasql.jjt file in the TD1510 directory.
    // Many of these (comma, parentheses, etc. are overridden by the basic definitions of these
    // tokens.)
    '||',
    ',',
    ';',
    ':',
    '.',
    '<',
    '<=',
    '>',
    '>=',
    '=',
    '<>',
    '(',
    ')',
    '[',
    ']',
    '**',
    '*',
    '/',
    '+',
    '-',
    '?',
    '@',
    '^',
  ],
  builtinFunctions: [
    // These function names were retrieved from a 15.10 system by running the query:
    //  select unique functionname from dbc.FunctionsV where databasename = 'TD_SYSFNLIB' order by 1;
    // Many of the resulting function names duplicate names defined above as unreservedkeywords. Those
    // words are colored as keywords.
    'AGGGEOM',
    'AGGGEOMINTERSECTION',
    'AGGGEOMUNION',
    'ARRAY_ADD',
    'ARRAY_AGG',
    'ARRAY_AVG',
    'ARRAY_COMPARE',
    'ARRAY_CONCAT',
    'ARRAY_COUNT_DISTINCT',
    'ARRAY_DIV',
    'ARRAY_EQ',
    'ARRAY_GE',
    'ARRAY_GET',
    'ARRAY_GT',
    'ARRAY_LE',
    'ARRAY_LT',
    'ARRAY_MAX',
    'ARRAY_MIN',
    'ARRAY_MOD',
    'ARRAY_MUL',
    'ARRAY_NE',
    'ARRAY_SUB',
    'ARRAY_SUM',
    'ARRAY_TO_JSON',
    'ARRAY_UPDATE',
    'ARRAY_UPDATE_STRIDE',
    'ASCII',
    'AS_SHREDTB',
    'AS_SHRED_GENERATE_SQL',
    'AS_SHRED_GETTABLES',
    'AVROCONTAINERSPLIT',
    'AVRO_CHECK',
    'BITAND',
    'BITNOT',
    'BITOR',
    'BITXOR',
    'BSON_CHECK',
    'CALCMATRIX',
    'CALCMATRIX_CONTRACT',
    'CAMSET',
    'CAMSET_L',
    'CARDINALITY',
    'CEIL',
    'CEILING',
    'CHR',
    'COUNTSET',
    'CREATEDATASET',
    'CREATEXML',
    'CSV',
    'CSVLD',
    'CSV_TO_AVRO',
    'CSV_TO_JSON',
    'DATASET_KEYS',
    'DATASET_PUBLISH',
    'DATASET_TABLE',
    'DATASIZE',
    'DAYNUMBER_OF_CALENDAR',
    'DAYNUMBER_OF_MONTH',
    'DAYNUMBER_OF_WEEK',
    'DAYNUMBER_OF_YEAR',
    'DAYOCCURRENCE_OF_MONTH',
    'DBQLDECODEOBJ',
    'DECAMSET',
    'DECAMSET_L',
    'DECODE',
    'EDITDISTANCE',
    'EMPTY_BLOB',
    'EMPTY_CLOB',
    'FLOOR',
    'FROM_BYTES',
    'FROM_MGRS',
    'FSYSSHOWBLOCKS',
    'FSYSSHOWBLOCKS_CONTRACT',
    'FSYSSHOWINNER',
    'FSYSSHOWWHERE',
    'FSYSSHOWWHERE_CONTRACT',
    'GEOJSONFROMGEOM',
    'GEOMETRYTOROWS',
    'GEOMFROMGEOJSON',
    'GEOSEQUENCEFROMROWS',
    'GEOSEQUENCETOROWS',
    'GETBIT',
    'GETCURRENTPXYROLES',
    'GETPSFVERSION',
    'GETQUERYBANDVALUESF',
    'GREATEST',
    'INITCAP',
    'INSTR',
    'JSONGETVALUE',
    'JSONMETADATA',
    'JSON_AGG',
    'JSON_CHECK',
    'JSON_COMPOSE',
    'JSON_COMPRESS',
    'JSON_DECOMPRESS',
    'JSON_KEYS',
    'JSON_PUBLISH',
    'JSON_SHRED_GENSQLS',
    'JSON_SHRED_GETTABLES',
    'JSON_TABLE',
    'LAST_DAY',
    'LEAST',
    'LENGTH',
    'LPAD',
    'LTRIM',
    'LZCOMP',
    'LZCOMP_L',
    'LZDECOMP',
    'LZDECOMP_L',
    'MONTHNUMBER_OF_CALENDAR',
    'MONTHNUMBER_OF_QUARTER',
    'MONTHNUMBER_OF_YEAR',
    'MONTHS_BETWEEN',
    'NEXT_DAY',
    'NGRAM',
    'NUMFPFNS',
    'NUMTODSINTERVAL',
    'NUMTOYMINTERVAL',
    'NVL',
    'NVL2',
    'NVP',
    'NVP2JSON',
    'OADD_MONTHS',
    'OCOUNT',
    'ODELETE',
    'OEXISTS',
    'OEXTEND',
    'OFIRST',
    'OLAST',
    'OLIMIT',
    'ONEXT',
    'OPRIOR',
    'OREPLACE',
    'OTRANSLATE',
    'OTRIM',
    'POLYGONSPLIT',
    'QBRESERVEDNAMEVALUES',
    'QGEXECUTEFOREIGNQUERY',
    'QGEXECUTEFOREIGNQUERYCONTRACT',
    'QGINITIATOREXPORT',
    'QGINITIATOREXPORTCONTRACT',
    'QGINITIATORIMPORT',
    'QGINITIATORIMPORTCONTRACT',
    'QGREMOTEEXPORT',
    'QGREMOTEEXPORTCONTRACT',
    'QGREMOTEIMPORT',
    'QGREMOTEIMPORTCONTRACT',
    'QUARTERNUMBER_OF_CALENDAR',
    'QUARTERNUMBER_OF_YEAR',
    'REGEXP_INSTR',
    'REGEXP_REPLACE',
    'REGEXP_SIMILAR',
    'REGEXP_SPLIT_TO_TABLE',
    'REGEXP_SUBSTR',
    'REGEXP_SUBSTR_GPL',
    'REVERSE',
    'ROTATELEFT',
    'ROTATERIGHT',
    'ROUND',
    'RPAD',
    'RTRIM',
    'SCHEMAEQUAL',
    'SCHEMAMATCH',
    'SCRIPT',
    'SETBIT',
    'SHIFTLEFT',
    'SHIFTRIGHT',
    'SIGN',
    'SNAPPY_COMPRESS',
    'SNAPPY_DECOMPRESS',
    'STRTOK',
    'STRTOK_SPLIT_TO_TABLE',
    'SUBBITSTR',
    'TDAMPCOPY',
    'TDAMPCOPY_CONTRACT',
    'TD_ARRAY2P',
    'TD_AWTDPSCACHE',
    'TD_AWTDPSCACHEDUMP',
    'TD_AWTDPSCACHEHASH',
    'TD_DBQLFUL',
    'TD_DBQLPARAM',
    'TD_FILERROWS',
    'TD_FRIDAY',
    'TD_GETTIMEBUCKET',
    'TD_GET_COD_LIMITS',
    'TD_LEFT',
    'TD_LZ_COMPRESS',
    'TD_LZ_DECOMPRESS',
    'TD_MONDAY',
    'TD_MONTH_BEGIN',
    'TD_MONTH_END',
    'TD_NORMALIZE_MEET',
    'TD_NORMALIZE_OVERLAP',
    'TD_NORMALIZE_OVERLAP_MEET',
    'TD_QUARTER_BEGIN',
    'TD_QUARTER_END',
    'TD_RIGHT',
    'TD_SATURDAY',
    'TD_SEQUENCED_AVG',
    'TD_SEQUENCED_COUNT',
    'TD_SEQUENCED_SUM',
    'TD_SPATIALDISTANCEKEY',
    'TD_SPATIALINDEXKEY',
    'TD_SPATIALMBBKEY',
    'TD_SUM_NORMALIZE_MEET',
    'TD_SUM_NORMALIZE_OVERLAP',
    'TD_SUM_NORMALIZE_OVERLAP_MEET',
    'TD_SUNDAY',
    'TD_THURSDAY',
    'TD_TUESDAY',
    'TD_TUNABLE',
    'TD_UNPIVOT',
    'TD_UNPIVOT_CONTRACT',
    'TD_WEDNESDAY',
    'TD_WEEK_BEGIN',
    'TD_WEEK_END',
    'TD_YEAR_BEGIN',
    'TD_YEAR_END',
    'TESSELLATE',
    'TESSELLATE_SEARCH',
    'TO_BYTE',
    'TO_BYTES',
    'TO_CHAR',
    'TO_DATE',
    'TO_DSINTERVAL',
    'TO_MGRS',
    'TO_NUMBER',
    'TO_TIMESTAMP',
    'TO_TIMESTAMP_TZ',
    'TO_YMINTERVAL',
    'TRANSUNICODETOUTF8',
    'TRANSUTF8TOUNICODE',
    'TRUNC',
    'TRYCAST',
    'TS_COMPRESS',
    'TS_DECOMPRESS',
    'UNNEST',
    'WEEKNUMBER_OF_CALENDAR',
    'WEEKNUMBER_OF_MONTH',
    'WEEKNUMBER_OF_QUARTER',
    'WEEKNUMBER_OF_YEAR',
    'XMLAGG',
    'XMLCLIENTFMTTXT',
    'XMLCOMMENT',
    'XMLCONCAT',
    'XMLDOCUMENT',
    'XMLELEMENT',
    'XMLFOREST',
    'XMLNORMALIZE',
    'XMLPADKEY',
    'XMLPARSE',
    'XMLPI',
    'XMLPUBLISHTABLE',
    'XMLPUBLISH_GENSQL',
    'XMLPUBLISH_GEN_CANONICAL_SQL',
    'XMLQUERY',
    'XMLSERIALIZE',
    'XMLSPLIT',
    'XMLTABLE',
    'XMLTEXT',
    'XMLTRANSFORM',
    'XMLVALIDATE',
    'XSLT_SHREDTB',
    'XSLT_SHRED_GENCANONICAL_SQL',
    'XSLT_SHRED_GENERATE_SQL',
    'XSLT_SHRED_GETTABLES',
    'XSLT_XML2SQL',
    'YEARNUMBER_OF_CALENDAR',
  ],
  builtinVariables: [
    // not applicable
  ],
  pseudoColumns: [
    // not applicable
  ],
  tokenizer: {
    root: [
      { include: '@comments' },
      { include: '@whitespace' },
      { include: '@pseudoColumns' },
      { include: '@numbers' },
      { include: '@strings' },
      { include: '@complexIdentifiers' },
      { include: '@scopes' },
      [/[;,.]/, 'delimiter'],
      [/[()]/, '@brackets'],
      [
        /[\w@#$]+/,
        {
          cases: {
            '@keywords': 'keyword',
            '@unreservedkeywords': 'keyword',
            '@operatorsSeparators': 'operator',
            '@builtinVariables': 'predefined',
            '@builtinFunctions': 'predefined',
            '@default': 'identifier',
          },
        },
      ],
      [/[<>=!%&+\-*/|~^]/, 'operator'],
    ],
    whitespace: [[/\s+/, 'white']],
    comments: [
      [/--+.*/, 'comment'],
      [/\/\*/, { token: 'comment.quote', next: '@comment' }],
    ],
    comment: [
      [/[^*/]+/, 'comment'],
      // Not supporting nested comments, as nested comments seem to not be standard?
      // i.e. http://stackoverflow.com/questions/728172/are-there-multiline-comment-delimiters-in-sql-that-are-vendor-agnostic
      // [/\/\*/, { token: 'comment.quote', next: '@push' }],    // nested comment not allowed :-(
      [/\*\//, { token: 'comment.quote', next: '@pop' }],
      [/./, 'comment'],
    ],
    pseudoColumns: [
      [
        /[$][A-Za-z_][\w@#$]*/,
        {
          cases: {
            '@pseudoColumns': 'predefined',
            '@default': 'identifier',
          },
        },
      ],
    ],
    numbers: [
      [/0[xX][0-9a-fA-F]*/, 'number'],
      [/[$][+-]?(\d*\.)?\d+/, 'number'],
      // eslint-disable-next-line no-useless-escape
      [/(\d*\.)?\d+([eE][+-]?\d+)?/, 'number'],
    ],
    strings: [
      [/N'/, { token: 'string', next: '@string' }],
      [/'/, { token: 'string', next: '@string' }],
    ],
    string: [
      [/[^']+/, 'string'],
      [/''/, 'string'],
      [/'/, { token: 'string', next: '@pop' }],
    ],
    complexIdentifiers: [
      [/\[/, { token: 'identifier.quote', next: '@bracketedIdentifier' }],
      [/"/, { token: 'identifier.quote', next: '@quotedIdentifier' }],
    ],
    bracketedIdentifier: [
      [/[^\]]+/, 'identifier'],
      [/]]/, 'identifier'],
      [/]/, { token: 'identifier.quote', next: '@pop' }],
    ],
    quotedIdentifier: [
      [/[^"]+/, 'identifier'],
      [/""/, 'identifier'],
      [/"/, { token: 'identifier.quote', next: '@pop' }],
    ],
    scopes: [
      [/BEGIN\s+(DISTRIBUTED\s+)?TRAN(SACTION)?\b/i, 'keyword'],
      [/BEGIN\s+TRY\b/i, { token: 'keyword.try' }],
      [/END\s+TRY\b/i, { token: 'keyword.try' }],
      [/BEGIN\s+CATCH\b/i, { token: 'keyword.catch' }],
      [/END\s+CATCH\b/i, { token: 'keyword.catch' }],
      [/(BEGIN|CASE)\b/i, { token: 'keyword.block' }],
      [/END\b/i, { token: 'keyword.block' }],
      [/WHEN\b/i, { token: 'keyword.choice' }],
      [/THEN\b/i, { token: 'keyword.choice' }],
    ],
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const definition16_50: any = {
  def: {
    id: 'td16_50',
    extensions: ['.sql'],
  },
  tokensProvider: definition,
  configuration: {
    comments: {
      lineComment: '--',
      blockComment: ['/*', '*/'],
    },
    brackets: [
      ['[', ']'],
      ['(', ')'],
    ],
    autoClosingPairs: [
      { open: '"', close: '"', notIn: ['string', 'comment'] },
      { open: "'", close: "'", notIn: ['string', 'comment'] },
      { open: '[', close: ']', notIn: ['string', 'comment'] },
      { open: '(', close: ')', notIn: ['string', 'comment'] },
    ],
  },
};
