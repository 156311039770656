import { Injectable, Provider, SkipSelf, Optional } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';

import {
  TdDialogService,
  IAlertConfig,
  TdAlertDialogComponent,
} from '@covalent/core/dialogs';

import { TranslateService } from '@ngx-translate/core';

export interface VantageError {
  message: string;
  error: number;
}

export function getRandomNum(min: number, max: number): number {
  return ((Math.random() * (max - min + 1)) | 0) + min;
}

@Injectable()
export class VantageErrorService {
  constructor(
    private _dialogService: TdDialogService,
    private _translate: TranslateService
  ) {}

  open(error: VantageError): MatDialogRef<TdAlertDialogComponent> | undefined {
    if (error) {
      let dialogId;
      if (!error.error) {
        dialogId = getRandomNum(1, 99999);
      }
      const config: IAlertConfig = {
        id:
          'vui-error-dialog' +
          (error.error !== undefined ? `-${error.error.toString()}` : dialogId),
        panelClass: 'vui-error-dialog',
        title: this._translate.instant('THERE_WAS_A_PROBLEM'),
        message: error.message,
        disableClose: true,
        closeButton: this._translate.instant('CLOSE'),
      };
      if (error.error) {
        config.message += ` (${error.error.toString()})`;
      }
      if (config.message || config.title) {
        return this._dialogService.openAlert(config);
      }
    }

    return undefined;
  }
}

export function VANTAGE_ERROR_PROVIDER_FACTORY(
  parent: VantageErrorService,
  dialogService: TdDialogService,
  translate: TranslateService
): VantageErrorService {
  return parent || new VantageErrorService(dialogService, translate);
}

export const VANTAGE_ERROR_PROVIDER: Provider = {
  // If there is already a service available, use that. Otherwise, provide a new one.
  provide: VantageErrorService,
  deps: [
    [new Optional(), new SkipSelf(), VantageErrorService],
    TdDialogService,
    TranslateService,
  ],
  useFactory: VANTAGE_ERROR_PROVIDER_FACTORY,
};
