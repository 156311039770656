import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { EDiagnosticCheckStatus, IDiagnostic } from './diagnostic.service';
import { ApiService } from '../common/api.service';

export interface IQGConnectorInfo {
  id: string;
  name: string;
  description?: string;
  disabled: boolean;
  version: EQGConnectorInfoVersion;
  versionId: string;
  lastModified?: string;
  softwareName: string;
  softwareVersion: string;
  fabricId: string;
  systemId: string;
  driverNodes?: string[];
  properties: Record<string, any>;
  overridablePropertyNames: string[] | null;
  initiatorPropertyNames?: string[] | null;
  confidentialPropertyNames?: string[] | null;
  allowedOSUsers: string[];
  installMethod?: EQGConnectorInstallMethod;
  driverLimitCount?: number;
  driverLimitEnabled?: boolean;
  _extraInfo?: {
    nodesOnline: number;
    nodesOffline: number;
    driversOnline: number;
    driversOffline: number;
  };
  isActive?(): boolean;
  isPending?(): boolean;
  isPrevious?(): boolean;
  enabled?: boolean;
  hasPendingVersion?: boolean;
  isDriverNodesStatus?: boolean;
}

export interface IQGConnectorInstallation {
  state: IDiagnostic;
  startTime: Date;
  connector: IQGConnectorInfo;
}

export type EQGConnectorInfoVersion = 'ACTIVE' | 'PENDING' | 'PREVIOUS';
export enum EQGConnectorInstallMethod {
  'NONE' = 'NONE',
  'ONE_DRIVER' = 'ONE_DRIVER',
  'ALL_NODES' = 'ALL_NODES',
}

@Injectable({
  providedIn: 'root',
})
export class ConnectorService {
  private connectorListChange = new Subject<string>();
  connectorListChanges$ = this.connectorListChange.asObservable();

  connectorInstallations: IQGConnectorInstallation[] = [];

  constructor(private _http: HttpClient, private _apiService: ApiService) {}

  private _errorHandler = (error: HttpErrorResponse) => {
    throw Object.assign({}, error.error, { httpStatus: error.status });
  };

  getActiveConnector(id: string): Observable<IQGConnectorInfo> {
    return this._http.get<any>(
      `${this.getBaseUrl()}/config/connectors/${id}/active`
    );
  }

  getPendingConnector(id: string): Observable<IQGConnectorInfo> {
    return this._http.get<any>(
      `${this.getBaseUrl()}/config/connectors/${id}/pending`
    );
  }

  getConnectorsBySystemId(systemId: string): Observable<IQGConnectorInfo[]> {
    return this.getConnectors('SYSTEM', systemId);
  }

  getConnector(id: string): Observable<any> {
    return this._http.get<any>(`${this.getBaseUrl()}/config/connectors/${id}`);
  }

  getConnectors(
    filterBy?: string,
    filterById?: string
  ): Observable<IQGConnectorInfo[]> {
    let baseUrl: string = `${this.getBaseUrl()}/config/connectors?flatten=true&extraInfo=true`;
    if (filterBy) {
      switch (filterBy) {
        case 'SYSTEM':
          baseUrl = baseUrl + '&filterBySystemId=' + filterById;
          break;
        case 'FABRIC':
          baseUrl = baseUrl + '&filterByFabricId=' + filterById;
          break;
      }
    }
    return this._http.get<any>(baseUrl).pipe(
      map(this._populateVersion),
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      })
    );
  }

  createConnector(connector: IQGConnectorInfo): Observable<IQGConnectorInfo> {
    if (connector.id !== null) {
      throw new Error('Connector cannot be created.');
    }
    return this._http
      .post<IQGConnectorInfo>(
        `${this.getBaseUrl()}/config/connectors`,
        connector
      )
      .pipe(
        tap({ complete: () => this._pushChange(connector.systemId) }),
        catchError(this._errorHandler)
      );
  }

  updateConnector(connector: IQGConnectorInfo): Observable<IQGConnectorInfo> {
    if (
      connector.id === null ||
      connector.version === ('PREVIOUS' as EQGConnectorInfoVersion)
    ) {
      throw new Error('Connector cannot be updated.');
    }
    let updateUrl: string;
    if (connector.version === 'ACTIVE') {
      updateUrl = `${this.getBaseUrl()}/config/connectors/${
        connector.id
      }/active`;
    } else {
      updateUrl = `${this.getBaseUrl()}/config/connectors/${
        connector.id
      }/pending`;
    }
    return this._http
      .put<IQGConnectorInfo>(updateUrl, connector)
      .pipe(
        tap({ complete: () => this._pushChange(connector.systemId) }),
        catchError(this._errorHandler)
      );
  }

  enableDisableConnector(
    connector: IQGConnectorInfo
  ): Observable<IQGConnectorInfo> {
    if (
      connector.id === null ||
      connector.version !== ('ACTIVE' as EQGConnectorInfoVersion)
    ) {
      throw new Error('Connector cannot be enabled/disabled.');
    }

    return this._http
      .patch<IQGConnectorInfo>(
        `${this.getBaseUrl()}/config/connectors/${connector.id}`,
        connector
      )
      .pipe(
        tap({ complete: () => this._pushChange(connector.systemId) }),
        catchError(this._errorHandler)
      );
  }

  deleteConnector(connector: IQGConnectorInfo): Observable<any> {
    let deleteUrl: string;
    if (connector.version === ('PENDING' as EQGConnectorInfoVersion)) {
      deleteUrl = `${this.getBaseUrl()}/config/connectors/${
        connector.id
      }/pending`;
    } else if (connector.version === ('PREVIOUS' as EQGConnectorInfoVersion)) {
      deleteUrl = `${this.getBaseUrl()}/config/connectors/${
        connector.id
      }/previous`;
    } else {
      deleteUrl = `${this.getBaseUrl()}/config/connectors/${connector.id}`;
    }
    return this._http
      .delete<any>(deleteUrl)
      .pipe(
        tap({ complete: () => this._pushChange(connector.systemId) }),
        catchError(this._errorHandler)
      );
  }

  activateConnector(connector: IQGConnectorInfo): Observable<IQGConnectorInfo> {
    if (
      connector.id === null ||
      connector.version === ('ACTIVE' as EQGConnectorInfoVersion)
    ) {
      throw new Error('Connector cannot be activated.');
    }
    return this._http
      .patch<IQGConnectorInfo>(
        `${this.getBaseUrl()}/config/connectors/${connector.id}/active`,
        connector.versionId
      )
      .pipe(
        tap({ complete: () => this._pushChange(connector.systemId) }),
        catchError(this._errorHandler)
      );
  }

  getConnectorDriverNodeIds(connector: IQGConnectorInfo): Observable<string[]> {
    return this._http
      .get<string[]>(
        `${this.getBaseUrl()}/config/connectors/${connector.id}/versions/${
          connector.versionId
        }/drivers`
      )
      .pipe(catchError(this._errorHandler));
  }

  getBaseUrl(): string {
    return this._apiService.getBaseUrl();
  }

  isInstallationInProgress(connector: IQGConnectorInfo): boolean {
    return this.connectorInstallations.some(
      (connectorInstallation) =>
        connectorInstallation.connector.id === connector.id &&
        connectorInstallation.connector.version === connector.version &&
        connectorInstallation.state.status ===
          EDiagnosticCheckStatus.IN_PROGRESS
    );
  }

  addConnectorInstallation(connectorInstall: IQGConnectorInstallation): void {
    this.connectorInstallations.push(connectorInstall);
  }

  updateConnectorInstallation(
    connectorInstall: IQGConnectorInstallation
  ): void {
    const index = this.connectorInstallations.findIndex(
      (existing) => existing.state.id === connectorInstall.state.id
    );
    if (index > -1) {
      this.connectorInstallations[index] = connectorInstall;
    }
  }

  removeConnectorInstallation(id: string): void {
    this.connectorInstallations.forEach((connectorInstall, index) => {
      if (connectorInstall.state.id === id) {
        this.connectorInstallations.splice(index, 1);
      }
    });
  }

  private _pushChange(systemId: string) {
    this.connectorListChange.next(systemId);
  }

  private _populateVersion(connectors: IQGConnectorInfo[]): IQGConnectorInfo[] {
    // create mapping of which connectors have pending versions
    const pending = <any>{};
    for (let i = 0; i < connectors.length; i++) {
      const connector: IQGConnectorInfo = connectors[i];
      pending[connector.id] =
        pending[connector.id] ||
        connector.version === ('PENDING' as EQGConnectorInfoVersion);
    }
    // set the hasPendingVersion property on each connector using the mapping
    for (let i = 0; i < connectors.length; i++) {
      const connector: IQGConnectorInfo = connectors[i];
      connector.hasPendingVersion = pending[connector.id];
      connector.isActive = () =>
        connector.version === ('ACTIVE' as EQGConnectorInfoVersion);
      connector.isPending = () =>
        connector.version === ('PENDING' as EQGConnectorInfoVersion);
      connector.isPrevious = () =>
        connector.version === ('PREVIOUS' as EQGConnectorInfoVersion);
      connector.enabled = !connector.disabled;
    }
    return connectors;
  }
}
