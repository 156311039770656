<mat-list-item
  *ngIf="expanded; else collapsed"
  [matMenuTriggerFor]="helpMenu"
  class="expanded-item"
  data-cy="helpMenu"
>
  <mat-icon matListIcon>help</mat-icon>
  <h3 matLine>
    <span class="mat-body-2">{{ 'HELP' | translate }}</span>
  </h3>
</mat-list-item>

<ng-template #collapsed>
  <mat-list-item disableRipple data-cy="helpMenu">
    <button
      mat-icon-button
      [matTooltip]="'HELP' | translate"
      matTooltipPosition="after"
      [matMenuTriggerFor]="helpMenu"
    >
      <mat-icon matListIcon>help</mat-icon>
    </button>
  </mat-list-item>
</ng-template>

<mat-menu #helpMenu="matMenu" backdropClass="rail-menu" class="no-overflow-y">
  <td-help-menu [showHelp]="displayInlineHelp$ | async"></td-help-menu>
</mat-menu>
