import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TdDialogService } from '@covalent/core/dialogs';
import { IDatasetLoadingJobs, LoadAssetService } from '@janus/help';
import { IUserProfile, ProfileService } from '@janus/profile';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { NotificationDialogComponent } from './notification-dialog/notification-dialog.component';

@Component({
  selector: 'notifications-nav-item',
  templateUrl: './notifications-nav-item.component.html',
  styleUrls: ['./notifications-nav-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsNavItemComponent {
  @Input() expanded: boolean;
  @Input() notificationCount: number;

  constructor(
    private readonly dialogService: TdDialogService,
    private readonly translateService: TranslateService,
    private readonly profileService: ProfileService,
    private readonly loadAssetService: LoadAssetService
  ) {}

  async showNotification(): Promise<void> {
    const profile: IUserProfile = await lastValueFrom(
      this.profileService.getUserProfile()
    );
    let completedJobs: IDatasetLoadingJobs[];

    if (profile && profile.dataset_loading_completed) {
      completedJobs = JSON.parse(profile.dataset_loading_completed);
    }

    this.dialogService.open(NotificationDialogComponent, {
      width: '300px',
      maxHeight: '600px',
      disableClose: true,
      position: { bottom: '20px', left: '80px' },
      data: completedJobs,
      panelClass: 'notification-dialog',
    });

    this.loadAssetService._loadAssetSubject.next(true);
  }
}
