<mat-list-item
  *ngIf="expanded; else collapsed"
  class="expanded-item"
  data-cy="notificationMenu"
  (click)="showNotification()"
>
  <mat-icon matListIcon>notifications</mat-icon>
  <h3 matLine>
    <span class="mat-body-2">{{ 'NOTIFICATIONS' | translate }}</span>
  </h3>
  <span
    [matBadge]="notificationCount"
    [matBadgeHidden]="notificationCount === 0"
    matBadgeColor="accent"
  ></span>
</mat-list-item>

<ng-template #collapsed>
  <mat-list-item disableRipple data-cy="notificationMenu">
    <button
      mat-icon-button
      [matTooltip]="'NOTIFICATIONS' | translate"
      matTooltipPosition="after"
      (click)="showNotification()"
    >
      <mat-icon
        matListIcon
        [matBadge]="notificationCount"
        matBadgeColor="accent"
        [matBadgeHidden]="notificationCount === 0"
        >notifications</mat-icon
      >
    </button>
  </mat-list-item>
</ng-template>
