import { Injectable } from '@angular/core';
import { Observable, map, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../common/api.service';
import { TranslateService } from '@ngx-translate/core';
import {
  IStepper,
  ICompleteableFormStep,
} from './connect-data-source-stepper.service';
import { uuid } from './diagnostic.service';
import { IBridge } from '../settings/bridges/bridge.service';
import { FormControl, FormGroup } from '@angular/forms';
import { IQGSystemDetails } from './data-source.interface';

export enum ENodeStatus {
  'ONLINE' = 'ONLINE',
  'OFFLINE' = 'OFFLINE',
}

export enum IQGNodeStatus {
  EMPTY = '',
  PROGRESS = 'register-in-progress',
  PENDING = 'register-pending',
  SUCCESS = 'register-success',
  FAILURE = 'register-failure',
}

export interface IQGNetwork {
  name: string;
  address: string;
}

export interface IQGConnector {
  connectorName: string;
  status: string;
  softwareVersion: string;
  fabricName: string;
  connectorVersion: string;
}

export interface IQGFabric {
  fabricName: string;
  status: string;
  softwareVersion: string;
  processId: string;
  version: string;
}
export interface IDataSourceNode {
  id: string;
  name: string;
  platform: string;
  _extraInfo: {
    status: string;
    primaryCluster: boolean;
    softwareVersion: string;
    startTime: string;
    lastHeartbeatTime: string;
    fabrics: IQGFabric[];
    connectors: IQGConnector[];
    primaryClusterManagers: string[];
  };
  networkInterfaces?: IQGNetwork[];
  aliases: string[];
  displayStatus: string;
  isNodeOnline: boolean;
}

export interface INodesRegisterStatus {
  registeredNodeCount: number;
  successNodeCount: number;
  failureNodeCount: number;
  status: IQGNodeStatus;
  completed: boolean;
  isExecutingNodesRegister: boolean;
  system: IQGSystemDetails;
  registerCountMessage?: string;
  showNumberExceededMessage: boolean;
  registeredNodes?: IDataSourceNode[];
  isConnectDS?: boolean;
  hideStatusRibbon?: boolean;
  processId: string;
  bridge?: IBridge;
  isAddBridge?: boolean;
  startTime?: number;
  endTime?: number;
}

export interface INodesRegisterStatusMap {
  systemId: string;
  status: INodesRegisterStatus;
}

@Injectable({
  providedIn: 'root',
})
export class NodeService {
  statusOnline = ENodeStatus.ONLINE;

  private activeNodeRegisterStatus: INodesRegisterStatusMap[] = [];

  private nodesRegisterStatusChange = new BehaviorSubject<
    INodesRegisterStatusMap[]
  >([]);
  nodesRegisterStatusChange$ = this.nodesRegisterStatusChange.asObservable();

  private nodeRegisterStatusClosedBS = new BehaviorSubject<boolean>(false);
  nodeRegisterStatusClosed$ = this.nodeRegisterStatusClosedBS.asObservable();

  private nodeRegisterSuccessStatusBS = new BehaviorSubject<boolean>(false);
  nodeRegisterSuccessStatus$ = this.nodeRegisterSuccessStatusBS.asObservable();
  constructor(
    private readonly http: HttpClient,
    private readonly _translate: TranslateService,
    private readonly _apiService: ApiService
  ) {}

  getNodesBySystemId(systemId: string): Observable<IDataSourceNode[]> {
    return this.getNodes(undefined, true, 'SYSTEM', systemId);
  }

  getNodesByConnectorId(connectorId: string): Observable<IDataSourceNode[]> {
    return this.getNodes(undefined, true, 'CONNECTOR', connectorId);
  }

  getNodes(
    nodeIds?: string[],
    extraInfo?: boolean,
    filterType?: string,
    filterId?: string
  ): Observable<IDataSourceNode[]> {
    let baseUrl: string = `${this.getBaseUrl()}/nodes`;
    baseUrl = baseUrl + '?extraInfo=' + extraInfo;
    if (filterType) {
      switch (filterType) {
        case 'SYSTEM':
          baseUrl = baseUrl + '&filterBySystemId=' + filterId;
          break;
        case 'FABRIC':
          baseUrl = baseUrl + '&filterByFabricId=' + filterId;
          break;
        case 'CONNECTOR':
          baseUrl = baseUrl + '&filterByConnectorId=' + filterId;
          break;
      }
    }
    return this.http.get<any>(baseUrl).pipe(
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      }),
      map((resultSet: IDataSourceNode[]) => {
        if (nodeIds && nodeIds.length > 0) {
          return this.addRenderedValues(
            resultSet.filter((node: IDataSourceNode) =>
              nodeIds.includes(node.id)
            )
          );
        } else {
          return this.addRenderedValues(resultSet);
        }
      })
    );
  }

  addRenderedValues(dataSourceNodes: IDataSourceNode[]): IDataSourceNode[] {
    dataSourceNodes.forEach((node: IDataSourceNode) => {
      this.setRenderedValues(node);
    });
    return dataSourceNodes;
  }

  setRenderedValues(node: IDataSourceNode): void {
    node.isNodeOnline = this.getIsOnline(node);
    node.displayStatus = this.getStatus(node.isNodeOnline);
  }

  getNode(nodeId: string): Observable<IDataSourceNode> {
    return this.http
      .get<any>(
        `${this.getBaseUrl()}/nodes/${nodeId}?extraInfo=true&details=true`
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        }),
        map((node: IDataSourceNode) => {
          this.setRenderedValues(node);
          return node;
        })
      );
  }

  getIsOnline(node: IDataSourceNode): boolean {
    return node._extraInfo?.status
      ? node._extraInfo?.status.toUpperCase() ===
          this.statusOnline.toUpperCase()
      : false;
  }

  getStatus(isOnline: boolean): string {
    return isOnline
      ? this._translate.instant('DATA_SOURCES.STATUS_ONLINE')
      : this._translate.instant('DATA_SOURCES.STATUS_OFFLINE');
  }

  deleteNode(nodeId: string): Observable<any> {
    return this.http.delete<any>(`${this.getBaseUrl()}/nodes/${nodeId}`).pipe(
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      })
    );
  }

  createPendingNodesRegisterStatus(
    system: IQGSystemDetails,
    isConnectDS: boolean
  ): INodesRegisterStatus {
    return {
      registeredNodeCount: 0,
      successNodeCount: 0,
      failureNodeCount: 0,
      status: IQGNodeStatus.PENDING,
      completed: false,
      isExecutingNodesRegister: false,
      system: system,
      showNumberExceededMessage: false,
      isConnectDS: isConnectDS,
      processId: uuid(),
      registeredNodes: [],
    };
  }

  setNodesRegisterStatus(statusMap: INodesRegisterStatusMap) {
    this.addNodeRegisterStatus(statusMap);
    const systemStatus = [];
    this.nodesRegisterStatusChange.value.forEach((val) => {
      if (val.systemId === statusMap.systemId) {
        systemStatus.push(val);
      }
    });

    if (systemStatus.length === 0) {
      this.nodesRegisterStatusChange.next([
        ...this.nodesRegisterStatusChange.getValue(),
        ...[statusMap],
      ]);
    } else {
      this.nodesRegisterStatusChange.next(
        this.nodesRegisterStatusChange.value.map(
          (status: INodesRegisterStatusMap) =>
            status.systemId === statusMap.systemId ? statusMap : status
        )
      );
    }
  }

  getBaseUrl(): string {
    return this._apiService.getBaseUrl();
  }

  updateStatusAndCountMessage(
    nodeCount: number,
    successNodesCount: number,
    failedNodesCount: number,
    status: IQGNodeStatus,
    nodeRegisterStatus: INodesRegisterStatus
  ): void {
    if (nodeRegisterStatus) {
      nodeRegisterStatus.registerCountMessage = this._translate.instant(
        'QUERYGRID.NODES_REGISTER.REGISTER_COUNT_MESSAGE',
        {
          nodeCount: successNodesCount + failedNodesCount,
          registeredNodeCount: nodeRegisterStatus.registeredNodeCount,
        }
      );
      nodeRegisterStatus.failureNodeCount = failedNodesCount;
      nodeRegisterStatus.successNodeCount = successNodesCount;
      nodeRegisterStatus.status = status;
    }
  }

  closeNodeRegisterStatus(processId: string): void {
    const systemStatus = this.nodesRegisterStatusChange.value.filter(
      (status) => status.status != null && status.status.processId !== processId
    );
    this.nodesRegisterStatusChange.next(systemStatus);
    this.nodeRegisterStatusClosedBS.next(true);
  }

  getNodeRegisterStatus(systemId: string): INodesRegisterStatusMap | undefined {
    return this.activeNodeRegisterStatus.find(
      (status) => status.systemId === systemId && status.status != null
    );
  }

  deleteActiveNodeRegisterStatus(
    systemId: string,
    closeNodeRegisterStatus: boolean = false
  ): boolean {
    const index = this.activeNodeRegisterStatus.findIndex(
      (status) => status.systemId === systemId && status.status != null
    );
    if (index !== -1) {
      this.activeNodeRegisterStatus.splice(index, 1);
      if (closeNodeRegisterStatus) {
        const systemStatus = this.nodesRegisterStatusChange.value.filter(
          (status) => status.systemId !== systemId
        );
        this.nodesRegisterStatusChange.next(systemStatus);
        this.nodeRegisterStatusClosedBS.next(true);
      }
      return true;
    }
    return false;
  }

  addNodeRegisterStatus(status: INodesRegisterStatusMap): void {
    const systemId = status.systemId;
    const index = this.activeNodeRegisterStatus.findIndex(
      (status) => status.systemId === systemId && status.status != null
    );
    if (index !== -1) {
      this.activeNodeRegisterStatus[index] = status;
    } else {
      this.activeNodeRegisterStatus.push(status);
    }
  }

  getAddNodeStepper(): IStepper {
    return {
      step1: this.getNodeRegisterStep(),
      step2: this.getNetworkDiagnosticsStep(),
    };
  }

  setSuccessRegisterStatus() {
    this.nodeRegisterSuccessStatusBS.next(true);
  }
  private getNodeRegisterStep(): ICompleteableFormStep {
    return {
      formGroup: new FormGroup({
        numberNodes: new FormControl(''),
      }),
      started: new BehaviorSubject<boolean>(false),
      completed: new BehaviorSubject<boolean>(false),
    };
  }

  private getNetworkDiagnosticsStep(): ICompleteableFormStep {
    return {
      formGroup: new FormGroup({}),
      started: new BehaviorSubject<boolean>(false),
      completed: new BehaviorSubject<boolean>(false),
    };
  }
}
