import { Injectable } from '@angular/core';
import { AuthenticationService } from '@janus/authentication';
import { ICloudPlatform } from '@janus/querygrid';

export const EXPANDED_RAIL_KEY = 'expanded-rail';

export interface NavItem {
  name: string;
  value?: string;
  route?: string;
  href?: string;
  subheader?: boolean;
  icon?: string;
  svgIcon?: boolean;
  iconClasses?: string;
  children?: NavItem[];
  exactMatch?: boolean;
  collapsed?: CollapsedProperties;
  flag?: string;
  supportedPlatforms?: ICloudPlatform[];
}

export interface CollapsedProperties {
  icon?: string;
  svgIcon?: boolean;
  iconClasses?: string;
  hovered?: CollapsedProperties;
}

export type NavItems = NavItem[];

export interface NavItemsCollection {
  home: NavItem;
  accounts: NavItem;
  // admin: NavItem; // hiding for now, until decision made
  alerts: NavItem;
  org: NavItem;
  orgs: NavItem;
  consumption: NavItem;
  costCalculator: NavItem;
  editor: NavItem;
  queries: NavItem;
  dataCatalog: NavItem;
  dataSharing: NavItem;
  dataSources: NavItem;
  dataCopy: NavItem;
  dataMigration: NavItem;
  udf: NavItem;
  configurableReports: NavItem;
  // Hide for now
  // monitoring: NavItem;
}
@Injectable()
export class NavRailData {
  constructor(private _authenticationService: AuthenticationService) {}

  navItemsCollection: NavItemsCollection = {
    home: {
      name: 'VANTAGE',
      value: 'home',
      icon: 'td-logo:logo-mark',
      iconClasses: 'logo-icon override-color',
      svgIcon: true,
      collapsed: {
        icon: 'td-logo:logo-mark',
        iconClasses: 'logo-icon push-top-xs push-bottom-sm',
        svgIcon: true,
        hovered: {
          icon: 'home',
          iconClasses: 'push-top-xs push-bottom-sm',
          svgIcon: false,
        },
      },
      route: '/',
    },
    accounts: {
      name: 'ENVIRONMENTS',
      value: 'environments',
      icon: 'td-icons:server',
      svgIcon: true,
      route: '/environments',
    },
    // leaving here in case admin ever comes back into focus
    // admin: {
    //   name: 'NAV.ADMINISTRATION',
    //   value: 'admin',
    //   icon: 'build',
    //   svgIcon: false,
    //   route: '/admin',
    //   children: [
    //     {
    //       name: 'NAV.DATA_SOURCES',
    //       value: 'data-sources',
    //       route: '/data-sources',
    //       flag: 'data_explore',
    //     },
    //   ],
    // },
    dataCatalog: {
      name: 'NAV.DATA_CATALOG',
      value: 'catalog',
      icon: 'bookmarks',
      svgIcon: false,
      flag: 'data_explore',
      route: '/catalog',
      href: '/data-explore/catalog',
    },
    // if this gets moved to admin, this would need to be removed
    dataSources: {
      name: 'NAV.DATA_SOURCES',
      value: 'data-sources',
      icon: 'td-icons:data_source',
      svgIcon: true,
      flag: 'data_explore',
      route: '/data-sources',
      href: '/data-explore/data-sources',
    },

    org: {
      name: 'ORGANIZATION',
      value: 'organization',
      icon: 'location_city',
      svgIcon: false,
      route: '/organization/' + this._authenticationService.getOrgId(),
    },
    orgs: {
      name: 'ORGANIZATIONS',
      value: 'organizations',
      icon: 'location_city',
      svgIcon: false,
      route: '/organizations',
    },
    consumption: {
      name: 'CONSUMPTION_NAV',
      value: 'consumption',
      icon: 'bar_chart',
      svgIcon: false,
      route: '/consumption',
    },
    costCalculator: {
      name: 'COST_CALCULATOR_NAV',
      value: 'cost-calculator',
      icon: 'td-icons:calculator',
      svgIcon: true,
      route: '/cost-calculator',
    },
    queries: {
      name: 'QUERIES',
      value: 'queries',
      icon: 'td-icons:advanced_sql',
      svgIcon: true,
      route: '/queries',
    },
    alerts: {
      name: 'ALERTS_TITLE',
      value: 'alerts',
      icon: 'mail_outline',
      svgIcon: false,
      flag: 'alerts',
      route: '/alerts/config',
    },
    editor: {
      name: 'EDITOR',
      value: 'query',
      icon: 'td-icons:product_editor',
      svgIcon: true,
      route: '/query',
    },
    dataSharing: {
      name: 'DATA_SHARING',
      value: 'data-sharing',
      icon: 'share',
      svgIcon: false,
      flag: 'data_sharing',
      route: '/data-sharing',
      children: [
        {
          name: 'NAV.PERMISSIONS',
          value: 'permissions',
          route: '/permissions',
          flag: 'data_sharing',
          href: '/data-sharing/permissions',
        },
      ],
      supportedPlatforms: [ICloudPlatform.AWS],
    },
    udf: {
      name: 'USER_DEFINED_FUNCTIONS',
      value: 'user-defined-functions',
      icon: 'td-icons:application',
      svgIcon: true,
      flag: 'third_party_apps',
      href: '/user-defined-functions',
    },
    dataCopy: {
      name: 'DATA_COPY',
      value: 'data-copy',
      icon: 'content_copy',
      svgIcon: false,
      flag: 'data_copy',
      href: '/data-mover/data-copy',
      supportedPlatforms: [ICloudPlatform.AWS],
    },
    dataMigration: {
      name: 'DATA_MIGRATION_SIDENAV',
      value: 'data-migration',
      icon: 'td-icons:server_migrate',
      svgIcon: true,
      flag: 'data_migration',
      href: '/data-mover/data-migration',
      supportedPlatforms: [ICloudPlatform.AWS],
    },
    configurableReports: {
      name: 'REPORTS_TITLE',
      value: 'conf-reports',
      icon: 'dashboard',
      svgIcon: false,
      flag: 'configurable_reports',
      href: '/conf-reports',
    },
    // Hide for now
    // monitoring: {
    //   name: 'MONITORING',
    //   icon: 'bar_chart',
    //   svgIcon: false,
    //   route: '/tenants',
    // },
  };

  getNavItems(): NavItems {
    if (this._authenticationService.isOrgAdmin()) {
      // Org Admin
      return [
        this.navItemsCollection.home,
        this.navItemsCollection.accounts,
        this.navItemsCollection.org,
        this.navItemsCollection.consumption,
        this.navItemsCollection.costCalculator,
        this.navItemsCollection.queries,
        this.navItemsCollection.alerts,
        this.navItemsCollection.editor,
        this.navItemsCollection.dataCatalog,
        this.navItemsCollection.dataSources,
        this.navItemsCollection.dataCopy,
        this.navItemsCollection.dataMigration,
        this.navItemsCollection.udf,
      ];
    } else if (this._authenticationService.isEditAccessSuperUser()) {
      // edit access superuser (basically a SysOps role to manage storage and scale operations)
      return [this.navItemsCollection.home, this.navItemsCollection.orgs];
    } else if (
      this._authenticationService.isSuperUser() ||
      this._authenticationService.isReadOnlySuperUser()
    ) {
      // Superuser
      return [
        this.navItemsCollection.home,
        this.navItemsCollection.orgs,
        this.navItemsCollection.consumption,
        this.navItemsCollection.queries,
        // Hide for now
        // this.navItemsCollection.monitoring,
        this.navItemsCollection.editor,
      ];
    } else {
      // DB USER
      return [
        this.navItemsCollection.home,
        this.navItemsCollection.accounts,
        this.navItemsCollection.queries,
        this.navItemsCollection.dataSharing,
        this.navItemsCollection.editor,
        this.navItemsCollection.dataCatalog,
        this.navItemsCollection.dataCopy,
        this.navItemsCollection.dataMigration,
        this.navItemsCollection.configurableReports,
      ];
    }
  }
}
