import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '@janus/authentication';

import { AppComponent } from './app.component';
import { AppRoutesGuard } from './app.routes.guard';
import { AppTrialsRoutesGuard } from './app.trials.guard';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: '',
        canActivate: [AppTrialsRoutesGuard],
        loadChildren: () =>
          import('./dashboard/dashboard.feature').then(
            (m: any) => m.DashboardFeature
          ),
      },
      {
        path: 'alerts',
        canActivate: [AppRoutesGuard],
        loadChildren: () =>
          import('./alerts/alerts.feature').then((m: any) => {
            return m.AlertsFeature;
          }),
      },
      {
        path: 'home',
        canActivate: [AppTrialsRoutesGuard],
        loadChildren: () =>
          import('./dashboard/dashboard.feature').then(
            (m: any) => m.DashboardFeature
          ),
      },
      {
        path: 'trial-expired',
        canActivate: [AppRoutesGuard],
        loadChildren: () =>
          import('./expired-trial/expired-trial.feature').then(
            (m: any) => m.ExpiredTrialFeature
          ),
      },
      {
        path: 'forbidden',
        canActivate: [AppRoutesGuard],
        loadChildren: () =>
          import('./forbidden/forbidden.feature').then(
            (m: any) => m.ForbiddenFeature
          ),
      },
      {
        path: 'environments',
        canActivate: [AppRoutesGuard, AppTrialsRoutesGuard],
        loadChildren: () =>
          import('./accounts/accounts.feature').then(
            (m: any) => m.AccountsFeature
          ),
      },
      {
        path: 'queries',
        canActivate: [AppRoutesGuard, AppTrialsRoutesGuard],
        loadChildren: () =>
          import(
            './accounts/components/query-management/query-management.feature'
          ).then((m: any) => m.QueryManagementFeature),
      },
      {
        path: 'organizations',
        canActivate: [AppRoutesGuard, AppTrialsRoutesGuard],
        loadChildren: () =>
          import('./organizations/organizations.feature').then(
            (m: any) => m.OrganizationsFeature
          ),
      },
      {
        path: 'tenants',
        canActivate: [AppRoutesGuard, AppTrialsRoutesGuard],
        loadChildren: () =>
          import('./tenants/tenants.feature').then(
            (m: any) => m.TenantsFeature
          ),
      },
      {
        path: 'tenants/tenant',
        canActivate: [AppRoutesGuard, AppTrialsRoutesGuard],
        loadChildren: () =>
          import('./tenants/tenant/tenant.feature').then(
            (m: any) => m.TenantFeature
          ),
      },
      {
        path: 'organization',
        canActivate: [AppRoutesGuard, AppTrialsRoutesGuard],
        loadChildren: () =>
          import('./organizations/organizations.feature').then(
            (m: any) => m.OrganizationsFeature
          ),
      },
      {
        path: 'consumption',
        canActivate: [AppRoutesGuard, AppTrialsRoutesGuard],
        loadChildren: () =>
          import('./consumption/consumption.feature').then(
            (m: any) => m.ConsumptionFeature
          ),
      },
      {
        path: 'cost-calculator',
        canActivate: [AppRoutesGuard, AppTrialsRoutesGuard],
        loadChildren: () =>
          import('./cost-calculator/cost-calculator.feature').then(
            (m: any) => m.CostCalculatorFeature
          ),
      },
      {
        path: 'your-account',
        loadChildren: () =>
          import('./your-account/your-account.feature').then(
            (m) => m.YourAccountModule
          ),
      },
      {
        path: '**',
        redirectTo: '/',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'corrected',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
