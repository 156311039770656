import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountsService, IAccount } from '@janus/accounts';
import { interval, lastValueFrom } from 'rxjs';

import {
  IAssetScript,
  ILoadAssetRes,
  LoadAssetService,
} from './load-asset-service';
import { VantageEditorConnectionService } from '@td/vantage/query';

@Component({
  selector: 'load-asset-dialog',
  templateUrl: './load-asset-dialog.component.html',
  styleUrls: ['./load-asset-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadAssetDialogComponent implements OnInit {
  assetForm: FormGroup;
  paramsReuried = false;
  accounts: IAccount[];
  account: string;
  showStatus: boolean = false;
  assetLoading: boolean = false;
  errorMsg: string;
  expandedIndex: number;
  scriptsMap = new Map<number, string>();
  dialogClosedBeforeDone = false;
  scripts: IAssetScript[];
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<LoadAssetDialogComponent>,
    private _httpClient: HttpClient,
    private _formBuilder: FormBuilder,
    private _accountsService: AccountsService,
    private _cdr: ChangeDetectorRef,
    private _loadAssetService: LoadAssetService,
    private _connectionService: VantageEditorConnectionService
  ) {}

  async ngOnInit(): Promise<void> {
    if (!this.data.showStatus) {
      this.accounts = await lastValueFrom(this._accountsService.getAccounts());
      this.account = this.accounts[0].account_name;
      const paramUrl =
        this.data.baseUrl +
        'datasets/' +
        this.data.dataset +
        '/parameters.json';

      this.assetLoading = true;
      this._httpClient.get(paramUrl).subscribe(
        (param: []) => {
          this.assetLoading = false;
          if (param.length != 0) {
            this.paramsReuried = true;
            this.assetForm = this._formBuilder.group(
              {
                userName: ['', Validators.required],
                tempPassword: [
                  '',
                  [
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(64),
                  ],
                ],
                tempConfirmPassword: [
                  '',
                  [
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(64),
                  ],
                ],
              },
              {
                validator: this.mustMatch(
                  'tempPassword',
                  'tempConfirmPassword'
                ),
              }
            );
          }
          this._cdr.markForCheck();
        },
        (err: any) => {
          this.assetLoading = false;
          this.errorMsg = err.message;
          this._cdr.markForCheck();
        }
      );
    } else {
      this.showStatus = true;
      this.assetLoading = true;
      this._accountsService
        .getUseCaseDataset(this.data.accountId, this.data.jobId)
        .subscribe(
          (res: ILoadAssetRes) => {
            this.assetLoading = false;
            this.scripts = res.scripts;
            this._cdr.markForCheck();
          },
          (err: any) => {
            this.assetLoading = false;
            this.errorMsg = err.message;
            this._cdr.markForCheck();
          }
        );
    }
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
  onLoadAsset(): void {
    const accountId: string = this.data.system.systemId;
    const data = {
      dataset_name: this.data.dataset,
    };

    if (this.paramsReuried) {
      Object.assign(data, {
        parameters: {
          User: this.assetForm.get('userName')?.value,
          Password: this.assetForm.get('tempPassword')?.value,
        },
      });
    }

    this.errorMsg = undefined;
    if (this.assetForm) {
      this.assetForm.disable();
    }

    this._accountsService.loadUseCaseDataset(accountId, data).subscribe(
      (res: any) => {
        this.showStatus = true;
        this.assetLoading = true;
        this._cdr.markForCheck();

        const jobId: string = res.use_case_dataset_job_id;

        this._loadAssetService.setDatasetLoading(
          accountId,
          jobId,
          data.dataset_name,
          true
        );

        const getJobStatus = interval(5000).subscribe(() => {
          this._accountsService.getUseCaseDataset(accountId, jobId).subscribe(
            async (loadAssetRes: ILoadAssetRes) => {
              if (
                loadAssetRes.status.toLowerCase() === 'done' ||
                loadAssetRes.status.toLowerCase() === 'failed'
              ) {
                // check if dialog is closed while job is running
                if (this.dialogClosedBeforeDone) {
                  loadAssetRes.id = jobId;
                  await this._loadAssetService.removeDatasetLoading(
                    jobId,
                    true
                  );
                  await this._loadAssetService.setDatasetLoading(
                    accountId,
                    jobId,
                    data.dataset_name,
                    false,
                    loadAssetRes.status
                  );
                  this._loadAssetService._loadAssetSubject.next(true);
                } else {
                  // dialog is opened and job is one. Don't need notificaion. Remove the job.
                  this._loadAssetService.removeDatasetLoading(jobId, true);
                }

                getJobStatus.unsubscribe();
              }
              if (this.showStatus) {
                this.assetLoading = false;
              }
              this.scripts = loadAssetRes.scripts;
              this._cdr.markForCheck();
            },
            (err: any) => {
              this.assetLoading = false;
              this.errorMsg = err.message;
              getJobStatus.unsubscribe();
              this._cdr.markForCheck();
            }
          );
        });
      },
      (err: any) => {
        if (this.assetForm) {
          this.assetForm.enable();
        }
        this.assetLoading = false;
        this.errorMsg = err.message;
        this._cdr.markForCheck();
      }
    );
  }

  onPanelExpanded(script: any, index: number): void {
    this.expandedIndex = index;
    if (!this.scriptsMap.has(index)) {
      this.assetLoading = true;

      this._httpClient
        .get(script.url, {
          responseType: 'text',
        })
        .subscribe((sql: string) => {
          this.assetLoading = false;
          this.scriptsMap.set(index, sql);
        });
    }
  }

  onPanelClosed(): void {
    this.expandedIndex = undefined;
  }

  getStatusClass(status: string) {
    switch (status.toUpperCase()) {
      case 'IN_PROGRESS':
        return {
          class: 'running-state',
          icon: 'td-icons:state_running',
        };
      case 'FAILED':
        return {
          class: 'tc-negative',
          icon: 'td-icons:state_negative',
        };
      case 'NOT_STARTED':
        return {
          class: 'not-started',
          icon: '',
        };
      case 'DONE':
        return {
          class: 'completed-state',
          icon: 'td-icons:check',
        };
    }
  }
  close(): void {
    this._dialogRef.close();
    this.dialogClosedBeforeDone = true;
  }
}
