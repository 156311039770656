/**
 * Teradata I18n translation interceptor
 * 
 * Use this to add the custom TD language header and currently selected language to every request
 * 
 * NOTE: To be able to use this in your Angular application, be sure to include this in your module's providers list:
 *
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LanguageInterceptor,
    multi: true
  }
 *
 */
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { getSelectedLanguage } from './translate.module';

// if we don't end up using a custom header, then fall back to this browser default
export const ACCEPT_LANGUAGE_HEADER = 'Accept-Language';
// if we want to avoid overriding the browser default language header, then use this instead
export const TD_LANGUAGE_HEADER = 'X-TD-Language';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(private translateService: TranslateService) {}

  // add the selected language to every request
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const language = getSelectedLanguage(this.translateService);
    const modifiedReq = req.clone({
      headers: req.headers.set(ACCEPT_LANGUAGE_HEADER, language),
    });
    return next.handle(modifiedReq);
  }
}
