import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  AuthenticationService,
  DB_USER_ROUTES,
  ORG_ADMIN_ROUTES,
  SUPERUSER_ROUTES,
} from '@janus/authentication';

@Injectable()
export class AppRoutesGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (
      (this._authenticationService.isASuperUser &&
        this.routeMatches(state.url, SUPERUSER_ROUTES)) ||
      (this._authenticationService.isOrgAdmin() &&
        this.routeMatches(state.url, ORG_ADMIN_ROUTES)) ||
      this.routeMatches(state.url, DB_USER_ROUTES)
    ) {
      return true;
    } else {
      this._router.navigateByUrl('/');
      return false;
    }
  }

  routeMatches(url: string, validRoutes: string[]): boolean {
    let matchFound = false;
    validRoutes.forEach((route: string) => {
      if (url.startsWith(route)) {
        matchFound = true;
      }
    });
    return matchFound;
  }
}
