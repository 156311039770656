<div class="pop-up" [class.edit-mode]="addMode">
  <div class="filter-header">
    <span class="mat-subheading-2">{{ 'FILTER_RULES' | translate }}</span>
    <button
      mat-icon-button
      aria-label="Add filters"
      (click)="addFilterToTable(undefined)"
    >
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <mat-divider></mat-divider>
  <mat-dialog-content>
    <div
      class="no-filters mat-caption"
      *ngIf="filters.length === 0; else filtersForm"
    >
      {{ 'NO_FILTERS' | translate }}
    </div>

    <ng-template #filtersForm>
      <div class="add-filters-form" [formGroup]="filterDisplayForm">
        <ng-container *ngFor="let filter of filters.controls; index as i">
          <div class="filter-form-row" [formGroup]="filter">
            <mat-form-field appearance="outline" class="dense">
              <mat-select formControlName="columnId">
                <mat-option
                  *ngFor="let column of getFilterableColumns()"
                  [value]="column.id"
                >
                  {{ column.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="dense shorter">
              <mat-select formControlName="filterType">
                <mat-option
                  *ngFor="let filterType of filterDisplayTypes(i)"
                  [value]="filterType"
                >
                  {{ filterTypeTranslation(filterType) }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field
              appearance="outline"
              class="dense"
              *ngIf="filterTypeIsEnum(i); else textVersion"
            >
              <mat-select formControlName="filterValue">
                <mat-option
                  *ngFor="let choice of filterValueChoices(i)"
                  [value]="choice.value"
                >
                  {{ choice.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <ng-template #textVersion>
              <mat-form-field appearance="outline" class="dense">
                <input matInput formControlName="filterValue" />
              </mat-form-field>
            </ng-template>

            <button
              class="delete-btn"
              mat-icon-button
              (click)="deleteFilterFromTable(i)"
            >
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </mat-dialog-content>

  <ng-container *ngIf="!data.instantFiltering">
    <mat-divider></mat-divider>
    <div mat-dialog-actions class="filter-footer">
      <button
        mat-flat-button
        color="accent"
        (click)="applyPopupFilters()"
        [disabled]="filterDisplayForm.invalid"
      >
        {{ 'APPLY' | translate }}
      </button>
    </div>
  </ng-container>
</div>
