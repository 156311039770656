import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiStatusComponent } from './status.component';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [UiStatusComponent],
  imports: [CommonModule, MatIconModule, MatProgressSpinnerModule],
  exports: [UiStatusComponent],
})
export class UiStatusModule {}
