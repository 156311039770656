const DEFAULT_SITE: string = 'DEFAULT_SITE_NAME';

const baseUrlMap: Map<string, string> = new Map<string, string>();
baseUrlMap.set(DEFAULT_SITE, '');

export function setVantageBaseURL(
  baseUrl: string,
  siteName: string = DEFAULT_SITE
): void {
  baseUrlMap.set(siteName, baseUrl);
}

export function getVantageBaseURL(
  siteName: string = DEFAULT_SITE
): string | undefined {
  return baseUrlMap.get(siteName)
    ? baseUrlMap.get(siteName)
    : baseUrlMap.get(DEFAULT_SITE);
}

export function removeVantageBaseURL(siteName: string = DEFAULT_SITE): boolean {
  return baseUrlMap.delete(siteName);
}
