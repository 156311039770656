import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'td-dialog-shell',
  templateUrl: './dialog-shell.component.html',
  styleUrls: ['./dialog-shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TdDialogShellComponent {
  @Input() actionButtonColor: string = 'accent';
  @Input() actionButtonDisabled: boolean = false;
  @Input() actionButtonName?: string;
  @Input() cancelButtonName: string = this.translate.instant('CANCEL');
  @Input() dialogTitle?: string;

  @Output() actionHit = new EventEmitter<void>();
  @Output() cancelHit = new EventEmitter<void>();

  constructor(private readonly translate: TranslateService) {}

  isActionButtonColor(colorToCheck: string): boolean {
    return this.actionButtonColor === colorToCheck;
  }
}
