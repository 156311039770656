export const environment: {
  production: boolean;
  data_dog_app_id: string;
  data_dog_client_token: string;
  env_name: string;
  help_ids: { [id: string]: string };
} = {
  production: false,
  data_dog_app_id: 'd9c0dc70-6291-4e03-ad72-1f532e51af05',
  data_dog_client_token: 'pub502e3e4f982d42653648c3dee23e43a1',
  env_name: 'review',
  help_ids: {
    query_grid: 'vyx1659391025497',
    query_grid_enable_priviate_link: 'wrw1640282047881',
    fabric: 'goh1640282193934',
    data_sources: 'znp1640282079399',
    data_nodes: 'yfd1674158023864',
    data_source_bridges_links: 'pvy1674785931688',
    data_source_connectors: 'zof1674159380680',
    data_source_links: 'kzu1674159463068',
    settings: 'zxp1640282246701',
    settings_bridges: 'wne1674087932617',
    settings_network: 'iwx1674087965329',
    settings_communication_policies: 'zap1674087994421',
    settings_users_and_roles_mappings: 'hmn1674088306575',
    issues: 'ama1689287374954',
    operations: 'oiw1640282294248',
  },
};
