import { Component, Input, OnInit } from '@angular/core';

enum PositionEnum {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}
@Component({
  selector: 'td-ghost-loading',
  templateUrl: './ghost-loading.component.html',
  styleUrls: ['./ghost-loading.component.scss'],
})
export class TdGhostLoadingComponent implements OnInit {
  @Input() linesCount = 3;
  @Input() centerVertically = false;
  @Input() position = PositionEnum.CENTER;
  @Input() percentageWidth = 100;
  @Input() pxWidth;
  @Input() pxHeight: number = 24;
  @Input() borderRadius: number = 8;
  @Input() disableSideMargins: boolean = false;

  lines: number[];
  containerStyles = {};
  loadingStyles = {};

  ngOnInit(): void {
    this.lines = Array(this.linesCount).map((_, i) => i);

    if (this.percentageWidth && !this.pxWidth) {
      this.containerStyles['width'] = this.percentageWidth + '%';
    } else if (this.pxWidth) {
      this.containerStyles['width'] = this.percentageWidth + 'px';
    }

    if (this.position === PositionEnum.CENTER) {
      this.containerStyles['margin'] = 'auto';
    } else if (this.position === PositionEnum.RIGHT) {
      this.containerStyles['margin-left'] = 'auto';
      this.containerStyles['margin-right'] = '0';
    }

    this.loadingStyles['line-height'] = `${this.pxHeight}px`;
    this.loadingStyles['border-radius'] = `${this.borderRadius}px`;

    if (this.disableSideMargins) {
      this.loadingStyles['margin-right'] = '0';
      this.loadingStyles['margin-left'] = '0';
    }
  }
}
