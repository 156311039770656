// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const require: any;

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CovalentDialogsModule } from '@covalent/core/dialogs';

import { CovalentMarkdownNavigatorModule } from '@covalent/markdown-navigator';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import {
  initTranslateService,
  startTranslateService,
  addTranslation,
  getSupportedLanguages,
  LocalizationModule,
} from '@janus/translate';

import { HelpMenuComponent } from './help-menu/help-menu.component';
import { InProductHelpComponent } from './in-product-help/in-product-help.component';
import { LoadAssetDialogComponent } from './load-asset-dialog/load-asset-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { CovalentHighlightModule } from '@covalent/highlight';
import { CovalentMessageModule } from '@covalent/core/message';
import { VantageErrorService } from '@td/vantage/user-feedback';

@NgModule({
  declarations: [
    HelpMenuComponent,
    InProductHelpComponent,
    LoadAssetDialogComponent,
  ],
  imports: [
    CommonModule,
    CovalentMarkdownNavigatorModule,
    CovalentDialogsModule,
    CovalentHighlightModule,
    CovalentMessageModule,
    MatProgressBarModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatDialogModule,
    MatMenuModule,
    MatTooltipModule,
    TranslateModule.forChild(),
    LocalizationModule,
  ],
  exports: [HelpMenuComponent, InProductHelpComponent],
  providers: [VantageErrorService],
})
export class HelpModule {
  constructor(translateService: TranslateService) {
    initTranslateService(translateService);

    getSupportedLanguages().forEach((lang: string) => {
      let translateFile: any;
      try {
        translateFile = require(`../../assets/i18n/${lang}.json`);
      } catch (err: any) {
        translateFile = {};
      }

      addTranslation(translateService, lang, translateFile);
    });

    startTranslateService(translateService);
  }
}
