import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CovalentDialogsModule } from '@covalent/core/dialogs';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    MatSelectModule,
    CovalentDialogsModule,
    TranslateModule.forChild(),
  ],
})
export class AccountsModule {}
