/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  firstValueFrom,
  of,
  retry,
  throwError,
} from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';

import { getVantageBaseURL } from './api-pointer';

export enum QueryResultColumnTypes {
  // Array
  'ARRAY' = 'ARRAY',
  'VARRAY' = 'VARRAY',

  // Byte
  'BLOB' = 'BLOB',
  'BYTE' = 'BYTE',
  'VARBYTE' = 'VARBYTE',

  // Numeric
  'BIGINT' = 'BIGINT',
  'BYTEINT' = 'BYTEINT',
  'DECIMAL' = 'DECIMAL',
  'DOUBLE PRECISION' = 'DOUBLE PRECISION',
  'FLOAT' = 'FLOAT',
  'INTEGER' = 'INTEGER',
  'NUMBER' = 'NUMBER',
  'NUMERIC' = 'NUMERIC',
  'REAL' = 'REAL',
  'SMALLINT' = 'SMALLINT',

  // DateTIme
  'DATE' = 'DATE',
  'TIME' = 'TIME',
  'TIMESTAMP' = 'TIMESTAMP',
  'TIME WITH TIME ZONE' = 'TIME WITH TIME ZONE',
  'TIMESTAMP WITH TIME ZONE' = 'TIMESTAMP WITH TIME ZONE',

  // Interval
  'INTERVAL' = 'INTERVAL',

  'INTERVAL DAY' = 'INTERVAL DAY',
  'INTERVAL DAY TO HOUR' = 'INTERVAL DAY TO HOUR',
  'INTERVAL DAY TO MINUTE' = 'INTERVAL DAY TO MINUTE',
  'INTERVAL DAY TO SECOND' = 'INTERVAL DAY TO SECOND',

  'INTERVAL HOUR' = 'INTERVAL HOUR',
  'INTERVAL HOUR TO MINUTE' = 'INTERVAL HOUR TO MINUTE',
  'INTERVAL HOUR TO SECOND' = 'INTERVAL HOUR TO SECOND',

  'INTERVAL MINUTE' = 'INTERVAL MINUTE',
  'INTERVAL MINUTE TO SECOND' = 'INTERVAL MINUTE TO SECOND',

  'INTERVAL MONTH' = 'INTERVAL MONTH',
  'INTERVAL SECOND' = 'INTERVAL SECOND',
  'INTERVAL YEAR' = 'INTERVAL YEAR',
  'INTERVAL YEAR TO MONTH' = 'INTERVAL YEAR TO MONTH',

  // Character
  'CHAR' = 'CHAR',
  'CHARACTER' = 'CHARACTER',
  'CHARACTER SET GRAPHIC' = 'CHARACTER SET GRAPHIC',
  'CLOB' = 'CLOB',
  'CHAR VARYING' = 'CHAR VARYING',
  'LONG VARCHAR' = 'LONG VARCHAR',
  'VARCHAR' = 'VARCHAR',

  // Period
  'PERIOD' = 'PERIOD',

  // UDT
  'udt_name' = 'udt_name',

  // Parameter
  'TD_ANYTYPE' = 'TD_ANYTYPE',
  'VARIANT_TYPE' = 'VARIANT_TYPE',
}

export enum DataFormatTypes {
  'EPOCH_MILLIS' = 'EPOCH_MILLIS',
  'TD_DB' = 'TD_DB',
  'ISO_8601' = 'ISO_8601',
}

export interface IQueryBands {
  ApplicationName: string;
  Version?: string;
  ClientUser?: string;
  [name: string]: string;
}

export interface IConnectable {
  nickname: string;
  name: string;
  siteURL: string;
  purpose: string;
  system_attributes?: { attributes: { [key: string]: string } };
  systemId?: string;
  host?: string;
}

export interface ISystem {
  defaultTransactionMode?: string;
  encryptData?: boolean;
  host: string;
  includeUsersInList?: boolean;
  logMech?: string;
  maxExplicitSessionsPerUser?: number;
  maxIdleSeconds?: number;
  maxImplicitSessionsPerUser?: number;
  maxQueuedRequestsPerUser?: number;
  name: string;
  port?: number;
  systemId: string;
  systemType: string;
  useXViews: false;
}

export interface IQueryPayload {
  query: string;
  session?: string;
  logMech?: string;
  rowOffset?: number;
  rowLimit?: number;
  format?: string; // (default)-object, array, or csv
  includeColumns?: boolean;
  includeColumnsTypes?: boolean;
  outputNumbersAsStrings?: boolean;
  spooledResultSet?: boolean;
  clientId?: string;
  queryBands?: IQueryBands;
  dateFormat?: DataFormatTypes;
  isMetadataQuery?: boolean;
  scriptId?: string;
}

export interface IDataBaseResultSet {
  name: string;
  dbKind: string;
  error?: string;
}

export interface IQueryResultSet {
  queueDuration: number;
  queryDuration: number;
  fetchTime?: number;
  rowCounts?: string[];
  results: IQueryResultSetResult[];
  history?: IQueryHistory;
  error?: string;
}

export enum QueryStatus {
  SUCCESSFUL = 'SUCCESSFUL',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
}

export interface IQueryHistory {
  conn?: string;
  compute?: string;
  sql?: string;
  date?: string;
  rowCount?: BigInt;
  elapsedTime?: number;
  fetchTime?: number;
  status?: string;
  statusType?: QueryStatus;
  user?: string;
}

export interface IQueryResultData {
  [name: string]: string | number;
}

export interface IQueryResultSetResult {
  resultSetName?: string;
  data: IQueryResultData[];
  resultSet: boolean;
  count?: number;
  rowCount?: number;
  rowLimitExceeded: boolean;
  query?: string;
  columns?: IQueryResultSetColumn[];
  statementNum?: number;
}

export interface IQueryResultSetColumn {
  name: string;
  type: keyof typeof QueryResultColumnTypes;
}

export interface IQueryInfo {
  success?: boolean;
  logTime?: string;
  error?: any;
  resultSet?: IQueryResultSet;
}

export interface ISQLEConnection {
  connectionId?: string;
  system: IConnectable;
  creds?: string;
  defaultDb?: string;
  computeGroup?: string;
  favoriteDatabases?: string[];
  full_creds?: string;
  username?: string;
  hasComputeGroupsView?: boolean;
  tempId?: string;
  savedConnection?: boolean;
}

export interface ISessionPayload {
  autoCommit: string;
  transactionMode: string;
  charSet: string;
  defaultDatabase?: string;
  logMech?: string;
  queryBands?: IQueryBands;
}

export interface IFakeResultSet {
  nativeSQL: string;
  requestNumber: number;
  statementNumber: number;
  activityType: number;
  activityCount: number;
  warningCode: number;
  warningMessage: string;
  columnMetadata: string;
  parameterMetadata: string;
}

export interface CsvConfig {
  fieldDelimiter?: string;
  recordDelimiter?: string;
  quotingChar?: string;
  quotedFields?: boolean;
  includeHeader?: boolean;
  trimTrailingSpaces?: boolean;
}

// const CONTEXT_PATH: string = ':1443/v1';
// const PROTOCOL: string = 'https://';
const CONTEXT_PATH: string = '/api/tdrest';
const PROTOCOL: string = '';

export const DEFAULT_COMPUTE_GROUP_VALUE = '___DefaultComputeGroup___';
export const NO_COMPUTE_GROUP_VALUE = '___NoComputeGroup___';
export const NOOP_COMPUTE_GROUP_VALUE = '___NoopComputeGroup___';

@Injectable({
  providedIn: 'root',
})
export class VantageQueryService {
  constructor(private _http: HttpClient) {}

  getSystems(): Observable<any[]> {
    const headers: HttpHeaders = new HttpHeaders()
      .append('Accept', 'application/vnd.com.teradata.rest-v1.0+json')
      .append('Content-Type', 'application/json');
    const request: Observable<ISystem[]> = this._http.get<any[]>(
      `${CONTEXT_PATH}/systems`,
      { headers }
    );

    return request.pipe(
      catchError((error: HttpErrorResponse) => {
        throw { ...error.error, httpStatus: error.status };
      })
    );
  }

  querySystem(
    connection: ISQLEConnection,
    payload: IQueryPayload
  ): Observable<IQueryResultSet> {
    let headers: HttpHeaders = new HttpHeaders()
      .append('Accept', 'application/vnd.com.teradata.rest-v1.0+json')
      .append('Content-Type', 'application/json');
    if (connection.creds) {
      headers = headers.set('X-Auth-Credentials', `Basic ${connection.creds}`);
      const attributes: { [key: string]: string } =
        connection.system.system_attributes?.attributes;
      payload.logMech =
        attributes?.log_mech || attributes?.logMech || 'DEFAULT';
    } else {
      payload.logMech = 'JWT';
    }
    const request: Observable<IQueryResultSet> =
      this._http.post<IQueryResultSet>(
        `${PROTOCOL}${getVantageBaseURL(
          connection.system.nickname
        )}${CONTEXT_PATH}/systems/` +
          encodeURIComponent(connection.system.name) +
          '/queries',
        payload,
        { headers }
      );

    return request.pipe(
      catchError((error: HttpErrorResponse) => {
        throw { ...error.error, httpStatus: error.status };
      })
    );
  }

  getTableInfo(
    connection: ISQLEConnection,
    databaseName: string,
    tableName: string
  ): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders()
      .append('Accept', 'application/vnd.com.teradata.rest-v1.0+json')
      .append('Content-Type', 'application/json');

    if (connection.creds) {
      headers = headers.set('X-Auth-Credentials', `Basic ${connection.creds}`);
    }

    const request: Observable<any> = this._http.get(
      `${PROTOCOL}${getVantageBaseURL(
        connection.system.nickname
      )}${CONTEXT_PATH}/systems/` +
        encodeURIComponent(connection.system.name) +
        '/databases/' +
        encodeURIComponent(databaseName) +
        '/tables/' +
        encodeURIComponent(tableName),
      { headers }
    );

    return request.pipe(
      catchError((error: HttpErrorResponse) => {
        throw { ...error.error, httpStatus: error.status };
      })
    );
  }

  getViewInfo(
    connection: ISQLEConnection,
    databaseName: string,
    viewName: string
  ): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders()
      .append('Accept', 'application/vnd.com.teradata.rest-v1.0+json')
      .append('Content-Type', 'application/json');

    if (connection.creds) {
      headers = headers.set('X-Auth-Credentials', `Basic ${connection.creds}`);
    }

    const request: Observable<any> = this._http.get(
      `${PROTOCOL}${getVantageBaseURL(
        connection.system.nickname
      )}${CONTEXT_PATH}/systems/` +
        encodeURIComponent(connection.system.name) +
        '/databases/' +
        encodeURIComponent(databaseName) +
        '/views/' +
        encodeURIComponent(viewName),
      { headers }
    );

    return request.pipe(
      catchError((error: HttpErrorResponse) => {
        throw { ...error.error, httpStatus: error.status };
      })
    );
  }

  getQuery(
    connection: ISQLEConnection,
    requestId: string
  ): Observable<IQueryResultSet> {
    let headers: HttpHeaders = new HttpHeaders()
      .append('Accept', 'application/vnd.com.teradata.rest-v1.0+json')
      .append('Content-Type', 'application/json');
    if (connection.creds) {
      headers = headers.set('X-Auth-Credentials', `Basic ${connection.creds}`);
    }
    const request: Observable<IQueryResultSet> =
      this._http.get<IQueryResultSet>(
        `${PROTOCOL}${getVantageBaseURL(
          connection.system.nickname
        )}${CONTEXT_PATH}/systems/` +
          encodeURIComponent(connection.system.name) +
          `/queries/${requestId}`,
        { headers }
      );

    return request.pipe(
      catchError((error: HttpErrorResponse) => {
        throw { ...error.error, httpStatus: error.status };
      })
    );
  }

  getCsvQueryResult(
    connection: ISQLEConnection,
    queryId: string
  ): Observable<any> {
    const headers = {
      Accept: 'application/vnd.com.teradata.rest-v1.0+json',
      'Content-Type': 'text/csv',
    };
    if (connection.creds) {
      // Vantage Lake
      headers['X-Auth-Credentials'] = `Basic ${connection.creds}`;
    } else {
      // Console Enterprise
      // const authToken: string = this._authService.getAccessToken();
      // headers['Authorization'] = `Bearer ${authToken}`;
    }

    const request: Observable<any> = fromFetch(
      `${getVantageBaseURL(
        connection.system.nickname
      )}${CONTEXT_PATH}/systems/` +
        encodeURIComponent(connection.system.name) +
        `/queries/${queryId}/results/`,
      {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
        headers: headers,
      }
    );

    return request.pipe(
      catchError((error: HttpErrorResponse) => {
        throw { ...error.error, httpStatus: error.status };
      })
    );
  }

  getQueryResult(
    connection: ISQLEConnection,
    queryId: string
  ): Observable<IQueryResultSet> {
    let headers: HttpHeaders = new HttpHeaders()
      .append('Accept', 'application/vnd.com.teradata.rest-v1.0+json')
      .append('Content-Type', 'application/json');
    if (connection.creds) {
      headers = headers.set('X-Auth-Credentials', `Basic ${connection.creds}`);
    }
    const request: Observable<IQueryResultSet> =
      this._http.get<IQueryResultSet>(
        `${PROTOCOL}${getVantageBaseURL(
          connection.system.nickname
        )}${CONTEXT_PATH}/systems/` +
          encodeURIComponent(connection.system.name) +
          `/queries/${queryId}/results`,
        { headers }
      );

    return request.pipe(
      catchError((error: HttpErrorResponse) => {
        throw { ...error.error, httpStatus: error.status };
      })
    );
  }

  deleteQuery(
    connection: ISQLEConnection,
    queryId: string
  ): Observable<IQueryResultSet> {
    let headers: HttpHeaders = new HttpHeaders()
      .append('Accept', 'application/vnd.com.teradata.rest-v1.0+json')
      .append('Content-Type', 'application/json');
    if (connection.creds) {
      headers = headers.set('X-Auth-Credentials', `Basic ${connection.creds}`);
    }
    const request: Observable<IQueryResultSet> =
      this._http.delete<IQueryResultSet>(
        `${PROTOCOL}${getVantageBaseURL(
          connection.system.nickname
        )}${CONTEXT_PATH}/systems/` +
          encodeURIComponent(connection.system.name) +
          `/queries/${queryId}`,
        { headers }
      );

    return request.pipe(
      catchError((error: HttpErrorResponse) => {
        throw { ...error.error, httpStatus: error.status };
      })
    );
  }

  createSession(
    connection: ISQLEConnection,
    payload: ISessionPayload = {
      autoCommit: 'true',
      transactionMode: 'TERA',
      charSet: 'UTF8',
    }
  ): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders()
      .append('Accept', 'application/vnd.com.teradata.rest-v1.0+json')
      .append('Content-Type', 'application/json');
    if (connection.creds) {
      headers = headers.set('X-Auth-Credentials', `Basic ${connection.creds}`);
      const attributes: { [key: string]: string } =
        connection.system.system_attributes?.attributes;
      payload.logMech =
        attributes?.log_mech || attributes?.logMech || 'DEFAULT';
    } else {
      payload.logMech = 'JWT';
    }
    const request: Observable<any> = this._http.post(
      `${PROTOCOL}${getVantageBaseURL(
        connection.system.nickname
      )}${CONTEXT_PATH}/systems/` +
        encodeURIComponent(connection.system.name) +
        '/sessions',
      payload,
      { headers }
    );

    return request.pipe(
      catchError((error: HttpErrorResponse) => {
        throw { ...error.error, httpStatus: error.status };
      })
    );
  }

  async setSessionComputeGroup(
    connection: ISQLEConnection,
    sessionId: string,
    scriptId: string,
    computeGroup: string
  ): Promise<IQueryResultSet> {
    if (!computeGroup || computeGroup === NOOP_COMPUTE_GROUP_VALUE) {
      return;
    }

    let sessionComputeGroup: string;
    if (computeGroup === DEFAULT_COMPUTE_GROUP_VALUE) {
      sessionComputeGroup = 'DEFAULT';
    } else if (computeGroup === NO_COMPUTE_GROUP_VALUE) {
      sessionComputeGroup = 'NULL';
    } else {
      sessionComputeGroup = '"' + computeGroup + '"';
    }

    const payload: IQueryPayload = {
      query: 'SET ROLE ALL',
      session: sessionId,
      scriptId,
    };

    await firstValueFrom(this.querySystem(connection, payload));

    payload.query = `SET SESSION COMPUTE GROUP ${sessionComputeGroup}`;

    return firstValueFrom(this.querySystem(connection, payload));
  }

  getAllSessions(connection: ISQLEConnection): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders()
      .append('Accept', 'application/vnd.com.teradata.rest-v1.0+json')
      .append('Content-Type', 'application/json');
    if (connection.creds) {
      headers = headers.set('X-Auth-Credentials', `Basic ${connection.creds}`);
    }
    const request: Observable<any> = this._http.get(
      `${PROTOCOL}${getVantageBaseURL(
        connection.system.nickname
      )}${CONTEXT_PATH}/systems/` +
        encodeURIComponent(connection.system.name) +
        '/sessions',
      { headers }
    );

    return request.pipe(
      catchError((error: HttpErrorResponse) => {
        throw { ...error.error, httpStatus: error.status };
      })
    );
  }

  deleteSession(
    connection: ISQLEConnection,
    sessionId: string
  ): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders()
      .append('Accept', 'application/vnd.com.teradata.rest-v1.0+json')
      .append('Content-Type', 'application/json');
    if (connection.creds) {
      headers = headers.set('X-Auth-Credentials', `Basic ${connection.creds}`);
    }
    const request: Observable<any> = this._http.delete(
      `${PROTOCOL}${getVantageBaseURL(
        connection.system.nickname
      )}${CONTEXT_PATH}/systems/` +
        encodeURIComponent(connection.system.name) +
        `/sessions/${sessionId}`,
      { headers }
    );

    return request.pipe(
      catchError((error: HttpErrorResponse) => {
        throw { ...error.error, httpStatus: error.status };
      })
    );
  }

  /*
  releaseConnection(scriptId: string): void {
    // do nothing for now
  }

  shutdownAllPools(): void {
    // do nothing for now
  }
  */

  cancelQuery(scriptId: string): void {
    // do nothing for now
  }

  getFilePath(): Observable<string> {
    // do nothing for now
    return of('1');
  }

  downloadResultsAsCsv(
    connection: ISQLEConnection,
    query: IQueryPayload,
    filePath: string,
    resultIdx: number,
    csvConfig: CsvConfig
  ): Observable<any> {
    // do nothing for now
    return of(1);
  }

  cancelSaveResultsAsCsv(scriptId: string, resultIdx: number): Observable<any> {
    // do nothing for now
    return of(1);
  }

  getAllQueries(connection: ISQLEConnection): Observable<IQueryResultSet[]> {
    let headers: HttpHeaders = new HttpHeaders()
      .append('Accept', 'application/vnd.com.teradata.rest-v1.0+json')
      .append('Content-Type', 'application/json');
    if (connection.creds) {
      headers = headers.set('X-Auth-Credentials', `Basic ${connection.creds}`);
    }
    const request: Observable<IQueryResultSet[]> = this._http.get<
      IQueryResultSet[]
    >(
      `${PROTOCOL}${getVantageBaseURL(
        connection.system.nickname
      )}${CONTEXT_PATH}/systems/${connection.system.name}/queries`,
      { headers }
    );

    return request.pipe(
      catchError((error: HttpErrorResponse) => {
        throw { ...error.error, httpStatus: error.status };
      })
    );
  }

  getQueriesBySessionId(
    connection: ISQLEConnection,
    sessionId: string
  ): Observable<IQueryResultSet[]> {
    let headers: HttpHeaders = new HttpHeaders()
      .append('Accept', 'application/vnd.com.teradata.rest-v1.0+json')
      .append('Content-Type', 'application/json');
    if (connection.creds) {
      headers = headers.set('X-Auth-Credentials', `Basic ${connection.creds}`);
    }
    const request: Observable<IQueryResultSet[]> = this._http.get<
      IQueryResultSet[]
    >(
      `${PROTOCOL}${getVantageBaseURL(
        connection.system.nickname
      )}${CONTEXT_PATH}/systems/${
        connection.system.name
      }/queries?session=${sessionId}`,
      { headers }
    );

    return request.pipe(
      catchError((error: HttpErrorResponse) => {
        throw { ...error.error, httpStatus: error.status };
      })
    );
  }

  getAllDataBases(connection: ISQLEConnection): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders()
      .append('Accept', 'application/vnd.com.teradata.rest-v1.0+json')
      .append('Content-Type', 'application/json');
    if (connection.creds) {
      headers = headers.set('X-Auth-Credentials', `Basic ${connection.creds}`);
    }
    return this._http
      .get(
        `${PROTOCOL}${getVantageBaseURL(
          connection.system.nickname
        )}${CONTEXT_PATH}/systems/` +
          encodeURIComponent(connection.system.name) +
          '/databases',
        { headers }
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw { ...error.error, httpStatus: error.status };
        })
      );
  }
}

@Injectable({
  providedIn: 'root',
})
export class VantageSessionQueryService extends VantageQueryService {
  private _session$ = new Map<string, BehaviorSubject<string | null>>();

  constructor(_http: HttpClient) {
    super(_http);
  }

  private _connectionSession(connection: ISQLEConnection) {
    const sessionKey = connection.system.name;

    if (!this._session$.has(sessionKey)) {
      this._session$.set(sessionKey, new BehaviorSubject(null));
      this._createServerSession(connection);
    }

    return this._session$.get(sessionKey);
  }

  private _createServerSession(connection: ISQLEConnection) {
    const _sessionSubject = this._connectionSession(connection);
    let _sessionId = null;
    _sessionSubject.next(_sessionId);

    super
      .createSession(connection)
      .pipe(
        switchMap((results) => {
          _sessionId = results.sessionId;
          return super.querySystem(connection, {
            query: 'SET ROLE ALL',
            session: _sessionId,
          });
        })
      )
      .subscribe(() => {
        _sessionSubject.next(_sessionId);
      });
  }

  _getSessionID(connection: ISQLEConnection) {
    return this._connectionSession(connection).pipe(
      filter((session) => session !== null),
      take(1)
    );
  }

  override querySystem(
    connection: ISQLEConnection,
    payload: IQueryPayload
  ): Observable<IQueryResultSet> {
    return this._getSessionID(connection).pipe(
      map((sessionID) => (payload.session = sessionID)),
      switchMap(() => super.querySystem(connection, payload)),
      catchError((err) => {
        if (err.httpStatus === 404) {
          this._createServerSession(connection);
        }
        return throwError(() => new Error(err));
      }),
      retry(1)
    );
  }
}
