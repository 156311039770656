import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  IOrganizationConsumptionMini,
  OrganizationsService,
} from '@janus/organizations';

@Injectable()
export class AppTrialsRoutesGuard implements CanActivate {
  constructor(private _router: Router, private _orgs: OrganizationsService) {}

  // redirect any expired trials to the expiration page
  canActivate():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this._orgs.getMyOrganizationTrialStatus().pipe(
      switchMap((isTrials) => {
        if (isTrials) {
          return this._orgs.getMyOrganizationConsumptionMini().pipe(
            map((data: IOrganizationConsumptionMini) => {
              if (data.days_left > 0) {
                return true;
              }

              this._router.navigateByUrl('/trial-expired');
              return false;
            })
          );
        }

        return of(true);
      })
    );
  }
}
