import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HelpIconsService {
  queryGridTabs = [
    'fabric',
    'data-sources',
    'settings',
    'issues',
    'operations',
  ];

  getQueryGridHelpTab(url: string): string | undefined {
    let tab;
    for (let i = 0; i < this.queryGridTabs.length; i++) {
      if (url.includes('query-grid') && url.includes(this.queryGridTabs[i])) {
        tab = this.queryGridTabs[i];
        break;
      }
    }
    return tab === 'data-sources' ? 'data_sources' : tab;
  }
}
