import { InjectionToken, Provider } from '@angular/core';

export const STORAGE_KEY_PREFIX: InjectionToken<string[]> = new InjectionToken<
  string[]
>('TD_STORAGE_KEY_PREFIX');

export const STORAGE_KEY_PROVIDER: Provider = {
  provide: STORAGE_KEY_PREFIX,
  useValue: '__td__',
};
