import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface IPat {
  active: boolean;
  created: string;
  description?: string;
  environment_id?: string;
  expiration: string;
  last_exchanged?: string;
  org_id?: string;
  org_name?: string;
  owner?: string;
  reference_id?: string;
  service?: string;
}

export interface IPatListResponse {
  pats: IPat[];
}

@Injectable({
  providedIn: 'root',
})
export class PersonalAccessTokensService {
  private readonly PAT_URI = '/api/pats';

  constructor(private readonly httpClient: HttpClient) {}

  getPATDetails$(tokenId: string): Observable<IPat> {
    return this.httpClient.get<IPat>(`${this.PAT_URI}/${tokenId}`);
  }

  getOrgPATs$(): Observable<HttpResponse<IPatListResponse>> {
    const params: HttpParams = new HttpParams();

    return this._getPATs$(params);
  }

  getUserPATs$(user: string): Observable<HttpResponse<IPatListResponse>> {
    const params: HttpParams = new HttpParams().set('owner', user);

    return this._getPATs$(params);
  }

  revokeAllOrganizationPAT$(orgId: string): Observable<void> {
    const params: HttpParams = new HttpParams().set('org_id', orgId);

    return this._revokeMultiplePATs$(params);
  }

  revokeAllServicePAT$(service: string): Observable<void> {
    const params: HttpParams = new HttpParams().set('service', service);

    return this._revokeMultiplePATs$(params);
  }

  revokeAllUserPAT$(user: string): Observable<void> {
    const params: HttpParams = new HttpParams().set('owner', user);

    return this._revokeMultiplePATs$(params);
  }

  revokeSpecificPAT$(tokenId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.PAT_URI}/${tokenId}`);
  }

  private _getPATs$(
    params: HttpParams
  ): Observable<HttpResponse<IPatListResponse>> {
    return this.httpClient.get<IPatListResponse>(this.PAT_URI, {
      params,
      observe: 'response',
    });
  }

  private _revokeMultiplePATs$(params: HttpParams): Observable<void> {
    return this.httpClient.delete<void>(`${this.PAT_URI}`, { params });
  }
}
