import { FactoryProvider } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export declare type MatPaginatorIntlFactory = (
  service: any
) => MatPaginatorIntl;

export const paginatorI18nFactory: MatPaginatorIntlFactory = (
  translate: TranslateService
) => {
  const paginatorI18n: MatPaginatorIntl = new MatPaginatorIntl();
  const provideLabel = (key: string, params = {}): string => {
    const label = translate.instant(key, params);
    paginatorI18n.changes.next(); // notify the component that a change has been made

    return label;
  };

  const { ROWS_PER_PAGE, NEXT_PAGE, PREVIOUS_PAGE, FIRST_PAGE, LAST_PAGE } =
    translate.instant('PAGINATOR');
  paginatorI18n.itemsPerPageLabel = ROWS_PER_PAGE;
  paginatorI18n.nextPageLabel = NEXT_PAGE;
  paginatorI18n.previousPageLabel = PREVIOUS_PAGE;
  paginatorI18n.firstPageLabel = FIRST_PAGE;
  paginatorI18n.lastPageLabel = LAST_PAGE;
  paginatorI18n.changes.next();

  paginatorI18n.getRangeLabel = (
    page: number,
    pageSize: number,
    length: number
  ) => {
    length = Math.max(length, 0);
    pageSize = Math.max(pageSize, 0);

    const noResults = length === 0 || pageSize === 0;

    if (noResults || length <= pageSize) {
      return provideLabel('PAGINATOR.NUMBER_OF_TOTAL', {
        number: noResults ? 0 : length,
        total: length,
      });
    }

    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;

    return provideLabel('PAGINATOR.RANGE_OF_TOTAL', {
      start: startIndex + 1,
      end: endIndex,
      total: length,
    });
  };

  return paginatorI18n;
};

export const paginatorI18nProvider: FactoryProvider = {
  provide: MatPaginatorIntl,
  useFactory: paginatorI18nFactory,
  deps: [TranslateService],
};
