import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TdGhostLoadingComponent } from './ghost-loading.component';

@NgModule({
  imports: [CommonModule],
  declarations: [TdGhostLoadingComponent],
  exports: [TdGhostLoadingComponent],
})
export class TdGhostLoadingModule {}
