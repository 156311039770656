import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class OrgAdminGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService
  ) {}

  canActivate(): boolean {
    if (this._authenticationService.isOrgAdmin()) {
      return true;
    } else {
      this._router.navigateByUrl('/');
      return false;
    }
  }
}
