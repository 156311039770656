import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  IOrganizationConsumptionMini,
  OrganizationsService,
} from '@janus/organizations';
import { NavItem, NavItems } from '../utils/nav-rail-data';
import { NavService } from '../utils/nav.service';

@Component({
  selector: 'shared-nav-content-expanded',
  templateUrl: './nav-content-expanded.component.html',
  styleUrls: ['./nav-content-expanded.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavContentExpandedComponent implements OnChanges {
  @Input() navItems: NavItems;
  @Input() isTrials: boolean;

  consumption: IOrganizationConsumptionMini;

  constructor(
    public router: Router,
    private _navService: NavService,
    private _orgService: OrganizationsService,
    private _cd: ChangeDetectorRef
  ) {}

  anyChildActive(navItem: NavItem) {
    return navItem.children.some((item) =>
      this.router.isActive(item.route, false)
    );
  }

  navigate(navItem: NavItem): void {
    this._navService.navigate(navItem);
  }

  getParentClasses(
    navItem: NavItem,
    isExpanded: boolean,
    isIcon: boolean = false
  ): string {
    const parentIsActiveClasses = this.isActiveRoute(navItem);
    const childIsActiveClasses = this.isActiveRouteInChildren(navItem);

    if (isExpanded) {
      if (isIcon) {
        return parentIsActiveClasses || childIsActiveClasses || '';
      }

      return parentIsActiveClasses;
    }

    if (isIcon) {
      return parentIsActiveClasses || childIsActiveClasses || '';
    }

    // we don't want to make the text "accented" if a child is currently
    // selected and the parent is closed, but we do want the background color
    return (
      parentIsActiveClasses ||
      childIsActiveClasses.replace('tc-accent', '') ||
      ''
    );
  }

  isActiveRoute(navItem: NavItem): string {
    return this._navService.isActiveRoute(navItem, true);
  }

  isActiveRouteInChildren(navItem: NavItem): string {
    return this._navService.isActiveRouteInChildren(navItem);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isTrials } = changes;
    if (
      isTrials &&
      isTrials.currentValue &&
      isTrials.currentValue !== isTrials.previousValue
    ) {
      this._orgService
        .getMyOrganizationConsumptionMini()
        .subscribe((remaining: IOrganizationConsumptionMini) => {
          this.consumption = remaining;
          this._cd.markForCheck();
        });
    }
  }
}
