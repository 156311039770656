import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, catchError, map, Observable } from 'rxjs';
import { ApiService } from '../../common/api.service';

export interface IUserRoleMapping {
  id: string;
  name: string;
  description?: string;
  lastModified: string;
  userMapping?: any;
  roleMapping?: any;
}

export interface IUserMapping {
  initiatingUser: string;
  targetUser: string;
}

export interface IRoleMapping {
  initiatingRole: string;
  targetRole: string;
}

export interface IMappingsAddEditData {
  userMapping: IUserMapping;
  roleMapping: IRoleMapping;
  mappingIndex: number;
  isUser: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UserRoleMappingService {
  // To Transfer user mapping data between user role mapping edit and mapping add edit components
  private userMappingData: IUserMapping[] = [];

  // Subscription used by user role mapping edit component
  //  on add/edit of user mappings data from mappings add edit component
  //  for User Mappings
  private userMappingChangedBS: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  userMappingChanged$ = this.userMappingChangedBS.asObservable();

  // To Transfer  role mapping data between user role mapping edit and mapping add edit components
  private roleMappingData: IRoleMapping[] = [];

  // Subscription used by user role mapping edit component
  //  on add/edit of user mappings data from mappings add edit component
  //  for Role Mappings
  private roleMappingChangedBS: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  roleMappingChanged$ = this.roleMappingChangedBS.asObservable();

  private userRoleMappingChangedBS: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  userRoleMappingChanged$ = this.userRoleMappingChangedBS.asObservable();

  constructor(private _http: HttpClient, private _apiService: ApiService) {}

  // Creates a new user role mapping
  public createUserRoleMapping(
    userRoleMapping: IUserRoleMapping
  ): Observable<any> {
    return this._http
      .post(this.getBaseUrl() + '/config/user-mappings', userRoleMapping)
      .pipe(
        map((resp: any) => {
          this.userRoleMappingChangedBS.next(true);
          return resp;
        }),
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  // Retrieves all the user and role mappings present
  public getUserRoleMappingsSummary(): Observable<IUserRoleMapping[]> {
    return this._http
      .get<any>(this.getBaseUrl() + '/config/user-mappings')
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  // Updates an existing user role mapping
  public updateUserRoleMapping(
    userRoleMapping: IUserRoleMapping
  ): Observable<any> {
    return this._http
      .put(
        this.getBaseUrl() + '/config/user-mappings/' + userRoleMapping.id,
        userRoleMapping
      )
      .pipe(
        map((resp: any) => {
          this.userRoleMappingChangedBS.next(true);
          return resp;
        }),
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  // Deletes a specific user role mapping
  public deleteUserRoleMapping(id: string): Observable<any> {
    return this._http
      .delete<any>(this.getBaseUrl() + '/config/user-mappings/' + id)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  public getUserMappings(): IUserMapping[] {
    return this.userMappingData;
  }

  public setUserMappings(userMappings: IUserMapping[]) {
    if (userMappings) this.userMappingData.push(...userMappings);
  }

  /**
   * Updates the user mappings with the changed(added/edited) mapping data from the mappings-add-edit component
   *
   * @param updatedUserMappings
   * @param isEditUser
   * @param mappingIndex
   */
  public updateUserMappings(
    updatedUserMappings: IUserMapping[],
    isEditUser: boolean,
    mappingIndex: number
  ) {
    if (isEditUser) {
      this.userMappingData[mappingIndex] = updatedUserMappings[0];
    } else {
      this.userMappingData.push(...updatedUserMappings);
    }
    this.userMappingChangedBS.next(true);
  }

  public getRoleMappings(): IRoleMapping[] {
    return this.roleMappingData;
  }

  public setRoleMappings(roleMappings: IRoleMapping[]) {
    if (roleMappings) this.roleMappingData.push(...roleMappings);
  }

  /**
   * Updates the role mappings with the changed(added/edited) mapping data from the mappings-add-edit component
   *
   * @param updatedRoleMappings
   * @param isEditRole
   * @param mappingIndex
   */
  public updateRoleMappings(
    updatedRoleMappings: IRoleMapping[],
    isEditRole: boolean,
    mappingIndex: number
  ) {
    if (isEditRole) {
      this.roleMappingData[mappingIndex] = updatedRoleMappings[0];
    } else {
      this.roleMappingData.push(...updatedRoleMappings);
    }
    this.roleMappingChangedBS.next(true);
  }

  public clearMappingsData() {
    this.userMappingData = [];
    this.userMappingChangedBS.next(false);
    this.roleMappingData = [];
    this.roleMappingChangedBS.next(false);
  }

  getBaseUrl(): string {
    return this._apiService.getBaseUrl();
  }
}
