import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface IRevokeDialogInput {
  dialogMessage: string; // should be ready to pass to translate pipe
  dialogTitle: string; // should be ready to pass to translate pipe
}

@Component({
  selector: 'token-revoke-dialog',
  templateUrl: './revoke-dialog.component.html',
  styleUrls: ['./revoke-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RevokeDialogComponent {
  constructor(
    private readonly dialogRef: MatDialogRef<RevokeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: IRevokeDialogInput
  ) {}

  onCancel(): void {
    this.dialogRef.close();
  }

  callRevoke(): void {
    this.dialogRef.close(true);
  }
}
