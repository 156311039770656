import {
  ChangeDetectorRef,
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { FeatureFlagService } from './flags.service';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[tdFlag]',
})
export class FlagsDirective {
  constructor(
    private _flagService: FeatureFlagService,
    private _vc: ViewContainerRef,
    private _template: TemplateRef<any>,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  @Input() set tdFlag(flag: string) {
    this._vc.clear();
    this._flagService
      .check(flag)
      .pipe(take(1))
      .subscribe((status: boolean) => {
        if (status) {
          this._vc.createEmbeddedView(this._template);
          this._changeDetectorRef.markForCheck();
        } else {
          this._vc.clear();
        }
      });
  }
}
