import { Component, Input, OnInit } from '@angular/core';
import {
  IOrganizationConsumptionMini,
  OrganizationsService,
} from '@janus/organizations';
import { TranslateService } from '@ngx-translate/core';

import { EXPANDED_RAIL_KEY, NavItems } from './utils/nav-rail-data';
import { FeatureFlagService } from '@td/vantage-flags';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-nav-rail',
  templateUrl: './nav-rail.component.html',
  styleUrls: ['./nav-rail.component.scss'],
  host: { class: 'z-3 nav-rail' },
})
export class NavRailComponent implements OnInit {
  @Input() expandedRail = false;
  @Input() navItems: NavItems;
  @Input() notificationCount: number;

  isTrials: boolean;
  consumption: IOrganizationConsumptionMini;

  askAiEnabled$: Observable<boolean>;

  constructor(
    private _translateService: TranslateService,
    private _orgService: OrganizationsService,
    private _featureFlagService: FeatureFlagService
  ) {}

  ngOnInit(): void {
    this._orgService
      .getMyOrganizationTrialStatus()
      .subscribe((status: boolean) => {
        this.isTrials = status;
        if (this.isTrials) {
          this.getConsumptionMini();
        }
      });

    this.askAiEnabled$ = this._featureFlagService.check('ask_ai');
  }

  toggleRail() {
    this.expandedRail = !this.expandedRail;
    window.sessionStorage.setItem(EXPANDED_RAIL_KEY, `${this.expandedRail}`);
  }

  getConsumptionMini(): void {
    this._orgService
      .getMyOrganizationConsumptionMini()
      .subscribe((consumption: IOrganizationConsumptionMini) => {
        this.consumption = consumption;
      });
  }
}
