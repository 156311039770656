import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TdEmptyStateComponent } from './empty-state.component';
import { IconModule } from '@td/ui/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [TdEmptyStateComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    IconModule,
    TranslateModule.forChild(),
  ],
  exports: [TdEmptyStateComponent],
})
export class TdEmptyStateModule {}
