import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { ICloudPlatform } from '@janus/querygrid';
import { INewUser } from '@janus/users';
import { TranslateService } from '@ngx-translate/core';

import { translateAPIMessages } from '@janus/translate';

export interface IOrganizationAccount {
  account_id: string;
  pod_id: string;
  public_ip_address: string;
}

export interface INewOrganization {
  name: string;
  domain_name: string;
  description: string;
  grpn: string;
  private_pods: boolean;
  admin_user: INewUser;
}

export interface IOrganization {
  id: string;
  name: string;
  domain_name: string;
  grpn?: string;
  description: string;
  private_pods: boolean;
  erp?: string;
  bank_name?: string;
  regions?: string[];
  operating_mode: string;
  geo_unit_location?: string;
  hibernate_date?: string;
  trials?: boolean;
  platform?: string;
}

export interface IOrganizationOptions {
  domain_suffix: string;
}

export interface IOrganizationConsumptionMini {
  days_left: number;
  units_left: number | string;
}

export interface IOrganizationAggregateConsumption {
  org_id: string;
  raw_storage_qty: number;
  ofs_storage_qty: number;
  sum_ttl_pog_unit_utlztn_nbr: number;
  sum_ttl_cw_unit_utlztn_nbr: number;
  sum_ttl_compute_unit_utlztn_nbr: number;
  ttl_unit_consumed: number;
  term_strt_dt: string;
  term_end_dt: string;
  unit_qty: number;
  unit_remaining: number;
  unit_usd_prcnt: number;
}

export interface IOrganizationConsumptionData {
  pocMode: boolean;
  consumption: IOrganizationConsumption;
}

export interface IOrganizationConsumption {
  org_id: string;
  disk_storage_committed: number;
  disk_storage_provisioned: number;
  environment_consumption: [];
  last_update: string;
  ofs_storage_committed: number;
  ofs_storage_provisioned: number;
  term_strt_date: string;
  term_end_date: string;
  total_compute_cluster_units_consumed: number;
  total_primary_cluster_units_consumed: number;
  total_units_consumed: number;
  units_remaining: number | null;
  organization_total_monthly_consumption: [];
}

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {
  private myOrgBS = new BehaviorSubject<IOrganization>(undefined);
  private consumptionBS = new BehaviorSubject<IOrganizationConsumption>(
    undefined
  );

  constructor(
    private _http: HttpClient,
    private _translate: TranslateService
  ) {}

  getOrganizationOptions(): Observable<IOrganizationOptions> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const request: Observable<any> = this._http.get(
      '/api/organization-options',
      {
        headers,
      }
    );

    return request.pipe(
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      }),
      map(translateAPIMessages),
      map((organizationOptions: IOrganizationOptions) => {
        return organizationOptions;
      })
    );
  }

  getOrganizations(): Observable<IOrganization[]> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const request: Observable<any> = this._http.get('/api/organizations', {
      headers,
    });

    return request.pipe(
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      }),
      map(translateAPIMessages),
      map((organizations: IOrganization[]) => {
        return organizations;
      })
    );
  }

  getOrganization(id: string): Observable<IOrganization> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const request: Observable<any> = this._http.get(
      '/api/organizations/' + id,
      {
        headers,
      }
    );

    return request.pipe(
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      }),
      map(translateAPIMessages),
      map((organization: IOrganization) => {
        return organization;
      })
    );
  }

  getMyOrganization(): Observable<IOrganization> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const request: Observable<any> = this._http.get('/api/my-organization', {
      headers,
    });

    return request.pipe(
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      }),
      map(translateAPIMessages),
      map((organization: IOrganization) => {
        this.myOrgBS.next(organization);
        return organization;
      })
    );
  }

  getMyOrganizationConsumptionMini(): Observable<IOrganizationConsumptionMini> {
    // use cached org data and consumption data to save on calls
    const org$ = this.myOrgBS.value ? this.myOrgBS : this.getMyOrganization();
    const consumption$ = this.consumptionBS.value
      ? this.consumptionBS
      : this.getMyOrganizationConsumption();

    return combineLatest([
      org$.pipe(map((data) => data?.hibernate_date)),
      consumption$.pipe(
        map((data: IOrganizationConsumption) => data?.units_remaining),
        catchError(() => of(null))
      ),
    ]).pipe(
      take(1),
      map(([date, units]: [string, number | null]) => {
        const end_date = new Date(date);
        // assume that end_date is a future date...
        const diff = end_date.valueOf() - Date.now();
        // convert ms to days
        let days_left = Math.ceil(diff / 1000 / 60 / 60 / 24);
        // no negative days, please
        days_left = days_left > 0 ? days_left : 0;

        // the API uses 'null' to indicate errors in fetching remaining units
        const units_left =
          units !== null && units !== undefined
            ? units.toFixed(2)
            : this._translate.instant('PENDING');

        return {
          days_left,
          units_left,
        };
      })
    );
  }

  getMyOrganizationConsumption(): Observable<IOrganizationConsumption> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const request: Observable<any> = this._http.get(
      '/api/my-organization/consumption',
      {
        headers,
      }
    );

    return request.pipe(
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      }),
      map(translateAPIMessages),
      map((organizationConsumption: IOrganizationConsumption) => {
        this.consumptionBS.next(organizationConsumption);
        return organizationConsumption;
      })
    );
  }

  getMyOrganizationCredits(): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');

    const request = this._http.get('/api/my-organization/credits', {
      headers,
    });

    return request.pipe(
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      }),
      map((response: number) => {
        return response;
      })
    );
  }

  getMyOrganizationPlatform(): Observable<ICloudPlatform> {
    // use cached org data to save on calls
    const org$ = this.myOrgBS.value ? this.myOrgBS : this.getMyOrganization();

    return org$.pipe(
      map((org) => {
        const platform = ICloudPlatform[org.platform.toUpperCase()];

        if (!platform) {
          throw new Error(`Unknown platform: ${org.platform}`);
        }

        return platform;
      })
    );
  }

  getMyOrganizationTrialStatus(): Observable<boolean> {
    // use cached org data to save on calls
    const org$ = this.myOrgBS.value ? this.myOrgBS : this.getMyOrganization();

    return org$.pipe(
      map((org) => org.operating_mode),
      // mock this for testing
      // map(() => true)
      // when not testing, use this instead
      map((mode: string) => mode === 'trial')
    );
  }

  getMyPlatformIsAWS(): Observable<boolean> {
    return this.getMyOrganizationPlatform().pipe(
      map((platform: ICloudPlatform) => platform === ICloudPlatform.AWS)
    );
  }

  getMyPlatformIsAzure(): Observable<boolean> {
    return this.getMyOrganizationPlatform().pipe(
      map((platform: ICloudPlatform) => platform === ICloudPlatform.AZURE)
    );
  }
}
