import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import {
  IDraggableRefs,
  ResizableDraggableDialog,
  TdDialogService,
} from '@covalent/core/dialogs';
import { CovalentSideSheetRef } from '@covalent/core/side-sheet';
import { AccountsService, IAccount, RUNNING_STATES } from '@janus/accounts';
import { TranslateService } from '@ngx-translate/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  filter,
  forkJoin,
  interval,
  map,
  merge,
  mergeMap,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import {
  AiServiceStatus,
  AskAiService,
  AskAiSidesheetOptions,
  CHAT_LOCAL_STORAGE_KEY,
  IMessage,
} from '../ask-ai.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { AuthenticationService } from '@janus/authentication';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TosDialogComponent } from './tos-dialog/tos-dialog.component';
import { DOCUMENT } from '@angular/common';
import { DragRef } from '@angular/cdk/drag-drop';

interface AiStatus {
  aiStatus: AiServiceStatus;
}

type AccountWithAiStatus = IAccount & AiStatus;

const defaultSnackbarConfig: MatSnackBarConfig = {
  horizontalPosition: 'center',
  duration: 4000,
};

@Component({
  selector: 'ask-ai-sidesheet',
  templateUrl: './ask-ai-sidesheet.component.html',
  styleUrls: ['./ask-ai-sidesheet.component.scss'],
})
export class AskAiSidesheetComponent implements OnDestroy, OnInit {
  private readonly destroy$ = new Subject<boolean>();
  loadingAccounts$ = new BehaviorSubject(true);

  accounts: AccountWithAiStatus[] = [];
  AiServiceStatuses = AiServiceStatus;
  state = 'not_enabled'; // not_enabled, chat
  selectedAccount: AccountWithAiStatus;
  chatIsDirty = false;
  termsAgreedTo = false;
  isAdminUser = false;
  chatHistory: IMessage[];

  @HostBinding('class.enabled') get enabled() {
    return this.state === 'chat';
  }

  @HostListener('document:keyup.escape')
  onEscape(): void {
    this.close();
  }

  constructor(
    private accountsService: AccountsService,
    private authService: AuthenticationService,
    private askAiService: AskAiService,
    private dialogRef: CovalentSideSheetRef<AskAiSidesheetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AskAiSidesheetOptions,
    private dialogService: TdDialogService,
    private translate: TranslateService,
    private snackbar: MatSnackBar,
    @Inject(DOCUMENT) private document: any,
    private renderer2: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.accountsService
      .getAccounts()
      .pipe(
        map((accounts) =>
          accounts.filter((a) => RUNNING_STATES.includes(a.account_state))
        ),
        mergeMap((accounts: IAccount[]) => {
          if (accounts.length === 0) {
            return of([]);
          }

          return forkJoin(
            accounts.map((a) =>
              this.askAiService.getAIServiceStatus(a.account_id)
            )
          ).pipe(
            map((statuses) =>
              accounts.map((account, index) => {
                return {
                  ...account,
                  aiStatus: statuses[index],
                } as AccountWithAiStatus;
              })
            )
          );
        }),
        tap((accounts) => {
          this.loadingAccounts$.next(false);
          accounts.forEach((acct) => {
            if (
              !!this.data.preselectAccountName &&
              acct.account_name === this.data.preselectAccountName
            ) {
              this.selectAccount(acct);
            }

            if (acct.aiStatus === AiServiceStatus.Provisioning) {
              this.setupPollingForInProgressAccounts(
                acct.account_id,
                acct.account_name
              );
            }
          });
        })
      )
      .subscribe((output) => (this.accounts = output));

    this.isAdminUser =
      this.authService.isOrgAdmin() || this.authService.isSuperUser();

    this.renderer2.listen(this.el.nativeElement, 'click', (e) => {
      if (
        e.target.tagName === 'A' &&
        e.target.parentElement.id === 'terms-checkbox-label'
      ) {
        this.openTOS();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  selectAccount(account: AccountWithAiStatus): void {
    this.selectedAccount = account;
    if (
      this.selectedAccount.aiStatus ===
      this.AiServiceStatuses.CreatedSuccessfully
    ) {
      this.snackbar.dismiss();
      this.state = 'chat';
    }

    if (this.data.preselectAccountName) {
      const chatHistory = localStorage.getItem(
        `${CHAT_LOCAL_STORAGE_KEY}-${this.selectedAccount.account_name}`
      );
      if (chatHistory) {
        this.setChatHistory(JSON.parse(chatHistory));
      }
    }
  }

  setChatHistory(chatHistory: IMessage[]): void {
    this.chatHistory = chatHistory;
  }

  backToList(): void {
    this.selectedAccount = undefined;
    this.termsAgreedTo = false;
  }

  enable(accountId: string): void {
    const account = this.accounts.find((acct) => acct.account_id === accountId);
    account.aiStatus = AiServiceStatus.Provisioning;

    this.selectedAccount = undefined;

    this.askAiService
      .provisionAiService(accountId)
      .pipe(
        map((status) => {
          if (status === AiServiceStatus.Error) {
            this.snackbar.open(
              this.translate.instant('ASK_AI.PROVISIONING_ERROR', {
                environment: account.account_name,
              }),
              undefined,
              defaultSnackbarConfig
            );
            account.aiStatus = status;
            return false;
          }
          this.snackbar.open(
            this.translate.instant('ASK_AI.PROVISIONING_ON_ENV', {
              environment: account.account_name,
            }),
            undefined,
            defaultSnackbarConfig
          );

          return true;
        })
      )
      .subscribe((success) => {
        if (success) {
          this.setupPollingForInProgressAccounts(
            accountId,
            account.account_name
          );
        }
      });
  }

  setupPollingForInProgressAccounts(
    accountId: string,
    accountName: string
  ): void {
    this.pollAiServiceStatus(accountId, accountName).subscribe((status) => {
      this.accounts = this.accounts.map((acct) => {
        if (acct.account_id === accountId) {
          acct.aiStatus = status;
        }
        return acct;
      });
    });
  }

  pollAiServiceStatus(
    accountId: string,
    accountName: string
  ): Observable<AiServiceStatus> {
    const stopPolling = new Subject();
    return interval(5000).pipe(
      takeUntil(merge(this.destroy$, stopPolling)),
      switchMap(() => this.askAiService.getAIServiceStatus(accountId)),
      filter((status) => status === AiServiceStatus.CreatedSuccessfully),
      tap(() => {
        stopPolling.next(true);
        const snackBarRef = this.snackbar.open(
          this.translate.instant('ASK_AI.PROVISIONING_COMPLETE', {
            environment: accountName,
          }),
          this.translate.instant('ASK_AI.PROVISIONING_COMPLETE_GOTO'),
          { ...defaultSnackbarConfig, duration: 10000 }
        );

        snackBarRef.onAction().subscribe(() => {
          this.selectedAccount = this.accounts.find(
            (acct) => acct.account_id === accountId
          );
          this.state = 'chat';
        });
      })
    );
  }

  setChatDirty(): void {
    this.chatIsDirty = true;
  }

  close(): void {
    if (this.data.saveChatHistory && this.chatHistory) {
      localStorage.setItem(
        `${CHAT_LOCAL_STORAGE_KEY}-${this.selectedAccount.account_name}`,
        JSON.stringify(this.chatHistory)
      );
    }

    this.chatHistory = null;

    if (!this.chatIsDirty || this.data.saveChatHistory) {
      this.dialogRef.close();
      return;
    }

    this.dialogService
      .openConfirm({
        title: this.translate.instant('ASK_AI.CONFIRM_CLOSE'),
        message: this.translate.instant('ASK_AI.CONFIRM_CLOSE_MESSAGE'),
        cancelButton: this.translate.instant('ASK_AI.CANCEL'),
        acceptButton: this.translate.instant('ASK_AI.CLOSE'),
        width: '400px',
        isDestructive: true,
        panelClass: 'ask-ai-confirm-close',
      })
      .afterClosed()
      .subscribe((close) => {
        if (close) {
          this.dialogRef.close();
        }
      });

    document
      .querySelector('.ask-ai-confirm-close')
      ?.setAttribute('data-cy', 'ask-ai-close-dialog');
  }

  openTOS(): void {
    const { matDialogRef, dragRefSubject }: IDraggableRefs<TosDialogComponent> =
      this.dialogService.openDraggable({
        component: TosDialogComponent,
        dragHandleSelectors: ['mat-toolbar'],
        config: {
          panelClass: ['td-window-dialog'],
          width: '800px',
        },
      });

    matDialogRef.componentInstance.closed
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => matDialogRef.close());

    let resizableDraggableDialog: ResizableDraggableDialog;
    dragRefSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe((dragRf: DragRef) => {
        resizableDraggableDialog = new ResizableDraggableDialog(
          this.document,
          this.renderer2,
          matDialogRef,
          dragRf
        );
      });

    // Detach resize-ability event listeners after dialog closes
    matDialogRef
      .afterClosed()
      .subscribe(() => resizableDraggableDialog.detach());
  }
}
