import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { IConnectable } from './query.service';

@Injectable({
  providedIn: 'root',
})
export class VantageConnectableService {
  private _connectable$: BehaviorSubject<IConnectable[]> = new BehaviorSubject<
    IConnectable[]
  >([]);

  getConnectableEntities(): Observable<IConnectable[]> {
    return this._connectable$.asObservable();
  }

  getCurrentConnectables(): IConnectable[] {
    return this._connectable$.value;
  }

  setConnectableEntities(entities: IConnectable[]): void {
    this._connectable$.next(entities);
  }
}
