import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TdDialogService } from '@covalent/core/dialogs';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { VantageConnectionService } from './connection.service';
import { VantageCredentialsDialogComponent } from './credentials-dialog/credentials-dialog.component';
import { ISQLEConnection } from './query.service';

@Injectable({
  providedIn: 'root',
})
export class VantageConnectionMonitorService {
  private _credDialog: MatDialogRef<VantageCredentialsDialogComponent>;
  private isDBCAccount: boolean = false;

  constructor(
    private _connectionService: VantageConnectionService,
    private _dialogService: TdDialogService
  ) {}

  getConnection(accountName?: string): Observable<boolean> {
    this._credDialog = this._dialogService.open(
      VantageCredentialsDialogComponent,
      {
        id: 'vui-sqle-credentials-dialog',
        width: '500px',
        disableClose: false,
      }
    );
    if (accountName) {
      this._credDialog.componentInstance.singleSystemName = accountName;
    }
    this._credDialog.componentInstance.basicAuthEnabled = true;
    return this._credDialog.afterClosed().pipe(
      map((result: ISQLEConnection) => {
        let success = false;
        if (result?.creds) {
          success = true;
          if (result.username.toLowerCase() === 'dbc') {
            this.isDBCAccount = true;
          }
        }
        this._credDialog = undefined;

        return success;
      })
    );
  }

  isDBC(): boolean {
    return this.isDBCAccount;
  }

  hasConnection(isDBUser = false): Observable<boolean> {
    return this._connectionService.currentConnection$.pipe(
      map((connection) => !!(connection || isDBUser)),
      tap((connected) => {
        if (!connected) {
          this.isDBCAccount = false; // reset isDBCAccount when no connection
        }
      })
    );
  }
}
