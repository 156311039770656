import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TdDialogService } from '@covalent/core/dialogs';
import {
  IDatasetLoadingJobs,
  LoadAssetDialogComponent,
  LoadAssetService,
} from '@janus/help';
import { IUserProfile, ProfileService } from '@janus/profile';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss'],
})
export class NotificationDialogComponent {
  constructor(
    private _dialogRef: MatDialogRef<NotificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _profileService: ProfileService,
    private _dialogService: TdDialogService,
    private _loadAssetService: LoadAssetService
  ) {}

  private loadAssetsDialog: MatDialogRef<LoadAssetDialogComponent>;

  async clearAll(): Promise<void> {
    const profile: IUserProfile = await lastValueFrom(
      this._profileService.getUserProfile()
    );

    const completedJobs: IDatasetLoadingJobs[] = JSON.parse(
      profile.dataset_loading_completed
    );
    if (completedJobs.length !== 0) {
      profile.dataset_loading_completed = JSON.stringify([]);
      await lastValueFrom(this._profileService.updateUserProfile(profile));
      this.data = [];
      this._loadAssetService._loadAssetSubject.next(true);
    }
  }
  closeDialog(): void {
    this._dialogRef.close();
  }

  async clearNotification(job): Promise<void> {
    const profile: IUserProfile = await lastValueFrom(
      this._profileService.getUserProfile()
    );
    const completedJobs: IDatasetLoadingJobs[] = JSON.parse(
      profile.dataset_loading_completed
    );
    const index = completedJobs.findIndex(
      (ele: IDatasetLoadingJobs) => (ele.jobId = job.jobId)
    );

    if (index != -1) {
      completedJobs.splice(index, 1);
      this.data.splice(index, 1);
      profile.dataset_loading_completed = JSON.stringify(completedJobs);
      await lastValueFrom(this._profileService.updateUserProfile(profile));
      this._loadAssetService._loadAssetSubject.next(true);
    }
  }

  async viewDetails(job): Promise<void> {
    const profile: IUserProfile = await lastValueFrom(
      this._profileService.getUserProfile()
    );

    const completedJobs: IDatasetLoadingJobs[] = JSON.parse(
      profile.dataset_loading_completed
    );

    this.loadAssetsDialog = this._dialogService.open(LoadAssetDialogComponent, {
      disableClose: true,
      width: '500px',
      data: {
        showStatus: true,
        accountId: job.accountId,
        jobId: job.jobId,
      },
    });

    const index = completedJobs.findIndex(
      (ele: IDatasetLoadingJobs) => (ele.jobId = job.jobId)
    );

    if (index != -1) {
      completedJobs.splice(index, 1);
      profile.dataset_loading_completed = JSON.stringify(completedJobs);
      await lastValueFrom(this._profileService.updateUserProfile(profile));
      this.closeDialog();
      this._loadAssetService._loadAssetSubject.next(true);
    }
  }
}
