import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

import { VantageConnectableService } from '../connectable.service';
import { VantageConnectionService } from '../connection.service';
import { VantageEditorConnectionService } from '../editor-connection.service';
import { IConnectable, ISQLEConnection } from '../query.service';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'vantage-sqle-credentials-dialog',
  templateUrl: './credentials-dialog.component.html',
  styleUrls: ['./credentials-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VantageCredentialsDialogComponent implements OnInit {
  loadingBS$ = new BehaviorSubject<boolean>(true);
  loading$ = this.loadingBS$.asObservable();

  get system$(): Observable<any> {
    return this._connectableService.getConnectableEntities().pipe(
      map((connections) => {
        //empty list so show dialog box to create environment
        if (connections.length === 0) {
          return null;
        }

        // configured for only one system
        if (this.singleSystemName) {
          const singleSystem = connections.find(
            (system) => system.name === this.singleSystemName
          );
          if (singleSystem) {
            connections = [singleSystem];
          }
        }

        //set default if not set and form is enabled
        const nickname = this.connectionForm.get('system.nickname');
        if (!nickname.valid && !nickname.disabled) {
          this.updateSystem(connections[0]);
        }

        // Hide the loading spinner after the connections load
        this.loadingBS$.next(false);

        //return list
        return connections;
      })
    );
  }

  get connecting(): boolean {
    return this.connectionForm.valid && this.connectionForm.disabled;
  }

  // some lame properties that shouldn't exist but external components set them
  // convert to inputs if we need to continue to support these
  basicAuthEnabled = false;
  showDefaultDb = false;
  singleSystemName: string | null = null;
  setAsCurrent = true;
  isDesktop = environment.app_type === 'desktop';

  // form controls move to typed forms in ng14
  // ConnectionForm will created an ISQLEConnection
  connectionForm = new FormGroup({
    // system is an IConnectable
    system: new FormGroup({
      name: new FormControl(''),
      nickname: new FormControl('', Validators.required),
      purpose: new FormControl(''),
      siteURL: new FormControl(''),
      host: new FormControl(''),
      systemId: new FormControl(''),
    }),
    // logmech gets added an IConnectable system attribute
    logmech: new FormControl('TD2'),
    // basicCredentials are added as optional creds
    basicCredentials: new FormGroup({
      username: new FormControl(''),
      password: new FormControl(''),
    }),
    // database is optional defaultDb
    database: new FormControl(''),
  });

  showPassword = false;
  errorMsg: string | null = null;

  constructor(
    private _dialogRef: MatDialogRef<VantageCredentialsDialogComponent>,
    private _connectionService: VantageConnectionService,
    private _cdr: ChangeDetectorRef,
    private _translate: TranslateService,
    private _connectableService: VantageConnectableService,
    private _router: Router,
    private _editorConnectionService: VantageEditorConnectionService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (this.isDesktop) {
      this.loadingBS$.next(false);
    }
  }

  syncName(value: string) {
    this.connectionForm.patchValue({
      system: { nickname: value },
    });
  }

  updateSystem(value: IConnectable) {
    // this doesn't handle changing logmech
    this.connectionForm.patchValue({
      system: value,
    });
  }

  //compareFunction for select dropdown
  compareSystemWith(a: IConnectable, b: string): boolean {
    return a && b && a.nickname === b;
  }

  async connect(): Promise<void> {
    try {
      this.connectionForm.disable();
      this.errorMsg = undefined;
      const connection: ISQLEConnection = this.getConnection();

      // connection complexity
      if (this.data?.isEditor) {
        if (
          this._editorConnectionService.exists(connection) &&
          this.setAsCurrent
        ) {
          await lastValueFrom(
            this._editorConnectionService.setAsCurrent(connection)
          );
        } else {
          await lastValueFrom(
            this._editorConnectionService.add(connection, this.setAsCurrent)
          );
        }

        if (connection.defaultDb) {
          const databaseExists: boolean = await lastValueFrom(
            this._editorConnectionService.databaseExists(
              connection,
              connection.defaultDb
            )
          );
          if (!databaseExists) {
            this._editorConnectionService.remove(connection);
            throw Error(this._translate.instant('DEFAULT_DATABASE_NO_EXISTS'));
          }
        }
      } else {
        await lastValueFrom(
          this._connectionService.addAndSetAsCurrent(connection)
        );
      }

      this._dialogRef.close(connection);
    } catch (error) {
      this.errorMsg = error.message;
      this.connectionForm.enable();
    } finally {
      this.connectionForm.enable();
      this._cdr.markForCheck();
    }
  }

  exitToCreateAccount(): void {
    this._dialogRef.close();
    this._router.navigate(['/environments/provision']);
  }

  cancel(): void {
    this._dialogRef.close();
  }

  private getConnection(): ISQLEConnection {
    const connection: ISQLEConnection = {
      system: this.connectionForm.get('system').value,
    };

    // logmech should be promoted to a first class citizen
    connection.system.system_attributes = {
      attributes: {
        logmech: this.connectionForm.get('logmech').value,
      },
    };

    if (this.basicAuthEnabled) {
      connection.username = this.connectionForm.get(
        'basicCredentials.username'
      ).value;
      connection.creds = btoa(
        `${connection.username}:${
          this.connectionForm.get('basicCredentials.password').value
        }`
      );
      connection.full_creds = btoa(
        `{"username":"${connection.username}","password":"${
          this.connectionForm.get('basicCredentials.password').value
        }"}`
      );
    }

    if (this.showDefaultDb) {
      connection.defaultDb = this.connectionForm.get('database').value;
    }

    return connection;
  }
}
