import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganizationsService } from '@janus/organizations';

@Injectable()
export class AppAzureRoutesGuard implements CanActivate {
  constructor(private _router: Router, private _orgs: OrganizationsService) {}

  // Bounce any Azure users back to the home page
  canActivate(): Observable<boolean | UrlTree> {
    return this._orgs
      .getMyPlatformIsAzure()
      .pipe(map((isAzure) => (isAzure ? this._router.parseUrl('/') : true)));
  }
}
