import { Injectable } from '@angular/core';

export enum ColumnType {
  ACTIONS = 'actions',
  BOOLEAN = 'boolean',
  DATE = 'date',
  ENUM = 'enum',
  ENUM_INCLUDE_ONLY = 'enum_include_only',
  NUMERIC = 'numeric',
  STATUS = 'status',
  STRING = 'string',
}

export enum FilterType {
  AFTER = 'after',
  BEFORE = 'before',
  CONTAINS = 'contains',
  IS = 'is',
  IS_NOT = 'is_not',
}

export interface ISelectedFilter {
  columnId: string;
  filterType: FilterType;
  filterValue: string;
  filterAccessor?: (val: any) => any;
  index?: number;
}

export interface IValueChoice {
  value: string;
  label: string;
}

export interface IColumn {
  id: string;
  name: string;
  type: string; // one day, we should use ColumnType
  displayed?: boolean;
  excludeFromFiltering?: boolean;
  statusChoices?: string[]; // used for the status type
  valueChoices?: IValueChoice[]; // used with booleans and emums
  accessor?: (val: any) => any; // used for extracting values for display/filtration/sorting
  index?: number;
}

export const FILTER_TYPES: unknown = {
  boolean: [FilterType.IS],
  date: [FilterType.CONTAINS, FilterType.IS], // should be before and after
  enum: [FilterType.IS, FilterType.IS_NOT],
  enum_include_only: [FilterType.IS],
  numeric: [FilterType.CONTAINS, FilterType.IS],
  status: [FilterType.IS, FilterType.IS_NOT],
  string: [FilterType.CONTAINS, FilterType.IS],
};

@Injectable({
  providedIn: 'root',
})
export class TdAttributeFilterService {
  filterData(data, filters: ISelectedFilter[]) {
    filters.forEach((filter: ISelectedFilter) => {
      if (filter.filterType) {
        data = this._filterByFilterType(
          data,
          filter.columnId,
          filter.filterType,
          filter.filterValue,
          filter.filterAccessor
        );
      }
    });

    return data;
  }

  private _filterByFilterType(
    data: unknown[],
    column: string,
    filterType: FilterType,
    filterVal: string,
    filterAccessor?
  ) {
    data = data.filter((d) => {
      d[column] =
        typeof d[column] === 'number' ? d[column].toString() : d[column];
      const columnValue = d[column]
        ? filterAccessor
          ? filterAccessor(d[column]).toLowerCase()
          : d[column].toLowerCase()
        : '';
      filterVal = filterVal ? filterVal.toLowerCase() : '';

      switch (filterType) {
        case FilterType.IS:
          return columnValue === filterVal;
          break;

        case FilterType.CONTAINS:
          return columnValue.includes(filterVal);
          break;

        case FilterType.IS_NOT:
          return columnValue !== filterVal;
          break;
      }

      return true;
    });
    return data;
  }
}
