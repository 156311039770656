import { Injectable } from '@angular/core';
import {
  CovalentSideSheet,
  CovalentSideSheetRef,
} from '@covalent/core/side-sheet';
import { AskAiSidesheetComponent } from './ask-ai-sidesheet/ask-ai-sidesheet.component';
import { Observable, catchError, map, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

export interface IMessage {
  text: string;
  from: 'user' | 'ai';
  timestamp: Date;
}

export interface AIServiceDetails {
  model_deployments: {
    gpt_model_status: string;
    ada_model_status: string;
  };
  service_name: string;
  service_status: string;
}

export enum AiServiceStatus {
  CreatedSuccessfully = 'created_successfully',
  NotProvisioned = 'not_provisioned',
  Provisioning = 'pending',
  Error = 'error',
}

export interface AskAiSidesheetOptions {
  width?: string;
  preselectAccountName?: string;
  saveChatHistory?: boolean;
}

const askAiOptionsDefaults: AskAiSidesheetOptions = {
  width: '800px',
  preselectAccountName: '',
  saveChatHistory: false,
};

export const CHAT_LOCAL_STORAGE_KEY = 'ask-ai-chat';

@Injectable({
  providedIn: 'root',
})
export class AskAiService {
  constructor(
    private sidesheet: CovalentSideSheet,
    private http: HttpClient,
    private translate: TranslateService
  ) {}

  openAskAi(
    options: AskAiSidesheetOptions = {}
  ): CovalentSideSheetRef<AskAiSidesheetComponent> {
    const opts = { ...askAiOptionsDefaults, ...options };

    return this.sidesheet.open(AskAiSidesheetComponent, {
      width: opts.width,
      data: opts,
      disableClose: true,
    });
  }

  getAIServiceStatus(accountId: string): Observable<AiServiceStatus> {
    return this.http
      .get<AIServiceDetails>(`/api/accounts/${accountId}/ai`)
      .pipe(
        map((resp) => {
          if (
            resp.model_deployments?.ada_model_status ===
              AiServiceStatus.CreatedSuccessfully &&
            resp.model_deployments?.gpt_model_status ===
              AiServiceStatus.CreatedSuccessfully
          ) {
            return AiServiceStatus.CreatedSuccessfully;
          }

          // if not both models CreatedSuccessfully and not error resp, service must be provisioning
          return AiServiceStatus.Provisioning;
        }),
        catchError((err) => {
          if (err.status === 404) {
            return of(AiServiceStatus.NotProvisioned);
          }

          return of(AiServiceStatus.Error);
        })
      );
  }

  provisionAiService(accountId: string): Observable<any> {
    return this.http.post(`/api/accounts/${accountId}/ai`, undefined).pipe(
      map(() => AiServiceStatus.Provisioning),
      catchError(() => {
        return of(AiServiceStatus.Error);
      })
    );
  }

  postQuestion(accountId: string, question: string): Observable<string> {
    return this.http
      .post(`/api/accounts/${accountId}/ai/questions`, { question })
      .pipe(
        map((res: any) => res.answer),
        catchError((err) => {
          if (err.status === 504) {
            return of(this.translate.instant('ASK_AI.TIMEOUT'));
          }
          console.log(err);
          return of(this.translate.instant('ASK_AI.UNABLE_TO_REACH_SERVICE'));
        })
      );
  }
}
