export const environment: {
  production: boolean;
  data_dog_app_id: string;
  data_dog_client_token: string;
  account_create_more_info: string;
  flow_help_links: { [id: string]: string };
  compute_groups_learn_more: string;
  help_ids: { [id: string]: string };
  env_name: string;
} = {
  production: true,
  data_dog_app_id: 'd9c0dc70-6291-4e03-ad72-1f532e51af05',
  data_dog_client_token: 'pub502e3e4f982d42653648c3dee23e43a1',
  account_create_more_info:
    'https://docs.teradata.com/r/Teradata-VantageCloud-Lake/Getting-Started/Signing-On-and-Getting-Started',
  flow_help_links: {
    explore_thumbnail: 'assets/images/flows_thumbnail.png',
    explore_video: 'assets/videos/flows_explore.mp4',
    learn_more:
      'https://docs.teradata.com/r/Teradata-VantageCloud-Lake/Loading-Data/Introduction-to-Flow-AWS',
    flow_authorization_doc:
      'https://docs.teradata.com/access/sources/dita/topic?dita:topicPath=opp1680103532746.dita',
  },
  compute_groups_learn_more:
    'https://docs.teradata.com/r/Teradata-VantageCloud-Lake/Using-VantageCloud-Lake-Console-to-Manage-VantageCloud-Lake/Managing-Environments',
  help_ids: {
    accessmanagementenvidprealms: 'mxq1680183881642',
    accessmanagementenvusers: 'wxe1659392685092',
    accessmanagementaccesstokens: 'mwx1694115292045',
    accessmanagementidp: 'whf1680184025148',
    accessmanagementrealms: 'ruf1680184116601',
    accounts: 'sbt1640280496980',
    environmentcreate: 'qiv1640281527006',
    accountcomputegroups: 'mqu1640280532737',
    accountnetwork: 'laq1640280582810',
    accountoverview: 'sbt1640280496980',
    accountkeyconcepts: 'nmr1658424425362',
    accountqueries: 'ajr1640280560519',
    computegroupkeyconcepts: 'ixo1658772101054',
    consumption: 'onj1682104977691',
    dataprotection: 'jrq1640280690304',
    editoridafunction: 'iql1691540875799',
    flows: 'auw1640280669500',
    flowcreate: 'luq1640282345986',
    flowupdate: 'dod1691610081069',
    flowsource: 'npn1691594431074',
    flowtarget: 'npn1691594431074',
    flowschemahints: 'npn1691594431074',
    flowfaq: 'dxg1691603081375',
    organizationaccounts: 'sbt1640280496980',
    organizationadmins: 'hrv1640281410572',
    organizationconfiguration: 'apw1640280478763',
    queries: 'ajr1640280560519',
    querybuilder: 'vkk1692059193535',
    usecases: 'bkm1640280721917',
    managecomputegroups: 'sqv1658772608902',
    // deepcode ignore NoHardcodedPasswords: this is a help ID, not credential based
    users: 'arh1640280750562',
    streams: 'auw1640280669500',
  },
  env_name: 'production',
};
