import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@janus/authentication';
import { translateAPIMessages } from '@janus/translate';
import { VantageEditorConnectionService } from '@td/vantage/query';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

export enum AccountStates {
  NOT_DEPLOYED = 'not_deployed',
  DEPLOYING = 'deploying',
  DEPLOYING_FAILED = 'deploying_failed',
  DEPLOYING_ROLLED_BACK = 'deploying_rolled_back',
  FAILED_TO_ATTACH_TO_POG_ROUTER = 'failed_to_attach_to_pog_router',
  RUNNING = 'running',
  HIBERNATING = 'hibernating',
  HIBERNATING_FAILED = 'hibernating_failed',
  HIBERNATED = 'hibernated',
  STARTING = 'starting',
  STARTING_FAILED = 'starting_failed',
  TERMINATING = 'terminating',
  TERMINATING_FAILED = 'terminating_failed',
  TERMINATED = 'terminated',
  NO_LONGER_AVAILABLE = 'no_longer_available',
  UNREACHABLE = 'unreachable',
}

export enum PrivateLinkJobStatus {
  FAILED = 'FAILED',
  FINISHED = 'FINISHED',
  RUNNING = 'RUNNING',
}

export enum PrivateLinkStatus {
  HEALTHY = 'HEALTHY',
  UNHEALTHY = 'UNHEALTHY',
  UNKNOWN = 'UNKNOWN',
}

export interface IInstanceSize {
  amps_per_node: number[];
  size: string;
}

export interface IAccountOptions {
  account_type: string[];
  az_ids?: Map<string, string[]>;
  compute_workers: boolean[];
  entitlement: string[];
  instance_options: {
    count_max: number;
    count_min: number;
    sizes: IInstanceSize[];
    storage_max: number;
    storage_min: number;
  };
  platform: string[];
  region: string[];
}

export interface INewAccount {
  account_name: string;
  account_type: string;
  amps_per_node?: number;
  az_id?: string;
  cmek_platform_resource_id?: string;
  instance_size: string;
  instance_count: number;
  total_storage: number;
  platform: string;
  region: string;
  dbc_password: string;
  compute_workers: boolean;
  entitlement: string;
  realm_id?: string;
}

export interface IAccount {
  account_id: string;
  account_name: string;
  account_state: string;
  account_type: string;
  availability_zone?: string;
  az_id?: string;
  cidrs: string[];
  cloud_account?: string;
  cmek?: {
    cmek_updated_at?: string;
    cmek_tags: ICmekTags;
    cmek_policy_statements: ICmekPolicyStatement[];
  };
  compute_workers: boolean;
  compute_workers_job_status?: string;
  database_state: string;
  entitlement: string;
  instance_count: number;
  instance_size: string;
  platform: string;
  pod_account_id?: string;
  pod_id?: string;
  public_ip: string;
  realm_id?: string;
  region: string;
  runtime_operation?: {
    operation: string;
    status: string;
  };
  site_id?: string;
  total_storage: number;
}

export interface ICmekTags {
  'teracloud:account': string;
  'teracloud:pod:id': string;
}

// TODO: update when swagger is up to date
export interface ICmekPolicyStatement {
  Action: string[];
  Condition: any;
  Effect: string;
  Principal: {
    AWS: string[];
  };
  Resource: string;
  Sid: string;
}

export interface IPOGRouterRequest {
  enable: boolean;
  accounts?: string[];
}
export interface IPOGRouterResponse {
  az_ids: any[];
  endpoint: string;
  health?: any;
  status: PrivateLinkStatus;
}
export interface IPrivateLinkConfigPOG {
  pogrouter: IPOGRouterRequest;
}
export interface IPrivateLinkResponsePOG {
  pogrouter: IPOGRouterResponse;
}
export interface IPrivateLinkConfiguration {
  private_link: IPrivateLinkConfigPOG;
}
export interface IPrivateLinkResponse {
  private_link: IPrivateLinkResponsePOG;
}
export interface IPrivateLinkDetails {
  configuration: IPrivateLinkConfiguration;
  job_state: PrivateLinkJobStatus;
  last_job_id: string;
  outputs: IPrivateLinkResponse;
  updated_at: number;
}

export interface IUseCase {
  dataset_name: string;
  parameters: {
    User: string;
    Password: string;
  };
}

export const ALLOW_ALL_IPS = '0.0.0.0/0';
export const CW_PENDING_STATES = ['adding', 'removing'];
export const DEPLOYING_STATES = ['not_deployed', 'deploying'];
export const RUNNING_STATES = ['running'];
export const RESERVED_STATES = ['reserved', 'validating'];
export const DEPLOY_FAILED_STATES = [
  'deploying_failed',
  'deploying_rolled_back',
];
export const HIBERNATED_STATES = [
  'hibernating',
  'hibernating_failed',
  'hibernated',
];
export const NO_LONGER_AVAILABLE_STATES = [
  'failed_to_attach_to_pog_router',
  'no_longer_available',
  'unavailable',
];
export const DATABASE_AVAILABLE = ['active', 'restore'];
export const DATABASE_UNAVAILABLE = [
  'unrecoverable',
  'disconnected',
  'interrupted',
  'oos',
];
export const OPTIMIZING_FAILED_STATES = ['optimizing_failed'];

export const CAN_HIBERNATE_STATES = ['running'];

export const CAN_RESTORE_STATES = ['hibernated'];

export const CANNOT_DELETE_STATES = [
  'not_deployed',
  'deploying',
  'hibernating',
  'optimizing',
  'running',
  'starting',
  'terminating',
];

export const IN_PROGRESS_STATES = ['hibernating', 'restoring', 'optimizing'];

export const UNREACHABLE_STATES = [AccountStates.UNREACHABLE];

const SUMMARY_ACCEPT_TYPE =
  'application/vnd.com.teradata.accounts.summary+json';

const DETAILS_ACCEPT_TYPE =
  'application/vnd.com.teradata.accounts.details+json';

@Injectable({
  providedIn: 'root',
})
export class AccountsService {
  private accountCache = new Map<string, IAccount>();
  cmekCompletedSubject: Subject<boolean> = new Subject();
  constructor(
    private _http: HttpClient,
    private _connectionService: VantageEditorConnectionService,
    private _authenticationService: AuthenticationService
  ) {}

  addComputeWorkers(accountId: string): Observable<any> {
    return this._http
      .post(`/api/accounts/${accountId}/compute_workers`, {})
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  removeComputeWorkers(accountId: string): Observable<any> {
    return this._http
      .delete(`/api/accounts/${accountId}/compute_workers`, {})
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  addAccountsToPrivateLink(
    accountId: string,
    accounts: string[]
  ): Observable<any> {
    return this._http
      .patch(`/api/accounts/${accountId}/private-link`, {
        private_link: {
          pogrouter: {
            accounts,
          },
        },
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  deleteCIDRs(accountId: string): Observable<any> {
    const url = `/api/accounts/${accountId}`;

    return this._http
      .patch(url, [
        {
          op: 'remove',
          path: 'customer_cidrs',
        },
      ])
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  saveCIDRs(accountId: string, cidrs: string[]): Observable<any> {
    const url = `/api/accounts/${accountId}`;

    return this._http
      .patch(url, [
        {
          op: 'update',
          path: 'customer_cidrs',
          value: cidrs,
        },
      ])
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  disablePrivateLink(accountId: string): Observable<any> {
    const payload: IPrivateLinkConfiguration = {
      private_link: {
        pogrouter: {
          enable: false,
        },
      },
    };
    const url = `/api/accounts/${accountId}/private-link`;

    return this._http.patch(url, payload).pipe(
      catchError((error: HttpErrorResponse) => {
        // if we've PATCHed where we should have PUT, then correct it
        if (error.status === 404) {
          return this._http.put(url, payload).pipe(
            catchError((err: HttpErrorResponse) => {
              throw Object.assign({}, err.error, { httpStatus: err.status });
            })
          );
        }
        throw Object.assign({}, error.error, { httpStatus: error.status });
      })
    );
  }

  enablePrivateLink(accountId: string, list?: string[]): Observable<any> {
    const payload: IPrivateLinkConfiguration = {
      private_link: {
        pogrouter: {
          enable: true,
        },
      },
    };
    if (list && list.length) {
      payload.private_link.pogrouter.accounts = list;
    }
    const url = `/api/accounts/${accountId}/private-link`;

    // to allow saving both public and private, we need to make the correct
    // call, otherwise private-link setting doesn't take
    return this.getAccountPrivateLink(accountId).pipe(
      switchMap((details: IPrivateLinkDetails) => {
        if (details) {
          return this._http.patch(url, payload);
        }

        return this._http.put(url, payload);
      }),
      catchError((err: HttpErrorResponse) => {
        throw Object.assign({}, err.error, { httpStatus: err.status });
      })
    );
  }

  getAccountOptions(): Observable<IAccountOptions> {
    return (
      this._http
        // choosing not to translate this currently as what comes back from here
        // (in "English") are the values that _must_ be sent back; not the
        // translated version
        .get<IAccountOptions>('/api/account-options')
        .pipe(
          // map((translateData) => translateAPIMessages(translateData)),
          catchError((error: HttpErrorResponse) => {
            throw Object.assign({}, error.error, { httpStatus: error.status });
          })
        )
    );
  }

  getAccount(
    accountId: string,
    summary?: boolean,
    forceRefresh?: boolean
  ): Observable<IAccount> {
    if (this.accountCache.has(accountId) && !forceRefresh) {
      return of(this.accountCache.get(accountId));
    }

    let headers: HttpHeaders = new HttpHeaders();
    headers = summary
      ? headers.set('Accept', SUMMARY_ACCEPT_TYPE)
      : headers.set('Accept', DETAILS_ACCEPT_TYPE);

    return this._http.get(`/api/accounts/${accountId}`, { headers }).pipe(
      map((translateData) => translateAPIMessages(translateData)),
      tap((account: IAccount) => {
        this.accountCache.set(accountId, account);
      }),
      map((account: IAccount) => {
        return account;
      }),
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      })
    );
  }

  getAccountPrivateLink(accountId: string): Observable<IPrivateLinkDetails> {
    return this._http.get(`/api/accounts/${accountId}/private-link`).pipe(
      map((privatelink: IPrivateLinkDetails) => {
        return privatelink;
      }),
      catchError((error: HttpErrorResponse) => {
        // 404 here means just enable the Create button
        if (error.status === 404) {
          return of(undefined);
        }
        throw error;
      }),
      catchError((error: HttpErrorResponse) => {
        // if slot is not configured, just return a blank disabled detail
        if (/slot is not configured/i.test(error.error.detail)) {
          return of({
            configuration: {
              private_link: {
                pogrouter: {
                  enable: false,
                },
              },
            },
            job_state: undefined,
            last_job_id: undefined,
            outputs: {
              private_link: {
                pogrouter: {
                  az_ids: [],
                  endpoint: undefined,
                  status: undefined,
                },
              },
            },
            updated_at: undefined,
          });
        }
        throw Object.assign({}, error.error, { httpStatus: error.status });
      })
    );
  }

  getAccounts(orgId?: string, summary?: boolean): Observable<IAccount[]> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = summary
      ? headers.set('Accept', SUMMARY_ACCEPT_TYPE)
      : headers.set('Accept', DETAILS_ACCEPT_TYPE);

    const url = orgId ? `/api/accounts?org=${orgId}` : '/api/accounts';
    return this._http.get(url, { headers }).pipe(
      map((translateData) => translateAPIMessages(translateData)),
      map((accounts: IAccount[]) => {
        // set name cache
        accounts.forEach((account) => {
          this.accountCache.set(account.account_id, account);
        });

        // Since status isn't returned on summary, cannot filter out terminating or terminated accounts
        if (summary) {
          return accounts;
        }
        // Filter out terminating and terminated accounts
        accounts = accounts
          ? accounts.filter(
              (account) => !account.account_state.startsWith('terminat')
            )
          : [];
        return accounts;
      }),
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      })
    );
  }

  createAccount(newAccount: INewAccount): Observable<string> {
    return this._http.post<string>('/api/accounts', newAccount).pipe(
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      })
    );
  }

  canHibernateAccount(accountState: string): boolean {
    return CAN_HIBERNATE_STATES.includes(accountState);
  }

  hibernateAccount(accountId: string): Observable<void> {
    return this._http
      .patch<void>(`/api/accounts/${accountId}`, [
        {
          op: 'update',
          path: 'account_state',
          value: 'hibernated',
        },
      ])
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  canRestoreAccount(accountState: string): boolean {
    return CAN_RESTORE_STATES.includes(accountState);
  }

  restoreAccount(accountId: string): Observable<void> {
    return this._http
      .patch<void>(`/api/accounts/${accountId}`, [
        {
          op: 'update',
          path: 'account_state',
          value: 'running',
        },
      ])
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  canDeleteAccount(accountState: string): boolean {
    return !CANNOT_DELETE_STATES.includes(accountState);
  }

  deleteAccount(accountId: string): Observable<void> {
    return this._http.delete<void>(`/api/accounts/${accountId}`).pipe(
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      })
    );
  }

  loadUseCaseDataset(accountId: string, data): Observable<any> {
    return this._http
      .post(`/api/accounts/${accountId}/use-case-dataset-job`, data, {
        headers: this.getHeaders(),
      })
      .pipe(
        map((loadAssetRes: any) => {
          return loadAssetRes;
        }),
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  getUseCaseDataset(accountId: string, jobId: string): Observable<any> {
    return this._http
      .get(`/api/accounts/${accountId}/use-case-dataset-job/${jobId}`, {
        headers: this.getHeaders(),
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  removeRealm(accountId: string): Observable<any> {
    return this._http
      .patch(`/api/accounts`, [
        {
          path: `/${accountId}/realm_id`,
          op: 'remove',
        },
      ])
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  updateRealm(accountId: string, realmId: string): Observable<any> {
    return this._http
      .patch(`/api/accounts`, [
        {
          path: `/${accountId}/realm_id`,
          op: 'update',
          value: realmId,
        },
      ])
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  updateEnvironmentSetting(
    accountId: string,
    orgId: string,
    formField: string,
    newValue: string | number
  ): Observable<void> {
    const params: HttpParams = new HttpParams().set('org', orgId);

    return this._http
      .patch<void>(
        `/api/accounts/${accountId}`,
        [
          {
            op: 'update',
            path: formField,
            value: newValue,
          },
        ],
        {
          params,
        }
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  startCmekProvisioning(accountId: string): Observable<void> {
    return this._http
      .patch<void>(`/api/accounts/${accountId}`, [
        {
          op: 'update',
          path: 'account_state',
          value: 'provisioning',
        },
      ])
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  deleteEnvironmentReservation(accountId: string): Observable<void> {
    return this._http
      .delete<void>(`/api/environment-reservations/${accountId}`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  updateCmek(accountId: string, keyArn: string): Observable<void> {
    return this._http
      .patch<void>(`/api/accounts/${accountId}`, [
        {
          op: 'update',
          path: 'cmek',
          value: keyArn,
        },
      ])
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  getHeaders(): HttpHeaders {
    if (this._authenticationService.isDBUser()) {
      return undefined;
    }
    return new HttpHeaders({
      'X-Auth-Credentials': `Basic ${this._connectionService.currentConnection?.creds}`,
    });
  }
}
