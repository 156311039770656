// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const require: any;

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { AccountsModule } from '@janus/accounts';
import {
  AuthenticationInterceptor,
  AuthenticationModule,
} from '@janus/authentication';
import { CogsModule } from '@janus/cogs';
import { NavigationModule } from '@janus/navigation';
import {
  initTranslateService,
  startTranslateService,
  LanguageInterceptor,
  getSupportedLanguages,
  addTranslation,
  LocalizationModule,
} from '@janus/translate';
import { paginatorI18nProvider } from '@janus/ui/table';

import { ThemeModule } from '@janus/theme';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes';
import { AppRoutesGuard } from './app.routes.guard';
import { AppTrialsRoutesGuard } from './app.trials.guard';
import { AppAzureRoutesGuard } from './app.azure.guard';

import packageInfo from '../../../../package.json';
import { environment } from '@janus/organizations';
import { HelpModule } from '@janus/help';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    AccountsModule,
    AuthenticationModule,
    CogsModule,
    NavigationModule,
    AppRoutingModule,
    ThemeModule,
    HelpModule,
    LocalizationModule,
    TranslateModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true,
    },
    AppRoutesGuard,
    AppTrialsRoutesGuard,
    AppAzureRoutesGuard,
    paginatorI18nProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  public static datadogRum: any;

  constructor(translateService: TranslateService) {
    initTranslateService(translateService);

    getSupportedLanguages().forEach((lang: string) => {
      let translateFile: any;
      let objectBrowserTranslateFile: any;
      let tableTranslateFile: any;

      try {
        translateFile = require(`../assets/i18n/${lang}.json`);
      } catch (err: any) {
        translateFile = {};
      }
      try {
        objectBrowserTranslateFile = require(`../../../../libs/vantage/object-browser/assets/i18n/${lang}.json`);
      } catch (err: any) {
        objectBrowserTranslateFile = {};
      }
      try {
        tableTranslateFile = require(`../../../../libs/ui/table/assets/i18n/${lang}.json`);
      } catch (err: any) {
        tableTranslateFile = {};
      }

      addTranslation(translateService, lang, {
        ...translateFile,
        ...objectBrowserTranslateFile,
        ...tableTranslateFile,
      });
    });

    startTranslateService(translateService);

    this.setupDataDog();
  }

  async setupDataDog(): Promise<void> {
    if (localStorage.getItem('allow_analytics') === 'true') {
      AppModule.datadogRum = await (
        await import('@datadog/browser-rum')
      ).datadogRum;

      AppModule.datadogRum.init({
        applicationId: environment.data_dog_app_id,
        clientToken: environment.data_dog_client_token,
        trackInteractions: true,
        service: packageInfo.name,
        version: packageInfo.version,
        env: environment.env_name,
      });
    }
  }
}
