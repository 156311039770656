import { ICloudPlatform } from '../regions/regions.service';

export enum ISystemType {
  'TERADATA' = 'TERADATA',
  'HADOOP' = 'HADOOP',
  'OTHER' = 'OTHER',
}

export enum IDataSourceType {
  'TERADATA' = 'TERADATA',
  'VANTAGECORE' = 'VANTAGECORE',
  'VANTAGECLOUD_ENTERPRISE' = 'VANTAGECLOUD_ENTERPRISE',
  'APACHE_HIVE' = 'HIVE',
  'APACHE_SPARK' = 'SPARK',
  'GENERICJDBC' = 'GENERICJDBC',
  'CLOUDERA_HIVE' = 'CLOUDERA_HIVE',
  'CLOUDERA_SPARK' = 'CLOUDERA_SPARK',
  'HDINSIGHT_HIVE' = 'HDINSIGHT_HIVE',
  'HDINSIGHT_SPARK' = 'HDINSIGHT_SPARK',
}

export enum IExecutionType {
  'THRIFT_SERVER' = 'THRIFTSERVER',
  'SPARK_SUBMIT' = 'SPARK-SUBMIT',
}

export interface IQGSystemDetails {
  id?: string;
  name: string;
  description?: string;
  softwareVersion: string;
  systemType?: ISystemType;
  platformType: ICloudPlatform;
  region?: string;
  dataCenterId: string;
  enableOverridePort?: boolean;
  overridePort?: number;
  internalNetworkId?: string;
  maximumMemoryPerNode?: number;
  bridgeOnly?: boolean;
  enableProxy?: boolean;
  proxyPort?: number;
  proxySupportType?: string;
  proxyNetworkId?: string;
  proxySystemId?: string;
  autoNodeDelete?: boolean;
  autoNodeDeleteMinutes?: number;
  _extraInfo?: {
    nodesOnline: number;
    nodesOffline: number;
  };
  tags?: {
    network_object_id: string;
    secretName?: string;
    antaresType?: string;
    subSystemType?: IDataSourceType;
  };
}

export interface IQGSystem extends IQGSystemDetails {
  datasourceType: ISystemType;
  connectorType: IDataSourceType;
  clusterOption: string;
  connectorName: string;
  connectorSoftwareVersion?: string;
  customerBridgeId?: string;
  bridgeNetworkId?: string;
  networkId?: string;
  targetSystem?: {
    userMappingId: string;
  };
}

export interface IDataSourceSpark extends IQGSystem {
  datasourceType: ISystemType.HADOOP;
  systemType: ISystemType.HADOOP;
  connectorType: IDataSourceType.APACHE_SPARK;
}

export interface IDataSourceHive extends IQGSystem {
  datasourceType: ISystemType.HADOOP;
  systemType: ISystemType.HADOOP;
  connectorType: IDataSourceType.APACHE_HIVE;
}

export interface IDataSourceTeradata extends IQGSystem {
  datasourceType: ISystemType.TERADATA;
  connectorType: IDataSourceType.TERADATA;
  systemType: ISystemType.TERADATA;
}

export interface IQGConnectorPropertiesCommon {
  server: string;
  port: number;
  hadoopLibPath: string;
  confFilePaths: string;
  sparkExecutionType?: IExecutionType;
  sparkHome: string;
  hadoopProperties: string;
  enableQueryLogging?: boolean;
}

// Cloudera Spark
export interface IQGConnectorPropertiesCPPSpark {
  server: string;
  port: number;
  hadoopLibPath: string;
  confFilePaths: string;
  sparkExecutionType: IExecutionType.SPARK_SUBMIT;
  authUserName: 'hive';
  authPassword: 'hive';
  databaseName: string;
  hadoopProperties?: string;
}
export interface ICPPSpark extends IDataSourceSpark {
  connectorProperties: IQGConnectorPropertiesCPPSpark;
}

// Cloudera Hive
export interface IQGConnectorPropertiesCPDHive {
  server: string;
  port: number;
  hadoopLibPath: string;
  confFilePaths: string;
  sparkExecutionType: string;
  sparkHome: string;
  hiveEngine: 'tez';
  authUserName: 'hive';
  authPassword: 'hive';
  databaseName: string;
  hadoopProperties?: string;
}
export interface ICPDHive extends IQGSystem {
  datasourceType: ISystemType.HADOOP;
  connectorType: IDataSourceType.APACHE_HIVE;
  systemType: ISystemType.HADOOP;
  connectorProperties: IQGConnectorPropertiesCPDHive;
}

// Apache Hive
export interface IEMRHive extends IDataSourceHive {
  connectorProperties: IQGConnectorPropertiesCommon;
}

// Apache Spark
export interface IQGConnectorPropertiesEMRSpark {
  server: string;
  port: number;
  hadoopLibPath: string;
  confFilePaths: string;
  sparkExecutionType: IExecutionType;
  sparkHome: string;
  hadoopProperties: string;
  enableQueryLogging?: boolean;
}
export interface IEMRSpark extends IDataSourceSpark {
  connectorProperties: IQGConnectorPropertiesEMRSpark;
}
// HDInsight Hive
export interface IQGConnectorPropertiesHDInsightHive {
  server: string;
  port: number;
  hadoopLibPath: string;
  confFilePaths: string;
  sparkExecutionType: string;
  sparkHome: string;
}

export interface IHDInsightHive extends IDataSourceHive {
  connectorProperties: IQGConnectorPropertiesHDInsightHive;
}

// HDInsight Spark
export interface IQGConnectorPropertiesHDInsightSpark {
  server: string;
  port: number;
  confFilePaths: string;
  sparkExecutionType: IExecutionType;
  sparkHome: string;
  databaseName: string;
  sparkAdditionalJarPaths: string;
  sparkCustomJars: string;
  authUserName: string;
  authPassword: string;
}
export interface IHDInsightSpark extends IDataSourceSpark {
  connectorProperties: IQGConnectorPropertiesHDInsightSpark;
}

// Generic JDBC
export interface IQGConnectorPropertiesJDBC {
  jdbcUrl: string;
  enableQueryLogging?: boolean;
}

export interface IJDBC extends IQGSystem {
  datasourceType: ISystemType.OTHER;
  connectorType: IDataSourceType.GENERICJDBC;
  systemType: ISystemType.OTHER;
  connectorProperties: IQGConnectorPropertiesJDBC;
}

//Teradata Vantage
export interface ITeradataVantage extends IDataSourceTeradata {
  connectorProperties: IQGConnectorPropertiesCommon;
}

//Teradata Vantage Enterprise
export interface ITeradataVantageEnterprise extends IDataSourceTeradata {
  connectorProperties: IQGConnectorPropertiesCommon;
}

export type IQGSystemAndConnector =
  | ICPPSpark
  | ICPDHive
  | IEMRSpark
  | IEMRHive
  | IHDInsightHive
  | IHDInsightSpark
  | IJDBC
  | ITeradataVantage
  | ITeradataVantageEnterprise;
