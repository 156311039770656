import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISQLEConnection, VantageConnectionService } from '@td/vantage/query';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthenticationService } from '@janus/authentication';

export interface IComputeGroup {
  name: string;
  description?: string;
  query_strategy?: string;
  panelOpened?: boolean;
}

export enum ScalingPolicy {
  BASIC = 'BASIC',
  STANDARD = 'STANDARD',
  EXTENDED = 'EXTENDED',
}

export enum InstanceType {
  STANDARD = 'STANDARD',
  UDF = 'UDF',
  GPU = 'GPU',
}

export enum ProfileStates {
  HIBERNATED = 'Hibernated',
  INITIALIZING = 'Initializing',
  RESUMING = 'Resuming',
  RUNNING = 'Running',
  SUSPENDING = 'Suspending',
  SUSPENDED = 'Suspended',
  UNREACHABLE = 'Unreachable',
  INVALID_STATE = 'Invalid State',
}

export interface IComputeGroupProfile {
  name: string;
  group_name: string;
  instance: string;
  scaling_policy?: string;
  instance_type?: string;
  min_instance_count?: number;
  max_instance_count?: number;
  initially_suspended?: boolean;
  // note: these two should be formatted as CRON times
  start_time?: string;
  end_time?: string;
  resource_limit?: string;
  cooldown_period?: number;
  compute_profile_state?: ProfileStates;
}

export interface IComputeGroupProfileOptions {
  available_instance_credits: number;
  instance_count_min: number;
  instance_count_max: number;
  instance_sizes: string[];
}

export enum OP {
  'add' = 'add',
  'remove' = 'remove',
}

export interface IAddRemoveOp {
  op: OP;
  value: string;
}

const catchHttpErrors = catchError((error: HttpErrorResponse) => {
  throw Object.assign({}, error.error, { httpStatus: error.status });
});

export interface IUserComputeGroup {
  name: string;
  is_admin: boolean;
  is_member: boolean;
}

export interface ICGEnvironmentProfileOptions {
  available_group_credits?: number;
  query_strategies: string[];
}

@Injectable({
  providedIn: 'root',
})
export class CogsService {
  constructor(
    private readonly _http: HttpClient,
    private _authenticationService: AuthenticationService,
    public _connectionService: VantageConnectionService
  ) {}

  get credentials(): string {
    return this._connectionService.currentConnection?.creds;
  }

  // Returns IComputeGroup[]
  getGroups(
    accountId: string,
    connection: ISQLEConnection = null
  ): Observable<any> {
    return this._http
      .get(`/api/accounts/${accountId}/computegroups`, {
        headers: this.getHeaders(connection?.creds),
      })
      .pipe(
        map((computeGroupsResponse: any) => {
          return computeGroupsResponse || [];
        }),
        catchHttpErrors
      );
  }

  createGroup(accountId: string, details: IComputeGroup): Observable<any> {
    return this._http
      .put(
        `/api/accounts/${accountId}/computegroups/${details.name}`,
        details,
        {
          headers: this.getHeaders(),
        }
      )
      .pipe(catchHttpErrors);
  }

  deleteGroup(accountId: string, groupId: string): Observable<any> {
    return this._http
      .delete(`/api/accounts/${accountId}/computegroups/${groupId}`, {
        headers: this.getHeaders(),
      })
      .pipe(catchHttpErrors);
  }

  upsertProfile(
    accountId: string,
    details: IComputeGroupProfile
  ): Observable<any> {
    return this._http
      .put(
        `/api/accounts/${accountId}/computegroups/${details.group_name}/computeprofiles/${details.name}`,
        details,
        { headers: this.getHeaders() }
      )
      .pipe(catchHttpErrors);
  }

  getProfiles(accountId: string, groupName: string): Observable<any> {
    return this._http
      .get(
        `/api/accounts/${accountId}/computegroups/${groupName}/computeprofiles`,
        {
          headers: this.getHeaders(),
        }
      )
      .pipe(
        map((profiles: IComputeGroupProfile[]) => {
          let dedupedProfiles: IComputeGroupProfile[] = [];

          if (profiles) {
            const profileMap: Map<string, IComputeGroupProfile[]> = new Map<
              string,
              IComputeGroupProfile[]
            >();
            // Find the set of profile names
            profiles.forEach((profile: IComputeGroupProfile) => {
              if (profileMap.get(profile.name) === undefined) {
                profileMap.set(profile.name, []);
              }
              const likeProfiles: IComputeGroupProfile[] = profileMap.get(
                profile.name
              );
              likeProfiles.push(profile);
              profileMap.set(profile.name, likeProfiles);
            });

            // De-dupe by just popping the first profile off of each array
            for (const key of profileMap.keys()) {
              const likeProfiles: IComputeGroupProfile[] = profileMap.get(key);
              dedupedProfiles.push(likeProfiles[0]);
            }
            dedupedProfiles = dedupedProfiles.sort(
              (first: IComputeGroupProfile, second: IComputeGroupProfile) => {
                return (
                  0 -
                  (first.name.toLowerCase() > second.name.toLowerCase()
                    ? -1
                    : 1)
                );
              }
            );
          }
          return dedupedProfiles;
        }),
        catchHttpErrors
      );
  }

  getProfile(
    accountId: string,
    groupName: string,
    profileName: string
  ): Observable<any> {
    return this._http
      .get(
        `/api/accounts/${accountId}/computegroups/${groupName}/computeprofiles/${profileName}`,
        { headers: this.getHeaders() }
      )
      .pipe(
        map((computeProfileResponse: any) => {
          return computeProfileResponse || {};
        }),
        catchHttpErrors
      );
  }

  getProfileOptions(accountId: string, groupName: string): Observable<any> {
    return this._http
      .get(
        `/api/accounts/${accountId}/computegroups/${groupName}/computeprofile-options`,
        { headers: this.getHeaders() }
      )
      .pipe(
        map((options: IComputeGroupProfileOptions) => {
          return options || {};
        }),
        catchHttpErrors
      );
  }

  getEnvironmentProfileOptions(accountId: string): Observable<any> {
    return this._http
      .get(`/api/accounts/${accountId}/computegroup-options`, {
        headers: this.getHeaders(),
      })
      .pipe(
        map((environmentOptions: ICGEnvironmentProfileOptions) => {
          return environmentOptions || {};
        }),
        catchHttpErrors
      );
  }

  canSuspendProfile(profile: IComputeGroupProfile): boolean {
    return profile.compute_profile_state === ProfileStates.RUNNING;
  }

  canResumeProfile(profile: IComputeGroupProfile): boolean {
    return profile.compute_profile_state === ProfileStates.SUSPENDED;
  }

  suspendProfile(
    accountId: string,
    groupName: string,
    profileName: string
  ): Observable<any> {
    return this._http
      .patch(
        `/api/accounts/${accountId}/computegroups/${groupName}/computeprofiles/${profileName}`,
        { target_profile_state: 'suspended' },
        { headers: this.getHeaders() }
      )
      .pipe(catchHttpErrors);
  }

  resumeProfile(
    accountId: string,
    groupName: string,
    profileName: string
  ): Observable<any> {
    return this._http
      .patch(
        `/api/accounts/${accountId}/computegroups/${groupName}/computeprofiles/${profileName}`,
        { target_profile_state: 'running' },
        { headers: this.getHeaders() }
      )
      .pipe(catchHttpErrors);
  }

  deleteProfile(
    accountId: string,
    groupName: string,
    profileName: string
  ): Observable<any> {
    return this._http
      .delete(
        `/api/accounts/${accountId}/computegroups/${groupName}/computeprofiles/${profileName}`,
        { headers: this.getHeaders() }
      )
      .pipe(
        map((deleteResponse: any) => {
          return deleteResponse || {};
        }),
        catchHttpErrors
      );
  }

  // Returns string[]
  getGroupAdmins(accountId: string, groupName: string): Observable<any> {
    return this._http
      .get(
        `/api/accounts/${accountId}/permissions/computegroups/${groupName}/computegroupadmins/users`,
        { headers: this.getHeaders() }
      )
      .pipe(
        map((adminsResponse: any) => {
          return adminsResponse || [];
        }),
        catchHttpErrors
      );
  }

  // Returns error on failure
  addGroupAdmins(
    accountId: string,
    groupName: string,
    usernames: string[]
  ): Observable<any> {
    const body: IAddRemoveOp[] = usernames.map((username) => {
      return { op: OP.add, value: username };
    });
    return this._http
      .patch(
        `/api/accounts/${accountId}/permissions/computegroups/${groupName}/computegroupadmins/users`,
        body,
        { headers: this.getHeaders() }
      )
      .pipe(
        map((addAdminsResponse: any) => {
          return addAdminsResponse;
        }),
        catchHttpErrors
      );
  }

  // Returns error on failure
  removeGroupAdmins(
    accountId: string,
    groupName: string,
    usernames: string[]
  ): Observable<any> {
    const body: IAddRemoveOp[] = usernames.map((username) => {
      return { op: OP.remove, value: username };
    });
    return this._http
      .patch(
        `/api/accounts/${accountId}/permissions/computegroups/${groupName}/computegroupadmins/users`,
        body,
        { headers: this.getHeaders() }
      )
      .pipe(
        map((removeAdminsResponse: any) => {
          return removeAdminsResponse;
        }),
        catchHttpErrors
      );
  }

  // Returns string[]
  getGroupAccessUsers(accountId: string, groupName: string): Observable<any> {
    return this._http
      .get(
        `/api/accounts/${accountId}/permissions/computegroups/${groupName}/computegroupaccess/users`,
        { headers: this.getHeaders() }
      )
      .pipe(
        map((usersResponse: any) => {
          return usersResponse || [];
        }),
        catchHttpErrors
      );
  }

  // Returns error on failure
  addGroupAccessUsers(
    accountId: string,
    groupName: string,
    usernames: string[]
  ): Observable<any> {
    const body: IAddRemoveOp[] = usernames.map((username) => {
      return { op: OP.add, value: username };
    });
    return this._http
      .patch(
        `/api/accounts/${accountId}/permissions/computegroups/${groupName}/computegroupaccess/users`,
        body,
        { headers: this.getHeaders() }
      )
      .pipe(
        map((addGroupAccessResponse: any) => {
          return addGroupAccessResponse;
        }),
        catchHttpErrors
      );
  }

  // Returns error on failure
  removeGroupAccessUsers(
    accountId: string,
    groupName: string,
    usernames: string[]
  ): Observable<any> {
    const body: IAddRemoveOp[] = usernames.map((username) => {
      return { op: OP.remove, value: username };
    });
    return this._http
      .patch(
        `/api/accounts/${accountId}/permissions/computegroups/${groupName}/computegroupaccess/users`,
        body,
        { headers: this.getHeaders() }
      )
      .pipe(
        map((removeGroupAccessResponse: any) => {
          return removeGroupAccessResponse;
        }),
        catchHttpErrors
      );
  }

  // Returns string[]
  getGroupAccessRoles(accountId: string, groupName: string): Observable<any> {
    return this._http
      .get(
        `/api/accounts/${accountId}/permissions/computegroups/${groupName}/computegroupaccess/roles`,
        { headers: this.getHeaders() }
      )
      .pipe(
        map((rolesResponse: any) => {
          return rolesResponse || [];
        }),
        catchHttpErrors
      );
  }

  // Returns error on failure
  addGroupAccessRoles(
    accountId: string,
    groupName: string,
    roles: string[]
  ): Observable<any> {
    const body: IAddRemoveOp[] = roles.map((role) => {
      return { op: OP.add, value: role };
    });
    return this._http
      .patch(
        `/api/accounts/${accountId}/permissions/computegroups/${groupName}/computegroupaccess/roles`,
        body,
        { headers: this.getHeaders() }
      )
      .pipe(
        map((addGroupAccessResponse: any) => {
          return addGroupAccessResponse;
        }),
        catchHttpErrors
      );
  }

  // Returns error on failure
  removeGroupAccessRoles(
    accountId: string,
    groupName: string,
    roles: string[]
  ): Observable<any> {
    const body: IAddRemoveOp[] = roles.map((role) => {
      return { op: OP.remove, value: role };
    });
    return this._http
      .patch(
        `/api/accounts/${accountId}/permissions/computegroups/${groupName}/computegroupaccess/roles`,
        body,
        { headers: this.getHeaders() }
      )
      .pipe(
        map((removeGroupAccessResponse: any) => {
          return removeGroupAccessResponse;
        }),
        catchHttpErrors
      );
  }

  getUserComputeGroups(accountId: string, userName: string): Observable<any> {
    return this._http
      .get(`/api/accounts/${accountId}/users/${userName}/computegroups`, {
        headers: this.getHeaders(),
      })
      .pipe(
        map((getUserResponse: IUserComputeGroup[]) => {
          return getUserResponse;
        }),
        catchHttpErrors
      );
  }

  getHeaders(creds: string = null): HttpHeaders {
    if (this._authenticationService.isDBUser()) {
      return undefined;
    }
    const credentials: string = creds || this.credentials;

    return new HttpHeaders({ 'X-Auth-Credentials': `Basic ${credentials}` });
  }
}
