import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { HelpMenuService } from '@janus/help';

@Component({
  selector: 'td-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(private _helpMenuService: HelpMenuService) {}
  ngOnInit(): void {
    this._helpMenuService.openHelpCheck(false);
  }
}
