<div
  [ngClass]="expandedRail ? 'rail-open' : 'rail-closed'"
  [class.collapsed]="!expandedRail"
  style="height: 100%"
  layout="column"
  layout-align="start center"
  flex="none"
  class="z-2 mat-body-2 nav-rail-inner-container overflow-y"
>
  <mat-nav-list class="full-width">
    <a data-cy="menuMenu" mat-list-item hide-gt-md tdLayoutToggle>
      <mat-icon matListIcon>menu</mat-icon>
      <mat-icon
        *ngIf="expandedRail"
        flex
        class="push-right-lg expanded-logo"
        svgIcon="td-logo:logo-wordmark"
      ></mat-icon>
    </a>
    <a
      mat-list-item
      hide-xs
      hide-sm
      hide-md
      class="line-up-icon"
      (click)="toggleRail()"
    >
      <mat-icon matListIcon>menu</mat-icon>
      <mat-icon
        *ngIf="expandedRail"
        flex
        class="push-right-lg expanded-logo"
        svgIcon="td-logo:logo-wordmark"
      ></mat-icon>
    </a>
  </mat-nav-list>

  <!-- Navigation items -->
  <ng-template #collapsed>
    <shared-nav-content-collapsed
      class="full-height full-width"
      [navItems]="navItems"
      [isTrials]="isTrials"
      [consumption]="consumption"
    ></shared-nav-content-collapsed>
  </ng-template>

  <shared-nav-content-expanded
    class="full-height full-width"
    [navItems]="navItems"
    [isTrials]="isTrials"
    *ngIf="expandedRail; else collapsed"
  ></shared-nav-content-expanded>
  <!-- User items -->
  <span flex></span>

  <mat-nav-list
    class="pad-bottom-sm full-width global-items"
    [class.expanded-nav-rail]="expandedRail"
    [class.collapsed-list]="!expandedRail"
  >
    <ask-ai-nav-item
      *ngIf="askAiEnabled$ | async"
      [expanded]="expandedRail"
    ></ask-ai-nav-item>
    <mat-divider class="match-content-edges"></mat-divider>
    <notifications-nav-item
      [expanded]="expandedRail"
      [notificationCount]="notificationCount"
    ></notifications-nav-item>
    <help-nav-item [expanded]="expandedRail"></help-nav-item>
    <your-account-nav-item [expanded]="expandedRail"></your-account-nav-item>
  </mat-nav-list>
</div>
