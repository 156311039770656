import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/*
 * Sanitize HTML snippets for insertion in your component templates
 */
@Pipe({ name: 'safeHtml', pure: true })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): string {
    return this.sanitizer.sanitize(SecurityContext.HTML, value);
  }
}
