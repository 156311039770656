<div class="support-flagged-content full-height">
  <nav class="collapsed-list">
    <ng-container *ngFor="let navItem of navItems">
      <ng-container *tdFlag="navItem.flag">
        <div class="list-item">
          <button
            mat-icon-button
            id="{{ navItem.name }}-collapsed-nav-item"
            [attr.data-cy]="navItem.name + '-collapsed-nav-item' | lowercase"
            [matTooltip]="navItem.name | translate"
            matTooltipPosition="after"
            [ngClass]="
              isActiveRoute(navItem) || isActiveRouteInChildren(navItem)
            "
            (click)="navigate(navItem)"
          >
            <mat-icon
              class="non-hovered"
              [ngClass]="getIconClasses(navItem)"
              [svgIcon]="getSvgIcon(navItem)"
            >
              {{ getIcon(navItem) }}
            </mat-icon>

            <!-- need a way to show the home icon when hovering over the 
              Vantage icon-button -->
            <mat-icon
              *ngIf="navItem.collapsed?.hovered?.icon"
              class="hovered"
              [ngClass]="getIconClasses(navItem, true)"
              [svgIcon]="getSvgIcon(navItem, true)"
            >
              {{ getIcon(navItem, true) }}
            </mat-icon>
          </button>
        </div>
      </ng-container>
    </ng-container>
  </nav>

  <ng-container *ngIf="isTrials">
    <div
      class="trials"
      cdkOverlayOrigin
      #originOverlay="cdkOverlayOrigin"
      (mouseover)="showInfo()"
      (mouseleave)="hideInfo()"
    >
      <span class="trials-tag">
        <small>{{ 'TRIAL' | translate }}</small>
      </span>
      <div class="trials-box">
        <h3 class="mat-body-2">{{ 'VANTAGECLOUD' | translate }}</h3>
        <ng-container *ngIf="consumption">
          <span class="mat-caption"
            >{{ 'DAYS_REMAINING' | translate }}
            {{ consumption.days_left }}</span
          >
          <br />
          <span class="mat-caption"
            >{{ 'UNITS_REMAINING' | translate }}
            {{ consumption.units_left }}</span
          >
        </ng-container>
      </div>
    </div>

    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="originOverlay"
      [cdkConnectedOverlayOpen]="isOpen"
    >
      <div class="trials-box">
        <ng-container *ngIf="consumption">
          <p class="unit">{{ consumption.days_left }}</p>
          <p class="textUnit secondary-text">
            {{ 'DAYS_REMAINING' | translate }}
          </p>
          <br />
          <p class="unit">{{ consumption.units_left }}</p>
          <p class="textUnit secondary-text">
            {{ 'UNITS_REMAINING' | translate }}
          </p>
        </ng-container>
      </div>
    </ng-template>
  </ng-container>
</div>
