import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TdDialogService } from '@covalent/core/dialogs';
import { AuthenticationService } from '@janus/authentication';
import { IDatasetLoadingJobs, LoadAssetService } from '@janus/help';
import { OrganizationsService } from '@janus/organizations';
import { IUserProfile, ProfileService } from '@janus/profile';
import {
  finalize,
  firstValueFrom,
  lastValueFrom,
  Subscription,
  take,
  tap,
} from 'rxjs';

import { CookiesDialogComponent } from '../cookies-dialog/cookies-dialog.component';
import { EulaDialogComponent } from '../eula-dialog/eula-dialog.component';
import { NavRailComponent } from '../nav-rail/nav-rail.component';
import {
  EXPANDED_RAIL_KEY,
  NavItems,
  NavRailData,
} from '../nav-rail/utils/nav-rail-data';
import { ICloudPlatform } from '@janus/querygrid';

@Component({
  selector: 'shared-nav-container',
  templateUrl: './nav-main.component.html',
  styleUrls: ['./nav-main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavMainComponent implements OnInit, OnDestroy {
  @Input() navItems: NavItems;
  @ViewChild('mainNav') private _mainNav: NavRailComponent;
  notificationCount: number;

  expandedRail: boolean = false;
  _querySubscription: Subscription;
  isSmallScreen: boolean = false;
  showEULA: boolean = false;
  menuInitWithoutPlatform: boolean = false;

  constructor(
    private _profileService: ProfileService,
    private _navRailData: NavRailData,
    private _changeDetectorRef: ChangeDetectorRef,
    private _ngZone: NgZone,
    private breakpointObserver: BreakpointObserver,
    private _loadAssetService: LoadAssetService,
    private _authenticationService: AuthenticationService,
    private _dialogService: TdDialogService,
    private _orgService: OrganizationsService,
    private _cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.expandedRail =
      window.sessionStorage.getItem(EXPANDED_RAIL_KEY) === 'true';
  }

  async ngOnInit(): Promise<void> {
    this.watchScreen();

    this.setPlatformDependantNavItems();

    if (!this.navItems) {
      this.menuInitWithoutPlatform = true;
      // show all nav items without platform restrictions since we don't have the org platform loaded yet
      // navItems populated here to avoid showing a blank menu
      // navItems will be updated (add only) on 'setPlatformDependantNavItems()' when org platform is loaded
      this.navItems = this._navRailData
        .getNavItems()
        .filter(
          (item) =>
            item.supportedPlatforms == null ||
            item.supportedPlatforms.length === 0
        );
    }

    this._changeDetectorRef.detectChanges();

    const isTrials$ = this._orgService.getMyOrganizationTrialStatus();

    const isTrials = await firstValueFrom(isTrials$);
    const accepted = await firstValueFrom(this._profileService.acceptedEULA());

    if (isTrials && !accepted) {
      this.openEULADialog();
    } else {
      this.openAnalyticsDialog();
    }

    this._loadAssetService.loadAsset$.subscribe((update) => {
      if (update) {
        this.getNotifications();
      }
    });

    this.getNotifications(true);
  }

  async getNotifications(checkTasks = false): Promise<void> {
    const profile: IUserProfile = await lastValueFrom(
      this._profileService.getUserProfile()
    );
    let runningJobs: IDatasetLoadingJobs[] = [];
    let completedJobs: IDatasetLoadingJobs[] = [];
    if (profile && profile.dataset_loading_running) {
      runningJobs = JSON.parse(profile.dataset_loading_running);
    }
    if (profile && profile.dataset_loading_completed) {
      completedJobs = JSON.parse(profile.dataset_loading_completed);
    }

    // add running jobs to completed jobs when user reopen the browser
    if (checkTasks) {
      if (runningJobs && runningJobs.length != 0) {
        profile.dataset_loading_running = '[]';
        completedJobs = completedJobs.concat(runningJobs);
        profile.dataset_loading_completed = JSON.stringify(completedJobs);
        await lastValueFrom(this._profileService.updateUserProfile(profile));
        this._loadAssetService._loadAssetSubject.next(true);
      }
    }

    this.notificationCount = 0;
    if (completedJobs) {
      this.notificationCount += completedJobs.length;
    }
    this._cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this._querySubscription?.unsubscribe();
  }

  watchScreen(): void {
    this._querySubscription = this.breakpointObserver
      .observe([Breakpoints.Small])
      .subscribe((result: any) => {
        this._ngZone.run(() => {
          if (this._mainNav && this._mainNav.expandedRail && result.matches) {
            this._mainNav.expandedRail = false;
          }

          this.isSmallScreen =
            !this.breakpointObserver.isMatched('(min-width: 600px)');
          this._changeDetectorRef.markForCheck();
        });
      });
  }

  async openAnalyticsDialog(): Promise<void> {
    const showDialog: boolean =
      await this._profileService.showAnalyticsWarning();

    if (showDialog) {
      this._dialogService
        .open(CookiesDialogComponent, {
          autoFocus: false,
          closeOnNavigation: false,
          disableClose: true,
          width: '100%',
          position: { bottom: '20px' },
        })
        .afterClosed()
        .subscribe((allow: boolean) => {
          this._profileService.setAnalytics(allow);
          if (allow) {
            this._document.defaultView.location.reload();
          } else {
            (window as any).pendo.stopSendingEvents();
            (window as any).pendo.clearSession();
            (window as any).pendo.hideLauncher();
            (window as any).pendo.hideGuides();
          }
        });
    }
  }

  openEULADialog(): void {
    this._dialogService
      .open(EulaDialogComponent, {
        closeOnNavigation: false,
        disableClose: true,
        height: '100%',
        width: '100%',
      })
      .afterClosed()
      .subscribe((accept: boolean) => {
        // we should probably also log them out at this point if they're not going to agree
        if (!accept) {
          this._authenticationService.logout();
        } else {
          this._profileService.setAcceptance(accept);

          // now, if we still need to, check on analytics
          this.openAnalyticsDialog();
        }
      });
  }

  private setPlatformDependantNavItems(): void {
    // sets | updates nav items based on platform exclusions
    let platform: ICloudPlatform;
    this._orgService
      .getMyOrganizationPlatform()
      .pipe(
        take(1),
        tap((p) => (platform = p)),
        finalize(() => {
          if (!this.navItems || this.menuInitWithoutPlatform) {
            this.navItems = this._navRailData.getNavItems().filter((item) => {
              if (item.supportedPlatforms != null) {
                return item.supportedPlatforms.includes(platform);
              }
              return true;
            });
          }
        })
      )
      .subscribe();
  }
}
