import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  catchError,
  concatMap,
  mergeMap,
  Observable,
  takeLast,
  takeWhile,
  timer,
} from 'rxjs';
import { ApiService } from '../common/api.service';
import { ISystemType } from './data-source.interface';

export type ForeignServerVersionType = 'ACTIVE' | 'PENDING';

export type ForeignServerAuthType = 'INVOKER' | 'DEFINER';

export type ForeignServerStatus = 'IN_PROGRESS' | 'SUCCESS' | 'FAILURE';

export interface IForeignServer {
  foreignServerName: string;
  initiatorAdminUser: string;
  initiatorAdminPassword: string;
  linkId: string;
  version: ForeignServerVersionType;
  authType?: ForeignServerAuthType;
  authObjectName?: string;
  targetAdminUser?: string;
  targetAdminPassword?: string;
  serviceAccountName?: string;
  serviceAccountPassword?: string;
  serviceAccountTempSize?: number;
  allowedUsers?: string[];
  keytab?: string;
}

export interface IForeignServerResponse {
  id: string;
  version: string;
}

export interface IForeignServerNode {
  status: ForeignServerStatus;
  error?: string;
  systemName: string;
}

export interface IForeignServerStatus {
  id: string;
  status: ForeignServerStatus;
  startTime: Date;
  endTime: Date;
  nodes: IForeignServerNode[];
}

const FOREIGN_SERVER_CREATION_POLLING_INTERVAL = 5000;

@Injectable({
  providedIn: 'root',
})
export class ForeignServerService {
  constructor(private _http: HttpClient, private _apiService: ApiService) {}

  createForeignServer(
    foreignServer: IForeignServer
  ): Observable<IForeignServerStatus> {
    return this._http
      .post<IForeignServerResponse>(
        `${this.getBaseUrl()}/operations/create-foreign-server`,
        foreignServer
      )
      .pipe(
        concatMap((response) => this.pollForForeignServerStatus(response.id)),
        takeLast(1),
        catchError(this._errorHandler)
      );
  }

  newForeignServerFormGroup(systemType: string): FormGroup {
    if (systemType === ISystemType.TERADATA) {
      return this.newForeignServerFormGroupForTeradata();
    } else if (systemType === ISystemType.HADOOP) {
      return this.newForeignServerFormGroupForHadoop();
    } else if (systemType === ISystemType.OTHER) {
      return this.newForeignServerFormGroupForOther();
    }
    return new FormGroup({});
  }

  newForeignServerFormGroupForTeradata(): FormGroup {
    return new FormGroup({
      ...this.commonFormControls(),
      ...this.teradataInitiatorDSFormControls(),
      ...this.teradataTargetDSFormControls(),
    });
  }
  commonFormControls(): any {
    return {
      initiatorAdminUser: new FormControl('', Validators.required),
      initiatorAdminPassword: new FormControl('', Validators.required),
      targetAdminUser: new FormControl('', Validators.required),
      targetAdminPassword: new FormControl('', Validators.required),
      queryInitiationType: new FormControl(''),
    };
  }
  teradataInitiatorDSFormControls(): any {
    return {
      authorizationType: new FormControl('', [Validators.required]),
      securityMechanism: new FormControl('', [Validators.required]),
      foreignServerName: new FormControl('', [Validators.required]),
      serviceAccountName: new FormControl('', [Validators.required]),
      serviceAccountPassword: new FormControl('', [Validators.required]),
      serviceAccountTempSize: new FormControl('', [
        Validators.required,
        Validators.min(0),
        Validators.max(2.147483647),
        Validators.pattern(/^([0-9]+(\.[0-9]*)?|\.[0-9]+)$/),
      ]),
      authObjectName: new FormControl('', [Validators.required]),
      allowedUsers: new FormControl(''),
    };
  }

  teradataTargetDSFormControls(): any {
    return {
      authorizationType_targetDS: new FormControl('', [Validators.required]),
      securityMechanism_targetDS: new FormControl('', [Validators.required]),
      foreignServerName_targetDS: new FormControl('', [Validators.required]),
      serviceAccountName_targetDS: new FormControl('', [Validators.required]),
      serviceAccountPassword_targetDS: new FormControl('', [
        Validators.required,
      ]),
      serviceAccountTempSize_targetDS: new FormControl('', [
        Validators.required,
        Validators.min(0),
        Validators.max(2.147483647),
        Validators.pattern(/^([0-9]+(\.[0-9]*)?|\.[0-9]+)$/),
      ]),
      authObjectName_targetDS: new FormControl('', [Validators.required]),
      allowedUsers_targetDS: new FormControl(''),
    };
  }

  newForeignServerFormGroupForHadoop(): FormGroup {
    return new FormGroup({
      authorizationType: new FormControl('', [Validators.required]),
      foreignServerName: new FormControl('', [Validators.required]),
      serviceAccountName: new FormControl(''),
      serviceAccountPassword: new FormControl(''),
      keytab: new FormControl(''),
      authObjectName: new FormControl(''),
      initiatorAdminUser: new FormControl('', Validators.required),
      initiatorAdminPassword: new FormControl('', Validators.required),
      kerberosType: new FormControl(''),
      allowedUsers: new FormControl(''),
      targetAdminUser: new FormControl(''),
      targetAdminPassword: new FormControl(''),
    });
  }

  newForeignServerFormGroupForOther(): FormGroup {
    return new FormGroup({
      authorizationType: new FormControl('', [Validators.required]),
      foreignServerName: new FormControl('', [Validators.required]),
      securityMechanism: new FormControl('', [Validators.required]),
      serviceAccountName: new FormControl(''),
      serviceAccountPassword: new FormControl(''),
      keytab: new FormControl(''),
      authObjectName: new FormControl(''),
      initiatorAdminUser: new FormControl('', Validators.required),
      initiatorAdminPassword: new FormControl('', Validators.required),
      targetAdminUser: new FormControl(''),
      targetAdminPassword: new FormControl(''),
    });
  }

  private pollForForeignServerStatus(
    id: string
  ): Observable<IForeignServerStatus> {
    return timer(0, FOREIGN_SERVER_CREATION_POLLING_INTERVAL).pipe(
      mergeMap(() => {
        return this._http
          .get<IForeignServerStatus>(
            `${this.getBaseUrl()}/operations/create-foreign-server/${id}`
          )
          .pipe(catchError(this._errorHandler));
      }),
      takeWhile((status) => status.status === 'IN_PROGRESS', true),
      catchError(this._errorHandler)
    );
  }

  private _errorHandler = (error: HttpErrorResponse) => {
    throw Object.assign({}, error.error, { httpStatus: error.status });
  };

  private getBaseUrl(): string {
    return this._apiService.getBaseUrl();
  }
}
