import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApiService } from '../common/api.service';

export enum EQueryGridStates {
  NOT_DEPLOYED = 'not_deployed',
  DEPLOYING = 'deploying',
  DEPLOYING_FAILED = 'deploying_failed',
  RUNNING = 'running',
  TERMINATING_FAILED = 'terminating_failed',
  TERMINATING = 'terminating',
}

export enum EQGPrivateLinkStatus {
  HEALTHY = 'HEALTHY',
  UNHEALTHY = 'UNHEALTHY',
  UNKNOWN = 'UNKNOWN',
}

export interface IPendingPrivateLinkAction {
  job_state: string;
  action: 'ADD' | 'DELETE' | 'UNKNOWN';
}

export interface IQueryGridResponse {
  id?: string;
  querygridErrorMessage?: string;
  status?: EQueryGridStates;
  error?: string;
  queryGridStatus(): EQueryGridStatusType;
}

export interface IQGPrivateLinkDetails {
  configuration?: IQGPrivateLinkConfiguration;
  job_state: QueryGridPrivateLinkJobStatus;
  last_job_id?: string;
  outputs?: IPrivateLinkOutputs;
  updated_at?: number;
  error?: string;
  queryGridStatus?(): EQueryGridStatusType;
}
export interface IQGPrivateLinkConfiguration {
  private_link?: IQGPrivateLinkConfigDetail;
}

export interface IQGPrivateLinkConfigDetail {
  querygrid?: IConfigRequest;
}

export interface IConfigRequest {
  enable: boolean;
  accounts?: string[];
}

export interface IPrivateLinkOutputs {
  private_link: IPrivateLinkOutputDetail;
}

export interface IPrivateLinkOutputDetail {
  querygrid?: IConfigResponse;
}

export interface IConfigResponse {
  az_ids: any[];
  endpoint: string;
  status: EQGPrivateLinkStatus;
}
export interface IQGProvisionDetails {
  querygridLoadbalancerAddress: string;
}

export enum QueryGridPrivateLinkJobStatus {
  NOT_DEPLOYED = 'NOT_DEPLOYED',
  FAILED = 'FAILED',
  FINISHED = 'FINISHED',
  RUNNING = 'RUNNING',
}

export enum EQueryGridStatusType {
  FAILURE = 'FAILURE',
  NOT_PROVISIONED = 'NOT_PROVISIONED',
  LINK_IN_PROGRESS = 'LINK_IN_PROGRESS',
  LINK_FAILURE = 'LINK_FAILURE',
  PRIVATE_LINK_ENABLED = 'PRIVATE_LINK_ENABLED',
  PROVISION_IN_PROGRESS = 'PROVISION_IN_PROGRESS',
  PROVISION_FAILURE = 'PROVISION_FAILURE',
  PROVISIONED = 'PROVISIONED',
  TERMINATING = 'TERMINATING',
  TERMINATING_FAILED = 'TERMINATING_FAILED',
}

@Injectable({
  providedIn: 'root',
})
export class QueryGridProvisionService {
  constructor(private _http: HttpClient, private _apiService: ApiService) {}

  private subjectEnable = new Subject<any>();
  private subjectInProgress = new Subject<any>();
  static queryGridProvisioned = false;
  pendingPrivateLinkChange$ = new BehaviorSubject<IPendingPrivateLinkAction>({
    job_state: QueryGridPrivateLinkJobStatus.FINISHED,
    action: 'UNKNOWN',
  });

  //EQueryGridStates
  private queryGridStateMap = new Map(
    Object.entries({
      NOT_DEPLOYED: EQueryGridStatusType.NOT_PROVISIONED,
      DEPLOYING: EQueryGridStatusType.PROVISION_IN_PROGRESS,
      DEPLOYING_FAILED: EQueryGridStatusType.PROVISION_FAILURE,
      RUNNING: EQueryGridStatusType.PROVISIONED,
      TERMINATING: EQueryGridStatusType.TERMINATING,
      TERMINATING_FAILED: EQueryGridStatusType.TERMINATING_FAILED,
    })
  );

  NOT_DEPLOYED_STATE = {
    job_state: QueryGridPrivateLinkJobStatus.NOT_DEPLOYED,
    queryGridStatus: () => EQueryGridStatusType.NOT_PROVISIONED,
  };

  moveToInProgress(
    status: EQueryGridStatusType,
    accountId: string,
    message?: string,
    serviceName?: string
  ) {
    this.subjectEnable.next({
      statusType: status,
      accountId,
      message,
      serviceName,
    });
  }

  moveToEnableNextStep(
    status: EQueryGridStatusType,
    accountId: string,
    message?: string,
    serviceName?: string
  ) {
    this.subjectInProgress.next({
      statusType: status,
      accountId,
      message,
      serviceName,
    });
  }

  getUpdate(): Observable<any> {
    return this.subjectEnable.asObservable();
  }

  getInProgressUpdate(): Observable<any> {
    return this.subjectInProgress.asObservable();
  }

  getQueryGridStatus(): Observable<IQueryGridResponse> {
    return this._http.get<any>(this.getBaseUrlProvision(), {}).pipe(
      map((queryGrid: IQueryGridResponse) => {
        queryGrid.queryGridStatus = () =>
          queryGrid.status
            ? (this.queryGridStateMap.get(
                queryGrid.status.toUpperCase()
              ) as EQueryGridStatusType)
            : EQueryGridStatusType.NOT_PROVISIONED;
        return queryGrid;
      }),
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      })
    );
  }

  getAccountPrivateLink(): Observable<any> {
    return this._http
      .get<IQGPrivateLinkDetails>(`${this.getAccountUrl()}/private-link`)
      .pipe(
        tap((privateLink) => {
          this.linkQueryGridStatus(privateLink);
          this.updatePendingPrivateLinkAction(privateLink);
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 404) {
            return of(this.NOT_DEPLOYED_STATE);
          }
          throw Object.assign({}, error, { httpStatus: error.status });
        })
      );
  }

  updatePendingPrivateLinkAction(privateLink: IQGPrivateLinkDetails): void {
    const { job_state = 'FINISHED' } = privateLink;
    const pending = this.pendingPrivateLinkChange$.value;
    this.pendingPrivateLinkChange$.next({ ...pending, job_state });
  }

  linkQueryGridStatus(privateLink: IQGPrivateLinkDetails) {
    const IsQgConfigEnabled =
      privateLink.configuration?.private_link?.querygrid?.enable;
    privateLink.queryGridStatus = () => {
      if (IsQgConfigEnabled) {
        return EQueryGridStatusType.PRIVATE_LINK_ENABLED;
      } else {
        if (privateLink.job_state === QueryGridPrivateLinkJobStatus.RUNNING) {
          return EQueryGridStatusType.LINK_IN_PROGRESS;
        } else if (
          privateLink.job_state === QueryGridPrivateLinkJobStatus.FAILED
        ) {
          return EQueryGridStatusType.LINK_FAILURE;
        } else {
          return EQueryGridStatusType.NOT_PROVISIONED;
        }
      }
    };
  }

  enablePrivateLink(
    linkConfiguration: IQGPrivateLinkConfiguration
  ): Observable<any> {
    const url = `${this.getAccountUrl()}/private-link`;
    return this._http.patch<any>(url, linkConfiguration).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          return this._http.put(url, linkConfiguration).pipe(
            catchError((err: HttpErrorResponse) => {
              throw Object.assign({}, err.error, { httpStatus: err.status });
            })
          );
        }
        throw Object.assign({}, error, { httpStatus: error.status });
      })
    );
  }

  updatePrivateLinkAccounts(accounts: string[]): Observable<any> {
    return this._http
      .patch<IQGPrivateLinkDetails>(`${this.getAccountUrl()}/private-link`, {
        private_link: { querygrid: { enable: true, accounts } },
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error, { httpStatus: error.status });
        })
      );
  }

  pollPLUpdateStatus(jobId: string): Observable<any> {
    return this._http.get<any>(`${this.getAccountUrl()}/${jobId}/private-link`);
  }

  provisionQueryGrid(provisionDetails: IQGProvisionDetails): Observable<any> {
    return this._http
      .post<any>(`${this.getBaseUrlProvision()}`, provisionDetails)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error, { httpStatus: error.status });
        })
      );
  }

  deleteQueryGrid(): Observable<any> {
    return this._http.delete<any>(`${this.getBaseUrlProvision()}`).pipe(
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error, { httpStatus: error.status });
      })
    );
  }

  getAccountUrl(): string {
    return `/api/accounts/${this._apiService.getAccountId()}`;
  }

  getBaseUrl(): string {
    return this._apiService.getBaseUrl();
  }

  getBaseUrlProvision(): string {
    return this._apiService.getBaseUrlProvision();
  }
}
