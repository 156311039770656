import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../common/api.service';
import {
  IScheduledTask,
  SchedulerService,
} from '../scheduler/scheduler.service';

export enum ESeverityTypes {
  'CRITICAL' = 'CRITICAL',
  'WARNING' = 'WARNING',
}
export interface ICurrentIssueList {
  criticalCount: number;
  warningCount: number;
  severityCount: number; // calculated count
  issues: ICurrentIssueDetail[];
  erred?: boolean;
  error?: any;
}
export interface ICurrentIssueDetail {
  id: string;
  problemType: string; // Issue
  severity: string; // Severity
  componentType: string; // Type
  componentName: string; // Name
  componentId: string; // Component Id
  messageLabel?: string; // Detail header
  messageParams?: string[]; // Details
  nodeIds?: string[]; // list of node ids
  createTime: string; // Created
  lastModified: string; // Updated
  isCritical: boolean; // issue is type critical
  tviAlertSeverityCode?: number;
}

const EMPTY_ISSUE_LIST: ICurrentIssueList = {
  criticalCount: 0,
  warningCount: 0,
  severityCount: 0,
  issues: [],
};

@Injectable({
  providedIn: 'root',
})
export class IssuesService implements OnDestroy {
  countSubject: Subject<number> = new Subject<number>();

  private issueListBS: BehaviorSubject<ICurrentIssueList> =
    new BehaviorSubject<ICurrentIssueList>(EMPTY_ISSUE_LIST);

  issueList$: Observable<ICurrentIssueList> = this.issueListBS.asObservable();
  private updateTask: IScheduledTask;

  constructor(
    private _http: HttpClient,
    private _schedulerService: SchedulerService,
    private _apiService: ApiService
  ) {
    this.updateTask = _schedulerService.createTask(() =>
      this.updateIssueList()
    );
  }

  activatePolling() {
    this.updateIssueList().then(() => this.updateTask.start());
  }

  ngOnDestroy() {
    this.updateTask.complete();
  }

  refreshIssueList(): Promise<string> {
    const promise = this.updateIssueList();
    promise.then(() => {
      this.updateTask.restart();
    });
    return promise;
  }

  private updateIssueList(): Promise<string> {
    return new Promise((resolve) => {
      this._http
        .get<any>(`${this.getBaseUrl()}/issues`)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            throw Object.assign({}, error.error, { httpStatus: error.status });
          }),
          map((issueList: ICurrentIssueList) => {
            issueList.severityCount = this.calculateSeverityCount(issueList);
            issueList.issues.forEach((element: ICurrentIssueDetail) => {
              element.isCritical = this.isIssueCritical(element);
            });
            return issueList;
          })
        )
        .subscribe({
          next: (issueList) => {
            this.issueListBS.next(issueList);
            resolve('done');
          },
          error: (error) => {
            this.issueListBS.next(
              Object.assign({}, EMPTY_ISSUE_LIST, { erred: true, error: error })
            );
            resolve('error');
          },
        });
    });
  }

  calculateSeverityCount(issue: ICurrentIssueList) {
    return issue.warningCount + issue.criticalCount;
  }

  isIssueCritical(issueDetail: ICurrentIssueDetail): boolean {
    return ESeverityTypes.CRITICAL === issueDetail.severity;
  }

  deleteIssue(id: string): Observable<any> {
    return this._http.delete<any>(`${this.getBaseUrl()}/issues/${id}`).pipe(
      map((resp: any) => {
        this.refreshIssueList();
        return resp;
      }),
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      })
    );
  }

  getBaseUrl(): string {
    return this._apiService.getBaseUrl();
  }
}
