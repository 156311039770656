<div class="support-flagged-content full-height">
  <mat-nav-list class="expanded-nav-rail">
    <ng-container *ngFor="let navItem of navItems">
      <ng-container *tdFlag="navItem.flag">
        <mat-expansion-panel
          #navExpansion
          class="mat-elevation-z0 full-width"
          [expanded]="anyChildActive(navItem)"
          hideToggle="true"
          *ngIf="navItem.children; else singleItem"
        >
          <mat-expansion-panel-header
            [collapsedHeight]="'48px'"
            [expandedHeight]="'48px'"
          >
            <mat-icon>
              {{ navExpansion.expanded ? 'arrow_drop_down' : 'arrow_right' }}
            </mat-icon>

            <mat-icon
              matListIcon
              [svgIcon]="navItem.svgIcon ? navItem.icon : null"
              class="push-right"
              [ngClass]="getParentClasses(navItem, navExpansion.expanded, true)"
            >
              {{ navItem.svgIcon ? '' : navItem.icon }}
            </mat-icon>

            <!-- need to toggle the expansion back to how it was (cause clicking
              on the header does a toggle too) -->
            <a
              mat-list-item
              (click)="navExpansion.toggle(); navigate(navItem)"
              [ngClass]="
                getParentClasses(navItem, navExpansion.expanded, false)
              "
              class="parent-link"
              disableRipple="true"
              [attr.data-cy]="navItem.name + '-expanded-nav-item' | lowercase"
            >
              <h3 matLine>
                <span class="mat-body-2">{{ navItem.name | translate }}</span>
              </h3>
            </a>
          </mat-expansion-panel-header>

          <mat-nav-list td-sidenav-content class="child-list">
            <ng-container *ngFor="let navChild of navItem.children">
              <ng-container *tdFlag="navChild.flag">
                <a
                  *ngIf="!navChild.subheader; else childSubHeader"
                  mat-list-item
                  (click)="navigate(navChild)"
                  [ngClass]="isActiveRoute(navChild)"
                  class="child-link"
                  disableRipple="true"
                  [attr.data-cy]="
                    navChild.name + '-expanded-nav-item' | lowercase
                  "
                >
                  <h3 matLine>
                    <span class="mat-body-1">
                      {{ navChild.name | translate }}
                    </span>
                  </h3>
                </a>

                <ng-template #childSubHeader>
                  <div mat-subheader>{{ navChild.name }}</div>
                </ng-template>
              </ng-container>
            </ng-container>
          </mat-nav-list>
        </mat-expansion-panel>

        <ng-template #singleItem>
          <ng-template #subHeader>
            <div mat-subheader>{{ navItem.name }}</div>
          </ng-template>
          <a
            *ngIf="!navItem.subheader; else subHeader"
            mat-list-item
            id="{{ navItem.name }}-expanded-nav-item"
            (click)="navigate(navItem)"
            [ngClass]="isActiveRoute(navItem)"
            [attr.data-cy]="navItem.name + '-expanded-nav-item' | lowercase"
          >
            <mat-icon
              matListIcon
              [svgIcon]="navItem.svgIcon ? navItem.icon : null"
              [ngClass]="navItem.iconClasses"
              class="push-right-xs"
            >
              {{ navItem.svgIcon ? '' : navItem.icon }}
            </mat-icon>

            <h3 matLine>
              <span class="mat-body-2">{{ navItem.name | translate }}</span>
            </h3>
          </a>
        </ng-template>
      </ng-container>
    </ng-container>
  </mat-nav-list>

  <ng-container *ngIf="isTrials">
    <div class="trials">
      <span class="trials-tag">
        <small>{{ 'TRIAL' | translate }}</small>
      </span>
      <div class="col">
        <h3 class="mat-body-2">{{ 'VANTAGECLOUD' | translate }}</h3>
        <span class="mat-caption" *ngIf="consumption?.days_left"
          >{{ 'DAYS_REMAINING' | translate }}: {{ consumption.days_left }}</span
        >
        <span class="mat-caption" *ngIf="consumption?.units_left"
          >{{ 'UNITS_REMAINING' | translate }}:
          {{ consumption.units_left }}</span
        >
      </div>
    </div>
  </ng-container>
</div>
