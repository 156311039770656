import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpStatusCode,
} from '@angular/common/http';
import { firstValueFrom, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

export interface IUserProfileResp {
  guid?: string;
  metadata: IUserProfile;
}

export interface IUserProfile {
  allow_analytics?: string;
  eula_accepted?: string;
  dataset_loading_running?: string;
  dataset_loading_completed?: string;
  language?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private _http: HttpClient, private _router: Router) {}

  userProfileResp: IUserProfileResp;

  getUserProfile(): Observable<IUserProfile> {
    if (this.userProfileResp) {
      return of(
        this.userProfileResp.metadata ? this.userProfileResp.metadata : {}
      );
    }

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const request: Observable<any> = this._http.get('/api/my-profile', {
      headers,
    });

    return request.pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.Forbidden) {
          this._router.navigateByUrl('/forbidden');
        }
        throw Object.assign({}, error.error, { httpStatus: error.status });
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      map((userProfileResp: IUserProfileResp) => {
        const metadata = userProfileResp.metadata;
        this.userProfileResp = userProfileResp;
        if (metadata) {
          localStorage.setItem('profile', JSON.stringify(metadata));
        }
        return userProfileResp?.metadata ? userProfileResp.metadata : {};
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateUserProfile(userProfile: IUserProfile): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const request: Observable<any> = this._http.put(
      '/api/my-profile',
      userProfile,
      {
        headers,
      }
    );

    return request.pipe(
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      map((response: any) => {
        this.userProfileResp.metadata = userProfile;
        localStorage.setItem('profile', JSON.stringify(userProfile));
        return response;
      })
    );
  }

  acceptedEULA(): Observable<boolean> {
    const local = localStorage.getItem('eula_accepted');

    return local !== null
      ? of(local === 'true')
      : this.getUserProfile().pipe(
          map((profile: IUserProfile) => profile?.eula_accepted === 'true')
        );
  }

  // Wrapper method to facilitate determining if analytics are currently enabled.
  async analyticsEnabled(): Promise<boolean> {
    const allowAnalytics: string = localStorage.getItem('allow_analytics');
    if (allowAnalytics === null) {
      try {
        const profile: IUserProfile = await this.getUserProfile().toPromise();
        if (!profile || !profile.allow_analytics) {
          return false;
        } else {
          if (profile.allow_analytics === 'true') {
            return true;
          } else {
            return false;
          }
        }
      } catch (error) {
        return false;
      }
    } else {
      if (allowAnalytics === 'true') {
        return true;
      } else {
        return false;
      }
    }
  }

  // Convenience method to indicate whether user has ever responded to the bottom sheet
  // cookie warning. If so, analytics are appropriately enabled.
  async showAnalyticsWarning(): Promise<boolean> {
    const profile: IUserProfile = await firstValueFrom(this.getUserProfile());
    const allowAnalytics: string = localStorage.getItem('allow_analytics');
    if (allowAnalytics === null) {
      try {
        const profile: IUserProfile = await firstValueFrom(
          this.getUserProfile()
        );
        // If 'allow_analytics' property missing from profile, show bottom sheet
        if (!profile || !profile.allow_analytics) {
          return true;
        } else {
          this.setAnalytics(profile.allow_analytics === 'true');
        }
      } catch (error) {
        return true;
      }
    } else {
      //To setanalytics cookies from stored in the DB (profile) for the same user logged in.
      localStorage.setItem(
        'allow_analytics',
        profile?.allow_analytics === 'true' ? 'true' : 'false'
      );
      const checkAnalytics: string = localStorage.getItem('allow_analytics');
      this.setAnalytics(checkAnalytics === 'true');
    }
    return false;
  }

  // for trials users, track whether the EULA was accepted
  setAcceptance(accepted: boolean): void {
    const setValue = accepted ? 'true' : 'false';
    localStorage.setItem('eula_accepted', setValue);

    this.getUserProfile()
      .pipe(
        filter(
          (profile: IUserProfile) =>
            !profile ||
            !profile.eula_accepted ||
            profile.eula_accepted !== setValue
        ),
        switchMap((profile: IUserProfile) => {
          profile.eula_accepted = setValue;
          return this.updateUserProfile(profile);
        })
      )
      .subscribe();
  }

  // Enable/disable analytics ane conditionally write back profile if state changed
  // or was not previously recorded.
  setAnalytics(enabled: boolean): void {
    const setValue = enabled ? 'true' : 'false';
    localStorage.setItem('allow_analytics', enabled ? 'true' : 'false');

    this.getUserProfile()
      .pipe(
        filter(
          (profile: IUserProfile) =>
            !profile ||
            !profile.allow_analytics ||
            profile.allow_analytics !== setValue
        ),
        switchMap((profile: IUserProfile) => {
          profile.allow_analytics = setValue;
          return this.updateUserProfile(profile);
        })
      )
      .subscribe();
  }

  // Set the user's preferred language
  setLanguage(lang: string): Observable<any> {
    return this.getUserProfile().pipe(
      switchMap((profile: IUserProfile) => {
        profile.language = lang;
        return this.updateUserProfile(profile);
      })
    );
  }

  // Retrieve or create GUID for user in user profile.
  async getUserGUID(): Promise<string> {
    await this.getUserProfile().toPromise();
    return this.userProfileResp ? this.userProfileResp.guid : '';
  }
}
