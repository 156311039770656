import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApiService } from '../common/api.service';

export interface INetwork {
  id?: string;
  name: string;
  description: string;
  lastModified?: string;
  version?: string;
  matchingRules?: MatchingRule[];
  versionId?: string;
  loadBalancerAddress?: string;
  connectionType: string;
  createPending?: boolean;
}
export interface INetworkLoadBalancer {
  querygridLoadbalancerId?: string;
  querygridLoadbalancerName: string;
  querygridLoadbalancerDescription: string;
  querygridLoadbalancerAddress: string;
}

export interface MatchingRule {
  type: string;
  value: string;
}

export enum NetworkVersion {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  PREVIOUS = 'PREVIOUS',
}

export enum EConnectionType {
  STANDARD = 'STANDARD',
  NO_INGRESS = 'NO_INGRESS',
  LOAD_BALANCER = 'LOAD_BALANCER',
}

@Injectable({
  providedIn: 'root',
})
export class NetworksService {
  constructor(private _http: HttpClient, private _apiService: ApiService) {}

  private networkChangedBS: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  networkChanged$ = this.networkChangedBS.asObservable();

  networkChangedIdBS: BehaviorSubject<string> = new BehaviorSubject<string>('');
  networkChangedId$ = this.networkChangedIdBS.asObservable();

  getNetworks(): Observable<any[]> {
    return this._http.get<any>(`${this.getBaseUrl()}/?flatten=false`).pipe(
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      })
    );
  }

  deleteNetwork(network: INetwork): Observable<any> {
    if (network.connectionType === EConnectionType.LOAD_BALANCER) {
      return this.deleteLoadBalancerNetwork(network);
    } else {
      let url;
      if (network.version == 'PREVIOUS') {
        url = this.getBaseUrl() + '/' + network.id + '/previous';
      } else if (network.version == 'PENDING') {
        url = this.getBaseUrl() + '/' + network.id + '/pending';
      } else {
        url = this.getBaseUrl() + '/' + network.id;
      }
      return this._http.delete<any>(url).pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
    }
  }

  private deleteLoadBalancerNetwork(network: INetwork): Observable<any> {
    const url = this.getConnectionTypeUrl(network);
    const payload = {
      querygridLoadbalancerId: network.id,
    };
    return this._http.delete<any>(url, { body: payload }).pipe(
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      })
    );
  }

  getNetwork(
    id: string,
    version: string,
    createPending: boolean
  ): Observable<any> {
    let url = `${this.getBaseUrl()}/${id}/`;
    if (version == NetworkVersion.ACTIVE || createPending) {
      url = url.concat(NetworkVersion.ACTIVE.toLowerCase());
    } else if (version == NetworkVersion.PENDING) {
      url = url.concat(NetworkVersion.PENDING.toLowerCase());
    } else if (version == NetworkVersion.PREVIOUS) {
      url = url.concat(NetworkVersion.PREVIOUS.toLocaleLowerCase());
    }
    return this._http.get<any>(url).pipe(
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      })
    );
  }

  activatePreviousPending(network: INetwork): Observable<any> {
    return this._http
      .patch<any>(
        `${this.getBaseUrl()}/${network.id}/active`,
        network.versionId
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  updateNetwork(
    id: string,
    version: string,
    payload: INetwork
  ): Observable<any> {
    let url = `${this.getConnectionTypeUrl(payload)}`;
    if (!this.isLoadBalancerType(payload)) {
      url =
        version == NetworkVersion.ACTIVE
          ? url + `/${id}/active`
          : url + `/${id}/pending`;
    }
    return this._http.put<any>(url, this.translateNetwork(id, payload)).pipe(
      map((resp: any) => {
        this.networkChangedBS.next(true);
        return resp;
      }),
      catchError((error: HttpErrorResponse) => {
        throw Object.assign({}, error.error, { httpStatus: error.status });
      })
    );
  }

  createNetwork(network: INetwork): Observable<any> {
    return this._http
      .post(
        `${this.getConnectionTypeUrl(network)}`,
        this.translateNetwork(null, network)
      )
      .pipe(
        map((resp: any) => {
          this.networkChangedBS.next(true);
          this.networkChangedIdBS.next(resp.id);
          return resp;
        }),
        catchError((error: HttpErrorResponse) => {
          throw Object.assign({}, error.error, { httpStatus: error.status });
        })
      );
  }

  getBaseUrl(): string {
    return this._apiService.getBaseUrl() + `/config/networks`;
  }

  getConnectionTypeUrl(network: INetwork): string {
    if (this.isLoadBalancerType(network)) {
      return this._apiService.getBaseUrlProvision() + `/loadbalancer`;
    } else {
      return this.getBaseUrl();
    }
  }

  isLoadBalancerType(network: INetwork): boolean {
    return network.connectionType === EConnectionType.LOAD_BALANCER;
  }

  translateNetwork(id: any, network: INetwork): any {
    if (this.isLoadBalancerType(network)) {
      return {
        querygridLoadbalancerId: id ?? network.id,
        querygridLoadbalancerName: network.name,
        querygridLoadbalancerDescription: network.description,
        querygridLoadbalancerAddress: network.loadBalancerAddress,
      } as INetworkLoadBalancer;
    }
    return network;
  }
}
