import { Component, Input } from '@angular/core';
import { NavItem, NavItems } from '../utils/nav-rail-data';
import { NavService } from '../utils/nav.service';
import { IOrganizationConsumptionMini } from '@janus/organizations';

@Component({
  selector: 'shared-nav-content-collapsed',
  templateUrl: './nav-content-collapsed.component.html',
  styleUrls: ['./nav-content-collapsed.component.scss'],
})
export class NavContentCollapsedComponent {
  @Input() navItems: NavItems;
  @Input() isTrials: boolean;
  @Input() consumption: IOrganizationConsumptionMini;
  isOpen = false;

  constructor(private _navService: NavService) {}

  getIcon(navItem: NavItem, hoveredState = false): string {
    if (navItem.collapsed) {
      if (hoveredState) {
        if (!navItem.collapsed.hovered.svgIcon) {
          return navItem.collapsed.hovered.icon;
        }
      } else {
        if (!navItem.collapsed.svgIcon) {
          return navItem.collapsed.icon;
        }
      }
    } else {
      if (!navItem.svgIcon) {
        return navItem.icon;
      }
    }
    return '';
  }

  getIconClasses(navItem: NavItem, hoveredState = false): string {
    if (navItem.collapsed) {
      if (hoveredState) {
        return navItem.collapsed.hovered.iconClasses;
      } else {
        return navItem.collapsed.iconClasses;
      }
    }

    return navItem.iconClasses;
  }

  getSvgIcon(navItem: NavItem, hoveredState = false): string {
    if (navItem.collapsed) {
      if (hoveredState) {
        if (navItem.collapsed.hovered.svgIcon) {
          return navItem.collapsed.hovered.icon;
        }
      } else {
        if (navItem.collapsed.svgIcon) {
          return navItem.collapsed.icon;
        }
      }
    } else {
      if (navItem.svgIcon) {
        return navItem.icon;
      }
    }

    return null;
  }

  navigate(navItem: NavItem): void {
    this._navService.navigate(navItem);
  }

  isActiveRoute(navItem: NavItem): string {
    return this._navService.isActiveRoute(navItem);
  }

  isActiveRouteInChildren(navItem: NavItem): string {
    return this._navService.isActiveRouteInChildren(navItem);
  }

  showInfo(): void {
    this.isOpen = true;
  }

  hideInfo(): void {
    this.isOpen = false;
  }
}
